import type { LdbAPIAction } from './LdbActionTypes';

export const getAccounts: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Returns all accesible LfxAccount',
  method: 'patch',
  collectionPath: '/account',
  path: '/account',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'List of LfxAccount' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {},
  implementedByFrontEnd: true,
};
export const downloadAccounts: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Generates a CSV of accounts available to the logged in user',
  method: 'put',
  collectionPath: '/account/download',
  path: '/account/download',
  tags: ['account'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    202: {
      description: 'In Progress',
      message:
        'Your report is being generated and will be emailed to you when it is complete. Note that this report will be in .csv format.',
      header: 'Report in Progress',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {},
  implementedByFrontEnd: true,
  reportAction: true,
};

export const bulkEnableIntermediaries: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Bulk enables all Not Migrated intermediaries',
  method: 'put',
  collectionPath: '/account',
  path: '/account/bulkEnableIntermediaries',
  tags: ['account'],
  headers: {},
  mfa: true,
  responses: {
    200: {
      description: 'LfxAccount',
      message: 'Bulk Enable In Progress. You will be emailed when it is compelte.',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    includeBulkEnableBranch: { type: 'boolean' },
    includeBulkEnableUser: { type: 'boolean' },
    cascadeBulkEnableUser: { type: 'boolean' },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};
export const bulkEnableIntermediaryReports: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseInterClientReports', 'releaseInterDealReports'],
  description: 'Bulk enables or disables ODS reports for intermediaries',
  method: 'put',
  collectionPath: '/account/{accountId}/bulkEnableIntermediaryReports',
  path: '/account/bulkEnableIntermediaryReports',
  tags: ['account'],
  headers: {},
  responses: {
    200: {
      description: 'LfxAccount',
      message: 'Bulk Report Enable In Progress. You will be emailed when it is compelte.',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { intermedariesConfig: { type: 'object', required: true } },
  implementedByFrontEnd: true,
};
export const accountDownloadDocuments: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of downloadable documents for an account',
  method: 'patch',
  collectionPath: '/account/accountDownloadDocuments',
  path: '/account/accountDownloadDocuments',
  tags: ['account'],
  headers: {},
  responses: {
    200: {
      description: 'Array of Downloadable Documents',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { accountUuid: { type: 'string', required: true } },
  implementedByFrontEnd: true,
};

export const editAccount: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageIntermediary', 'manageBranch', 'manageClient'],
  description: 'Edits an existing account',
  method: 'post',
  collectionPath: '/account',
  path: '/account/{accountId}',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'lfxAccount', message: 'Account successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    name: { type: 'string' },
    cifNumber: { type: 'string' },
    accountLevel: { type: 'string' },
    commissionAccount: { type: 'string' },
    organisation: { type: 'string' },
    bpNumber: { type: 'string' },
    mayCompleteOwnKyc: { type: 'boolean' },
    mayProcessBulkFees: { type: 'boolean' },
    allowClientsToSubmitPortfolios: { type: 'boolean' },
    bankDirectClients: { type: 'boolean' },
    allowOnboardingWithIncompleteData: { type: 'boolean' },
    enforceSimplifiedTrading: { type: 'string' },
    migrationStatus: { type: 'string' },
    clientReportEnable: { type: 'boolean' },
    accountBalanceReportEnable: { type: 'boolean' },
    dailyDealReportEnable: { type: 'boolean' },
    intermediaryHasOwnSignUpForm: { type: 'boolean' },
    intermediaryCanSeeStampedStatements: { type: 'boolean' },
    intermediaryCanSeeAccountConfirmations: { type: 'boolean' },
    intermediaryMayTakeMarkupsOnFecMod: { type: 'boolean' },
    externalReference: { type: 'string' },
    bopFormSignatoryOption: { type: 'string' },
    signatoryOrder: { type: 'array', items: { type: 'number' } },
    openDealLimit: { type: 'number' },
    mayBookFec: { type: 'boolean' },
    fecDocumentsInOrder: { type: 'boolean' },
    fecDisabledNote: { type: 'string' },
    note: { type: 'string' },
  },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getAccount: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Gets an LfxAccount by ID',
  method: 'get',
  collectionPath: '/account',
  path: '/account/{accountId}',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'List of lfxAccount' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const createIntermediary: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageIntermediary'],
  description: 'Creates a new intermediary',
  method: 'post',
  collectionPath: '/account/{accountId}/intermediary',
  path: '/account/{accountId}/intermediary',
  tags: ['account'],
  headers: {},
  responses: {
    200: {
      description: 'List of lfxAccount',
      message: 'Intermediary successfully created',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    name: { type: 'string' },
    cifNumber: { type: 'string' },
    commissionAccount: { type: 'string', required: true },
    organisation: { type: 'string', required: true },
    bpNumber: { type: 'string', required: true },
    mayCompleteOwnKyc: { type: 'boolean' },
    externalReference: { type: 'string' },
    bopFormSignatoryOption: { type: 'string' },
    bankDirectClients: { type: 'boolean' },
    allowOnboardingWithIncompleteData: { type: 'boolean' },
    enforceSimplifiedTrading: { type: 'string' },
  },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const createBranch: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageBranch'],
  description: 'Creates a new branch',
  method: 'post',
  collectionPath: '/account/{accountId}/branch',
  path: '/account/{accountId}/branch',
  tags: ['account'],
  headers: {},
  responses: {
    200: {
      description: 'List of lfxAccount',
      message: 'Branch successfully created',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    name: { type: 'string' },
    externalReference: { type: 'string' },
    bopFormSignatoryOption: { type: 'string' },
  },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const moveAccount: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['moveAccount'],
  description: 'Changes the parent of an account',
  method: 'put',
  collectionPath: '/account/{accountId}/moveAccount',
  path: '/account/{accountId}/moveAccount',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'List of lfxAccount', message: 'Account successfully moved', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { accountId: { type: 'integer' } },
  pathParams: { accountId: { type: 'integer', required: true } },
  // implementedByFrontEnd: false,
};
export const moveClient: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageClient'],
  description: 'Changes the parent of a client',
  method: 'put',
  collectionPath: '/account/{accountId}/moveClient',
  path: '/account/{accountId}/moveClient',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'lfxAccount', message: 'Client successfully moved', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { accountId: { type: 'integer' } },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const moveBranch: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageBranch'],
  description: 'Changes the parent of an branch',
  method: 'put',
  collectionPath: '/account/{accountId}/moveBranch',
  path: '/account/{accountId}/moveBranch',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'lfxAccount', message: 'Branch successfully moved', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { accountId: { type: 'integer' } },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const createUser: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser'],
  mfa: true,
  description: 'Creates a new user under an account',
  method: 'post',
  collectionPath: '/account/{accountId}/user',
  path: '/account/{accountId}/user',
  tags: ['account'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxUser', message: 'User successfully created', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    firstName: { type: 'string', required: true },
    surname: { type: 'string', required: true },
    preferredName: { type: 'string' },
    middleNames: { type: 'string' },
    emailAddress: { type: 'string', required: true },
    msisdn: { type: 'string', required: true },
    idNumber: { type: 'string', required: true },
    systemUser: { type: 'boolean' },
  },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getAccountUsers: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser', 'releasePermission'],
  description: 'Gets all users for an account',
  method: 'patch',
  collectionPath: '/account/{accountId}/user',
  path: '/account/{accountId}/user',
  tags: ['account'],
  responses: {
    200: { description: 'List of LfxUser' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { accountId: { type: 'integer', required: true } },
};

export const bulkEnableUsers: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Bulk Enables Users of an account',
  method: 'put',
  collectionPath: '/account/{accountId}/bulkEnableUsers',
  path: '/account/{accountId}/bulkEnableUsers',
  tags: ['account'],
  headers: {},
  responses: {
    200: {
      description: 'LfxAccount',
      message: 'Bulk Enable In Progress. You will be emailed when it is compelte.',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { cascadeToBranches: { type: 'boolean', required: true } },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getDBIntermediarySettings: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Get LfxIntermediarySettings item for the account',
  method: 'get',
  collectionPath: '/account/{accountId}/getDBIntermediarySettings',
  path: '/account/{accountId}/getDBIntermediarySettings',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxIntermediarySettings' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { accountId: { type: 'integer', required: true } },
};
export const intermediaryCommissionAccountStatement: LdbAPIAction = {
  accountLevels: ['intermediary'],
  permissions: ['viewCommissionAccountBalance'],
  description: 'Retrieves a statement for the intermediarys own commission account',
  method: 'put',
  collectionPath: '/account/{accountId}/intermediaryCommissionAccountStatement',
  path: '/account/{accountId}/intermediaryCommissionAccountStatement',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'Account Statement Results' },
    400: { description: 'Account Statement Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    fromDate: { type: 'string', format: 'date', required: true },
    toDate: { type: 'string', format: 'date', required: true },
    reportType: { type: 'string', required: true },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
  reportAction: true,
};

export const getAccountAllSettings: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Get all account settings from Dynamo',
  method: 'get',
  collectionPath: '/account/{accountId}/allAccountSettings',
  path: '/account/{accountId}/allAccountSettings',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'Settings Object' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { accountId: { type: 'integer', required: true } },
};

export const getAccountGeneralSettings: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Get account settings from Dynamo',
  method: 'get',
  collectionPath: '/account/{accountId}/generalSettings',
  path: '/account/{accountId}/generalSettings',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'Settings Object' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { accountId: { type: 'integer', required: true } },
};

export const getAccountIntermediarySettings: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: [],
  description: 'Get account settings from Dynamo',
  method: 'get',
  collectionPath: '/account/{accountId}/intermediarySettings',
  path: '/account/{accountId}/intermediarySettings',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'Settings Object' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { accountId: { type: 'integer', required: true } },
};

export const getAccountClientSettings: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: [],
  description: 'Get account settings from Dynamo',
  method: 'get',
  collectionPath: '/account/{accountId}/clientSettings',
  path: '/account/{accountId}/clientSettings',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'Settings Object' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { accountId: { type: 'integer', required: true } },
};
export const updateAccountGeneralSettings: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageIntermediary', 'manageBranch', 'manageClient'],
  description: 'Updates account settings in Dynamo',
  method: 'put',
  collectionPath: '/account/{accountId}/generalSettings',
  path: '/account/{accountId}/generalSettings',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'List of lfxAccount' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { newSettings: { type: 'object' } },
  pathParams: { accountId: { type: 'integer', required: true } },
};

export const updateAccountIntermediarySettings: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageIntermediary'],
  description: 'Updates account settings in Dynamo',
  method: 'put',
  collectionPath: '/account/{accountId}/intermediarySettings',
  path: '/account/{accountId}/intermediarySettings',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'List of lfxAccount' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { newSettings: { type: 'object' } },
  pathParams: { accountId: { type: 'integer', required: true } },
};

export const updateAccountClientSettings: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageClient'],
  description: 'Updates account settings in Dynamo',
  method: 'put',
  collectionPath: '/account/{accountId}/clientSettings',
  path: '/account/{accountId}/clientSettings',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'List of lfxAccount' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { newSettings: { type: 'object' } },
  pathParams: { accountId: { type: 'integer', required: true } },
};

export const createAccountMarkup: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageMarkups'],
  description: 'Creates a new lfxAccountMarkup under an account',
  method: 'post',
  collectionPath: '/account/{accountId}/markup',
  path: '/account/{accountId}/markup',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'lfxAccountMarkup', message: 'Markup successfully created', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    currencyPair: { type: 'string', required: true },
    markupType: { type: 'string', required: true },
    pips: { type: 'number' },
    percentage: { type: 'number' },
  },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getAccountMarkups: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: [],
  description: 'Returns all lfxAccountMarkup for an account',
  method: 'patch',
  collectionPath: '/account/{accountId}/markup',
  path: '/account/{accountId}/markup',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'List of lfxAccountMarkup' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const getAccountMarkupLogs: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: [],
  description: 'Returns all lfxAccountMarkupLogs for an account',
  method: 'patch',
  collectionPath: '/account/{accountId}/markupLogs',
  path: '/account/{accountId}/markupLogs',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'List of lfxAccountMarkupLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getAccountParentMarkup: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Returns all parent account with their lfxAccountMarkups',
  method: 'put',
  collectionPath: '/account/{accountId}/parentMarkups',
  path: '/account/{accountId}/parentMarkups',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'List of LfxAccount with lfxAccountMarkups' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const editAccountMarkups: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageMarkups'],
  description: 'Edits an existing lfxAccountMarkup',
  method: 'post',
  collectionPath: '/account/{accountId}/markup',
  path: '/account/{accountId}/markup/{markupId}',
  tags: ['account'],
  headers: {},
  responses: {
    200: {
      description: 'List of lfxAccount',
      message: 'Markup successfully updated',
      header: 'Success',
    },
    202: {
      description: 'In Progress',
      message: 'Update to Account Maurkup has Been Requested',
      header: 'Update Requested',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    markupType: { type: 'string', required: true },
    pips: { type: 'number', required: true },
    percentage: { type: 'number', required: true },
    discountedBankMarkup: { type: 'number' },
    maximumIntermediaryMarkup: { type: 'number' },
    securityToken: { type: 'string' }, // Required for Checker Leg of Maker Checker
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    markupId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const editAccountFecMarkups: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageMarkups'],
  description: 'Edits the additional FEC Markup for an account',
  method: 'put',
  collectionPath: '/account/{accountId}/fecMarkup',
  path: '/account/{accountId}/fecMarkup',
  tags: ['account'],
  headers: {},
  responses: {
    200: {
      description: 'FEC Markup',
      message: 'Markup successfully updated',
      header: 'Success',
    },
    202: {
      description: 'In Progress',
      message: 'Update to Account FEC Maurkup has Been Requested',
      header: 'Update Requested',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    fecMarkupsRecord: { type: 'object' },
    securityToken: { type: 'string' }, // Required for Checker Leg of Maker Checker
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getAccountMarkup: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: [],
  description: 'Gets an lfxAccountMarkup by ID',
  method: 'get',
  collectionPath: '/account/{accountId}/markup',
  path: '/account/{accountId}/markup/{markupId}',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'lfxAccountMarkup' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    markupId: { type: 'integer', required: true },
  },
};

export const hideAccountMarkup: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageMarkups'],
  description: 'Marks a lfxAccountMarkup as hidden',
  method: 'put',
  collectionPath: '/account/{accountId}/markup/{markupId}/hide',
  path: '/account/{accountId}/markup/{markupId}/hide',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'lfxAccountMarkup', message: 'Markup successfully hidden', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    markupId: { type: 'integer', required: true },
  },
};
export const showAccountMarkup: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageMarkups'],
  description: 'Marks a lfxAccountMarkup as visible',
  method: 'put',
  collectionPath: '/account/{accountId}/markup/{markupId}/show',
  path: '/account/{accountId}/markup/{markupId}/show',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'lfxAccountMarkup', message: 'Markup successfully unhidden', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    markupId: { type: 'integer', required: true },
  },
};

export const createSignatory: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client', 'bank'],
  permissions: ['manageSignatory'],
  mfa: true,
  description: 'Creates a new LfxSignatory under an account',
  method: 'post',
  collectionPath: '/account/{accountId}/signatory',
  path: '/account/{accountId}/signatory',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory', message: 'Signatory successfully created', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    isGroupMailBox: { type: 'boolean' },
    firstName: { type: 'string' },
    middleName: { type: 'string' },
    surname: { type: 'string' },
    idNumber: { type: 'string' },
    capacity: { type: 'string' },
    msisdn: { type: 'string' },
    emailAddress: { type: 'string' },
    accountOpeningSignatory: { type: 'boolean' },
    paymentSignatory: { type: 'boolean' },
    signerLevel: { type: 'string' },
    signerRequired: { type: 'string' },
    receivesDealRelease: { type: 'boolean' },
    receivesPaymentConfirmations: { type: 'boolean' },
    receivesPaymentRejections: { type: 'boolean' },
    isDirector: { type: 'boolean' },
    isSigningDirector: { type: 'boolean' },
  },
  pathParams: { accountId: { type: 'integer', required: true } },
};

export const getAccountSignatories: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Returns all LfxSignatory for an account',
  method: 'patch',
  collectionPath: '/account/{accountId}/signatory',
  path: '/account/{accountId}/signatory',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'List of LfxSignatory' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { accountId: { type: 'integer', required: true } },
};
export const getAccountSignatoriesLogs: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Returns all LfxSignatoryLogs for an account',
  method: 'patch',
  collectionPath: '/account/{accountId}/signatoryLog',
  path: '/account/{accountId}/signatoryLog',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'List of LfxSignatoryLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { accountId: { type: 'integer', required: true } },
};
export const editSignatory: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client', 'bank'],
  permissions: ['manageSignatory'],
  mfa: true,
  description: 'Edits a new LfxSignatory by ID',
  method: 'post',
  collectionPath: '/account/{accountId}/signatory',
  path: '/account/{accountId}/signatory/{signatoryId}',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory', message: 'Signatory successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    isGroupMailBox: { type: 'boolean' },
    firstName: { type: 'string' },
    middleName: { type: 'string' },
    surname: { type: 'string' },
    idNumber: { type: 'string' },
    capacity: { type: 'string' },
    msisdn: { type: 'string' },
    emailAddress: { type: 'string' },
    accountOpeningSignatory: { type: 'boolean' },
    paymentSignatory: { type: 'boolean' },
    signerLevel: { type: 'string' },
    signerRequired: { type: 'string' },
    receivesDealRelease: { type: 'boolean' },
    receivesPaymentConfirmations: { type: 'boolean' },
    receivesPaymentRejections: { type: 'boolean' },
    isDirector: { type: 'boolean' },
    isSigningDirector: { type: 'boolean' },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
  },
};
export const setSignatoryToContact: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client', 'bank'],
  permissions: ['manageSignatory'],
  mfa: true,
  description: 'Turns a Signatory into a contact',
  method: 'put',
  collectionPath: '/account/{accountId}/signatory',
  path: '/account/{accountId}/signatory/{signatoryId}/setToContact',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory', message: 'Signatory successfully changed to Contact', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
  },
};
export const setContactToSignatory: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client', 'bank'],
  permissions: ['manageSignatory'],
  mfa: true,
  description: 'Turns a Contact into a Signatory',
  method: 'put',
  collectionPath: '/account/{accountId}/signatory',
  path: '/account/{accountId}/signatory/{signatoryId}/setToSignatory',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory', message: 'Contact successfully changed to Signatory', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
  },
};
export const setSignatoryAsFecReviewSignatory: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageClient'],
  mfa: true,
  description: 'Sets the signatory to the FEC Review Signatory',
  method: 'put',
  collectionPath: '/account/{accountId}/signatory',
  path: '/account/{accountId}/signatory/{signatoryId}/setSignatoryAsFecReviewSignatory',
  tags: ['account'],
  headers: {},
  responses: {
    200: {
      description: 'LfxSignatory',
      message: 'Signatory successfully set as FEC Review Signatory',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
  },
};

export const getAccountSignatory: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets an LfxSignatory by ID',
  method: 'get',
  collectionPath: '/account/{accountId}/signatory',
  path: '/account/{accountId}/signatory/{signatoryId}',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
  },
};
export const getAccountSignatoryLogs: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets an LfxSignatoryLogs by Signatory ID',
  method: 'patch',
  collectionPath: '/account/{accountId}/signatory/log',
  path: '/account/{accountId}/signatory/{signatoryId}/log',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
  },
};
export const getSignatorySignatoryRequests: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Returns all LfxSignatoryRequest for a Signatory',
  method: 'patch',
  collectionPath: '/account/{accountId}/signatory/signatoryRequest',
  path: '/account/{accountId}/signatory/{signatoryId}/signatoryRequest',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {
    accountId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
  },
};
export const getSignatoryRequest: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Retrieves a Signatory Request by ID',
  method: 'get',
  collectionPath: '/account/{accountId}/signatory/{signatoryId}/signatoryRequest/',
  path: '/account/{accountId}/signatory/{signatoryId}/signatoryRequest/{signatoryRequestId}',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory', message: 'Signatory Request', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
    signatoryRequestId: { type: 'integer', required: true },
  },
};
export const releaseSignatoryRequest: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['reviewSignatory'],
  description: 'Releases a Signatory Request',
  method: 'put',
  collectionPath: '/account/{accountId}/signatory/{signatoryId}/signatoryRequest/',
  path: '/account/{accountId}/signatory/{signatoryId}/signatoryRequest/{signatoryRequestId}/release',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory', message: 'Signatory Request successfully Released', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
    signatoryRequestId: { type: 'integer', required: true },
  },
};
export const rejectSignatoryRequest: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['reviewSignatory'],
  description: 'Rejects a Signatory Request',
  method: 'put',
  collectionPath: '/account/{accountId}/signatory/{signatoryId}/signatoryRequest/',
  path: '/account/{accountId}/signatory/{signatoryId}/signatoryRequest/{signatoryRequestId}/reject',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory', message: 'Signatory Request successfully Released', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { },
  pathParams: {
    accountId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
    signatoryRequestId: { type: 'integer', required: true },
  },
};

export const getIndividualSignatory: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets an LfxSignatory for an individual',
  method: 'get',
  collectionPath: '/account/{accountId}/individualSignatory',
  path: '/account/{accountId}/individualSignatory',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
};

export const enableSignatory: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client', 'bank'],
  permissions: ['manageSignatory'],
  mfa: true,
  description: 'Enables a signatory',
  method: 'put',
  collectionPath: '/account/{accountId}/signatory/{signatoryId}/enable',
  path: '/account/{accountId}/signatory/{signatoryId}/enable',
  tags: ['account'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxSignatory', message: 'Signatory successfully enabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
  },
};

export const disableSignatory: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client', 'bank'],
  permissions: ['manageSignatory'],
  mfa: true,
  description: 'Disables a signatory',
  method: 'put',
  collectionPath: '/account/{accountId}/signatory/{signatoryId}/disable',
  path: '/account/{accountId}/signatory/{signatoryId}/disable',
  tags: ['account'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxSignatory', message: 'Signatory successfully disabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    signatoryId: { type: 'integer', required: true },
  },
};

export const enableIndividualSignatory: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['manageSignatory'],
  mfa: true,
  description: 'Enables a signatory for an individual client',
  method: 'put',
  collectionPath: '/account/{accountId}/individualSignatoryEnable',
  path: '/account/{accountId}/individualSignatoryEnable',
  tags: ['account'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxSignatory', message: 'Signatory successfully enabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    token: { type: 'string', required: true },
  },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const enableEntityMainContact: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['manageSignatory'],
  mfa: true,
  description: 'Enables a main contact for an entity client',
  method: 'put',
  collectionPath: '/account/{accountId}/entityMainContactEnable',
  path: '/account/{accountId}/entityMainContactEnable',
  tags: ['account'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxSignatory', message: 'Contact successfully enabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    token: { type: 'string', required: true },
  },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const updateIndividualSignatory: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['manageSignatory'],
  mfa: true,
  description: 'Updates a signatory for an individual client from Bancs',
  method: 'put',
  collectionPath: '/account/{accountId}/signatory/{signatoryId}/individualSignatoryUpdate',
  path: '/account/{accountId}/signatory/{signatoryId}/individualSignatoryUpdate',
  tags: ['account'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxSignatory', message: 'Signatory successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    token: { type: 'string', required: true },
  },
  pathParams: { accountId: { type: 'integer', required: true }, signatoryId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const updateEntityMainContact: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch'],
  permissions: ['manageSignatory'],
  mfa: true,
  description: 'Updates the main contact for an entity client from Bancs',
  method: 'put',
  collectionPath: '/account/{accountId}/signatory/{signatoryId}/entityMainContactUpdate',
  path: '/account/{accountId}/signatory/{signatoryId}/entityMainContactUpdate',
  tags: ['account'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxSignatory', message: 'Signatory successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    token: { type: 'string', required: true },
  },
  pathParams: { accountId: { type: 'integer', required: true }, signatoryId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const getAccountParentSignatories: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: [],
  description: 'Returns all parent account with their lfxSignatory',
  method: 'put',
  collectionPath: '/account/{accountId}/parentSignatories',
  path: '/account/{accountId}/parentSignatories',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'List of LfxAccount with lfxSignatory' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const hideAccount: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageIntermediary', 'manageBranch', 'manageClient'],
  description: 'Sets the Visibility field of an account to Hidden',
  method: 'put',
  collectionPath: '/account/{accountId}/hide',
  path: '/account/{accountId}/hide',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxAccount', message: 'Account successfully hidden', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const unhideAccount: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageIntermediary', 'manageBranch', 'manageClient'],
  description: 'Sets the Visibility field of an account to Vissible',
  method: 'put',
  collectionPath: '/account/{accountId}/unhide',
  path: '/account/{accountId}/unhide',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxAccount', message: 'Account successfully unhidden', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const enableIntermediary: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageIntermediary'],
  description: 'Enables an Intermediary and sets their Account May Trade to true',
  method: 'put',
  collectionPath: '/account/{accountId}/enableIntermediary',
  path: '/account/{accountId}/enableIntermediary',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxAccount', message: 'Intermediary successfully enabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const disableIntermediary: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageIntermediary'],
  description: 'Disables an Intermediary and sets their Account May Trade to false',
  method: 'put',
  collectionPath: '/account/{accountId}/disableIntermediary',
  path: '/account/{accountId}/disableIntermediary',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxAccount', message: 'Intermediary successfully disabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const disableIntermediaryTrading: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageIntermediary'],
  description: 'Sets their Account May Trade to false for an intermediary',
  method: 'put',
  collectionPath: '/account/{accountId}/disableIntermediaryTrading',
  path: '/account/{accountId}/disableIntermediaryTrading',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxAccount', message: 'Intermediary trading successfully disabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const enableBranch: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageBranch'],
  description: 'Enables a Branch and sets their Account May Trade to true',
  method: 'put',
  collectionPath: '/account/{accountId}/enableBranch',
  path: '/account/{accountId}/enableBranch',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxAccount', message: 'Branch successfully enabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const bulkEnableBranch: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Bulk Enables Branches of an account and sets their Account May Trade to true',
  method: 'put',
  collectionPath: '/account/{accountId}/bulkEnableBranch',
  path: '/account/{accountId}/bulkEnableBranch',
  tags: ['account'],
  headers: {},
  responses: {
    200: {
      description: 'LfxAccount',
      message: 'Bulk Enable In Progress. You will be emailed when it is compelte.',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const disableBranch: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageBranch'],
  description: 'Disables an Branch and sets their Account May Trade to false',
  method: 'put',
  collectionPath: '/account/{accountId}/disableBranch',
  path: '/account/{accountId}/disableBranch',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxAccount', message: 'Branch successfully disabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const disableBranchTrading: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageBranch'],
  description: 'Sets the Account May Trade to false for a branch',
  method: 'put',
  collectionPath: '/account/{accountId}/disableBranchTrading',
  path: '/account/{accountId}/disableBranchTrading',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxAccount', message: 'Branch trading successfully disabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const enableClientTrading: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageClient'],
  description: 'Sets the Account May Trade to true for a client',
  method: 'put',
  collectionPath: '/account/{accountId}/enableClientTrading',
  path: '/account/{accountId}/enableClientTrading',
  tags: ['client'],
  headers: {},
  responses: {
    200: { description: 'LfxAccount', message: 'Client trading successfully enabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const disableClientTrading: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageClient'],
  description: 'Sets the Account May Trade to false for a client',
  method: 'put',
  collectionPath: '/account/{accountId}/disableClientTrading',
  path: '/account/{accountId}/disableClientTrading',
  tags: ['client'],
  headers: {},
  responses: {
    200: { description: 'LfxAccount', message: 'Client trading successfully disabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { tradingDisabledReason: { type: 'string', required: true } },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getAccountBulkFeeBatches: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary'],
  permissions: [],
  description: 'Gets bulk fees batches for an account',
  method: 'patch',
  collectionPath: '/account/{accountId}/bulkFeeBatch',
  path: '/account/{accountId}/bulkFeeBatch',
  tags: ['account'],
  responses: {
    200: { description: 'List of LfxBulkFeeBatch' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const getAccountBulkFeeBatch: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary'],
  permissions: [],
  description: 'Gets a Bulk Fee Batch by ID',
  method: 'get',
  collectionPath: '/user',
  path: '/account/{accountId}/bulkFeeBatch/{bulkFeeBatchId}',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'LfxBulkFeeBatch' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true }, bulkFeeBatchId: { type: 'integer', required: true } },
};
export const downloadBulkFeeBatchSource: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary'],
  permissions: [],
  description: 'Downloads the source file for a Bulk Fee Batch',
  method: 'put',
  collectionPath: '/account/{accountId}/bulkFeeBatch/{bulkFeeBatchId}/downloadSource',
  path: '/account/{accountId}/bulkFeeBatch/{bulkFeeBatchId}/downloadSource',
  tags: ['account'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true }, bulkFeeBatchId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
  reportAction: true,
};
export const downloadBulkFeeBatchResult: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary'],
  permissions: [],
  description: 'Downloads the result file for a Bulk Fee Batch',
  method: 'put',
  collectionPath: '/account/{accountId}/bulkFeeBatch/{bulkFeeBatchId}/downloadResult',
  path: '/account/{accountId}/bulkFeeBatch/{bulkFeeBatchId}/downloadResult',
  tags: ['account'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true }, bulkFeeBatchId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
  reportAction: true,
};

export const createBeneficiary: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageBeneficiary'],
  mfa: true,
  description: 'Creats a beneficiary for a given account',
  method: 'post',
  collectionPath: '/account/{accountId}/beneficiary',
  path: '/account/{accountId}/beneficiary',
  tags: ['beneficiary'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: {
      description: 'LfxBeneficiary',
      message: 'Beneficiary successfully created',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    beneficiaryType: { type: 'string', required: true },
    name: { type: 'string', required: true },
    individualFirstname: { type: 'string' },
    individualGender: { type: 'string' },
    rsaResident: { type: 'string' },
    passportNumber: { type: 'string' },
    passportCountryId: { type: 'string' },
    address: { type: 'string', required: true },
    city: { type: 'string', required: true },
    province: { type: 'string' },
    postalCode: { type: 'string', required: true },
    countryId: { type: 'string', required: true },
    externalReference: { type: 'string' },
    notes: { type: 'string' },
  },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getBeneficiaries: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Gets all beneficiaries for a given account',
  method: 'patch',
  collectionPath: '/account/{accountId}/beneficiary',
  path: '/account/{accountId}/beneficiary',
  tags: ['beneficiary'],
  headers: {},
  responses: {
    200: { description: 'List of lfxBeneficiary' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const bulkDisableBeneficiary: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageBeneficiary'],
  mfa: true,
  description: 'Bulk Disable all beneficaries for an account',
  method: 'put',
  collectionPath: '/account/{accountId}/beneficiaryBulkDisable',
  path: '/account/{accountId}/beneficiaryBulkDisable',
  tags: ['beneficiary'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: {
      description: 'Okay',
      message: 'Beneficiaries successfully disabled',
      header: 'Success',
    },
    400: { description: 'Beneficiary Disable Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const updateBeneficiary: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageBeneficiary'],
  description: 'Updates a beneficiary',
  method: 'post',
  mfa: true,
  collectionPath: '/account/{accountId}/beneficiary',
  path: '/account/{accountId}/beneficiary/{beneficiaryId}',
  tags: ['beneficiary'],
  headers: {},
  responses: {
    200: {
      description: 'LfxBeneficiary',
      message: 'Beneficiary successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    beneficiaryType: { type: 'string', required: true },
    name: { type: 'string', required: true },
    individualFirstname: { type: 'string' },
    individualGender: { type: 'string' },
    rsaResident: { type: 'string' },
    passportNumber: { type: 'string' },
    passportCountryId: { type: 'string' },
    address: { type: 'string', required: true },
    city: { type: 'string', required: true },
    province: { type: 'string' },
    postalCode: { type: 'string', required: true },
    countryId: { type: 'string', required: true },
    externalReference: { type: 'string' },
    notes: { type: 'string' },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    beneficiaryId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getBeneficiary: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Gets a Beneficiary by ID',
  method: 'get',
  collectionPath: '/account/{accountId}/beneficiary',
  path: '/account/{accountId}/beneficiary/{beneficiaryId}',
  tags: ['beneficiary'],
  headers: {},
  responses: {
    200: { description: 'lfxBeneficiary' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    beneficiaryId: { type: 'integer', required: true },
  },
};
export const getBeneficiaryLogs: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Gets logs for a Beneficiary by ID',
  method: 'patch',
  collectionPath: '/account/{accountId}/beneficiary/log',
  path: '/account/{accountId}/beneficiary/{beneficiaryId}/log',
  tags: ['beneficiary'],
  headers: {},
  responses: {
    200: { description: 'List of lfxBeneficiaryLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    beneficiaryId: { type: 'integer', required: true },
  },
};

export const enableBeneficiary: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageBeneficiary'],
  mfa: true,
  description: 'Enables a beneficary',
  method: 'put',
  collectionPath: '/account/{accountId}/beneficiary/{beneficiaryId}/enable',
  path: '/account/{accountId}/beneficiary/{beneficiaryId}/enable',
  tags: ['beneficiary'],
  headers: {
    mfaToken: {
      type: 'string',
      required: true,
    },
  },
  responses: {
    200: {
      description: 'LfxBeneficiary',
      message: 'Beneficiary successfully enabled',
      header: 'Success',
    },
    400: { description: 'Beneficiary Enable Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    beneficiaryId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const disableBeneficiary: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageBeneficiary'],
  mfa: true,
  description: 'Disables a beneficary',
  method: 'put',
  collectionPath: '/account/{accountId}/beneficiary/{beneficiaryId}/disable',
  path: '/account/{accountId}/beneficiary/{beneficiaryId}/disable',
  tags: ['beneficiary'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: {
      description: 'LfxBeneficiary',
      message: 'Beneficiary successfully disabled',
      header: 'Success',
    },
    400: { description: 'Beneficiary Disable Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    beneficiaryId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const createBeneficiaryBankAccount: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageBeneficiary'],
  mfa: true,
  description: 'Creats a bank account for a given beneficiary',
  method: 'post',
  collectionPath: '/account/{accountId}/beneficiary/{beneficiaryId}/bankAccount',
  path: '/account/{accountId}/beneficiary/{beneficiaryId}/bankAccount',
  tags: ['beneficiary'],
  headers: {},
  responses: {
    200: {
      description: 'LfxBankAccount',
      message: 'Beneficiary bank account successfully created',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    description: { type: 'string', required: true },
    currencyId: { type: 'string', required: true },
    bankName: { type: 'string', required: true },
    branch: { type: 'string', required: true },
    branchCode: { type: 'string' },
    accountNumber: { type: 'string', required: true },
    bankAddress: { type: 'string' },
    bankCity: { type: 'string' },
    bankCountryId: { type: 'string', required: true },
    swiftCode: { type: 'string', required: true },
    correspondentCode: { type: 'string' },
    detailsOfCharge: { type: 'string' },
    beneficyaryReference: { type: 'string' },
    specialInstructions: { type: 'string' },
    externalReference: { type: 'string' },
    notes: { type: 'string' },
    isCrypto: { type: 'boolean' },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    beneficiaryId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getBeneficiaryBankAccounts: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Gets all bank accounts for a given beneficiary',
  method: 'patch',
  collectionPath: '/account/{accountId}/beneficiary/{beneficiaryId}/bankAccount',
  path: '/account/{accountId}/beneficiary/{beneficiaryId}/bankAccount',
  tags: ['beneficiary'],
  responses: {
    200: { description: 'List of lfxBankAccount' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {
    accountId: { type: 'integer', required: true },
    beneficiaryId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const updateBeneficiaryBankAccount: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageBeneficiary'],
  mfa: true,
  description: 'Updates a beneficiary',
  method: 'post',
  collectionPath: '/account/{accountId}/beneficiary/{beneficiaryId}/bankAccount',
  path: '/account/{accountId}/beneficiary/{beneficiaryId}/bankAccount/{bankAccountId}',
  tags: ['beneficiary'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: {
      description: 'LfxBeneficiary',
      message: 'Beneficiary Bank Account successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    description: { type: 'string', required: true },
    currencyId: { type: 'string', required: true },
    bankName: { type: 'string', required: true },
    branch: { type: 'string', required: true },
    branchCode: { type: 'string' },
    accountNumber: { type: 'string', required: true },
    bankAddress: { type: 'string' },
    bankCity: { type: 'string' },
    bankCountryId: { type: 'string', required: true },
    swiftCode: { type: 'string', required: true },
    correspondentCode: { type: 'string' },
    detailsOfCharge: { type: 'string' },
    beneficyaryReference: { type: 'string' },
    specialInstructions: { type: 'string' },
    externalReference: { type: 'string' },
    notes: { type: 'string' },
    isCrypto: { type: 'boolean' },
  },
  pathParams: {
    accountId: { type: 'integer', required: true },
    beneficiaryId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const getBankBeneficiaryAccount: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Gets a Bank Account by ID',
  method: 'get',
  collectionPath: '/account/{accountId}/beneficiary/{beneficiaryId}/bankAccount',
  path: '/account/{accountId}/beneficiary/{beneficiaryId}/bankAccount/{bankAccountId}',
  tags: ['beneficiary'],
  headers: {},
  responses: {
    200: { description: 'lfxBankAccount' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    beneficiaryId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
};

export const enableBankAccount: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageBeneficiary'],
  mfa: true,
  description: 'Enables a beneficiary bank account',
  method: 'put',
  collectionPath: '/account/{accountId}/beneficiary/{beneficiaryId}/bankAccount/{bankAccountId}/enable',
  path: '/account/{accountId}/beneficiary/{beneficiaryId}/bankAccount/{bankAccountId}/enable',
  tags: ['beneficiary'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: {
      description: 'LfxBeneficiary',
      message: 'Bank Account successfully enabled',
      header: 'Success',
    },
    400: { description: 'Beneficiary Enable Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    beneficiaryId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const disableBankAccount: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageBeneficiary'],
  mfa: true,
  description: 'Disables a beneficiary bank account',
  method: 'put',
  collectionPath: '/account/{accountId}/beneficiary/{beneficiaryId}/bankAccount/{bankAccountId}/disable',
  path: '/account/{accountId}/beneficiary/{beneficiaryId}/bankAccount/{bankAccountId}/disable',
  tags: ['beneficiary'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: {
      description: 'LfxBeneficiary',
      message: 'Bank Account successfully disabled',
      header: 'Success',
    },
    400: { description: 'Beneficiary Disable Failed' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    accountId: { type: 'integer', required: true },
    beneficiaryId: { type: 'integer', required: true },
    bankAccountId: { type: 'integer', required: true },
  },
  implementedByFrontEnd: true,
};

export const uploadBulkBeneficiaryImportBatch: LdbAPIAction = {
  accountLevels: ['intermediary', 'intermediaryBranch', 'client'],
  permissions: ['bulkCreateBeneficary'],
  description: 'Uploads a data import for bulk beneficiary creation',
  method: 'post',
  collectionPath: '/account/{accountId}/bulkBeneficiaryCreate',
  path: '/account/{accountId}/bulkBeneficiaryCreate',
  tags: ['dataImport'],
  headers: {},
  responses: {
    200: { description: 'LfxImportBatch' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    fileName: {
      type: 'object',
      format: 'binary',
    },
  },
  pathParams: {},
  implementedByFrontEnd: true,
  reportAction: true,
};
export const getIncomingReports: LdbAPIAction = {
  accountLevels: ['intermediary', 'bank'],
  permissions: [],
  description: 'Gets logs for a transactionId',
  method: 'patch',
  collectionPath: '/account/{accountId}/incomingReports',
  path: '/account/{accountId}/incomingReports',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxIncomingReport' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const downloadIncomingReport: LdbAPIAction = {
  accountLevels: ['intermediary', 'bank'],
  permissions: [],
  description: 'Downloads an incoming report file',
  method: 'put',
  collectionPath: '/account/{accountId}/incomingReports/{incomingReportId}/download',
  path: '/account/{accountId}/incomingReports/{incomingReportId}/download',
  tags: ['account'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    400: { description: 'Error', message: 'Report not available' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { accountId: { type: 'integer', required: true }, incomingReportId: { type: 'string', required: true } },
  implementedByFrontEnd: true,
  reportAction: true,
};
export const confirmUserReview: LdbAPIAction = {
  accountLevels: ['intermediary', 'bank', 'client'],
  permissions: ['reviewUser'],
  description: 'Downloads an incoming report file',
  method: 'put',
  collectionPath: '/account/{accountId}/confirmUserReview',
  path: '/account/{accountId}/confirmUserReview',
  tags: ['account'],
  headers: {},
  responses: {
    200: { description: 'Okay' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { userTeamId: { type: 'string' } },
  pathParams: { accountId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getUsers: LdbAPIAction = {
  accountLevels: [],
  permissions: ['manageUser', 'releasePermission', 'reviewUser'],
  description: 'Gets all users accross all accounts',
  method: 'patch',
  collectionPath: '/user',
  path: '/user',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'List of lfxUser' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {},
  implementedByFrontEnd: true,
};

export const editUser: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser'],
  description: 'Updates a user',
  method: 'post',
  collectionPath: '/user',
  path: '/user/{userId}',
  tags: ['user'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'lfxUser', message: 'User successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  mfa: true,
  params: {
    firstName: { type: 'string', required: true },
    surname: { type: 'string', required: true },
    middleNames: { type: 'string' },
    preferredName: { type: 'string' },
    idNumber: { type: 'string', required: true },
  },
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getUser: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Gets a user by ID',
  method: 'get',
  collectionPath: '/user',
  path: '/user/{userId}',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'lfxUser' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const bankLogin: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: "Logs a user into the Bank's systems",
  method: 'put',
  collectionPath: '/user/{userId}/bankLogin',
  path: '/user/{userId}/bankLogin',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'lfxUser', message: 'Bank Login successful', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'LoginError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const odinLogin: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Logs a user into Odin',
  method: 'put',
  collectionPath: '/user/{userId}/odinLogin',
  path: '/user/{userId}/odinLogin',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'lfxUser', message: 'Odin Login successful', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'LoginError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const updateBankLoginDetails: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageUser', 'releasePermission'],
  description: 'Update bank login details for a user',
  method: 'put',
  collectionPath: '/user/{userId}/bankLoginDetails',
  path: '/user/{userId}/bankLoginDetails',
  tags: ['user'],
  headers: {},
  responses: {
    200: {
      description: 'lfxUser',
      message: 'ESB Login Details successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'Update Error' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    esbUsername: { type: 'string', required: true },
    esbPassword: { type: 'string' },
  },
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const updateOdinLoginDetails: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageUser', 'releasePermission'],
  description: 'Update Odin login details for a user',
  method: 'put',
  collectionPath: '/user/{userId}/odinLoginDetails',
  path: '/user/{userId}/odinLoginDetails',
  tags: ['user'],
  headers: {},
  responses: {
    200: {
      description: 'lfxUser',
      message: 'ESB Login Details successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'Update Error' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    odnUsername: { type: 'string', required: true },
    odnPassword: { type: 'string' },
  },
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const requestMfa: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Requests multi factor authentication for the user',
  method: 'put',
  collectionPath: '/user/{userId}/mfa',
  path: '/user/{userId}/mfa',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'Success (including token and expiry)' },
    400: { description: 'Error' },
    401: { description: 'Failure' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    requestedAction: { type: 'string', required: true },
    messageText: { type: 'string', required: true },
  },
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const confirmMfaOtp: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Confirms an OTP generated by /user/{userId}/mfa',
  method: 'put',
  collectionPath: '/user/{userId}/confirmMfaOtp',
  path: '/user/{userId}/confirmMfaOtp',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'Success (including token and expiry)' },
    400: { description: 'Error' },
    401: { description: 'Failure' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { otp: { type: 'string' } },
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const moveUser: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageUser'],
  description: "Changes a user's account",
  method: 'put',
  collectionPath: '/user/{userId}/moveUser',
  path: '/user/{userId}/moveUser',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'LfxUser', message: 'User successfully moved', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { accountId: { type: 'integer' } },
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const unlockUser: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser'],
  mfa: true,
  description: 'Unlocks a locked users access',
  method: 'put',
  collectionPath: '/user/{userId}/unlockUser',
  path: '/user/{userId}/unlockUser',
  tags: ['user'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'lfxUser', message: 'User successfully unlocked', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const enableUser: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser'],
  mfa: true,
  description: 'Sets the user to the enabled status and creates a Cognito user if necessary',
  method: 'put',
  collectionPath: '/user/{userId}/enableUser',
  path: '/user/{userId}/enableUser',
  tags: ['user'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'lfxUser', message: 'User successfully enabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const resendCognitoEmail: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser'],
  description: "Resends the users's Cognito welcome email",
  method: 'put',
  collectionPath: '/user/{userId}/resendCognitoEmail',
  path: '/user/{userId}/resendCognitoEmail',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'lfxUser', message: 'Email successfully re-sent', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const disableUser: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser'],
  mfa: true,
  description: 'Sets the user to a disabled state',
  method: 'put',
  collectionPath: '/user/{userId}/disableUser',
  path: '/user/{userId}/disableUser',
  tags: ['user'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'lfxUser', message: 'User successfully disabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const updateUserSettings: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Updates the user settings in Dynamo',
  method: 'put',
  collectionPath: '/user/{userId}/updateSettings',
  path: '/user/{userId}/updateSettings',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'lfxUser' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { newSettings: { type: 'object' } },
  pathParams: { userId: { type: 'integer', required: true } },
};
export const updateUserUserTeam: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageUser'],
  description: 'Moves a user to a different user team',
  method: 'put',
  collectionPath: '/user/{userId}/updateUserUserTeam',
  path: '/user/{userId}/updateUserUserTeam',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'lfxUser', message: 'User Team Successfully Assigned' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { userTeamId: { type: 'string' } },
  pathParams: { userId: { type: 'integer', required: true } },
};

export const getUserPermissions: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser', 'releasePermission', 'reviewUser'],
  description: "Get the user's LdbUserPermissions",
  method: 'patch',
  collectionPath: '/user/{userId}/permission',
  path: '/user/{userId}/permission',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'List of LdbUserPermission' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const updateUserPermissions: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser'],
  mfa: true,
  description: "Updates the user's LdbUserPermissions",
  method: 'put',
  collectionPath: '/user/{userId}/permission',
  path: '/user/{userId}/permission',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'lfxUser', message: 'User permissions successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { requiredPermissions: { type: 'array' } },
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const updatePermissionsInDynamo: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser', 'releasePermission'],
  description: "Re caches the user's permissions in Dynamo",
  method: 'put',
  collectionPath: '/user/{userId}/permissionRecache',
  path: '/user/{userId}/permissionRecache',
  tags: ['user'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxUser' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
};
export const grantUserPermissions: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePermission'],
  mfa: true,
  description: 'Grants any pending UserPermissions for the user',
  method: 'put',
  collectionPath: '/user/{userId}/permissionGrant',
  path: '/user/{userId}/permissionGrant',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'lfxUser', message: 'User permissions successfully granted', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const rejectUserPermissions: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePermission'],
  mfa: true,
  description: 'Rejects any pending UserPermissions for the user',
  method: 'put',
  collectionPath: '/user/{userId}/permissionReject',
  path: '/user/{userId}/permissionReject',
  tags: ['user'],
  headers: {},
  responses: {
    200: {
      description: 'lfxUser',
      message: 'User permissions successfully rejected',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const resendUserPermissionsEmail: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releasePermission'],
  description: 'Resends the email requesting user permissions',
  method: 'put',
  collectionPath: '/user/{userId}/resendUserPermissionsEmail',
  path: '/user/{userId}/resendUserPermissionsEmail',
  tags: ['user'],
  headers: {},
  responses: {
    200: {
      description: 'lfxUser',
      message: 'User permissions email successfully re-sent',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getUserSettingsAndPermissions: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: [],
  description: 'Gets User Permissions and Settings and Account Settings',
  method: 'get',
  collectionPath: '/user/{userId}/settingsAndPermissions',
  path: '/user/{userId}/settingsAndPermissions',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'Permissions and Settings Object' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getUserSignatory: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets an LfxSignatory for a User',
  method: 'get',
  collectionPath: '/user/{userId}/signatory',
  path: '/user/{userId}/signatory',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'LfxSignatory' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
};

export const enableUserSignatory: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageSignatory'],
  mfa: true,
  description: 'Enables a signatory for a user',
  method: 'put',
  collectionPath: '/user/{userId}/signatoryEnable',
  path: '/user/{userId}/signatoryEnable',
  tags: ['user'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: {
      description: 'LfxSignatory',
      message: 'User signatory successfully enabled',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
};

export const editUserAuth: LdbAPIAction = {
  accountLevels: [],
  permissions: ['manageUser'],
  mfa: true,
  description: "Updates a user's authentication information",
  method: 'put',
  collectionPath: '/user/{userId}/authEdit',
  path: '/user/{userId}/authEdit',
  tags: ['user'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: {
      description: 'LfxUser',
      message: 'User Login Details successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    emailAddress: { type: 'string', required: true },
    msisdn: { type: 'string', required: true },
  },
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const enableMfa: LdbAPIAction = {
  accountLevels: [],
  permissions: ['manageUser'],
  mfa: true,
  description: 'Enables MFA for a User',
  method: 'put',
  collectionPath: '/user/{userId}/enableMfa',
  path: '/user/{userId}/enableMfa',
  tags: ['user'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxUser', message: 'User MFA successfully enabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const disableMfa: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['manageUser'],
  mfa: true,
  description: 'Disables MFA for a user',
  method: 'put',
  collectionPath: '/user/{userId}/disableMfa',
  path: '/user/{userId}/disableMfa',
  tags: ['user'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxUser', message: 'User MFA successfully disabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const addSimHoldExclusion: LdbAPIAction = {
  accountLevels: [],
  permissions: ['manageUser'],
  mfa: true,
  method: 'put',
  path: '/user/{userId}/addSimHoldExclusion',
  collectionPath: '/user/{userId}/addSimHoldExclusion',
  params: {},
  description: 'Adds a Sim Hold Exclusion',
  tags: ['user'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxUser', message: 'SIM Hold Exclusion successfully added', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const addOtpExclusion: LdbAPIAction = {
  accountLevels: [],
  permissions: ['manageUser'],
  mfa: true,
  description: 'Adds an OTP Exclusion',
  method: 'put',
  collectionPath: '/user/{userId}/addOtpExclusion',
  path: '/user/{userId}/addOtpExclusion',
  tags: ['user'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxUser', message: 'OTP Exclusion successfully added', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { mfaExclusionExpiry: { type: 'string', required: true }, otpType: { type: 'string', required: true } }, // otpType: 'sms'|'email'
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const clearMfaExclusion: LdbAPIAction = {
  accountLevels: [],
  permissions: ['manageUser'],
  mfa: true,
  description: 'Clears existing MFA Exclusions',
  method: 'put',
  collectionPath: '/user/{userId}/clearMfaExclusion',
  path: '/user/{userId}/clearMfaExclusion',
  tags: ['user'],
  headers: { mfaToken: { type: 'string', required: true } },
  responses: {
    200: { description: 'LfxUser', message: 'MFA Exclusion successfully cleared', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getUserAccessLogs: LdbAPIAction = {
  accountLevels: [],
  permissions: ['manageUser', 'releasePermission'],
  description: 'Gets access logs for a userId',
  method: 'patch',
  collectionPath: '/user/{userId}/accessLogs',
  path: '/user/{userId}/accessLogs',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxUserAccessLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const getUserEmailLogs: LdbAPIAction = {
  accountLevels: [],
  permissions: ['manageUser'],
  description: 'Gets access logs for a userId',
  method: 'patch',
  collectionPath: '/user/{userId}/emailLogs',
  path: '/user/{userId}/emailLogs',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxUserEmailLog' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    timestamp: { type: 'string', format: 'date' },
  },
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const hideUser: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser'],
  description: 'Sets the Visibility field of a user to Hidden',
  method: 'put',
  collectionPath: '/user/{userId}/hide',
  path: '/user/{userId}/hide',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'LfxUser', message: 'User successfully hidden', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const unhideUser: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch', 'client'],
  permissions: ['manageUser'],
  description: 'Sets the Visibility field of a user to Vissible',
  method: 'put',
  collectionPath: '/user/{userId}/unhide',
  path: '/user/{userId}/unhide',
  tags: ['user'],
  headers: {},
  responses: {
    200: { description: 'LfxUser', message: 'User successfully unhidden', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { userId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

//  AUTH PATHS
export const cognitoLogin: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Logs a user into the system using Cognito',
  method: 'put',
  collectionPath: '/auth/login',
  path: '/auth/login',
  tags: ['auth'],
  headers: {},
  responses: {
    200: { description: 'LfxUser' },
    202: { description: 'Password Reset Token' },
    300: { description: 'OTP Token' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    username: { type: 'string', required: true },
    password: { type: 'string', required: true },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};
export const completeOtpLogin: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Passes an OTP back to the auth controller to complete login',
  method: 'put',
  collectionPath: '/auth/completeOtpLogin',
  path: '/auth/completeOtpLogin',
  tags: ['auth'],
  headers: {},
  responses: {
    200: { description: 'LfxUser' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    otp: { type: 'string', required: true },
    otpToken: { type: 'string', required: true },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};
export const cognitoRefreshSession: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Refreshes user tokens in Cognito',
  method: 'put',
  collectionPath: '/auth/refreshSession',
  path: '/auth/refreshSession',
  tags: ['auth'],
  headers: {},
  responses: {
    200: { description: 'LfxUser' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    refreshToken: { type: 'string', required: true },
    username: { type: 'string', required: true },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};
export const resetTempPassword: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Completes first login in Cognito using a login session token and activates user',
  method: 'put',
  collectionPath: '/auth/completeFirstLogin',
  path: '/auth/completeFirstLogin',
  tags: ['auth'],
  headers: {},
  responses: {
    200: { description: 'LfxUser' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    username: { type: 'string', required: true },
    newPassword: { type: 'string', required: true },
    session: { type: 'string', required: true },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};
export const cognitoLogout: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Logs a user out of the system using Cognito',
  method: 'put',
  collectionPath: '/auth/logout',
  path: '/auth/logout',
  tags: ['auth'],
  headers: {},
  responses: {
    200: { description: 'LfxUser' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    username: { type: 'string', required: true },
    newPassword: { type: 'string', required: true },
    session: { type: 'string', required: true },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};
export const cognitoForgotPassword: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Sends a forgot password email through Cognito',
  method: 'put',
  collectionPath: '/auth/forgotPassword',
  path: '/auth/forgotPassword',
  tags: ['auth'],
  headers: {},
  responses: {
    200: { description: 'LfxUser' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    username: { type: 'string', required: true },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};
export const cognitoConfirmForgotPassword: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Resets a password in Cognito using the code from the forgotPassword email',
  method: 'put',
  collectionPath: '/auth/confirmForgotPassword',
  path: '/auth/confirmForgotPassword',
  tags: ['auth'],
  headers: {},
  responses: {
    200: { description: 'LfxUser' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    username: { type: 'string', required: true },
    password: { type: 'string', required: true },
    confirmationCode: { type: 'string', required: true },
  },
  pathParams: {},
  implementedByFrontEnd: true,
};

export const getCountries: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of Countries',
  method: 'patch',
  collectionPath: '/config/{configId}/country',
  path: '/config/{configId}/country',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Array of LdbCountry' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getCurrencies: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of Currencies',
  method: 'patch',
  collectionPath: '/config/{configId}/currency',
  path: '/config/{configId}/currency',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Array of LdbCurrency' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const createCurrencyPair: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Creates a CurrencyPair',
  method: 'post',
  collectionPath: '/config/{configId}/currencyPair',
  path: '/config/{configId}/currencyPair',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'LfxCurrencyPair',
      message: 'Currency Pair successfully created',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    baseCurrency: { type: 'string' },
    quoteCurrency: { type: 'string' },
    instrument: { type: 'string' },
    baseAdjustmentSpread: { type: 'number' },
    parityPair: { type: 'boolean' },
    allowTrading: { type: 'boolean' },
    allowSameDay: { type: 'boolean' },
    allowForward: { type: 'boolean' },
    defaultSpread: { type: 'number', required: true },
  },
  pathParams: { configId: { type: 'integer', required: true } },
};

export const getCurrencyPairs: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of currencyPairs',
  method: 'patch',
  collectionPath: '/config/{configId}/currencyPair',
  path: '/config/{configId}/currencyPair',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Array of LdbCurrencyPair' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const updateCurrencyPair: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Edits a CurrencyPair',
  method: 'post',
  collectionPath: '/config/{configId}/currencyPair',
  path: '/config/{configId}/currencyPair/{currencyPairId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'LfxCurrencyPair',
      message: 'Currency Pair successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    instrument: { type: 'string', required: true },
    baseAdjustmentSpread: { type: 'number', required: true },
    parityPair: { type: 'boolean', required: true },
    allowTrading: { type: 'boolean', required: true },
    allowSameDay: { type: 'boolean', required: true },
    allowForward: { type: 'boolean', required: true },
    defaultSpread: { type: 'number', required: true },
  },
  pathParams: {
    configId: { type: 'integer', required: true },
    currencyPairId: { type: 'string', required: true },
  },
};

export const getCurrencyPair: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets an LfxCurrencyPair by ID',
  method: 'get',
  collectionPath: '/config/{configId}/currencyPair',
  path: '/config/{configId}/currencyPair/{currencyPairId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'LfxCurrencyPair' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    configId: { type: 'integer', required: true },
    currencyPairId: { type: 'string', required: true },
  },
};

export const createPublicHoliday: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['managePublicHolidays'],
  description: 'Creates a Public Holiday',
  method: 'post',
  collectionPath: '/config/{configId}/publicHoliday',
  path: '/config/{configId}/publicHoliday',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'LfxPublicHoliday',
      message: 'Public Holiday successfully created',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    currencyId: { type: 'string' },
    date: { type: 'string' },
    name: { type: 'string' },
    status: { type: 'string' },
  },
  pathParams: { configId: { type: 'integer', required: true } },
};

export const getPublicHolidays: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of Public Holidays',
  method: 'patch',
  collectionPath: '/config/{configId}/publicHoliday',
  path: '/config/{configId}/publicHoliday',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxCurrencyHoliday' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    searchField: { type: 'string' },
    searchValue: { type: 'string' },
  },
  pathParams: { configId: { type: 'integer', required: true } },
};

export const updatePublicHoliday: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['managePublicHolidays'],
  description: 'Edits a Public Holiday',
  method: 'post',
  collectionPath: '/config/{configId}/publicHoliday',
  path: '/config/{configId}/publicHoliday/{publicHolidayId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'LfxPublicHoliday',
      message: 'Public Holiday successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    currencyId: { type: 'string' },
    date: { type: 'string' },
    name: { type: 'string' },
    status: { type: 'string' },
  },
  pathParams: {
    configId: { type: 'integer', required: true },
    publicHolidayId: { type: 'string', required: true },
  },
};

export const getPublicHoliday: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a LfxPublicHoliday by ID',
  method: 'get',
  collectionPath: '/config/{configId}/publicHoliday',
  path: '/config/{configId}/publicHoliday/{publicHolidayId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'LfxPublicHoliday' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    configId: { type: 'integer', required: true },
    publicHolidayId: { type: 'string', required: true },
  },
};

export const getSystemSettings: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets the system settings',
  method: 'get',
  collectionPath: '/config/{configId}/systemSettings',
  path: '/config/{configId}/systemSettings',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'System Settings' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { configId: { type: 'integer', required: true } },
};
export const editSystemSettingsTrading: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Updates the fields in the Trading Group of the system settings',
  method: 'post',
  collectionPath: '/config/{configId}/systemSettings/editSystemSettingsTrading',
  path: '/config/{configId}/systemSettings/editSystemSettingsTrading',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'System Settings' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    tradingEnabledRates: { type: 'boolean' },
    tradingEnabledDealingRoom: { type: 'boolean' },
    tradingEnabledSystem: { type: 'boolean' },
    tradingTradingStartTime: { type: 'string', format: 'date' },
    tradingTradingEndTime: { type: 'string', format: 'date' },
    tradingSameDayCutOffTime: { type: 'string', format: 'date' },
    tradingMaximumMarkupPercentage: { type: 'number' },
    tradingForwardOptionalPercentage: { type: 'number' },
    // tradingFecAdditionalSpreads:{ type: 'object' } },
    tradingForeignCurrencyVdHolidayType: { type: 'string' },
  },
  pathParams: { configId: { type: 'integer', required: true } },
};
export const editSystemSettingsAccess: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Updates the fields in the MFA and General Group of the system settings',
  method: 'post',
  collectionPath: '/config/{configId}/systemSettings/editSystemSettingsAccess',
  path: '/config/{configId}/systemSettings/editSystemSettingsAccess',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'System Settings' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    mfaMfaEnabled: { type: 'boolean' },
    mfaMfaType: { type: 'string' },
    generalLoginEnabled: { type: 'boolean' },
    generalAccessEnabled: { type: 'boolean' },
    // generalGeneralSystemMessage: { type: 'string' }, // replaced by updateGeneralSystemMessage
  },
  pathParams: { configId: { type: 'integer', required: true } },
};
export const editSystemSettingsUserReview: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Updates the fields in the User Review Group of the system settings',
  method: 'post',
  collectionPath: '/config/{configId}/systemSettings/editSystemSettingsUserReview',
  path: '/config/{configId}/systemSettings/editSystemSettingsUserReview',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'System Settings' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    userReviewEnabled: { type: 'boolean' },
    userReviewNextIntermediaryReviewDate: { type: 'string', format: 'date' },
    userReviewNextBankReviewDate: { type: 'string', format: 'date' },
    userReviewIntermediaryReviewPeriod: { type: 'number' },
    userReviewBankReviewPeriod: { type: 'number' },
    userReviewDaysBeforeButtonEnable: { type: 'number' },
    userReviewDaysBeforeInitialEmail: { type: 'number' },
    userReviewDaysBeforeFollowUpEmail: { type: 'number' },
    userReviewDaysBeforeBannerAtLogin: { type: 'number' },
    userReviewDaysBeforePerHourBanner: { type: 'number' },
    userReviewDaysBeforePermanentBanner: { type: 'number' },
  },
  pathParams: { configId: { type: 'integer', required: true } },
};
export const updateGeneralSystemMessage: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem', 'communicateToUsers'],
  description: 'Updates the General System Message',
  method: 'post',
  collectionPath: '/config/{configId}/systemSettings/updateGeneralSystemMessage',
  path: '/config/{configId}/systemSettings/updateGeneralSystemMessage',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'System Settings' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    generalGeneralSystemMessage: { type: 'string' },
  },
  pathParams: { configId: { type: 'integer', required: true } },
};

export const getBopCatGroups: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of BOP Category Groups',
  method: 'patch',
  collectionPath: '/config/{configId}/bopCatGroup',
  path: '/config/{configId}/bopCatGroup',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxBopCatGroup' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const updateBOPCatGroup: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Edits a LfxBopCatGroup',
  method: 'post',
  collectionPath: '/config/{configId}/bopCatGroup',
  path: '/config/{configId}/bopCatGroup/{bopCatGroupId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'LfxBopCatGroup',
      message: 'BOP Category Group successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    name: { type: 'string', required: true },
    direction: { type: 'string', required: true },
    clientType: { type: 'boolean', required: true },
    status: { type: 'string', required: true },
  },
  pathParams: {
    configId: { type: 'integer', required: true },
    bopCatGroupId: { type: 'string', required: true },
  },
};

export const getBOPCatGroup: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets an BOP Category Group by ID',
  method: 'get',
  collectionPath: '/config/{configId}/bopCatGroup',
  path: '/config/{configId}/bopCatGroup/{bopCatGroupId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'LfxBopCatGroup' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    configId: { type: 'integer', required: true },
    bopCatGroupId: { type: 'string', required: true },
  },
};

export const getBopCatsForGroup: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of BOP Categories for a GroupId',
  method: 'patch',
  collectionPath: '/config/{configId}/bopCatGroup/{bopCatGroupId}/bopCat',
  path: '/config/{configId}/bopCatGroup/{bopCatGroupId}/bopCat',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxBopCat' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {
    configId: { type: 'integer', required: true },
    bopCatGroupId: { type: 'integer', required: true },
  },
};

export const getBopCats: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of BOP Categories',
  method: 'patch',
  collectionPath: '/config/{configId}/bopCat',
  path: '/config/{configId}/bopCat',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxBopCat' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const updateBOPCat: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Edits a LfxBopCatGroup',
  method: 'post',
  collectionPath: '/config/{configId}/bopCat',
  path: '/config/{configId}/bopCat/{bopCatId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'LfxBopCat', message: 'BOP Category successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    groupId: { type: 'number', required: true },
    name: { type: 'string', required: true },
    help: { type: 'string', required: true },
    requiredInfo: { type: 'array', items: { type: 'string' }, required: true },
    optionalInfo: { type: 'array', items: { type: 'string' }, required: true },
    requiredDocuments: { type: 'array', items: { type: 'string' }, required: true },
    optionalDocuments: { type: 'array', items: { type: 'string' }, required: true },
    direction: { type: 'string', required: true },
    clientType: { type: 'string', required: true },
    status: { type: 'string', required: true },
    hotList: { type: 'boolean', required: true },
  },
  pathParams: {
    configId: { type: 'integer', required: true },
    bopCatId: { type: 'string', required: true },
  },
};

export const getBopCat: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets an BOP Category by ID',
  method: 'get',
  collectionPath: '/config/{configId}/bopCat',
  path: '/config/{configId}/bopCat/{bopCatId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'LfxBopCat' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    configId: { type: 'integer', required: true },
    bopCatId: { type: 'string', required: true },
  },
};

export const createPermission: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Creates a new LdbPermission',
  method: 'post',
  collectionPath: '/config/{configId}/permission',
  path: '/config/{configId}/permission',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'New LdbPermission',
      message: 'Permission successfully created',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    id: { type: 'string' },
    description: { type: 'string' },
    accountLevels: { type: 'array', items: { type: 'string' } },
    requestPermissions: { type: 'array', items: { type: 'string' } },
    reviewPermissions: { type: 'array', items: { type: 'string' } },
    requiresReview: { type: 'boolean' },
  },
  pathParams: { configId: { type: 'integer', required: true } },
};

export const getPermissions: LdbAPIAction = {
  accountLevels: [],
  permissions: ['manageUser', 'releasePermission'],
  description: 'Returns all available LdbPermissions',
  method: 'patch',
  collectionPath: '/config/{configId}/permission',
  path: '/config/{configId}/permission',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'A list of LdbPermissions',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const editPermission: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Update a LdbPermission',
  method: 'post',
  collectionPath: '/config/{configId}/permission',
  path: '/config/{configId}/permission/{permissionId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'Updated LdbPermission',
      message: 'Permission successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    description: { type: 'string', required: true },
    accountLevels: { type: 'array', items: { type: 'string' }, required: true },
    requestPermissions: { type: 'array', items: { type: 'string' }, required: true },
    reviewPermissions: { type: 'array', items: { type: 'string' }, required: true },
    requiresReview: { type: 'boolean', required: true },
    status: { type: 'string', required: true },
  },
  pathParams: {
    configId: { type: 'integer', required: true },
    permissionId: { type: 'string', required: true },
  },
  implementedByFrontEnd: true,
};

export const getPermission: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageUser', 'releasePermission'],
  description: 'Returns an LdbPermissions',
  method: 'get',
  collectionPath: '/config/{configId}/permission',
  path: '/config/{configId}/permission/{permissionId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'LdbPermissions' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    configId: { type: 'integer', required: true },
    permissionId: { type: 'string', required: true },
  },
  implementedByFrontEnd: true,
};

export const createPermissionGroup: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Creates a new LdbPermissionGroup',
  method: 'post',
  collectionPath: '/config/{configId}/permissionGroup',
  path: '/config/{configId}/permissionGroup',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'New LdbPermissionGroup',
      message: 'Permission Group successfully created',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    name: { type: 'string' },
    description: { type: 'string' },
    accountLevels: { type: 'array', items: { type: 'string' } },
  },
  pathParams: { configId: { type: 'integer', required: true } },
};

export const getPermissionGroups: LdbAPIAction = {
  accountLevels: [],
  permissions: ['manageUser', 'releasePermission'],
  description: 'Gets a list of PermissionGroups',
  method: 'patch',
  collectionPath: '/config/{configId}/permissionGroup',
  path: '/config/{configId}/permissionGroup',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'Array of LdbPermissionGroup',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const editPermissionGroup: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Update a LdbPermissionGroup',
  method: 'post',
  collectionPath: '/config/{configId}/permissionGroup',
  path: '/config/{configId}/permissionGroup/{permissionGroupId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'Updated LdbPermissionGroup',
      message: 'Permission Group successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    name: { type: 'string', required: true },
    description: { type: 'string', required: true },
    accountLevels: { type: 'array', items: { type: 'string' }, required: true },
    status: { type: 'string', required: true },
  },
  pathParams: {
    configId: { type: 'integer', required: true },
    permissionGroupId: { type: 'integer', required: true },
  },
};

export const getPermissionGroup: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: ['manageUser', 'releasePermission'],
  description: 'Returns an LdbPermissionGroup',
  method: 'get',
  collectionPath: '/config/{configId}/permissionGroup',
  path: '/config/{configId}/permissionGroup/{permissionGroupId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'LdbPermissionGroup',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    configId: { type: 'integer', required: true },
    permissionGroupId: { type: 'integer', required: true },
  },
};

export const addPermissionToGroup: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Adds a Permission to a Permission Group',
  method: 'put',
  collectionPath: '/config/{configId}/permissionGroup/{permissionGroupId}/addPermission',
  path: '/config/{configId}/permissionGroup/{permissionGroupId}/addPermission/{permissionId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'Success',
      message: 'Permission successfully added to group',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    configId: { type: 'integer', required: true },
    permissionGroupId: { type: 'integer', required: true },
    permissionId: { type: 'string', required: true },
  },
};

export const removePermissionFromGroup: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Removes a Permission from a Permission Group',
  method: 'put',
  collectionPath: '/config/{configId}/permissionGroup/{permissionGroupId}/removePermission',
  path: '/config/{configId}/permissionGroup/{permissionGroupId}/removePermission/{permissionId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'Success',
      message: 'Permission successfully removed from group',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    configId: { type: 'integer', required: true },
    permissionGroupId: { type: 'integer', required: true },
    permissionId: { type: 'string', required: true },
  },
};

export const recacheValueDates: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Deletes all cached currency value dates and recaches them',
  method: 'put',
  collectionPath: '/config/{configId}/recacheValueDates',
  path: '/config/{configId}/recacheValueDates',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Success', message: 'Value dates successfully recached', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getRatesFeedStatus: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['enableTradingDealingRoom', 'enableTradingSystem'],
  description: 'Returns the current status of the rates feed',
  method: 'get',
  collectionPath: '/config/{configId}/ratesFeedStatus',
  path: '/config/{configId}/ratesFeedStatus',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'RatesFeedStatus' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { configId: { type: 'integer', required: true } },
};

export const restartRatesFeed: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['enableTradingDealingRoom', 'enableTradingSystem'],
  description: 'Restarts the rates feed',
  method: 'put',
  collectionPath: '/config/{configId}/restartRatesFeed',
  path: '/config/{configId}/restartRatesFeed',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Restart Sent' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { configId: { type: 'integer', required: true } },
};

export const getCommissionLevels: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of Account Commission Levels',
  method: 'patch',
  collectionPath: '/config/{configId}/commissionLevels',
  path: '/config/{configId}/commissionLevels',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxBankAccountCommissionLevel' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getEventTypes: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of LdbEventType',
  method: 'patch',
  collectionPath: '/config',
  path: '/config/{configId}/eventType/',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Array of LdbEventType' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { configId: { type: 'integer', required: true } },
};
export const createEventType: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Create a LdbEventType',
  method: 'post',
  collectionPath: '/config',
  path: '/config/{configId}/eventType/',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'New LdbEventType',
      message: 'Event Tyep successfully created',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    id: { type: 'string', required: true },
    category: { type: 'string' },
    saveToDb: { type: 'boolean' },
    showNotification: { type: 'boolean' },
    sendEmail: { type: 'boolean' },
    sendSms: { type: 'boolean' },
    prioritory: { type: 'integer' },
    notificationText: { type: 'string' },
    closable: { type: 'boolean' },
    status: { type: 'string' },
  },
  pathParams: { configId: { type: 'integer', required: true } },
};

export const getEventType: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a LdbEventType by ID',
  method: 'get',
  collectionPath: '/config',
  path: '/config/{configId}/eventType/{eventTypeID}',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'LdbEventType' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { configId: { type: 'integer', required: true }, eventTypeID: { type: 'integer', required: true } },
};
export const updateEventType: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Updates a LdbEventType by ID',
  method: 'post',
  collectionPath: '/config',
  path: '/config/{configId}/eventType/{eventTypeID}',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'LdbEventType', message: 'Event Type successfully updated', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    category: { type: 'string' },
    saveToDb: { type: 'boolean' },
    showNotification: { type: 'boolean' },
    sendEmail: { type: 'boolean' },
    sendSms: { type: 'boolean' },
    prioritory: { type: 'integer' },
    notificationText: { type: 'string' },
    closable: { type: 'boolean' },
    status: { type: 'string' },
  },
  pathParams: { configId: { type: 'integer', required: true }, eventTypeID: { type: 'integer', required: true } },
};
export const getClientApplicationDocumentTypes: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a list of LfxApplicationDocumentType',
  method: 'patch',
  collectionPath: '/config',
  path: '/config/{configId}/clientApplicationDocumentType',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Array of LfxApplicationDocumentType' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { configId: { type: 'integer', required: true } },
};
export const createClientApplicationDocumentTypes: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Create a LfxApplicationDocumentType',
  method: 'post',
  collectionPath: '/config',
  path: '/config/{configId}/clientApplicationDocumentType/',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'New LfxApplicationDocumentType',
      message: 'Application Document Type successfully created',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    documentName: { type: 'string', required: true },
    description: { type: 'string', required: true },
    accountType: { type: 'string', required: true },
    applicationType: { type: 'string', required: true },
    mandatory: { type: 'boolean', required: true },
    status: { type: 'string', required: true },
  },
  pathParams: { configId: { type: 'integer', required: true } },
};
export const getClientApplicationDocumentType: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets a LfxApplicationDocumentType',
  method: 'get',
  collectionPath: '/config',
  path: '/config/{configId}/clientApplicationDocumentType/{applicationDocumentTypeId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'LfxApplicationDocumentType' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: {
    configId: { type: 'integer', required: true },
    applicationDocumentTypeId: { type: 'integer', required: true },
  },
};
export const updateClientApplicationDocumentType: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Updates a LfxApplicationDocumentType',
  method: 'post',
  collectionPath: '/config',
  path: '/config/{configId}/clientApplicationDocumentType/{applicationDocumentTypeId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'Updated LfxApplicationDocumentType',
      message: 'Application Document Type successfully created',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    documentName: { type: 'string' },
    description: { type: 'string' },
    accountType: { type: 'string' },
    applicationType: { type: 'string' },
    mandatory: { type: 'boolean' },
    status: { type: 'string' },
  },
  pathParams: {
    configId: { type: 'integer', required: true },
    applicationDocumentTypeId: { type: 'integer', required: true },
  },
};

export const enableTradingDealingRoom: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['enableTradingDealingRoom'],
  description: 'Sets enabledDealingRoom to true',
  method: 'put',
  collectionPath: '/config',
  path: '/config/{configId}/enableTradingDealingRoom',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Okay', message: 'Trading successfully enabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { configId: { type: 'integer', required: true } },
  params: {},
};

export const enableTradingSystem: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['enableTradingSystem'],
  description: 'Sets enableTradingSystem to true',
  method: 'put',
  collectionPath: '/config',
  path: '/config/{configId}/enableTradingSystem',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Okay', message: 'Trading successfully enabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { configId: { type: 'integer', required: true } },
  params: {},
};

export const disableTradingDealingRoom: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['enableTradingDealingRoom'],
  description: 'Sets enabledDealingRoom to false',
  method: 'put',
  collectionPath: '/config',
  path: '/config/{configId}/disableTradingDealingRoom',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Okay', message: 'Trading successfully disabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { configId: { type: 'integer', required: true } },
  params: {},
};

export const disableTradingSystem: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['enableTradingSystem'],
  description: 'Sets enableTradingSystem to false',
  method: 'put',
  collectionPath: '/config',
  path: '/config/{configId}/disableTradingSystem',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'Okay', message: 'Trading successfully disabled', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { configId: { type: 'integer', required: true } },
  params: {},
};

export const createUserTeam: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Creates a new LfxUserTeam',
  method: 'post',
  collectionPath: '/config/{configId}/userTeam',
  path: '/config/{configId}/userTeam',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'New LfxUserTeam',
      message: 'User Team successfully created',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    description: { type: 'string', required: true },
    accountLevel: { type: 'string', required: true },
    status: { type: 'string', required: true },
  },
  pathParams: { configId: { type: 'integer', required: true } },
};

export const getUserTeams: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Returns all available LfxUserTeam',
  method: 'patch',
  collectionPath: '/config/{configId}/userTeam',
  path: '/config/{configId}/userTeam',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'A list of LfxUserTeam',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const editUserTeam: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Update a LfxUserTeam',
  method: 'post',
  collectionPath: '/config/{configId}/userTeam',
  path: '/config/{configId}/userTeam/{userTeamId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: {
      description: 'Updated LfxUserTeam',
      message: 'User Team successfully updated',
      header: 'Success',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    id: { type: 'string', required: true },
    description: { type: 'string', required: true },
    accountLevel: { type: 'string', required: true },
    status: { type: 'string', required: true },
  },
  pathParams: {
    configId: { type: 'integer', required: true },
    userTeamId: { type: 'string', required: true },
  },
  implementedByFrontEnd: true,
};

export const getUserTeam: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Returns an LfxUserTeam',
  method: 'get',
  collectionPath: '/config/{configId}/userTeam',
  path: '/config/{configId}/userTeam/{userTeamId}',
  tags: ['config'],
  headers: {},
  responses: {
    200: { description: 'LfxUserTeam' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {
    configId: { type: 'integer', required: true },
    userTeamId: { type: 'string', required: true },
  },
  implementedByFrontEnd: true,
};

export const logFrontEndError: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Logs a front end error',
  method: 'put',
  collectionPath: '/config',
  path: '/admin/{configId}/logError',
  tags: ['admin'],
  headers: {},
  params: {
    errorType: { type: 'string' },
    errorReport: { type: 'object' },
  },
  responses: {
    200: { description: 'Okay', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { configId: { type: 'integer', required: true } },
};
export const getMakerCheckerToken: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Gets the current value of a maker checker token',
  method: 'put',
  collectionPath: '/config',
  path: '/admin/{configId}/getMakerCheckerToken',
  tags: ['admin'],
  headers: {},
  params: {
    cacheKey: { type: 'string' },
  },
  responses: {
    200: { description: 'Okay', header: 'Success' },
    204: { description: 'No Cached Object', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};
export const clearMakerCheckerToken: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Clears the current value of a maker checker token',
  method: 'put',
  collectionPath: '/config',
  path: '/admin/{configId}/clearMakerCheckerToken',
  tags: ['admin'],
  headers: {},
  params: {
    cacheKey: { type: 'string' },
  },
  responses: {
    200: { description: 'Okay', header: 'Success' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const getSignatoryRequests: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['reviewSignatory'],
  description: 'Returns all available LfxSignatoryRequest',
  method: 'patch',
  collectionPath: '/admin/{configId}/signatoryRequest',
  path: '/admin/{configId}/signatoryRequest',
  tags: ['admin'],
  headers: {},
  responses: {
    200: {
      description: 'A list of LfxSignatoryRequest',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: { configId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const syncDatabase: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Runs the sequelize.sync command',
  method: 'put',
  collectionPath: '/dev',
  path: '/dev/syncDatabase',
  tags: ['dev'],
  headers: {},
  responses: {
    200: { description: 'Okay' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {},
};

export const createInitialDbEntries: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Creates initial database entries if they do not already exist',
  method: 'put',
  collectionPath: '/dev',
  path: '/dev/createInitialDbEntries',
  tags: ['dev'],
  headers: {},
  responses: {
    200: { description: 'Okay' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {},
};

export const setupDefaultSettings: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Creates initial system settings if they do not already exist',
  method: 'put',
  collectionPath: '/dev',
  path: '/dev/setupDefaultSettings',
  tags: ['dev'],
  headers: {},
  responses: {
    200: { description: 'Okay' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {},
};

export const dealSummaryReport: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: [],
  description: 'Retrieves Data for the deal summary report',
  method: 'put',
  collectionPath: '/report/1/dealSummary',
  path: '/report/1/dealSummary',
  tags: ['report'],
  headers: {},
  responses: {
    200: { description: 'Deal Summary Data' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    dateType: { type: 'string', required: true },
    dateFrom: { type: 'string', format: 'date', required: true },
    dateTo: { type: 'string', format: 'date', required: true },
    dealStatuses: { type: 'string', required: true },
  },
  pathParams: {},
  reportAction: true,
};

export const invoiceReport: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: [],
  description: 'Generates the invoice report and either returns the stream or emails',
  method: 'put',
  collectionPath: '/report/1/invoiceReport',
  path: '/report/1/invoiceReport',
  tags: ['report'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    202: {
      description: 'In Progress',
      message:
        'Your report is being generated and will be emailed to you when it is complete. Note that this report will be in .csv format.',
      header: 'Report in Progress',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    dateType: { type: 'string', required: true },
    dateFrom: { type: 'string', format: 'date', required: true },
    dateTo: { type: 'string', format: 'date', required: true },
    dealStatuses: { type: 'string', required: true },
    branchId: { type: 'number' },
  },
  pathParams: {},
  reportAction: true,
};

export const paymentProcessingReport: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: [],
  description: 'Generates the payment processing report and returns the data',
  method: 'put',
  collectionPath: '/report/1/paymentProcessingReport',
  path: '/report/1/paymentProcessingReport',
  tags: ['report'],
  headers: {},
  responses: {
    200: { description: 'Report Data' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    dateFrom: { type: 'string', format: 'date', required: true },
    dateTo: { type: 'string', format: 'date', required: true },
  },
  pathParams: {},
  reportAction: true,
};
export const downloadPaymentProcessingReport: LdbAPIAction = {
  accountLevels: ['bank', 'intermediary', 'intermediaryBranch'],
  permissions: [],
  description: 'Generates the payment processing report and either returns the streams or emails',
  method: 'put',
  collectionPath: '/report/1/downloadPaymentProcessingReport',
  path: '/report/1/downloadPaymentProcessingReport',
  tags: ['report'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    202: {
      description: 'In Progress',
      message:
        'Your report is being generated and will be emailed to you when it is complete. Note that this report will be in .csv format.',
      header: 'Report in Progress',
    },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    dateFrom: { type: 'string', format: 'date', required: true },
    dateTo: { type: 'string', format: 'date', required: true },
  },
  pathParams: {},
  reportAction: true,
};

export const downloadClientList: LdbAPIAction = {
  accountLevels: ['intermediary'],
  permissions: ['manageClient'],
  description: 'Downloads the most recent client list for the intermediary',
  method: 'put',
  collectionPath: '/report/1/clientList',
  path: '/report/1/clientList',
  tags: ['report'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    400: { description: 'Error', message: 'Report not available' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {},
  implementedByFrontEnd: true,
  reportAction: true,
};
export const downloadDealList: LdbAPIAction = {
  accountLevels: ['intermediary'],
  permissions: ['bookDeal', 'managePayment'],
  description: 'Downloads the most recent deal list for the intermediary',
  method: 'put',
  collectionPath: '/report/1/dealList',
  path: '/report/1/dealList',
  tags: ['report'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    400: { description: 'Error', message: 'Report not available' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {},
  implementedByFrontEnd: true,
  reportAction: true,
};
export const downloadClientBalances: LdbAPIAction = {
  accountLevels: ['intermediary'],
  permissions: ['viewAccountBalance'],
  description: 'Downloads the most recent client balance list for the intermediary',
  method: 'put',
  collectionPath: '/report/1/clientBalances',
  path: '/report/1/clientBalances',
  tags: ['report'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    400: { description: 'Error', message: 'Report not available' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: {},
  implementedByFrontEnd: true,
  reportAction: true,
};
export const seachReportAccessLogs: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: [],
  description: 'Search Report Access Logs',
  method: 'put',
  collectionPath: '/report/1/seachReportAccessLogs',
  path: '/report/1/seachReportAccessLogs',
  tags: ['report'],
  headers: {},
  responses: {
    200: { description: 'Report Access Log' },
    400: { description: 'Error', message: 'Report not available' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    searchType: { type: 'string', required: true }, //'incomingReportId' | 'intermediaryIdReportMonth' | 'userIdReportMonth' | 'intermediaryIdAccessMonth' | 'userIdAccessMonth'
    incomingReportId: { type: 'string' },
    intermediaryId: { type: 'number' },
    userId: { type: 'number' },
    reportTimestamp: { type: 'string', format: 'date' },
    accessTimestamp: { type: 'string', format: 'date' },
  },
  pathParams: {},
  implementedByFrontEnd: true,
  reportAction: true,
};
export const seachEmailLogs: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: [],
  description: 'Search Email Logs',
  method: 'put',
  collectionPath: '/report/1/seachEmailLogs',
  path: '/report/1/seachEmailLogs',
  tags: ['report'],
  headers: {},
  responses: {
    200: { description: 'Email Log' },
    400: { description: 'Error', message: 'Report not available' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    searchType: { type: 'string', required: true }, // 'email' | 'table'
    emailAddress: { type: 'string' },
    timestamp: { type: 'string', format: 'date' },
    linkedTable: { type: 'string' },
    linkedId: { type: 'string' },
  },
  pathParams: {},
  implementedByFrontEnd: true,
  reportAction: true,
};
export const userAccessReport: LdbAPIAction = {
  accountLevels: [],
  permissions: ['manageUser'],
  description: 'Generates a user access report download',
  method: 'put',
  collectionPath: '/report/1/userAccessReport',
  path: '/report/1/userAccessReport',
  tags: ['report'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    includeInactive: { type: 'boolean' },
    accountId: { type: 'number' },
    showBankOnly: { type: 'boolean' },
    showMyTeamOnly: { type: 'boolean' },
  },
  pathParams: {},
  reportAction: true,
};

export const getImportBatches: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Returns all import batches available to the user',
  method: 'patch',
  collectionPath: '/dataImport',
  path: '/dataImport',
  tags: ['dataImport'],
  headers: {},
  responses: {
    200: { description: 'List of LfxImportBatch' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: { additionalSelector: { $ref: '#/components/schemas/LdbSelect', required: true } },
  pathParams: {},
  implementedByFrontEnd: true,
};

export const uploadImportBatch: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Uploads a data import batch',
  method: 'post',
  collectionPath: '/dataImport',
  path: '/dataImport',
  tags: ['dataImport'],
  headers: {},
  responses: {
    200: { description: 'LfxImportBatch' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    importType: {
      type: 'string',
      required: true,
    },
    fileSource: {
      type: 'string',
      required: true, //'s3' | 'upload'
    },
    s3Key: {
      type: 'string',
    },
    s3Bucket: {
      type: 'string',
    },
    fileName: {
      type: 'object',
      format: 'binary',
    },
  },
  pathParams: {},
  implementedByFrontEnd: true,
  reportAction: true,
};
export const uploadBulkClientClose: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Uploads a data import batch',
  method: 'post',
  collectionPath: '/dataImport/uploadBulkClientClose',
  path: '/dataImport/uploadBulkClientClose',
  tags: ['dataImport'],
  headers: {},
  responses: {
    200: { description: 'LfxImportBatch' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    fileName: {
      type: 'object',
      format: 'binary',
    },
  },
  pathParams: {},
  implementedByFrontEnd: true,
  reportAction: true,
};
export const uploadBulkBankAccountClose: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['releaseClient'],
  description: 'Uploads a data import batch',
  method: 'post',
  collectionPath: '/dataImport/uploadBulkBankAccountClose',
  path: '/dataImport/uploadBulkBankAccountClose',
  tags: ['dataImport'],
  headers: {},
  responses: {
    200: { description: 'LfxImportBatch' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    fileName: {
      type: 'object',
      format: 'binary',
    },
  },
  pathParams: {},
  implementedByFrontEnd: true,
  reportAction: true,
};
export const postProcessBatchedImports: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Runs any post processing for imports',
  method: 'put',
  collectionPath: '/dataImport/postProcess',
  path: '/dataImport/postProcess',
  tags: ['dataImport'],
  headers: {},
  responses: {
    200: { description: 'OK' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    startDate: { type: 'string', format: 'date' },
  },
  implementedByFrontEnd: true,
};

export const getImportBatch: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Returns all import batches',
  method: 'get',
  collectionPath: '/dataImport/{dataImportId}',
  path: '/dataImport/{dataImportId}',
  tags: ['dataImport'],
  headers: {},
  responses: {
    200: { description: 'LfxImportBatch' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { dataImportId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const updateImpotBatchStatus: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Updates the status of an import batch',
  method: 'post',
  collectionPath: '/dataImport/{dataImportId}',
  path: '/dataImport/{dataImportId}',
  tags: ['dataImport'],
  headers: {},
  responses: {
    200: { description: 'LfxImportBatch' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {
    status: {
      type: 'string',
    },
  },
  pathParams: { dataImportId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const resumeImportBatch: LdbAPIAction = {
  accountLevels: ['bank'],
  permissions: ['configureSystem'],
  description: 'Resumes a batch that has previously been stopped',
  method: 'put',
  collectionPath: '/dataImport/{dataImportId}/resume',
  path: '/dataImport/{dataImportId}/resume',
  tags: ['dataImport'],
  headers: {},
  responses: {
    200: { description: 'LfxImportBatch' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  pathParams: { dataImportId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
};

export const downloadImportBatchUploadFile: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Downloads the source file for a ImportBatch',
  method: 'put',
  collectionPath: '/dataImport/{dataImportId}/downloadSource',
  path: '/dataImport/{dataImportId}/downloadSource',
  tags: ['dataImport'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { dataImportId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
  reportAction: true,
};
export const downloadImportBatchErrorFile: LdbAPIAction = {
  accountLevels: [],
  permissions: [],
  description: 'Downloads the error file for a ImportBatch',
  method: 'put',
  collectionPath: '/dataImport/{dataImportId}/downloadError',
  path: '/dataImport/{dataImportId}/downloadError',
  tags: ['dataImport'],
  headers: {},
  responses: {
    200: { type: 'download', description: 'File Stream' },
    400: { description: 'Error' },
    401: { description: 'AuthError' },
    500: { description: 'System Error' },
  },
  summary: '',
  params: {},
  pathParams: { dataImportId: { type: 'integer', required: true } },
  implementedByFrontEnd: true,
  reportAction: true,
};
