//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2023 LandoByte (Pty) Ltd.
//  File        : LfxSystemSettingsDef.ts
//  Author      : Bevan Timm
//  Description : This module defines the System Settings record for LandoByte TS systems. This definition is intended for frontend use only.
//  Created     : 14 August 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition, LdbFields } from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

type LfxSettingsDynamoTime = { h: number; m: number; s: number };
type LfsSameDayCutOffTimes = {
  outwardDeal: LfxSettingsDynamoTime;
  transferDeal: LfxSettingsDynamoTime;
  inwardDeal: LfxSettingsDynamoTime;
};

type LfxSettingsFecAdditionalSpread = {
  '1month': number;
  '2month': number;
  '3month': number;
  '4month': number;
  '5month': number;
  '6month': number;
};

type LfxSettingsMfaType = 'ussd' | 'otp' | 'both';

type LfxSettingsOnboardingDocumentUpload = 'signiFlow' | 'local';
type foreignCurrencyVdHolidayType = 'skipTom' | 'pushOutSpot' | 'pushOutSpotSkipUsdTom';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxSystemSettingsInt {
  systemVersion: string;
  systemUpgradeInProgress: boolean;
  tradingEnabledRates: boolean;
  tradingEnabledDealingRoom: boolean;
  tradingEnabledSystem: boolean;
  tradingTradingStartTime: LfxSettingsDynamoTime;
  tradingTradingEndTime: LfxSettingsDynamoTime;
  tradingSameDayCutOffTime: LfxSettingsDynamoTime;
  tradingSameDayCutOffTimes: LfsSameDayCutOffTimes;
  tradingMaximumMarkupPercentage: number;
  tradingForwardOptionalPercentage: number;
  tradingFecAdditionalSpreads: LfxSettingsFecAdditionalSpread;
  tradingForeignCurrencyVdHolidayType: foreignCurrencyVdHolidayType;

  mfaMfaEnabled: boolean;
  mfaMfaType: LfxSettingsMfaType;

  onboardingDocumentUpload: LfxSettingsOnboardingDocumentUpload;

  generalLoginEnabled: boolean;
  generalAccessEnabled: boolean;
  generalMaintenanceAccessEnabled: boolean;
  generalGeneralSystemMessage: string;

  slowPortolioMonitorEnabled: boolean;
  slowPortolioMonitorNotifyMsisdns: string[];
  slowPortolioMonitorAlertPortfolioMinutes: number;
  slowPortolioMonitorApplicationEnabled: boolean;
  slowPortolioMonitorApplicationNotifyMsisdns: string[];
  slowPortolioMonitorAlertApplicationAlertPortfolioMinutes: number;

  permissionsRequestEnabled: boolean;
  permissionsRequestAdditionalAddresses: { name: string; email: string }[];

  accountCreationCipcEnabled: boolean;
  accountCreationHomeAffairsEnabled: boolean;
  accountCreationCifExistsAddresses: { name: string; email: string }[];
  accountCreationMandateDatabaseConflictAddresses: { name: string; email: string }[];

  dealOfflineCreateFailedTreasuryDealAddresses: { name: string; email: string }[];

  administrationBulkEnableIntermediaryReportsAddresses: { name: string; email: string }[];

  refinitivNotificationNotifyStatuses: string[];
  refinitivNotificationNotifyAfterRetries: number;
  refinitivNotificationStopNotifyAfterRetries: number;
  refinitivNotificationToEmailAddresses: { name: string; email: string }[];

  userReviewEnabled: boolean;
  userReviewPreviousIntermediaryReviewStartDate: Date;
  userReviewPreviousIntermediaryReviewDate: Date;
  userReviewNextIntermediaryReviewStartDate: Date;
  userReviewNextIntermediaryReviewDate: Date;
  userReviewPreviousBankReviewStartDate: Date;
  userReviewPreviousBankReviewDate: Date;
  userReviewNextBankReviewStartDate: Date;
  userReviewNextBankReviewDate: Date;
  userReviewIntermediaryReviewPeriod: number;
  userReviewBankReviewPeriod: number;
  userReviewReviewConfirmEmailAddresses: { name: string; email: string }[];
  userReviewDaysBeforeButtonEnable: number;
  userReviewDaysBeforeInitialEmail: number;
  userReviewDaysBeforeFollowUpEmail: number;
  userReviewDaysBeforeBannerAtLogin: number;
  userReviewDaysBeforePerHourBanner: number;
  userReviewDaysBeforePermanentBanner: number;

  featureEnableSdaOnBop: boolean;
  featureEnableCraOnboarding: boolean;
  featureEnableMandatesDatabase: boolean;
  featureEnableTocSignatoryReview: boolean;
}

//----------------------------------------------------------------------------------------------------------------------

// NOTE! This definition is intended for front end use only. It is not intended for interaction with the database.
// For back end use, see LfxSettings.ts

const odinFields = (): LdbFields => {
  const fieldNamesArray = [
    'odinLogin',
    'esbLogin',
    'bankAccountEnquiry',
    'bankBalanceEnquiry',
    'bankAccountStatement',
    'commissionAccountStatement',
    'retrieveAccountConfirmation',
    'retrieveStatementList',
    'retrieveStampedStatement',
    'performMfa',
    'bankClientSearch',
    'cipcSearch',
    'homeAffairsSearch',
    'bankClientEnquiry',
    'submitClientApplicationPortfolio',
    'getClientPortfolioStatus',
    'cancelClientPortfolio',
    'addDocumentToClientPortfolio',
    'removeDocumentFromClientPortfolio',
    'submitCraRequest',
    'submitNewCif',
    'submitNewAssocCif',
    'submitNewMOIAccount',
    'sendCustomerDocumentsAdded',
    'resendPortfolioCommunication',
    'sendDealRequest',
    'levyFee',
    // 'createTocMandateClient', // Mandate Service is already migrated
    // 'createTocMandateAccount',
    // 'changeTocMandateClient',
    // 'apiGetTocMandate',
    'sendBopPortfolio',
    'addContributorsToPortfolio',
    'addDocumentToPortfolio',
    'removeDocumentFromPortfolio',
    'resendSignerEmail',
    'getPortfolioStatus',
    'cancelPortfolio',
    'sharePortfolioInstruction',
    'retrieveOnbaseDocument',
  ];
  const returnObject: LdbFields = {};
  for (const fieldName of fieldNamesArray) {
    returnObject['odinEnable' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1)] = {
      label: 'Odin Enable ' + fieldName,
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'odinEnable',
    };
  }
  return returnObject;
};

export const LfxSystemSettingsDef: LdbDefinition = {
  title: 'System Settings',
  table: 'LfxSystemSettings',
  view: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
  pagingType: 'backEnd',
  collectionPath: 'systemSettings',
  indexes: [],
  groups: {
    system: { label: 'System', priority: 1 },
    trading: { label: 'Trading', priority: 2 },
    mfa: { label: 'MFA', priority: 3 },
    onboarding: { label: 'On-Boarding', priority: 4 },
    general: { label: 'General', priority: 5 },
    slowPortolioMonitor: { label: 'Slow Portolio Monitor', priority: 6 },
    permissionsRequest: { label: 'Permission Requests', priority: 7 },
    accountCreation: { label: 'Account Creation', priority: 8 },
    dealOfflineCreate: { label: 'Deal Offline Create', priority: 9 },
    administration: { label: 'Administration', priority: 10 },
    refinitivNotification: { label: 'Refinitiv Notifications', priority: 11 },
    userReview: { label: 'User Review', priority: 12 },
    featureEnable: { label: 'Feature Enabling', priority: 99 },
    odinEnable: { label: 'Odin Enabling', priority: 98 },
  },
  fields: {
    // System
    systemVersion: {
      label: 'Version',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    systemUpgradeInProgress: {
      label: 'Upgrade In Progress',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    // Trading
    tradingEnabledRates: {
      label: 'Enabled - Rates',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'trading',
    },
    tradingEnabledDealingRoom: {
      label: 'Enabled - Dealing Room',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'trading',
    },
    tradingEnabledSystem: {
      label: 'Enabled - System',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'trading',
    },
    tradingTradingStartTime: {
      label: 'Trading Stat Time',
      datatype: {
        datetime: {
          type: 'time',
          format: 'human',
          input: 'input',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'trading',
    },
    tradingTradingEndTime: {
      label: 'Trading End Time',
      datatype: {
        datetime: {
          type: 'time',
          format: 'human',
          input: 'input',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'trading',
    },
    tradingSameDayCutOffTime: {
      label: 'Same Day Cut Off Time - Depricated',
      datatype: {
        datetime: {
          type: 'time',
          format: 'human',
          input: 'input',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'trading',
    },
    tradingSameDayCutOffTimes: {
      label: 'Same Day Cut Off Times',
      datatype: {
        json: {},
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'trading',
    },
    tradingMaximumMarkupPercentage: {
      label: 'Maximum Markup Percentage',
      datatype: 'float',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'trading',
    },
    tradingForwardOptionalPercentage: {
      label: 'FEC Optional Percentage',
      datatype: 'float',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'trading',
    },
    tradingFecAdditionalSpreads: {
      label: 'FEC Additional Spreads',
      datatype: 'object', //TODO handle LfxSettingsFecAdditionalSpread properly
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'trading',
    },
    tradingForeignCurrencyVdHolidayType: {
      label: 'Foreign Currency Holiday Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'skipTom', name: 'Skip Tom Date', tagType: 'info' },
            { id: 'pushOutSpot', name: 'Push Out Spot (Including USD)', tagType: 'info' },
            { id: 'pushOutSpotSkipUsdTom', name: 'Push Out Spot, Skip Tom USD', tagType: 'info' },
          ],
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'trading',
    },

    // MFA
    mfaMfaEnabled: {
      label: 'MFA Enabled',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'mfa',
    },
    mfaMfaType: {
      label: 'MFA Types',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'ussd', name: 'USSD Only', tagType: 'info' },
            { id: 'otp', name: 'OTP Only', tagType: 'info' },
            { id: 'both', name: 'Both', tagType: 'warning' },
          ],
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'mfa',
    },

    // On-Boarding

    onboardingDocumentUpload: {
      label: 'Onboarding Upload Document Types',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'signiFlow', name: 'SigniFlow', tagType: 'info' },
            { id: 'local', name: 'Local', tagType: 'info' },
          ],
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'onboarding',
    },
    onboardingCraApprovalTimeout: {
      label: 'CRA Approval Timeout',
      datatype: 'integer',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'onboarding',
    },

    // General
    generalLoginEnabled: {
      label: 'Login Enabled',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'general',
    },
    generalAccessEnabled: {
      label: 'Access Enabled',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'general',
    },
    generalMaintenanceAccessEnabled: {
      label: 'Maintenance Access Enabled',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'general',
    },
    generalGeneralSystemMessage: {
      label: 'General System Message',
      datatype: 'memo',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'general',
    },
    // Slow Portfolio Monitor
    slowPortolioMonitorEnabled: {
      label: 'Slow Portfolio Monitor Enabled',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'slowPortolioMonitor',
    },
    slowPortolioMonitorNotifyMsisdns: {
      label: 'Slow Portfolio Monitor MSISDNs',
      datatype: 'object', //TODO handle slowPortolioMonitorNotifyMsisdns properly
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'slowPortolioMonitor',
    },
    slowPortolioMonitorAlertPortfolioMinutes: {
      label: 'Slow Portfolio Monitor Alert Minutes',
      datatype: 'integer',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'slowPortolioMonitor',
    },

    slowPortolioMonitorApplicationEnabled: {
      label: 'Slow Application Portfolio Monitor Enabled',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'slowPortolioMonitor',
    },
    slowPortolioMonitorApplicationNotifyMsisdns: {
      label: 'Slow Application Portfolio Monitor MSISDNs',
      datatype: 'object', //TODO handle slowPortolioMonitorNotifyMsisdns properly
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'slowPortolioMonitor',
    },
    slowPortolioMonitorAlertApplicationAlertPortfolioMinutes: {
      label: 'Slow Application Portfolio Monitor Alert Minutes',
      datatype: 'integer',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'slowPortolioMonitor',
    },
    // Permission Request Emails
    permissionsRequestEnabled: {
      label: 'Permission Request Monitor Enabled',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'slowPortolioMonitor',
    },

    permissionsRequestAdditionalAddresses: {
      label: 'Permission Request Email Addresses',
      datatype: 'object', //TODO handle slowPortolioMonitorNotifyMsisdns properly
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'permissionsRequest',
    },

    // Account Creation
    accountCreationCipcEnabled: {
      label: 'CIPC Check Enabled',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'accountCreation',
    },
    accountCreationHomeAffairsEnabled: {
      label: 'Home Affairs Check Enabled',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'accountCreation',
    },
    accountCreationCifExistsAddresses: {
      label: 'Account Creation CIF Exists Email Addresses',
      datatype: 'object',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'accountCreation',
    },
    accountCreationMandateDatabaseConflictAddresses: {
      label: 'Account Creation Mandate DB Conflict Email Addresses',
      datatype: 'object',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'accountCreation',
    },
    dealOfflineCreateFailedTreasuryDealAddresses: {
      label: 'Failed Treasury Deal Email Addresses',
      datatype: 'object',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'dealOfflineCreate',
    },
    dealOfflineCreateAutoImportClientAddresses: {
      label: 'Auto Import Client Email Addresses',
      datatype: 'object',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'dealOfflineCreate',
    },
    administrationBulkEnableIntermediaryReportsAddresses: {
      label: 'Bulk Enable Intermediary Report Email Addresses',
      datatype: 'object',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'administration',
    },
    //Refinitiv Notifications

    refinitivNotificationNotifyStatuses: {
      label: 'Refinitiv Notification Statuses',
      datatype: 'object',
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'refinitivNotification',
    },
    refinitivNotificationNotifyAfterRetries: {
      label: 'Refinitiv Notify After Retries',
      datatype: 'integer',
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'refinitivNotification',
    },
    refinitivNotificationStopNotifyAfterRetries: {
      label: 'Refinitiv Stop  Notifying After Retries',
      datatype: 'integer',
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'refinitivNotification',
    },
    refinitivNotificationToEmailAddresses: {
      label: 'Refinitiv Notification To Email Addresses',
      datatype: 'object',
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'refinitivNotification',
    },

    // User Review

    userReviewEnabled: {
      label: 'Enabled',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewPreviousIntermediaryReviewStartDate: {
      label: 'Previous Inter Review Start Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewPreviousIntermediaryReviewDate: {
      label: 'Previous Inter Review Due Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewNextIntermediaryReviewStartDate: {
      label: 'Next Intermediary Review Start Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewNextIntermediaryReviewDate: {
      label: 'Next Intermediary Review Due Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewPreviousBankReviewStartDate: {
      label: 'Previous Bank Review Start Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewPreviousBankReviewDate: {
      label: 'Previous Bank Review Due Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewNextBankReviewStartDate: {
      label: 'Next Bank Review Start Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewNextBankReviewDate: {
      label: 'Next Bank Review Due Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewIntermediaryReviewPeriod: {
      label: 'Intermediary Review Period (Months)',
      datatype: 'integer',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewBankReviewPeriod: {
      label: 'Bank Review Period (Months)',
      datatype: 'integer',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewReviewConfirmEmailAddresses: {
      label: 'User Review - On Confirm Email Addresses',
      datatype: 'object',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewDaysBeforeButtonEnable: {
      label: 'Time Before Deadline - Button Available (Days)',
      datatype: 'integer',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewDaysBeforeInitialEmail: {
      label: 'Time Before Deadline - Initial Email (Days)',
      datatype: 'integer',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewDaysBeforeFollowUpEmail: {
      label: 'Time Before Deadline - Follow Up Email (Days)',
      datatype: 'integer',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewDaysBeforeBannerAtLogin: {
      label: 'Time Before Deadline - Banner at Login (Days)',
      datatype: 'integer',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewDaysBeforePerHourBanner: {
      label: 'Time Before Deadline - Banner Every Hour (Days)',
      datatype: 'integer',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },
    userReviewDaysBeforePermanentBanner: {
      label: 'Time Before Deadline - Permanent Banner (Days)',
      datatype: 'integer',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'userReview',
    },

    // Feature Enabling
    featureEnableSdaOnBop: {
      // Added 2023-07-27
      label: 'Enable SDA Features on BOP Forms',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'featureEnable',
    },
    featureEnableCraOnboarding: {
      // Added 2024-02-12
      label: 'Enable CRA in Client Onboarding',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'featureEnable',
    },
    featureEnableMandatesDatabase: {
      // Added 2024-08-22
      label: 'Enable Mandate Database',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'featureEnable',
    },

    featureEnableTocSignatoryReview: {
      // Added 2025-02-25
      label: 'Enable TOC Signatory Review',
      datatype: 'boolean',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'featureEnable',
    },
    ...odinFields(), // Added 2024-09-26
  },
};
