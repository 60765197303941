<template>
  <LFXListView :definition="definition" :records="records" />
  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxSignatoryRequestsList',
  components: { LFXListView },
  props: {},
  data() {
    return {};
  },
  created() {
  },
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['signatoryRequest']),

    // ----- Parent record -----
    isReviewRoute() {
      return this.$route.meta?.signatoryReviewRoute || false
    },
    signatoryId() {
      return +this.$route.params?.signatoryId;
    },

    definition() {
      return getDefinition('signatoryRequest');
    },

    records() {
      const res = {};
      for (const sigRequestId in this.signatoryRequest) {
        if (
          (this.isReviewRoute && this.signatoryRequest[sigRequestId].status === 'requested') ||
          (!this.isReviewRoute && this.signatoryRequest[sigRequestId].signatoryId === this.signatoryId)
        ) {
          res[sigRequestId] = this.signatoryRequest[sigRequestId];
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
