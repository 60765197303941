<template>
  <LFXPagedListView 
    :definition="definition" 
    action="getAllClientApplications" 
    :actionParams="{}" 
    :search="searchConfig"
    :defaultWhere="{}"/>
</template>


<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXPagedListView from '@/views/general/LFXPagedListView.vue';

export default {
  name: 'LfxApplicationsList',
  components: { LFXPagedListView },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['authUser']),
    searchConfig() {
      const userIsBank = this.authUser?.accountLevel === 'bank';
      let inProgressChip;
      if (userIsBank) {
        inProgressChip = {id:'inProgressBank', label:'In Progress',selector:{ status:{in:['inProgress', 'partiallyCompleted']},visibility:{eq:'visible'}},default:true};
      } else {
        inProgressChip = {id:'inProgressIntermediary', label:'In Progress',selector:{ status:{in:['new', 'inProgress', 'error', 'partiallyCompleted', 'rejected']},visibility:{eq:'visible'}},default:true};
      }
      const statusChips = [
            {id:'all',        label:'All',        selector:{ visibility:{eq:'visible'}}},
            inProgressChip,
            {id:'submitted', label:'Submitted',selector:{ status:{notIn:['rejected','cancelled']},integrationStatus:{in:['sent','inactive']}},visibility:{eq:'visible'}},
            {id:'awaitingReview', label:'Awaiting Review',selector:{ integrationStatus:{in:['pendingReview','cifExists']}}},
            {id:'cra', label:'CRA Review',selector:{ craStatus:{in:['inProgress','eddSubmitted']}}},
            {
              id: 'sentBack',
              label: 'Sent Back',
              selector: { applicationPortfolioStatus: { in: ['readyForSubmission'] }, 
              requestedAt: { gte: new Date("2000-01-01") }},
            },
            {id:'completed',  label:'Completed'  ,selector:{ status:{in:['completed']},visibility:{eq:'visible'}}},
            {id:'cancelled',  label:'Cancelled'  ,selector:{ status:{in:['cancelled']},visibility:{eq:'visible'}}},
            {in:'hidden',     label:'Hidden'     ,selector:{ visibility:{eq:'hidden'} }}
          ];
      if (userIsBank) {
        statusChips.push({
          id: 'slowPortfolio',
          label: 'Slow Portfolio',
          selector: {
            applicationPortfolioStatus: 'submitted',
            requestedAt: { lte: new Date(new Date().getTime() + -10 * 60 * 1000) },
          },
        });
        statusChips.push({id:'cifExists', label:'CIF Exists',selector:{ integrationStatus:{in:['cifExists']}}},);
      }
      return {
        searchFields:['reference','portfolioIntegrationId'],
        choices:[
        //   {id:'applicationType',
        //   title:'Types',
        //   chips:[
        //     {id:'all',   label:'All',       selector:{ },default:true},
        //     {id:'newClient',   label:'All',       selector:{ applicationType:{in:["newClient"]}}},
        //     {id:'newAccount',  label:'Entity',    selector:{ applicationType:{in:["newAccount"]}}},
        //     {id:'amendClient', label:'Individual',selector:{ applicationType:{in:['amendClient']}}},
        //     {id:'amendAccount',label:'Individual',selector:{ applicationType:{in:['amendAccount']}}},
        //   ]
        // },
        {id:'statuses',
          title:'Status',
          chips:statusChips
        },
        ],
      }      
    },

    definition() {
      return getDefinition('application');
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
};
</script>
