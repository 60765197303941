<template>
  <LDBModal type="result" result="info" :header="header">
    <template #body>
    <p>
      This will process your uploaded file and close the associated Bank Account for each line in the file.<br/><br/>
      <b>Please Note:</b><br/> Once the file has been submitted it will be processed automatically. The process is not reversable.<br/>
      Please ensure that you have checked your file thoroughly and that you are uploading it under the correct account.
    </p>
    <br/>
    <capitec-file-upload :label="uploadButtonName" @selection-change="onSelectionChange" :disabled="uploadDisabled" :type="uploadButtonType" :fileTypes="fileTypes" hint="Jannie">
    
    </capitec-file-upload>
    </template>
    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :enabledGuards="button.enabledGuards"
        @on-api-success="button.onApiSuccess"
        @click="button.clickEvent"
      ></LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState } from 'vuex';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';
import LDBButton from '@/landobyte_vue/LDBButton.vue';

export default {
  name: 'LfxImportBatchUploadBeneficiary',
  components: { LDBItemView,LDBButton },
  data() {
    return {
      files:null,
      record:{}
    }
  },
  computed: {
    header() {
      return "Bulk Bank Account Close Upload"
    },
    uploadDisabled() {
      return false
    },
    uploadButtonType() {
      if (this.uploadDisabled) {
        return 'disabled'
      } else {
        return ''
      }
    },
    uploadButtonName() {
      return "Select CSV File"
    },
    fileTypes() {
      return "text/csv"        
    }, 
    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          action: 'click',
          params: {},
          enabledGuards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'uploadImportBatchFile',
          label: 'Submit',
          type: 'primary',
          action: 'uploadBulkBankAccountClose',
          params: {accountId:this.accountId},
          enabledGuards: true,
          onApiSuccess(response) {
            const batchId = response.response.id;
            self.$router.push({
                name: 'adminDataImportBatchDetails',
                params: { importBatchId: batchId },
              })
          },
          actionParams: {
            fileName:this.files,
          },
          clickEvent(response) {
  
          }
        },
      ];
      return allButtons;      
    },    
  },
  methods: {
    onSelectionChange(evt) {
      this.files = evt.detail.files[0];
    },
    on_change({ field, value }) {
      this.record[field] = value;
    },

  },
};
</script>
