//@ts-nocheck

import axios from 'axios';

import { createStore } from 'vuex';

import { ENV_VARIABLES } from './config_env';

// import PERMISSIONS from '@/definitions/systemPermissions';

import ACTIONS from '@/definitions/actions';

const configId = ENV_VARIABLES.VUE_CONFIG_ID;

import { initialiseWebSockets, closeWebSockets } from '@/webSockets/LdbWebSocketsFe';

import { getSettingsAndPermissions } from '@/api_gateway';

import { LdbAuthUser, LdbDbObject, LdbUser } from '@/definitions/LdbInterfaces';

import {
  lfx_auth_login,
  lfx_check_current_session,
  lfx_auth_confirm_forgot_password,
  lfx_auth_forgot_password,
  lfx_auth_complete_first_login,
  lfx_auth_complete_otp_login,
  lfx_auth_get_mfa_token,
  lfx_auth_dont_send_mfa_otp,
  lfx_auth_send_mfa_otp,
  lfx_auth_logout,
  lfx_auth_resend_otp,
  lfx_auth_forceRefreshSession,
} from './lfx_auth';

import {
  db_get_collection,
  db_create_document,
  db_create_client,
  db_update_document,
  db_get_document,
  db_put_action,
  db_interface_firestore,
} from '@/db-interface/db_interface_rest';

import { performContextAction, multiLoadPatchContent } from '@/lfx_rest/lfx_action';

// import { db_assign_document_through_store } from '@/db-interface/db_state';

// function user_has_permission(permission, state) {
//   let userPermissions = state.authUser.permissions;
//   let userAccountLevel = state.authUser.accountLevel;
//   // let SSE = Math.round(new Date().getTime() / 1000)  // permisison should not check MFA, only on API call - avr
//   let userHasAccountLevel =
//     permission.accountLevels.length === 0 || permission.accountLevels.indexOf(userAccountLevel) >= 0;
//   let userHasPermissions =
//     permission.permissions.length === 0 || permission.permissions.some((p) => userPermissions.includes(p));
//   // let userHasValidMFA = true; //(permission.mfa === true && state.)  // permisison should not check MFA, only on API call - avr
//   // return userHasAccountLevel && userHasPermissions && userHasValidMFA;  // permisison should not check MFA, only on API call - avr
//   return userHasAccountLevel && userHasPermissions;
// }

function authUserCanAction(action, state) {
  let authUserPermissions = state.authUser.permissions;
  let authUserAccountLevel = state.authUser.accountLevel;
  let authUserHasAccountLevel = action.accountLevels.length === 0 || action.accountLevels.includes(authUserAccountLevel);
  let authUserHasPermission =
    action.permissions.length === 0 || action.permissions.some((p) => authUserPermissions?.includes(p));
  // console.log(action,authUserHasAccountLevel,authUserHasPermission)
  return authUserHasAccountLevel && authUserHasPermission;
}

const { db_load_active_collections } = db_interface_firestore;

import { db_vuex_actions } from '@/db-interface';
import { LdbEventInt } from '@/definitions/LdbEventDef';

import { doPermissionAction } from '@/lfx_rest/lfx_action';
// import { getSystemSettings } from '@/definitions/actions/LfxAdminActions'; // avr was here - this wasn't used anywhere

interface AWSUserDef {
  'custom:lfx_users'?: string;
}

type AuthDef = {
  user: AWSUserDef;
  uuid: string;
  accountUuid: string;
};

export type stateDef = {
  loading: boolean;
  toast: {
    show: boolean;
    type: 'info';
    header: 'Toast';
    message: 'Toast Message';
    timer: undefined | number;
  };
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  loginFailed: boolean;
  isFirstLogin: boolean;
  auth: AuthDef | null;
  accountId: null;
  user: { [id: number]: LdbUser };
  mfa: { expires: number };
  users: { [id: number]: LdbDbObject };
  account: { [id: number]: LdbDbObject; accountLevel: string };
  otp: {
    username?: string;
    password?: string;
    status?: 'pending' | 'failed' | 'timeout' | 'success';
    otpToken?: string;
  };
  mfa: {
    expiry: number;
    mfaToken: string;
    otpSent: boolean;
    requesting: boolean;
    otpType: 'email' | 'sms';
    resolve: (value: any) => void;
    reject: (value: any) => void;
  };
  userPermissions: {};
  userSettings: {};
  dbIntermediarySettings: {};
  clientSettings: {};
  intermediarySettings: {};
  accountSettings: {};
  accountlevel: {
    bank: { name: 'Bank' };
    intermediary: { name: 'Intermediary' };
    intermediaryBranch: { name: 'Intermediary Branch' };
    client: { name: 'Client' };
  };
  config: { 1: { publicHoliday: {} } };
  authUser: LdbAuthUser;
  devSwitch: boolean;
  displaySdlcOption: 'LOCAL' | 'DEV' | 'INT' | 'QA' | 'PROD';
  rates: { [ric: string]: ratesFeedItem };
  events: { [id: string]: LdbEventInt };
  ratesFeedStatus: 'disconnected' | 'connecting' | 'normal' | 'upgraded';
  bankAccount: { [id: number]: LdbDbObject };
  beneficiary: { [id: number]: LdbDbObject };
  signatory: { [id: number]: LdbDbObject };
  signatoryRequest: { [id: number]: LdbDbObject };
  eventsVisible: boolean;
  ratesFeedSettingsVisible: boolean;
  widgetSettingsVisible: boolean;
  timer: undefined;
  now: 0;
  systemNowAsDate: Date;
  systemTodayAsString: String;
  currentSAST: string;
  currentLocalTime: string;
  lfx_auth: undefined;
  // systemNotification: { show: Boolean; title: String; message: String };
  sessionSettings: any;
  feSettings: { version: string };
  systemSettings: {
    system: {
      upgradeInProgress: boolean;
      version: string;
    };
    trading: {
      enabledDealingRoom: boolean;
      enabledRates: boolean;
      enabledSystem: boolean;
      fecAdditionalSpreads: {
        '1month': number;
        '2month': number;
        '3month': number;
        '4month': number;
        '5month': number;
        '6month': number;
        '12month': number;
      };
      forwardOptionalPercentage: number;
      maximumMarkupPercentage: number;
      tradingEndTime: { h: number; m: number; s: number };
      tradingStartTime: { h: number; m: number; s: number };
      sameDayCutOffTimes: {
        outwardDeal: { h: number; m: number; s: number };
        transferDeal: { h: number; m: number; s: number };
        inwardDeal: { h: number; m: number; s: number };
      };
    };
    mfa: {
      mfaEnabled: boolean;
      mfaType: string;
      otpType: 'email' | 'sms';
    };
    general: {
      accessEnabled: boolean;
      generalSystemMessage: string;
      loginEnabled: boolean;
    };
    onboarding: {
      documentUpload: 'signiFlow' | 'local';
    };
    logs: {};
  };
};

type recordField = 'users' | 'accounts' | 'country';

function assignToState(state: stateDef, field: recordField, records: LdbDbObject[]) {
  for (let i = 0; i < records.length; i++) {
    let record: LdbDbObject = records[i];
    let id = record.id;
    state[field][id] = record;
  }
}

const state: stateDef = {
  loading: false,
  isAuthenticated: false,
  isAuthenticating: false,
  loginFailed: false,
  isFirstLogin: false,
  auth: null,
  accountId: null,
  otp: { username: '', password: '', status: '' },
  mfa: {
    expiry: 0,
    mfaToken: '',
    otpSent: false,
    requesting: false,
    reject: null,
    resolve: null,
  },
  toast: {
    show: false,
    type: 'info',
    header: 'Toast',
    message: 'Toast Message',
    timer: undefined,
  },
  config: { 1: { publicHoliday: {} } },
  user: {},
  active: {},
  hover: {},
  account: {},
  transaction: {},
  deal: {},
  payment: {},
  authUser: {},
  userPermissions: {},
  userSettings: {},
  dbIntermediarySettings: {},
  clientSettings: {},
  intermediarySettings: {},
  accountSettings: {},
  window_size: {
    width: 0,
    height: 0,
  },
  accountlevel: {
    bank: { name: 'Bank' },
    intermediary: { name: 'Intermediary' },
    intermediaryBranch: { name: 'Intermediary Branch' },
    client: { name: 'Client' },
  },
  devSwitch: false, //true,
  displaySdlcOption: 'PROD',
  rates: {},
  deal: {},
  swift: {},
  events: {},
  eventPopups: {},
  config: { 1: { id: '1', Table: 'LfxConfig' } },
  ratesFeedStatus: 'disconnected',
  bankAccount: {},
  beneficiary: {},
  signatory: {},
  signatoryRequest: {},
  eventsVisible: false,
  systemNowAsDate: new Date(),
  systemTodayAsString: new Date().toLocaleString('en-ZA').substring(0, 10).replaceAll('/', '-'),
  // currentSAST: new Date().toLocaleString('en-ZA', {
  //   timeZone: 'Africa/Johannesburg',
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   timeZoneName: 'short',
  // }),
  currentSAST: '',
  // currentLocalTime: new Date().toLocaleString('en-ZA', {
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   timeZoneName: 'short',
  // }),
  currentLocalTime: '',
  lfx_auth: undefined,
  // systemNotification: {
  //   show: false,
  //   status: 'warning',
  //   title: 'Trading Hours',
  //   message: 'You are currently operating outside of trading hours.',
  // },
  feSettings: { version: '0.138' },
  sessionSettings: {},
  systemSettings: {
    system: {
      upgradeInProgress: false,
      version: '',
    },
    trading: {
      enabledDealingRoom: true,
      enabledRates: true,
      enabledSystem: true,
      fecAdditionalSpreads: {
        '1month': 30,
        '2month': 60,
        '3month': 90,
        '4month': 120,
        '5month': 160,
        '6month': 200,
        '12month': 200,
      },
      forwardOptionalPercentage: 0.1,
      maximumMarkupPercentage: 0.1,
      sameDayCutOffTimes: {
        outwardDeal: { h: 0, m: 0, s: 0 },
        transferDeal: { h: 0, m: 0, s: 0 },
        inwardDeal: { h: 0, m: 0, s: 0 },
      },
      tradingEndTime: { h: 15, m: 30, s: 0 },
      tradingStartTime: { h: 3, m: 0, s: 0 },
    },
    mfa: {
      mfaEnabled: false,
      mfaType: 'both',
    },
    general: {
      accessEnabled: true,
      generalSystemMessage: ' ',
      loginEnabled: true,
    },
    onboarding: {
      documentUpload: 'signiFlow',
    },
    logs: {},
  },
};
const actions = {
  ...db_vuex_actions,

  lfx_auth_login,
  db_get_collection,
  db_create_document,
  db_create_client,
  db_update_document,
  db_get_document,
  db_put_action,
  db_load_active_collections,
  performContextAction,
  multiLoadPatchContent,

  // db_assign_document_through_store,
  lfx_check_current_session,
  lfx_auth_confirm_forgot_password,
  lfx_auth_complete_first_login,
  lfx_auth_forgot_password,
  lfx_auth_logout,
  lfx_auth_forceRefreshSession,
  lfx_auth_complete_otp_login,
  lfx_auth_resend_otp,
  lfx_auth_dont_send_mfa_otp,
  lfx_auth_send_mfa_otp,
  lfx_auth_get_mfa_token,
  saveUserSettings(context, settings) {
    doPermissionAction({}, 'updateUserSettings', { userId: context.state.authUser.id }, { newSettings: settings });
  },
  saveRatesFeedSetting(context, { rateId, status }) {
    if (context.state.authUser.settings.ratesFeedSettings === undefined) {
      context.state.authUser.settings.ratesFeedSettings = {};
    }
    let ratesFeedSettings = context.state.authUser.settings.ratesFeedSettings;
    context.state.authUser.settings.ratesFeedSettings[rateId] = status;
    doPermissionAction(
      {},
      'updateUserSettings',
      { userId: context.state.authUser.id },
      { newSettings: { ratesFeedSettings } }
    );
  },
  async saveRatesFeedOrder(context, ratesFeedOrder) {
    context.state.authUser.settings.ratesFeedOrder = ratesFeedOrder;
    let saveResult = await doPermissionAction(
      {},
      'updateUserSettings',
      { userId: context.state.authUser.id },
      { newSettings: { ratesFeedOrder } }
    );
  },
  async saveWidgetOrder(context, widgetOrder) {
    context.state.authUser.settings.widgetOrder = widgetOrder;
    let saveResult = await doPermissionAction(
      {},
      'updateUserSettings',
      { userId: context.state.authUser.id },
      { newSettings: { widgetOrder } }
    );
  },

  async saveWidgetSettings(context, widgetSettings) {
    context.state.authUser.settings.widgetSettings = widgetSettings;
    let saveResult = await doPermissionAction(
      {},
      'updateUserSettings',
      { userId: context.state.authUser.id },
      { newSettings: { widgetSettings } }
    );
  },

  async saveUISettings(context, uiSettings) {
    let currentUISettings = context.state.authUser?.settings?.uiSettings || {};
    let updatedUISettings = { ...currentUISettings };

    updatedUISettings.uiSettings = uiSettings;
    for (let setting in uiSettings) {
      updatedUISettings[setting] = uiSettings[setting];
    }

    context.state.authUser.settings.uiSettings = updatedUISettings;
    let saveResult = await doPermissionAction(
      {},
      'updateUserSettings',
      { userId: context.state.authUser.id },
      { newSettings: { uiSettings: updatedUISettings } }
    );
    return saveResult;
  },

  showEvents(context, show) {
    context.state.eventsVisible = show;
  },
  showWidgetSettings(context, show) {
    context.state.widgetSettingsVisible = show;
  },
  showRatesFeedSettings(context, show) {
    context.state.ratesFeedSettingsVisible = show;
  },
  deleteEventNotification(context, notificationId) {
    delete context.state.events[notificationId];
  },
  doDeleteEventPopup(context, popupId) {
    delete context.state.eventPopups[popupId];
  },
  window_size(context, size) {
    context.state.window_size.height = size.height;
    context.state.window_size.width = size.width;
  },
  add_test(context, test) {
    context.state.test = test;
  },
  db_on_error(context, error) {
    context.dispatch('showToast', { type: 'error', header: 'Error', message: error.errorMessage, show: true });
  },
  auth_otp_required(context, payload) {
    context.state.isFirstLogin = true;
    context.state.isAuthenticating = false;
    context.state.isAuthenticated = false;
  },
  auth_password_required(context, payload) {
    context.state.isFirstLogin = true;
    context.state.isAuthenticating = false;
    context.state.isAuthenticated = false;
  },
  environment_loaded(context, environment) {
    context.dispatch('check_current_session', {});
  },
  auth_logout(context, payload) {
    delete context.state.user;
    delete context.state.account;
    delete context.state.lfx_auth;
    delete context.state.deal;
    delete context.state.payment;
    delete context.state.rates;
    delete context.state.swift;
    delete context.state.transaction;
    delete context.state.authUser;
    delete context.state.userPermissions;
    delete context.state.userSettings;
    delete context.state.signatory;
    delete context.state.signatoryRequest;
    delete context.state.clientSettings;
    delete context.state.beneficiary;
    delete context.state.bankAccount;
    delete context.state.accountSettings;

    context.state.user = {};
    context.state.account = {};
    context.state.lfx_auth = {};
    context.state.deal = {};
    context.state.payment = {};
    context.state.rates = {};
    context.state.swift = {};
    context.state.transaction = {};
    context.state.authUser = {};
    context.state.userPermissions = {};
    context.state.userSettings = {};
    context.state.signatory = {};
    context.state.signatoryRequest = {};
    context.state.clientSettings = {};
    context.state.beneficiary = {};
    context.state.bankAccount = {};
    context.state.accountSettings = {};

    if (context.state.config) {
      delete context.state.config;
    }
    if (context.state.auth) {
      delete context.state.auth;
    }
    context.state.events = {};
    context.state.isAuthenticating = false;
    context.state.isAuthenticated = false;
  },
  check_current_session(context, payload) {
    context.state.isAuthenticating = true;
    lfx_check_current_session(context, payload);
  },

  auth_login_required(context, payload) {
    context.state.isAuthenticating = false;
    context.state.isAuthenticated = false;
  },

  auth_first_login(context, payload) {
    context.state.isFirstLogin = true;
  },

  auth_logging_in(context, payload) {
    context.state.isAuthenticating = true;
  },
  auth_login_failed(context, payload) {
    context.state.isAuthenticating = false;
    context.state.isAuthenticated = false;
    context.state.loginFailed = true;
  },

  auth_login_success(context, lfxAuthUser) {
    context.state.timer = setInterval(function () {
      context.commit('set_store_time');
    }, 1000);

    context.state.timer = setInterval(function () {
      context.dispatch('runSecondly');
    }, 1000);

    context.commit('set_auth_headers', { accessToken: lfxAuthUser.accessToken, myUserId: lfxAuthUser.user.user.id });
    if (state.auth === null) {
      state.auth = { user: {} };
    }
    let customLfxUser = lfxAuthUser.user.user;
    // customLfxUser.cognitoId = currentUser.idToken.payload.sub;
    // state.userPermissions
    // state.userPermissions = await getUserPermissions(customLfxUser);
    // state.userSettings = await getUserSettings(customLfxUser);
    // state.clientSettings = await getClientSettings(customLfxUser);
    // state.intermediarySettings = await getIntermediarySettings(customLfxUser);
    // state.accountSettings = await getGeneralSettings(customLfxUser);
    state.authUser = customLfxUser;

    let on_user_loaded = async (user) => {
      // state.isAuthenticated = true;
      let settingsAndPermissions = await getSettingsAndPermissions(context);
      // console.log('settingsAndPermissions', settingsAndPermissions);
      if (['intermediary', 'intermediaryBranch', 'client'].includes(state.authUser.accountLevel)) {
        this.dispatch('load_my_intermediary_settings');
      }

      state.accountSettings = settingsAndPermissions.account;
      // TODO set retrieval status to true

      // let permissions = await user.getPermissions();
      let permissions = settingsAndPermissions.user.permissions;
      let settings = settingsAndPermissions.user.settings;
      let permissionsOnly = Object.entries(permissions)
        .filter(([e, v]) => v === true)
        .map(([e, v]) => e);
      let permissionsObject = {};
      for (let p = 0; p < permissionsOnly.length; p++) {
        let permission = permissionsOnly[p];
        permissionsObject[permission] = true;
      }
      state.authUser.permissions = permissionsOnly;
      state.authUser.settings = settings; //!!!!!

      const myAccount = context.getters.myAccount;
      // state.authUser.accountIsActive = state.account[state.authUser.accountId].status === 'active'
      state.authUser.accountIsActive = myAccount?.status === 'active' && myAccount?.userReviewStatus !== 'nonCompliant';
      context.commit('is_authenticated', { authenticated: true, user: customLfxUser });
      if (
        myAccount?.userReviewStatus !== 'nonCompliant' ||
        state.authUser.accountLevel === 'client'
        // || state.authUser.accountLevel === 'bank'  //2024-09-19 Bank clients should be disabled if no review done
      ) {
        this.dispatch('initialise_web_sockets', customLfxUser);
        // this.dispatch('forceRefreshSession',{})
      }
    };

    this.dispatch('db_get_document', {
      collection_path: `/user`,
      id: state.authUser.id,
      options: { on_receive_document: on_user_loaded },
    });
    this.dispatch('get_on_login_data', customLfxUser);
  },

  runSecondly(context) {
    let now = new Date();
    context.commit('set_systemNowAsDate', now);

    let seconds = now.getSeconds();
    if (seconds == 0) {
      context.dispatch('runMinutely', now);
    }
  },
  runMinutely(context, now) {
    void context.getters.isWithinTradingHours;
    void context.getters.isBeforeSameDayCutOffTimes;

    context.state.currentSAST = context.state.systemNowAsDate.toLocaleString('en-ZA', {
      timeZone: 'Africa/Johannesburg',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });
    context.state.currentLocalTime = context.state.systemNowAsDate.toLocaleString('en-ZA', {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });

    let minutes = now.getMinutes();
    if (minutes == 0) {
      context.dispatch('runHourly', now);
    }
    if (context.state.lfx_auth.isValidSession === false) {
      console.log('LOGGING USER OUT DUE TO SESSION EXPIRED...');
      context.state.lfx_auth.logout();
    }
  },
  runHourly(context, now) {
    context.dispatch('update_systemTodayAsString', now);
  },
  update_systemTodayAsString(context, now) {
    let today = now.toLocaleString('en-ZA').substring(0, 10).replaceAll('/', '-');
    if (context.state.systemTodayAsString !== today) {
      context.commit('set_systemTodayAsString', today);
    }
  },

  showLoader(context, showLoader) {
    context.state.loading = showLoader;
  },

  showToast(context, { type, header, message, show }) {
    if (context.state.toast.timer !== undefined) {
      clearTimeout(context.state.toast.timer);
    }
    context.state.toast.timer = undefined;
    if (show === false) {
      context.state.toast.show = false;
    } else {
      context.state.toast.type = type;
      context.state.toast.header = header;
      context.state.toast.message = message;
      context.state.toast.show = true;
      if (typeof show === 'number') {
        let hide_toast = function () {
          context.dispatch('showToast', { show: false });
        };
        context.state.toast.timer = setTimeout(hide_toast, show);
      }
    }
  },

  showEventPopup(context, { type, header, message, show }) {
    if (context.state.eventPopup.timer !== undefined) {
      clearTimeout(context.state.eventPopup.timer);
    }
    context.state.eventPopup.timer = undefined;
    if (show === false) {
      context.state.eventPopup.show = false;
    } else {
      context.state.eventPopup.type = type;
      context.state.eventPopup.header = header;
      context.state.eventPopup.message = message;
      context.state.eventPopup.show = true;
      if (typeof show === 'number') {
        let hide_event_popup = function () {
          context.dispatch('showEventPopup', { show: false });
        };
        context.state.eventPopup.timer = setTimeout(hide_event_popup, show);
      }
    }
  },

  get_on_login_data(context, user) {
    // if (getters.isDevEnv()) {
    //   console.log('LOADING DATA FOR DEV MODE');
    let accountId = context.state.authUser.accountId;
    context.dispatch('performContextAction', {
      action: 'getAccount',
      pathParams: { accountId: accountId },
      params: {},
    });
    switch (user.accountLevel) {
      case 'bank':
        context.dispatch('multiLoadPatchContent', {
          action: 'getAccounts',
          pathParams: {},
          additionalSelectorObject: { where: { accountLevel: { in: ['intermediary', 'intermediaryBranch'] } } },
        }); //INTERMEDIARIES AND BRANCHES
        context.dispatch('multiLoadPatchContent', {
          action: 'getAccounts',
          pathParams: {},
          additionalSelectorObject: { where: { accountLevel: 'client' }, orderBy: { updatedAt: 'desc' } },
        }); //CLIENTS
        context.dispatch('multiLoadPatchContent', {
          action: 'getUserTeams',
          pathParams: { configId: 1 },
          additionalSelectorObject: {},
        });
        context.dispatch('multiLoadPatchContent', { 
          action: 'getSignatoryRequests', 
          pathParams: { configId: 1 }, 
          additionalSelectorObject: { where:{status:'requested'} } 
        });
        break;
      case 'client':
        context.dispatch('multiLoadPatchContent', {
          action: 'getUserTeams',
          pathParams: { configId: 1 },
          additionalSelectorObject: { accountId: user.accountId },
        });
        break;
      default:
        context.dispatch('multiLoadPatchContent', {
          action: 'getAccounts',
          pathParams: {},
          additionalSelectorObject: { where: { accountLevel: 'intermediaryBranch' } },
        }); //BRANCHES
        context.dispatch('multiLoadPatchContent', {
          action: 'getAccounts',
          pathParams: {},
          additionalSelectorObject: { where: { accountLevel: 'client' }, orderBy: { updatedAt: 'desc' } },
        }); //CLIENTS
    }
    context.dispatch('performContextAction', {
      action: 'getAccountParentMarkup',
      pathParams: { accountId: accountId },
      params: {},
    });
    context.dispatch('multiLoadPatchContent', { action: 'getUsers', pathParams: {}, additionalSelectorObject: {} });

    context.dispatch('multiLoadPatchContent', {
      action: 'getCountries',
      pathParams: { configId: 1 },
      additionalSelectorObject: {},
    });
    context.dispatch('multiLoadPatchContent', {
      action: 'getCurrencies',
      pathParams: { configId: 1 },
      additionalSelectorObject: {},
    });
    context.dispatch('multiLoadPatchContent', {
      action: 'getCurrencyPairs',
      pathParams: { configId: 1 },
      additionalSelectorObject: { orderBy: { index: 'asc' } },
    });
    context.dispatch('multiLoadPatchContent', {
      action: 'getPermissions',
      pathParams: { configId: 1 },
      additionalSelectorObject: {},
    });
    context.dispatch('multiLoadPatchContent', {
      action: 'getPermissionGroups',
      pathParams: { configId: 1 },
      additionalSelectorObject: {},
    });
    context.dispatch('multiLoadPatchContent', {
      action: 'getCommissionLevels',
      pathParams: { configId: 1 },
      additionalSelectorObject: {},
    });
    context.dispatch('multiLoadPatchContent', {
      action: 'getPublicHolidays',
      pathParams: { configId: 1 },
      additionalSelectorObject: {},
    });
    context.dispatch('multiLoadPatchContent', {
      action: 'getBopCatGroups',
      pathParams: { configId: 1 },
      additionalSelectorObject: { orderBy: { id: 'asc' } },
    });
    context.dispatch('multiLoadPatchContent', {
      action: 'getBopCats',
      pathParams: { configId: 1 },
      additionalSelectorObject: { orderBy: { id: 'asc' } },
    });
    context.dispatch('multiLoadPatchContent', {
      action: 'getClientApplicationDocumentTypes',
      pathParams: { configId: 1 },
      additionalSelectorObject: {},
    });

    context.dispatch('loadSystemSettings');

    context.dispatch('multiLoadPatchContent', {
      action: 'getTransactionFiles',
      pathParams: { configId: 1 },
      additionalSelectorObject: {
        where: {
          status: ['new', 'awaitingDealRelease', 'awaitingPayment', 'dealConflict', 'paymentConflict'],
        },
      },
    });
    context.dispatch('multiLoadPatchContent', {
      action: 'getDeals',
      pathParams: { configId: 1 },
      additionalSelectorObject: {
        where: {
          status: ['prepared', 'requested', 'processed', 'booked', 'released', 'fecModificationRequested'],
        },
      },
    });
    context.dispatch('multiLoadPatchContent', {
      action: 'getPayments',
      pathParams: { configId: 1 },
      additionalSelectorObject: {
        where: {
          status: [
            'new',
            'readyForSubmission',
            'submitted',
            'readyForSigning',
            'signed',
            'awaitingSettlement',
            'informationQuery',
            'awaitingFunds',
            'recallRequested',
          ],
        },
      },
    });
    context.dispatch('multiLoadPatchContent', {
      action: 'getInwardSwifts',
      pathParams: { configId: 1 },
      additionalSelectorObject: {
        where: {
          status: ['new', 'available', 'assigned'],
        },
      },
    });

    context.dispatch('multiLoadPatchContent', {
      action: 'getBankAccounts',
      pathParams: { accountId: accountId },
      additionalSelectorObject: {},
    });
    // } else {
    //   console.log('LOADING DATA FOR PROD MODE');
    //   let accountId = context.state.authUser.accountId;
    //   context.dispatch('performContextAction', {
    //     action: 'getAccount',
    //     pathParams: { accountId: accountId },
    //     params: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', { action: 'getAccounts', pathParams: {}, additionalSelectorObject: {} });
    //   context.dispatch('performContextAction', {
    //     action: 'getAccountParentMarkup',
    //     pathParams: { accountId: accountId },
    //     params: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', { action: 'getUsers', pathParams: {}, additionalSelectorObject: {} });

    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getCountries',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getCurrencies',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getCurrencyPairs',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getPermissions',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getPermissionGroups',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getCommissionLevels',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getPublicHolidays',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getBopCatGroups',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getBopCats',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });

    //   context.dispatch('loadSystemSettings');

    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getTransactionFiles',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getDeals',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getPayments',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });
    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getInwardSwifts',
    //     pathParams: { configId: 1 },
    //     additionalSelectorObject: {},
    //   });

    //   context.dispatch('multiLoadPatchContent', {
    //     action: 'getBankAccounts',
    //     pathParams: { accountId: accountId },
    //     additionalSelectorObject: {},
    //   });

    //   // let accountId = context.state.authUser.accountId;
    //   // context.dispatch('db_get_document', { collection_path: '/account', id: accountId });
    //   // doPermissionAction({}, 'getAccountParentMarkup', { accountId: accountId }, {});
    //   // context.dispatch('db_get_collection', { collection_path: '/account' });
    //   // context.dispatch('db_get_collection', { collection_path: '/user' });

    //   // context.dispatch('db_get_collection', {
    //   //   collection_path: `/config/${configId}/country`,
    //   //   options: { additionalSelector: { limit: 500 } },
    //   // });
    //   // context.dispatch('db_get_collection', { collection_path: `/config/${configId}/currency` });
    //   // context.dispatch('db_get_collection', { collection_path: `/config/${configId}/currencyPair` });
    //   // context.dispatch('db_get_collection', { collection_path: `/config/${configId}/permission` });
    //   // context.dispatch('db_get_collection', { collection_path: `/config/${configId}/permissionGroup` });
    //   // context.dispatch('db_get_collection', { collection_path: `/config/${configId}/commissionLevels` });
    //   // context.dispatch('db_get_collection', { collection_path: `/config/${configId}/publicHoliday` });
    //   // doPermissionAction({}, 'getBopCatGroups', { configId: configId });
    //   // doPermissionAction({}, 'getBopCats', { configId: configId }, { additionalSelector: { limit: 600 } });

    //   // context.dispatch('loadSystemSettings');

    //   // context.dispatch('db_get_collection', { collection_path: '/transaction' });
    //   // context.dispatch('db_get_collection', { collection_path: '/deal' });
    //   // context.dispatch('db_get_collection', { collection_path: '/payment' });
    //   // context.dispatch('db_get_collection', { collection_path: '/swift' });
    //   // doPermissionAction(this, 'getBankAccounts', { accountId: accountId }, {});
    // }
  },

  async loadSystemSettings(context) {
    let res = await doPermissionAction({}, 'getSystemSettings', { configId: configId });
    if (res.status === 'success') {
      context.commit('setSystemSettings', res?.response);
    }
  },

  initialise_web_sockets(context, user) {
    initialiseWebSockets(context);
  },
  async load_my_intermediary_settings(context) {
    const settingsResponse = await doPermissionAction({}, 'getDBIntermediarySettings', {
      accountId: state.authUser.accountId,
    });
    if (settingsResponse?.status === 'success') {
      context.state.dbIntermediarySettings = settingsResponse.response;
    }
  },
  // cognitoLogin,
  async checkCurrentSession(context, {}) {
    // let loggedInRes = await authenticateCognitoUser(context);
    let lfxAuthRes = await lfx_check_current_session(context, {});
    if (lfxAuthRes === null) {
      // let loginRes = await lfx_auth_login(context,{username:'administrator',password:'c0nf0und'});
    }
  },
  getUserDetails(context, auth) {
    let lfxUser = auth.user['custom:lfx_users'];
    context.state.accountId = lfxUser.accountId;
  },
  async submitLogin(context, payload) {
    // cognitoLogin(context, payload);
    context.state.isAuthenticating = true;
    return await context.dispatch('lfx_auth_login', payload);
  },

  async logout(context, payload) {
    context.state.isAuthenticated = false;
    closeWebSockets(context);
    context.dispatch('lfx_auth_logout', {});
    return;
  },

  async forceRefreshSession(context, payload) {
    context.dispatch('lfx_auth_forceRefreshSession', {});
    return;
  },
};

const mutations = {
  set_auth_headers(state, headers) {},
  is_authenticated(state, { authenticated, user }) {
    state.isAuthenticated = authenticated;
    state.isAuthenticating = false;
    state.auth = user;
  },
  showEventAsPopup(state, eventObject) {
    state.eventPopups[eventObject.id] = eventObject;
    // let popup = {show:5000,header:'Testing',type:'success',message:'This is a toast message'};
    // context.dispatch('showEventPopup',{});
  },

  set_store_time(state) {
    state.now = Math.round(new Date().getTime() / 1000);
  },

  set_systemNowAsDate(state, now) {
    state.systemNowAsDate = now;
  },
  set_systemTodayAsString(state, today) {
    state.systemTodayAsString = today;
  },

  setSystemSettings(state, payload) {
    if (
      state.sessionSettings?.systemNotificationHasBeenDismissed &&
      state?.systemSettings?.general?.generalSystemMessage !== payload.general?.generalSystemMessage
    ) {
      state.sessionSettings.systemNotificationHasBeenDismissed = false;
    }
    state.systemSettings = payload;
  },

  async authSuccess(state, currentUser) {
    console.error('THE BUG IS HERE...');

    console.error('THIS CODE SHOULD NEVER BE CALLLED!');
    // if (state.auth === null) {
    //   state.auth = { user: {} };
    // }
    // state.auth.user = currentUser.idToken.payload;
    // if (state.auth.user['custom:lfx_users']) {
    //   let customLfxUser = JSON.parse(state.auth.user['custom:lfx_users'])[0];
    //   customLfxUser.cognitoId = currentUser.idToken.payload.sub;
    //   state.authUser = customLfxUser;
    //   let on_user_loaded = async (user) => {
    //     let settingsAndPermissions = await getSettingsAndPermissions(user);
    //     console.log('settingsAndPermissions:',settingsAndPermissions);
    //     let permissions = settingsAndPermissions.user.permissions;
    //     let settings = settingsAndPermissions.user.settings;
    //     let permissionsOnly = Object.entries(permissions)
    //       .filter(([e, v]) => v === true)
    //       .map(([e, v]) => e);
    //     let permissionsObject = {};
    //     for (let p = 0; p < permissionsOnly.length; p++) {
    //       let permission = permissionsOnly[p];
    //       permissionsObject[permission] = true;
    //     }
    //     state.authUser.settings = settings;
    //     state.authUser.permissions = permissionsOnly;
    //     this.dispatch('initialise_web_sockets', currentUser);
    //   };
    //   this.dispatch('db_get_document', {
    //     collection_path: `/user`,
    //     id: state.authUser.id,
    //     options: { on_receive_document: on_user_loaded },
    //   });
    //   this.dispatch('get_accounts', currentUser);
    // }
  },
  // cognitoLoginSuccess,
  clearStore(state) {
    state.auth = null;
  },
  setAuth(state, payload) {
    state.auth = payload;
  },
  saveRates(state, object) {
    state.rates = object;
  },
  saveEvent(state, object) {
    state.events[object.id!] = object;
  },
  deleteEvent(state, object) {
    delete state.events[object.id!];
  },
  deleteOutOfDateEvents(state, object) {
    let continueWithCreate = false;
    if (object.dbObject && object.dbObject.Table && object.dbObject.id && object.timeStamp) {
      for (const eventId in state.events) {
        const eventObject = state.events[eventId];
        if (
          eventObject.id !== object.id &&
          eventObject.dbObject &&
          eventObject.dbObject.Table === object.dbObject.Table &&
          eventObject.dbObject.id === object.dbObject.id &&
          eventObject.dbObject.id === object.dbObject.id
        ) {
          if (eventObject.timeStamp < object.timeStamp) {
            store.commit('deleteEvent', eventObject);
          } else {
            continueWithCreate = false;
          }
        }
      }
    }
    return continueWithCreate;
  },
};

const getters = {
  // System Notifications
  systemNotification(state, getters) {
    const systemNotification = {
      show: false,
      status: 'info',
      title: 'Trading Hours',
      message: 'You are currently operating outside of trading hours.',
      closable: true,
    };
    if (state?.systemSettings?.userReview && state.systemSettings.userReview.enabled) {
      const myAccount = getters.myAccount;
      if (['bank', 'intermediary'].includes(state.authUser?.accountLevel)) {
        const reviewIsDue = myAccount?.userReviewStatus !== 'compliant';
        const nextReviewDueDate =
          state.authUser?.accountLevel === 'bank'
            ? new Date(state.systemSettings.userReview.nextBankReviewDate)
            : new Date(state.systemSettings.userReview.nextIntermediaryReviewDate); //todo
        if (reviewIsDue) {
          const date = new Date();
          const daysToDue = Math.round((nextReviewDueDate.getTime() - date.getTime()) / (1000 * 3600 * 24));
          if (myAccount?.userReviewStatus === 'nonCompliant') {
            systemNotification.show = true;
            systemNotification.title = 'User Review Missed';
            systemNotification.message =
              'According to our records the User review audit was not completed in the time frame provided. Please complete the user review to reinstate users’ access.';
            systemNotification.closable = false;
          } else if (daysToDue <= state?.systemSettings.userReview.daysBeforeBannerAtLogin) {
            systemNotification.show = true;
            systemNotification.title = 'User Review Due';
            systemNotification.message =
              'This is a friendly reminder to complete the User review. Please click on the Reports’ tab, then User Access and action before ' +
              nextReviewDueDate.toDateString() +
              '. Failure to comply will result in Users’ access being revoked.';
            if (daysToDue <= state?.systemSettings.userReview.daysBeforePermanentBanner) {
              systemNotification.closable = false;
            } else if (daysToDue <= state?.systemSettings.userReview.daysBeforePerHourBanner) {
              console.log(' TODO - reset banner every hour');
            }
          }
        }
      } else if (myAccount?.userReviewStatus === 'nonCompliant') {
        systemNotification.show = true;
        systemNotification.title = 'User Review Missed';
        systemNotification.message =
          'According to our records the User review audit was not completed in the time frame provided. Please contact your admin to rectify this.';
        systemNotification.closable = false;
      }
    }
    const systemMessageIsCaptured = (state?.systemSettings?.general?.generalSystemMessage || '').trim() !== '';
    if (systemMessageIsCaptured) {
      systemNotification.show = true;
      systemNotification.title = 'Notification';
      systemNotification.message = state.systemSettings?.general?.generalSystemMessage;
    }
    if (
      state?.systemSettings?.trading?.enabledRates === false ||
      state?.systemSettings?.trading?.enabledDealingRoom === false ||
      state.systemSettings?.trading?.enabledSystem === false
    ) {
      systemNotification.show = true;
      systemNotification.title = 'TRADING DISABLED!';
      systemNotification.status = 'error';
      if (!systemMessageIsCaptured) {
        if (!state?.systemSettings?.trading?.enabledDealingRoom) {
          systemNotification.message = 'Trading has been disabled by the Dealing Room.';
        } else if (!state?.systemSettings?.trading?.enabledSystem) {
          systemNotification.message = 'Trading has been disabled by the System Team.';
        } else if (!state?.systemSettings?.trading?.enabledRates) {
          systemNotification.message = 'Rates feed is not available.';
        }
      }
    }
    return systemNotification;
  },

  //  ----- Auth -----

  authHeaders(state) {
    let headers = {};
    if (state.lfx_auth) {
      if (state?.lfx_auth?.accessToken) {
        let accessToken = state?.lfx_auth?.accessToken;
        headers.accessToken = accessToken;
      }
      if (state?.lfx_auth?.user?.id) {
        let myUserId = state?.lfx_auth?.user?.id;
        headers.myUserId = myUserId;
      }
    }
    return headers;
  },
  hasMFA(state) {
    let now = Math.floor(new Date().getTime() / 1000);
    return state.mfa.expiry === 0 || state.mfa.expiry > now || state.mfa.mfaToken === '';
  },

  // ----- SDLC -----

  sdlc(state, getters) {
    return ENV_VARIABLES.SDLC;
    // return 'LOCAL';
    // return 'DEV';
    // return 'INT';
    // return 'QA';
    // return 'PROD';
  },
  isProdSdlc(state, getters) {
    return getters.sdlc === 'PROD' ? true : false;
  },
  isPreProdSdlc(state, getters) {
    return ['LOCAL', 'DEV', 'INT', 'QA'].includes(getters.sdlc);
  },
  isTestingSdlc(state, getters) {
    return ['LOCAL', 'DEV', 'INT'].includes(getters.sdlc);
  },
  isLocalSdlc(state, getters) {
    return getters.sdlc === 'LOCAL';
  },

  isDevEnv(state, getters) {
    // return process.env.NODE_ENV === 'development';
    return getters.isLocalSdlc;
  },
  isDevelopment(state, getters) {
    let isDevEnv = getters.isDevEnv;
    return isDevEnv && state.devSwitch;
  },

  // ----- Auth User -----

  permissions(state) {
    let res = {};
    for (let a in ACTIONS) {
      let action = ACTIONS[a];
      res[a] = authUserCanAction(action, state);
    }
    return res;
  },
  myAccount(state) {
    return state.account[state.authUser.accountId];
  },
  iAmAnUnmigratedIntermediary(state) {
    const myAccount = getters.myAccount(state);
    return (
      ['intermediary', 'intermediaryBranch'].includes(myAccount.accountLevel) &&
      myAccount.migrationStatus === 'notMigrated' &&
      myAccount.status === 'new'
    );
  },
  // ----- Accounts -----

  intermediaries(state) {
    let res = {};
    for (let c in state.account) {
      let acc = state.account[c];
      if (acc.accountLevel === 'intermediary') {
        res[acc.id] = acc;
      }
    }
    return res;
  },

  clients(state) {
    let res = {};
    for (let c in state.account) {
      let acc = state.account[c];
      if (acc.accountLevel === 'client') {
        res[acc.id] = acc;
      }
    }
    return res;
  },

  // ----- Deals -----

  deals(state) {
    return state.deal;
  },

  dealsByTransactionFileId: (state, getters) => (transactionFileId) => {
    let res = {};
    let deals = getters.deals;
    for (let d in deals) {
      let deal = deals[d];
      if (deal.transactionFileId === transactionFileId) {
        res[d] = deal;
      }
    }
    return res;
  },

  // ----- Payments -----

  bankDirectClients(state, getters) {
    return state.dbIntermediarySettings?.bankDirectClients || state.account[state.authUser.accountId].bankDirectClients;
  },

  payments(state) {
    return state.payment;
  },

  paymentsByTransactionFileId: (state, getters) => (transactionFileId) => {
    let res = {};
    let payments = getters.payments;
    for (let p in payments) {
      let payment = payments[p];
      if (payment.transactionFileId === transactionFileId) {
        res[p] = payment;
      }
    }
    return res;
  },

  // ----- Users -----

  userPermissionRequests(state) {
    let res = {};
    for (let u in state.user) {
      let usr = state.user[u];
      if (usr.permissionStatus === 'requested') {
        res[usr.id] = usr;
      }
    }
    return res;
  },

  upcomingHolidays(state, getters) {
    let today = new Date().getTime();
    let nextWeek = today + 366 * 24 * 60 * 60 * 1000;
    let todayStr = new Date(today).toISOString().split('T')[0];
    let nextWeekStr = new Date(nextWeek).toISOString().split('T')[0];
    let holidays = getters.config.publicHoliday
      ? Object.values(getters.config.publicHoliday)?.filter(
          (holiday) => holiday.date >= todayStr && holiday.date <= nextWeekStr && holiday.status === 'active'
        )
      : [];
    let holidayDates = Array.from(new Set(holidays.map((holiday) => `${holiday.date}`)));
    let holidaysByDate = {};
    for (let d = 0; d < holidayDates.length; d++) {
      let date = holidayDates[d];
      holidaysByDate[date] = { currencies: [], date, names: [] };
      let holidaysOnDate = holidays.filter((holiday) => holiday.date === date);
      for (let h = 0; h < holidaysOnDate.length; h++) {
        let holiday = holidaysOnDate[h];
        holidaysByDate[date].currencies.push({ currency: holiday.currencyId, name: holiday.name });
        if (holidaysByDate[date].names.indexOf(holiday.name) === -1) {
          holidaysByDate[date].names.push(holiday.name);
        }
      }
    }
    return holidaysByDate;
  },

  // ----- Rates -----

  allowedToViewRics(state, getters) {
    const allRics = Object?.values(state.rates)?.map((r) => r.ric) || [];
    if (state.authUser?.accountLevel === 'client') {
      const clientUserAllowedRics = [];
      for (const currencyPairId of getters.currencyPairIdsWithClientMarkups) {
        const currencyPair = getters.currencyPairs?.[currencyPairId];
        if (allRics.includes(currencyPair.instrument)) {
          clientUserAllowedRics.push(currencyPair.instrument);
        }
      }
      return clientUserAllowedRics;
    }
    return allRics;
  },
  sortedAllowedToViewRics(state, getters) {
    const sortedRics = [];
    if (Array.isArray(state.authUser?.settings?.ratesFeedOrder)) {
      for (const savedRicId of state.authUser?.settings?.ratesFeedOrder) {
        if (getters.allowedToViewRics.includes(savedRicId)) {
          sortedRics.push(savedRicId);
        }
      }
    }
    for (const allowedRicId of getters.allowedToViewRics) {
      if (!sortedRics.includes(allowedRicId)) {
        sortedRics.push(allowedRicId);
      }
    }
    return sortedRics;
  },

  // ----- Config -----

  configId(state) {
    return configId;
  },

  config(state, getters) {
    return state.config[getters.configId];
  },

  // Countries

  longCountryNames(state, getters) {
    const longCountries = [];
    const countries = getters.config.country;
    for (const countryId in countries) {
      const country = countries[countryId];
      if (country.name.length > 20) {
        longCountries.push(country.name);
      }
    }
    return longCountries;
  },

  // Public Holidays and Working Days

  todayZARHoliday(state, getters) {
    let todayZARHoliday = undefined;
    let publicHolidays = getters.config.publicHoliday;
    for (let p in publicHolidays) {
      let publicHoliday = publicHolidays[p];
      if (
        publicHoliday.date === state.systemTodayAsString &&
        publicHoliday.currencyId === 'ZAR' &&
        publicHoliday.status === 'active'
      ) {
        todayZARHoliday = publicHoliday.name;
      }
    }
    return todayZARHoliday;
  },
  isTodayZARHoliday(state, getters) {
    return getters.todayZARHoliday ? true : false;
  },
  isTodayWeekend(state, getters) {
    let isTodayWeekend = false;
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(state.systemTodayAsString);
    let day = days[date.getDay()];
    if (['Saturday', 'Sunday'].includes(day)) {
      isTodayWeekend = true;
    }
    return isTodayWeekend;
  },
  isTodayWorkingDay(state, getters) {
    return getters.isTodayWeekend || getters.isTodayZARHoliday ? false : true;
  },

  publicHolidayForDate: (state, getters) => (dateString, currencyPairId) => {
    let publicHolidayForDate = undefined;

    let publicHolidays = getters.config.publicHoliday;

    let currencyPair = getters.config.currencyPair[currencyPairId];

    let currencyIdsArray = ['ZAR'];
    currencyIdsArray.push(currencyPair.baseCurrency);
    currencyIdsArray.push(currencyPair.quoteCurrency);
    currencyIdsArray.push('USD');

    let currencyIdsSet = new Set(currencyIdsArray);
    let uniqueCurrencyIdsArray = Array.from(currencyIdsSet);

    for (let currencyId of uniqueCurrencyIdsArray) {
      if (!publicHolidayForDate) {
        for (let p in publicHolidays) {
          let publicHoliday = publicHolidays[p];
          if (
            publicHoliday.date === dateString &&
            publicHoliday.currencyId === currencyId &&
            publicHoliday.status === 'active'
          ) {
            publicHolidayForDate = publicHoliday;
            break;
          }
        }
      } else {
        break;
      }
    }
    return publicHolidayForDate;
  },

  // Trading

  tradeSettings(state, getters) {
    const settings = {
      allowOutwardDeals: true,
      allowTransferDeals: true,
      allowInwardDeals: true,
      allowOutwardPayments: true,
      allowInwardPayments: true,
      defaultFixedOption: 'pips',
      allowModifyFixedOption: true,
      allowModifyFixedPips: true,
      allowModifyFixedRate: true,
      displayFlatFee: false,
      isFlatFeeMandatory: false,
      allowDeliveryTypes: ['Cash', 'Tom', 'Spot', 'FWD'],
      allowMarkupType: ['fixedRate', 'fixedSpread'],
      fixedRateEditable: true,
      fixedSpreadEditable: true,
      allowIntermediaryMarkupToBeNegative: false,
    };
    switch (
      state.dbIntermediarySettings?.enforceSimplifiedTrading ||
      state.account[state.authUser.accountId].enforceSimplifiedTrading
    ) {
      case 'fixesMarkup':
        settings.allowTransferDeals = false;
        settings.allowInwardDeals = false;
        settings.allowOutwardPayments = false;
        settings.allowInwardPayments = false;
        settings.allowModifyFixedOption = false;
        settings.allowModifyFixedPips = false;
        settings.allowModifyFixedRate = false;
        settings.displayFlatFee = true;
        break;
      case 'fixedRate':
        settings.allowTransferDeals = false;
        settings.allowInwardDeals = false;
        settings.allowOutwardPayments = false;
        settings.allowInwardPayments = false;
        settings.defaultFixedOption = 'rate';
        settings.allowModifyFixedOption = false;
        settings.allowModifyFixedPips = false;
        settings.allowModifyFixedRate = false;
        settings.displayFlatFee = true;
        settings.isFlatFeeMandatory = true;
        settings.allowDeliveryTypes = ['Cash', 'Tom', 'Spot'];
        settings.allowMarkupType = ['fixedRate'];
        settings.fixedRateEditable = false;
        settings.fixedRateEditable = false;
        if (getters.bankDirectClients) {
          settings.allowIntermediaryMarkupToBeNegative = true;
        }
        break;
      case 'none':
      default:
    }
    return settings;
  },

  tradingStartTime_asDate(state, getters) {
    let date = new Date();
    let h = state.systemSettings.trading.tradingStartTime.h;
    let m = state.systemSettings.trading.tradingStartTime.m;
    let s = state.systemSettings.trading.tradingStartTime.s;
    date.setUTCHours(h, m, s);
    return date;
  },
  tradingEndTime_asDate(state, getters) {
    let date = new Date();
    let h = state.systemSettings.trading.tradingEndTime.h;
    let m = state.systemSettings.trading.tradingEndTime.m;
    let s = state.systemSettings.trading.tradingEndTime.s;
    date.setUTCHours(h, m, s);
    return date;
  },
  isWithinTradingHours(state, getters) {
    return (
      state.systemNowAsDate >= getters.tradingStartTime_asDate && state.systemNowAsDate < getters.tradingEndTime_asDate
    );
  },

  sameDayCutOffTimes_asDates(state, getters) {
    const outwardDealDate = new Date();
    outwardDealDate.setUTCHours(
      state.systemSettings.trading.sameDayCutOffTimes.outwardDeal.h,
      state.systemSettings.trading.sameDayCutOffTimes.outwardDeal.m,
      state.systemSettings.trading.sameDayCutOffTimes.outwardDeal.s
    );
    const transferDealDate = new Date();
    transferDealDate.setUTCHours(
      state.systemSettings.trading.sameDayCutOffTimes.transferDeal.h,
      state.systemSettings.trading.sameDayCutOffTimes.transferDeal.m,
      state.systemSettings.trading.sameDayCutOffTimes.transferDeal.s
    );
    const inwardDealDate = new Date();
    inwardDealDate.setUTCHours(
      state.systemSettings.trading.sameDayCutOffTimes.inwardDeal.h,
      state.systemSettings.trading.sameDayCutOffTimes.inwardDeal.m,
      state.systemSettings.trading.sameDayCutOffTimes.inwardDeal.s
    );

    return { outwardDeal: outwardDealDate, transferDeal: transferDealDate, inwardDeal: inwardDealDate };
  },
  isBeforeSameDayCutOffTimes(state, getters) {
    return {
      outwardDeal: state.systemNowAsDate < getters.sameDayCutOffTimes_asDates.outwardDeal,
      transferDeal: state.systemNowAsDate < getters.sameDayCutOffTimes_asDates.transferDeal,
      inwardDeal: state.systemNowAsDate < getters.sameDayCutOffTimes_asDates.inwardDeal,
    };
    // TODO
  },

  isTradingEnabled(state, getters) {
    return (
      state.systemSettings.trading.enabledDealingRoom &&
      state.systemSettings.trading.enabledRates &&
      state.systemSettings.trading.enabledSystem
    );
  },

  isRatesFeedConnected(state, getters) {
    return ['normal', 'upgraded'].includes(state.ratesFeedStatus);
  },

  hasAllSystemRequirementsForTradingBeenMet(state, getters) {
    return (
      getters.isTradingEnabled &&
      getters.isRatesFeedConnected &&
      getters.isTodayWorkingDay &&
      getters.isWithinTradingHours
    );
  },

  // getDealValueDates(state, getters) {
  //   let valueDates = new Set();
  //   for (let dealId in state.deal) {
  //     let deal = state.deal[dealId];
  //     valueDates.add(deal.valueDate);
  //   }
  //   return valueDates;
  // },

  // Currency Pairs

  currencies(state, getters) {
    return getters.config?.currency || {};
  },

  currencyPairs(state, getters) {
    return getters.config?.currencyPair || {};
  },

  currencyPairIdsWithClientMarkups(state, getters) {
    const res = [];
    if (state.authUser?.accountLevel === 'client') {
      const accountRecord = state.account?.[state.authUser?.accountId];
      for (const accountMarkupId in accountRecord?.markup) {
        const accountMarkup = accountRecord?.markup?.[accountMarkupId];
        if (accountMarkup.status === 'visible') {
          res.push(accountMarkup.currencyPair);
        }
      }
    }
    return res;
  },

  activeCurrencyPairs(state, getters) {
    // TODO filter on active status - e.g. for future if we ever get rid of inverted rates or need to disable currencies separate from disabling trading
    return getters.currencyPairs;
  },
  allowTradingCurrencyPairs(state, getters) {
    let allowTradingCurrencyPairs = {};
    let availableCurrencypairs = getters.activeCurrencyPairs;
    for (let cp in availableCurrencypairs) {
      let currencyPair = availableCurrencypairs[cp];
      if (currencyPair.allowTrading && getters.allowedToViewRics?.includes(currencyPair?.instrument)) {
        allowTradingCurrencyPairs[cp] = currencyPair;
      }
    }
    return allowTradingCurrencyPairs;
  },

  // BOP Categories

  activeBopCatInfo(state, getters) {
    let info = [];
    let bopCats = getters.config.bopCat;
    for (let bc in bopCats) {
      let bopCat = bopCats[bc];
      if (bopCat.status === 'active') {
        let bopCatInfo = [...bopCat.requiredInfo, ...bopCat.optionalInfo];
        bopCatInfo.forEach((i) => info.push(i));
      }
    }
    let activeBopCatInfo = new Set(info);
    let activeBopCatInfoArray = Array.from(activeBopCatInfo).sort();
    return activeBopCatInfoArray;
  },
  bopCatsWithIncorrectInfo(state, getters) {
    let incorrectBopCatInfo = {
      // Agreement: [], // old example
    };
    let bopCats = getters.config.bopCat;
    for (let bc in bopCats) {
      let bopCat = bopCats[bc];
      if (bopCat.status === 'active') {
        let bopCatInfo = [...bopCat.requiredInfo, ...bopCat.optionalInfo];
        for (const wrongInfo in incorrectBopCatInfo) {
          if (bopCatInfo.includes(wrongInfo)) {
            incorrectBopCatInfo?.[wrongInfo]?.push(bopCat.id);
          }
        }
      }
    }
    return incorrectBopCatInfo;
  },

  activeBopCatDocs(state, getters) {
    let documents = [];
    let bopCats = getters.config.bopCat;
    for (let bc in bopCats) {
      let bopCat = bopCats[bc];
      if (bopCat.status === 'active') {
        let bopCatDocuments = [...bopCat.requiredDocuments, ...bopCat.optionalDocuments];
        bopCatDocuments.forEach((doc) => documents.push(doc));
      }
    }
    let activeBopCatDocs = new Set(documents);
    let activeBopCatDocsArray = Array.from(activeBopCatDocs).sort();
    return activeBopCatDocsArray;
  },
  bopCatsSortedByDocsAndInfo(state, getters) {
    let res = { docs: { requiredDocuments: {}, optionalDocuments: {} }, info: { requiredInfo: {}, optionalInfo: {} } };
    for (const doc of getters.activeBopCatDocs) {
      res.docs.requiredDocuments[doc] = [];
      res.docs.optionalDocuments[doc] = [];
    }
    for (const info of getters.activeBopCatInfo) {
      res.info.requiredInfo[info] = [];
      res.info.optionalInfo[info] = [];
    }

    let bopCats = getters.config.bopCat;
    for (const bc in bopCats) {
      const bopCat = bopCats[bc];
      for (const doc of bopCat.requiredDocuments) {
        res.docs.requiredDocuments[doc].push(bopCat.id);
      }
      for (const doc of bopCat.optionalDocuments) {
        res.docs.optionalDocuments[doc].push(bopCat.id);
      }
      for (const info of bopCat.requiredInfo) {
        res.info.requiredInfo[info].push(bopCat.id);
      }
      for (const info of bopCat.optionalInfo) {
        res.info.optionalInfo[info].push(bopCat.id);
      }
    }
    return res;
  },
  bopCatsWithIncorrectDocs(state, getters) {
    let incorrectBopCatDocs = {
      // invoice: [], // old example
    };
    let bopCats = getters.config.bopCat;
    for (let bc in bopCats) {
      let bopCat = bopCats[bc];
      if (bopCat.status === 'active') {
        let bopCatDocuments = [...bopCat.requiredDocuments, ...bopCat.optionalDocuments];
        for (const wrongDoc in incorrectBopCatDocs) {
          if (bopCatDocuments.includes(wrongDoc)) {
            incorrectBopCatDocs?.[wrongDoc]?.push(bopCat.id);
          }
        }
      }
    }
    return incorrectBopCatDocs;
  },
  bopCatsWithNoDocsRequired(state, getters) {
    // TODO remove this getter once payments can be saved where no docs are required - avr
    let res = [];
    for (let bopCatId in getters.config.bopCat) {
      let bopCat = getters.config.bopCat[bopCatId];
      if (bopCat.requiredDocuments.length == 0 && bopCat.optionalDocuments.length == 0) {
        res.push(bopCat);
      }
    }
    return res;
  },

  // ----- Unused? -----
  // activeUser(state) {
  //   return state.user[state.active.userId];
  // },
};

const store = createStore<stateDef>({
  state,
  getters,
  mutations,
  actions,
});

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  window.state = store.state;
  // @ts-ignore
  window.store = store;
}

export default store;
