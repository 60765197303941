<template>
  <LDBLoader v-if="signatory_retrievalStatus === 'retrieving'" />
  <LDBItemView
    v-if="signatory_retrievalStatus === 'retrieved' && hasSignatoryRecordLoaded"
    :view="view"
    :definition="signatoryDefinition"
    :record="signatoryRecord"
    :action="action"
    :params="params"
    @on-save="onSave"
    @on-api-success="onApiSuccess"
  />
  <RouterView />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';

export default {
  name: 'LfxSignatoryDetails',
  components: { LDBItemView },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['account', 'user', 'signatory']),

    // ----- View -----

    view() {
      return this.$route.meta?.content?.view || 'item';
    },

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId] || {};
    },

    // ----- User Record -----

    userId() {
      return +this.$route.params?.userId;
    },
    userRecord() {
      return this.user?.[this.userId];
    },

    // ----- Signatory Record -----

    signatoryId() {
      return +this.$route.params?.signatoryId;
    },
    signatoryRecord() {
      return this.userId ? this.userRecord?.userSignatory : this.signatory?.[this.signatoryId] || {};
    },

    signatoryDefinition() {
      return getDefinition('signatory');
    },

    signatory_retrievalStatus() {
      return this.userId ? this.userRecord?.userSignatory_retrievalStatus : 'retrieved'; // TODO fix this for account signatories
    },

    hasSignatoryRecordLoaded() {
      return this.signatoryRecord?.definition ? true : false;
    },

    // ----- API action -----

    action() {
      return 'editSignatory';
    },
    params() {
      return {
        accountId: this.accountId,
        signatoryId: this.signatoryId,
      };
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    onSave({ fields }) {
    },

    onApiSuccess() {
      this.$router.go(-1);
    },
  },
};
</script>
