<template>
  <div class="lfx-inward-swift-page">
    <capitec-content-box>
      <div>
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="undefined"
          :record="optionsRecord"
          @change="on_change"
        />
      </div>
      <capitec-action-bar>
        <LDBButton
          v-for="button in formButtons"
          :key="button.name"
          :label="button.label"
          :action="button.action"
          :actionParams="button.actionParams"
          :params="button.params"
          :type="button.type"
          :tooltip="button.tooltip"
          :modal="button.modal"
          :viewGuards="button.guards"
          :enabledGuards="button.enabledGuards"
          :onSuccess="button.onSuccess"
          @beforeClick="button.beforeClick"
          @click="button.onClick"
          @on-api-success="button.onApiSuccess"
          @on-api-error="button.onApiError"
        >
          <template v-if="button.modal !== undefined" #modal>
            <div v-html="button.modal.content"></div>
          </template>
        </LDBButton>
      </capitec-action-bar>
      <template v-if="dataStatus === 'retrieving'">
        <LDBLoader />
      </template>
      <div
        v-else-if="dataStatus === 'retrieved' && reportData && Object.keys(reportData).length > 0"
        class="lfx-inward-swift-table-container"
      >
        <LFXListView :definition="definition" :records="reportData" />
      </div>
      <div v-else-if="dataStatus === 'retrieved'">No Data Returned for these selectors</div>
    </capitec-content-box>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBField from '@/landobyte_vue/LDBField.vue';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';
import LFXListView from '@/views/general/LFXListView.vue';

export default {
  name: 'LfxInwardSwiftReport',
  components: { LDBField, LDBLoader, LFXListView },
  props: {},
  data() {
    return {
      selectorsUpdated: true,
      dataStatus: 'none',
      optionsRecord: {
        reportType: 'previousTradingDay',
        dateFrom: new Date().toISOString(),
        dateTo: new Date().toISOString(),
      },
      reportData: undefined,
    };
  },
  created() {},
  mounted() {
    this.optionsRecord.dateFrom = this.previousTradingDay.toISOString();
    this.optionsRecord.dateTo = this.previousTradingDay.toISOString();


    console.log(this.definition)
  },
  computed: {
    ...mapGetters(['currencies']),
    ...mapState([]),

    // ----- Buttons -----

    formButtons() {
      let self = this;
      return [
        {
          name: 'generateReport',
          label: 'Generate Report',
          type: 'primary',
          action: 'getInwardSwifts',
          onSuccess: { doStoreUpdate: false },
          enabledGuards: this.selectorsUpdated === true,
          actionParams: {
            additionalSelector: this.additionalSelector,
            paymentWhereClause: this.paymentWhereClause
          },
          beforeClick() {
            self.dataStatus = 'retrieving';
          },
          onApiSuccess: self.onApiSuccess,
          onApiError() {
            self.dataStatus = 'error';
          },
        },
        {
          name: 'downloadSwifts',
          label: 'Download',
          type: '',
          guards: self.dataStatus === 'retrieved',
          action: 'downloadInwardSwifts',
          params: {},
          onSuccess: { doStoreUpdate: false },
          actionParams: { additionalSelector: this.additionalSelector, paymentWhereClause: this.paymentWhereClause },
        },
      ];
    },
    zarCurrency() {
      return this.currencies['ZAR']
    },

    previousTradingDay() {
      const today = new Date();
      return this.zarCurrency.previousValidValueDate(today);
    },
    previousCalendarDay() {
      const today = new Date();
      return new Date(new Date(today).setDate(today.getDate() - 1))},
    additionalSelector() {
      switch (this.optionsRecord.reportType) {
        case 'openSwifts':
            return {
            where: {
              and: [
                { status: { notIn: [ 'complete', 'cancelled'] } },
              ],
            },
          };
        case 'incompleteSwifts':
          return {
              where: {
                and: [
                  { status: { in: ['assigned'] } },
                ],
              },
            };
        case 'previousTradingDay':
        case 'previousCalendarDay':
        case 'other': 
        return {
          where: {
            and: [
              { valueDate: { gte: this.optionsRecord.dateFrom } },
              { valueDate: { lte: this.optionsRecord.dateTo } },
            ],
          },
      };
      }
    },
    paymentWhereClause() {
      if (this.optionsRecord.reportType === 'incompleteSwifts') {
        return {status:'complete'}
      }
      return undefined
    },

    // ----- Fields -----

    viewFields() {
      const allFields = {
        reportType: {
          name: 'reportType',
          label: 'Report Type',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'openSwifts', name: 'All Open SWIFTs' },
                { id: 'incompleteSwifts', name: 'Incomplete Swifts on Completed Payments' },
                { id: 'previousTradingDay', name: 'Previous Trading Day' },
                { id: 'previousCalendarDay', name: 'Previous Calendar Day' },
                { id: 'other', name: 'Other' },
              ],
            },
          },
          view: 'edit',
          guards: true,
        },
        dateFrom: {
          name: 'dateFrom',
          label: 'Value Date From',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2014/01/01',
              maxDate: { type: 'day', offset: 0 },
            },
          },
          enableGuards: true,
          view: this.optionsRecord.reportType === 'other' ? 'edit' : 'item',
          guards: !['openSwifts','incompleteSwifts'].includes(this.optionsRecord.reportType),
        },
        dateTo: {
          name: 'dateTo',
          label: 'Value Date To',
          datatype: {
            datetime: {
              type: 'date',
              format: 'human',
              minDate: '2014/01/01',
              maxDate: { type: 'day', offset: 7 },
            },
          },
          enableGuards: true,
          view: this.optionsRecord.reportType === 'other' ? 'edit' : 'item',
          guards: !['openSwifts','incompleteSwifts'].includes(this.optionsRecord.reportType),
        },
      };
      const returnFields = {};
      for (const f in allFields) {
        if (allFields[f].guards !== false) {
          returnFields[f] = allFields[f];
        }
      }
      return returnFields;
    },
    definitionName() {
      return 'inward_swift';
    },
    definition() {
      return getDefinition(this.definitionName);
    },
    collectionPathTemplate() {
      return this.definition?.collectionPath;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    on_change({ field, value }) {
      this.optionsRecord[field] = value;
      if (field === 'reportType') {
        switch (value) {
          case 'previousTradingDay':
            this.optionsRecord.dateFrom = this.previousTradingDay.toISOString();
            this.optionsRecord.dateTo = this.previousTradingDay.toISOString();
            break
          case 'previousCalendarDay':
            this.optionsRecord.dateFrom = this.previousCalendarDay.toISOString();
            this.optionsRecord.dateTo = this.previousCalendarDay.toISOString();
            break
        }
      }
      this.selectorsUpdated = true;
      console.log("this.paymentWhereClause",this.paymentWhereClause)
    },
    onApiSuccess(response) {
      this.reportData = {};
      for (const row of response.response?.rows) {
        row.collection_path = Mustache.render(this.collectionPathTemplate, row);
        this.reportData[row.id] = this.definition.class(row, this.$store);
      }
      this.dataStatus = 'retrieved';
      this.selectorsUpdated = false;
    },
  },
};
</script>

<style>
.lfx-inward-swift-table-container {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.lfx-inward-swift-page {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
</style>
