//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxPaymentDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Payment record for LandoByte TS systems
//  Created     : 14 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import {
  intermediaryFieldViewGuard,
  fieldsAreNotTheSameViewGuard,
  displayBranchForListView,
} from '../sharedUtils/LdbDefinitionUtils';
import type { LdbAuthUser, LdbDefinition, LdbViewFunction } from '../definitions/LdbInterfaces';
import { standardTextWhiteList } from '../sharedUtils/LdbValidations';
import type { LfxBopCatAllowanceType, LfxBopCatInt } from './LfxBopCatDef';
import { LfxAccountSignatoryOption } from './LfxAccountDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxPaymentStatus =
  | 'new'
  | 'readyForSubmission'
  | 'submitted'
  | 'readyForSigning'
  | 'signed'
  | 'awaitingSettlement'
  | 'settled'
  | 'complete'
  | 'informationQuery'
  | 'awaitingFunds'
  | 'recallRequested'
  | 'cancelled'
  | 'deleted';
export type LfxPaymentDirection = 'inward' | 'outward' | 'transfer';
export type LfxPaymentDetailsOfCharge = 'OUR' | 'SHARE' | 'BEN';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxPaymentInt {
  id?: number;
  uuid?: string;
  transactionFileId?: number;
  accountId?: number;
  intermediaryId?: number;
  branchId?: number;
  originaltransactionFileId?: number;

  reference?: string;
  externalReference?: string;

  status?: LfxPaymentStatus;

  paymentCurrency?: string;
  paymentAmount?: number;
  foreignCurrency?: string;
  foreignAmount?: number;
  originalValueDate?: Date;
  valueDate?: Date;
  direction?: LfxPaymentDirection;

  sourceAccount?: number;
  destinationAccount?: number;
  inwardSwiftId?: number;

  isCrypto?: boolean;
  previousCryptoPaymentId?: number;
  numberOfBopCats?: number;
  bopCategories?: {
    bopCat: string;
    foreignAmount: number;
    allowanceIndicators?: LfxBopCatAllowanceType;
    fiaPin?: string;
  }[];

  detailsOfCharge?: LfxPaymentDetailsOfCharge;
  beneficiaryReference?: string;
  specialInstructions?: string;

  purposeOfPayment?: string;
  sarbApprovalNumber?: string;
  sarbAuthReferenceNumber?: string;
  sarbLoanReferenceNumber?: string;
  ucrNumber?: string;
  sarsApnNumber?: string;

  taxClearanceCertificateRef?: string;
  loanTenor?: string;
  loanInterestRate?: number;

  importPermitConfirmation?: string;
  invoiceNumber?: string;
  mrn?: string;
  sourceOfFunds?: string;
  reason?: string;

  signatoryOption?: LfxAccountSignatoryOption;
  signatories?: number[];
  documents?: {}[];

  submittedCount?: number;

  integrationId?: string;
  currentPortfolioId?: number;
  rejectionReason?: string;

  submittedAt?: Date;
  submittedBy?: number;
  readyForSigningAt?: Date;
  signedAt?: Date;
  completedAt?: Date;
  rejectedAt?: Date;

  mostRecent?: Date;

  paymentLocked?: boolean;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

const addToStoreIfNotExist = (_dbObject: LfxPaymentInt, authUser: LdbAuthUser) => {
  if (authUser.accountLevel !== 'bank') {
    return true;
  }
  const requriedPermissions = ['releasePayment'];
  var hasPermission = false;
  for (const permission of requriedPermissions) {
    if (authUser.permissions.includes(permission)) {
      hasPermission = true;
    }
  }
  return hasPermission;
};

//----------------------------------------------------------------------------------------------------------------------

export const viewIsBeneficiaryPaymentList: LdbViewFunction = (viewFunctionObject) => {
  return !!viewFunctionObject.routeMetaData?.viewIsBeneficiaryPaymentList;
};

//----------------------------------------------------------------------------------------------------------------------

export const isValidSarsApnNumber = (value: string | null | undefined, _object?: object) => {
  if (!value) {
    return true;
  }
  if (!['APN', 'BGA'].includes(value.substring(0, 3))) {
    return false;
  }
  return value.length <= 35 && value.length >= 30;
};

//----------------------------------------------------------------------------------------------------------------------

export const LfxPaymentDef: LdbDefinition = {
  title: 'Paymenf',
  table: 'LfxPayment',
  // collectionPath: '/transaction/{{transactionFileId}}/payment',
  collectionPath: '/payment', // avr was here
  addToStoreIfNotExist: addToStoreIfNotExist,
  groups: {
    system: { priority: 1, label: 'General' },
    payment: { priority: 2, label: 'Payment' },
    integration: { priority: 3, label: 'BOP Portfolio' },
    bopCategories: { priority: 4, label: 'BOP Categories' },
    bopInfo: { priority: 5, label: 'BOP Info' },
    documents: { priority: 6, label: 'Documents' },
    signatories: { priority: 7, label: 'Signatories' },
    changes: { priority: 8, label: 'Changes' },
  },
  view: {
    create: true,
    edit: true,
    item: true,
    list: true,
    delete: false,
    csv: false,
    tiles: {
      default: {
        layout: [
          ['title', 'arrow'],
          ['subtitle', 'subtitle'],
          ['status', 'infoValue'],
          ['status', 'infoLabel'],
        ],
        color: { type: 'field', field: 'status', optionField: 'tagType', colorType: 'class' },
      },
    },
  },
  actions: {
    list: 'getPayments',
    item: 'getPayment',
  },
  indexes: ['transactionFileId', 'accountId', 'branchId'],
  pagingType: 'backEnd',
  fields: {
    id: {
      label: 'Reference',
      datatype: { id: { descriptionField: 'reference' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    transactionFileId: {
      label: 'Transaction File',
      datatype: {
        foreignKey: {
          collection: 'fk_transaction_files',

          linkTable: 'LfxTransactionFile',
          linkField: 'id',
          displayField: 'reference',
          sequelizeBelongsTo: 'TransactionFile',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'system',
    },
    accountId: {
      label: 'Client',
      datatype: {
        foreignKey: {
          collection: 'fk_clients',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Client',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'system',
    },
    clientCif: {
      label: 'Client CIF',
      datatype: {
        foreignKey: {
          collection: 'fk_clients',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'cifNumber',
          localForeignKeyLinkField: 'accountId',
          sequelizeBelongsTo: 'Client',
        },
      },
      default: null,
      mandatory: false,
      property: { read: true, write: false, source: 'backend', sort: 'none' },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: true },
      group: 'system',
    },
    clientExternalReference: {
      label: 'Client External Reference',
      datatype: {
        foreignKey: {
          collection: 'fk_clients',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'externalReference',
          localForeignKeyLinkField: 'accountId',
          sequelizeBelongsTo: 'Client',
        },
      },
      default: null,
      mandatory: false,
      property: { read: true, write: false, source: 'backend', sort: 'none' },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: true },
      group: 'system',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Intermediary',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: intermediaryFieldViewGuard,
        list: intermediaryFieldViewGuard,
        delete: false,
        csv: true,
      },
      group: 'system',
    },
    branchId: {
      label: 'Branch',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_branches',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Branch',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: displayBranchForListView, delete: false, csv: true },
      group: 'system',
    },
    originaltransactionFileId: {
      label: 'Original Transaction File',
      datatype: {
        foreignKey: {
          collection: 'fk_transaction_files',
          linkTable: 'LfxTransactionFile',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: false,
        delete: false,
        csv: true,
        search: true,
        tiles: { default: 'title' },
      },
      group: 'payment',
    },
    externalReference: {
      label: 'External Reference',
      datatype: 'text',
      default: null,
      mandatory: false,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'External Reference should be between 0 and 250 characters', args: [0, 250] },
      },
      views: { create: false, edit: true, item: true, list: true, delete: false, csv: true, search: true },
      group: 'payment',
    },

    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'Details Outstanding', tagType: 'info' },
            { id: 'readyForSubmission', name: 'Ready For Submission', tagType: 'info' },
            { id: 'submitted', name: 'Submitted', tagType: 'warning' },
            { id: 'readyForSigning', name: 'Ready For Signing', tagType: 'info' },
            { id: 'signed', name: 'Awaiting Bank Validation', tagType: 'warning' },
            { id: 'awaitingSettlement', name: 'Awaiting Settlement', tagType: 'warning' },
            { id: 'settled', name: 'Settled', tagType: 'warning' },
            { id: 'complete', name: 'Completed', tagType: 'success' },
            { id: 'informationQuery', name: 'Information Query', tagType: 'warning' },
            { id: 'awaitingFunds', name: 'Awaiting Funds', tagType: 'info' },
            { id: 'recallRequested', name: 'Recall Requested', tagType: 'warning' },
            { id: 'cancelled', name: 'Cancelled', tagType: 'cancelled' },
            { id: 'deleted', name: 'Deleted', tagType: 'cancelled' },
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: false,
        list: true,
        delete: false,
        search: true,
        csv: true,
      },
      group: 'system',
    },
    consolidatedStatus: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'Details Outstanding', tagType: 'info' },
            { id: 'readyForSubmission', name: 'Ready For Submission', tagType: 'info' },
            { id: 'submitted', name: 'Submitted', tagType: 'warning' },
            { id: 'readyForSigning', name: 'Ready For Signing', tagType: 'info' },
            { id: 'signed', name: 'Awaiting Bank Validation', tagType: 'warning' },
            { id: 'awaitingSettlement', name: 'Awaiting Settlement', tagType: 'warning' },
            { id: 'settled', name: 'Settled', tagType: 'warning' },
            { id: 'complete', name: 'Completed', tagType: 'success' },
            { id: 'informationQuery', name: 'Information Query', tagType: 'info' },
            { id: 'awaitingFunds', name: 'Awaiting Funds', tagType: 'info' },
            { id: 'recallRequested', name: 'Recall Requested', tagType: 'warning' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
            { id: 'deleted', name: 'Deleted', tagType: undefined },

            { id: 'bopCatAmountConflict', name: 'BOP Cat Amount Conflict', tagType: 'error' },
          ],
        },
      },
      property: { read: true, write: false, source: 'frontend', sort: 'status' },
      default: 'new',
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: false,
        delete: false,
        csv: false,
        tiles: { default: 'status' },
      },
      group: 'system',
    },

    paymentCurrency: {
      label: 'Payment Currency',
      datatype: {
        foreignKey: {
          collection: 'fk_currencies',

          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: true,
        delete: false,
        csv: true,
        tiles: { default: 'infoLabel' },
      },
      group: 'payment',
    },
    paymentAmount: {
      label: 'Payment Amount',
      datatype: { currency: { symbol: '' } },
      default: 0,
      mandatory: true,
      allowNullValues: false,
      views: {
        create: false,
        edit: true,
        item: true,
        list: true,
        delete: false,
        csv: true,
        tiles: { default: 'infoValue' },
      },
      group: 'payment',
    },
    foreignCurrency: {
      label: 'Foreign Currency',
      datatype: {
        foreignKey: {
          collection: 'fk_currencies',

          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: true },
      group: 'payment',
    },
    foreignAmount: {
      label: 'Foreign Amount',
      datatype: { currency: { symbol: '' } },
      default: 0,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, search: true, csv: true },
      group: 'payment',
    },
    originalValueDate: {
      label: 'Original Value Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: {
        create: false,
        edit: false,
        item: fieldsAreNotTheSameViewGuard('valueDate', 'originalValueDate'),
        list: false,
        delete: false,
        search: true,
        csv: true,
      },
      group: 'payment',
    },
    valueDate: {
      label: 'Value Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      displayType: {
        displayAsTag: true,
        tagTypeFunctionName: 'valueDateTagType',
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: true, delete: false, search: true, csv: true },
      group: 'payment',
    },
    direction: {
      label: 'Payment Direction',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'inward', name: 'Inward' },
            { id: 'outward', name: 'Outward' },
            { id: 'transfer', name: 'Transfer' },
          ],
        },
      },
      default: 'outward',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, search: true, csv: true },
      group: 'payment',
    },

    sourceAccount: {
      label: 'Source Account',
      datatype: {
        foreignKey: {
          collection: 'fk_bank_accounts',

          linkTable: 'LfxBankAccount',
          linkField: 'id',
          displayField: 'description',
          sequelizeBelongsTo: 'SourceAccount',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'payment',
    },
    destinationAccount: {
      label: 'Destination Account',
      datatype: {
        foreignKey: {
          collection: 'fk_bank_accounts',

          linkTable: 'LfxBankAccount',
          linkField: 'id',
          displayField: 'description',
          sequelizeBelongsTo: 'DestinationAccount',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: viewIsBeneficiaryPaymentList, delete: false, csv: true },
      group: 'payment',
    },
    destinationAccountCountry: {
      label: 'Destination Account Bank Country',
      datatype: {
        foreignKey: {
          collection: 'fk_bank_accounts',
          linkTable: 'LfxBankAccount',
          linkField: 'id',
          displayField: 'bankCountryId',
          localForeignKeyLinkField: 'destinationAccount',
          sequelizeBelongsTo: 'DestinationAccount',
        },
      },
      default: null,
      mandatory: false,
      property: { read: true, write: false, source: 'backend', sort: 'none' },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: true },
      group: 'system',
    },
    destinationAccountSwift: {
      label: 'Destination Account SWIFT Code',
      datatype: {
        foreignKey: {
          collection: 'fk_bank_accounts',
          linkTable: 'LfxBankAccount',
          linkField: 'id',
          displayField: 'swiftCode',
          localForeignKeyLinkField: 'destinationAccount',
          sequelizeBelongsTo: 'DestinationAccount',
        },
      },
      default: null,
      mandatory: false,
      property: { read: true, write: false, source: 'backend', sort: 'none' },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: true },
      group: 'system',
    },
    inwardSwiftId: {
      label: 'Inward SWIFT',
      datatype: {
        foreignKey: {
          collection: 'fk_inward_swifts',

          linkTable: 'LfxInwardSWIFT',
          linkField: 'id',
          displayField: 'swiftNumber',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'payment',
    },
    isCrypto: {
      label: 'Is Crypto Payment',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'payment',
    },
    previousCryptoPaymentId:{
      label: 'Previous Crypto Payment',
      datatype: {
        foreignKey: {
          collection: 'fk_payments',
          linkTable: 'LfxPayment',
          linkField: 'id',
          displayField: 'reference',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'payment',
    },
    numberOfBopCats: {
      label: 'Number of BOP Categories',
      datatype: 'integer',
      default: 0,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'bopCategories',
    },
    bopCategories: {
      label: 'BOP Categories',

      datatype: {
        json: {
          component: 'LfxFieldBOPCategories',
          structure: [
            {
              bopCat: 'string',
              foreignAmount: 'number',
              allowanceIndicators: 'LfxBopCatAllowanceType',
              fiaPin: 'string',
            },
          ],
        },
      },

      default: [],
      mandatory: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'bopCategories',
    },
    detailsOfCharge: {
      label: 'Details of Charge',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'OUR', name: 'Our - All transaction charges are to be borne by the ordering customer' },
            {
              id: 'SHARE',
              name: "Share - Transaction charges on the Sender's side are to be borne by the ordering customer, transaction Charges on the Receiver's side are to be borne by the beneficiary customer.",
            },
            { id: 'BEN', name: 'Ben - All transaction charges are to be borne by the beneficiary customer' },
          ],
        },
      },
      default: 'OUR',
      mandatory: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'payment',
    },
    beneficiaryReference: {
      label: 'Beneficiary Reference',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Beneficiary Reference should be between 0 and 250 characters', args: [0, 250] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, search: true, csv: true },
      group: 'payment',
    },
    specialInstructions: {
      label: 'Special Instructions',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Special Instructions should be between 0 and 130 characters', args: [0, 130] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'payment',
    },
    purposeOfPayment: {
      label: 'Purpose of Payment',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Purpose of Payment should be between 0 and 130 characters', args: [0, 130] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'bopInfo',
    },
    sarbApprovalNumber: {
      label: 'SARB Approval Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'SARB Approval Number should be between 2 and 50 characters', args: [2, 50] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    sarbAuthReferenceNumber: {
      label: 'SARB Auth Reference Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'SARB Auth Reference Number should be between 2 and 50 characters', args: [2, 50] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    sarbLoanReferenceNumber: {
      label: 'SARB Loan Reference Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'SARB Loan Reference Number should be between 2 and 50 characters', args: [2, 50] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    ucrNumber: {
      label: 'UCR Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'UCR Number should be between 2 and 50 characters', args: [2, 50] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    sarsApnNumber: {
      label: 'SARS APN Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        custom: {
          msg: 'Please enter a valid SARS APN number',
          vFunction: isValidSarsApnNumber,
          validatingInterface: 'frontend',
        },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    taxClearanceCertificateRef: {
      label: 'Tax Clearance Certificate Reference',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'UCR Number should be between 2 and 50 characters', args: [2, 50] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    loanTenor: {
      label: 'Loan Tenor',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'UCR Number should be between 2 and 50 characters', args: [2, 50] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    loanInterestRate: {
      label: 'Loan Interest Rate',
      datatype: 'float',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        isNumeric: { msg: 'Priority should be numeric' },
        max: { msg: 'Priority can not be greater than 100', args: [100] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    importPermitConfirmation: {
      label: 'Import Permit Confirmation',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Import Permit Confirmation Number should be between 2 and 250 characters', args: [2, 250] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    invoiceNumber: {
      label: 'Invoice Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Invoice Number should be between 2 and 250 characters', args: [2, 250] },
      },
      views: { create: false, edit: true, item: true, list: viewIsBeneficiaryPaymentList, delete: false, csv: true },
      group: 'bopInfo',
    },
    mrn: {
      label: 'MRN',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'MRN should be between 2 and 250 characters', args: [2, 250] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    sourceOfFunds: {
      label: 'Source Of Funds',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Source Of Funds should be between 2 and 250 characters', args: [2, 250] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    reason: {
      label: 'Reason',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Reason should be between 2 and 250 characters', args: [2, 250] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    transportDocumentNumber: {
      label: 'Transport Document Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Transport Document Number should be between 2 and 250 characters', args: [2, 250] },
      },
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'bopInfo',
    },
    signatoryOption: {
      label: 'Signatory Option',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'client', name: 'Client', tagType: 'info' },
            { id: 'intermediary', name: 'Intermediary', tagType: 'info' },
            { id: 'submittingUser', name: 'Submitting User', tagType: 'info' },
            { id: 'intermediaryAndClient', name: 'Intermediary And Client', tagType: 'info' },
          ],
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'signatories',
    },

    signatories: {
      label: 'Signatories',
      datatype: {
        json: { component: 'LfxFieldSignatories', structure: ['signatoryId'] },
      },
      default: [],
      mandatory: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'signatories',
    },
    documents: {
      label: 'Documents',
      datatype: {
        json: {
          structure: [
            {
              documentName: 'string',
              count: 'number',
            },
          ],
        },
      },
      default: [],
      mandatory: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'documents',
    },
    submittedCount: {
      label: 'Submitted Count',
      datatype: 'integer',
      default: 0,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    integrationId: {
      label: 'Integration ID',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: true,
        delete: false,
        csv: true,
        search: true,
        tiles: { default: 'subtitle' },
      },
      group: 'integration',
    },
    currentPortfolioId: {
      label: 'Current Portfolio',
      datatype: {
        foreignKey: {
          linkTable: 'LfxPaymentPortfolio',
          linkField: 'id',
          displayField: 'reference',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'integration',
    },
    rejectionReason: {
      label: 'Rejection Reason',
      datatype: 'memo',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'integration',
    },
    submittedAt: {
      label: 'Submitted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true, search: true },
      group: 'integration',
    },
    submittedBy: {
      label: 'Submitted By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
          sequelizeBelongsTo: 'SubmittedBy',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    submittedByEmail: {
      label: 'Submitted By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'emailAddress',
          sequelizeBelongsTo: 'SubmittedBy',
        },
      },
      default: null,
      mandatory: true,
      property: { read: true, write: false, source: 'backend', sort: 'submittedBy' },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: true },
      group: 'integration',
    },
    readyForSigningAt: {
      label: 'Ready for Signing At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true, search: true },
      group: 'integration',
    },
    signedAt: {
      label: 'Signed At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'integration',
    },
    completedAt: {
      label: 'Completed At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true, search: true },
      group: 'integration',
    },
    rejectedAt: {
      label: 'Rejected At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'integration',
    },

    mostRecent: {
      label: 'Most Recent',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'integration',
    },
    paymentLocked: {
      label: 'Payment Locked',
      datatype: 'boolean',
      default: false,
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};

//----------------------------------------------------------------------------------------------------------------------

export const requiredFields = (
  payment: LfxPaymentInt,
  bopCategories?: LfxBopCatInt[],
  allowEditingInPortfolio?: boolean
) => {
  let requiredFields
  if (allowEditingInPortfolio) {
    requiredFields = ['bopCategories', 'signatoryOption'];
  } else {
    requiredFields = ['destinationAccount', 'bopCategories', 'purposeOfPayment', 'signatoryOption'];
  }

  if (payment.direction === 'inward') {
    requiredFields.push('inwardSwiftId');
  } else {
    requiredFields.push('sourceAccount');
    requiredFields.push('detailsOfCharge');
  }
  if (payment.signatoryOption !== 'submittingUser') {
    requiredFields.push('signatories');
  }
  let includeDocuments = false;
  if (bopCategories) {
    for (const bopCategory of bopCategories) {
      for (const field of bopCategory?.requiredInfo! || []) {
        if (!allowEditingInPortfolio && !requiredFields.includes(field)) {
          requiredFields.push(field);
        }
      }
      for (const bopCategoryDocument of bopCategory.requiredDocuments || []) {
        if (typeof bopCategoryDocument === 'string') {
          includeDocuments = true;
        } else {
          switch (bopCategoryDocument.guardDescription) {
            case 'fiaSelected':
              includeDocuments = fiaSelectedOnABopCat(payment.bopCategories);
              break;
            case 'fiaNotSelected':
              includeDocuments = !fiaSelectedOnABopCat(payment.bopCategories);
              break;
            default:
              break;
          }
        }
      }
    }
  }
  if (includeDocuments) {
    requiredFields.push('documents');
  }

  return requiredFields;
};

const fiaSelectedOnABopCat = (
  bopCategories?: {
    bopCat: string;
    foreignAmount: number;
    allowanceIndicators?: LfxBopCatAllowanceType;
    fiaPin?: string;
  }[]
) => {
  if (Array.isArray(bopCategories)) {
    for (const bopCat of bopCategories) {
      if (bopCat.allowanceIndicators === 'FIA') {
        return true;
      }
    }
  }
  return false;
};

//----------------------------------------------------------------------------------------------------------------------

export const availableFields = (payment: LfxPaymentInt, bopCategories?: LfxBopCatInt[]) => {
  const requiredFields = [
    'destinationAccount',
    'bopCategories',
    'beneficiaryReference',
    'purposeOfPayment',
    'signatoryOption',
    'signatories',
    'documents',
  ];

  if (payment.direction === 'inward') {
    requiredFields.push('inwardSwiftId');
  } else {
    requiredFields.push('sourceAccount');
    requiredFields.push('detailsOfCharge');
  }
  if (bopCategories) {
    for (const bopCategory of bopCategories) {
      for (const field of bopCategory.requiredInfo!) {
        if (!requiredFields.includes(field)) {
          requiredFields.push(field);
        }
      }
      for (const field of bopCategory.optionalInfo!) {
        if (!requiredFields.includes(field)) {
          requiredFields.push(field);
        }
      }
    }
  }

  return requiredFields;
};

//----------------------------------------------------------------------------------------------------------------------

export const allowedPermissionsForPortfolioAction = (
  action:
    | 'rejectPaymentPortfolio'
    | 'paymentPortfolioReadyForSignature'
    | 'paymentPortfolioSentBack'
    | 'paymentPortfolioQueryInformation'
    | 'paymentPortfolioSigned'
    | 'paymentAwaitingFunds'
    | 'paymentAwaitingSettlement'
    | 'paymentSettlementPostponed'
    | 'paymentComplete'
    | 'paymentConfirmationUploaded'
): LfxPaymentStatus[] => {
  switch (action) {
    case 'rejectPaymentPortfolio':
      return [
        'submitted',
        'readyForSigning',
        'signed',
        'awaitingSettlement',
        'settled',
        'informationQuery',
        'awaitingFunds',
        'recallRequested',
      ];
    case 'paymentPortfolioReadyForSignature':
      return ['submitted'];
    case 'paymentPortfolioSentBack':
      return ['signed', 'awaitingSettlement', 'settled', 'informationQuery', 'awaitingFunds', 'recallRequested'];
    case 'paymentPortfolioQueryInformation':
      return ['signed', 'awaitingSettlement', 'settled', 'awaitingFunds'];
    case 'paymentPortfolioSigned':
      return ['readyForSigning'];
    case 'paymentAwaitingFunds':
      return ['signed', 'awaitingSettlement', 'settled', 'informationQuery', 'recallRequested'];
    case 'paymentAwaitingSettlement':
      return ['signed', 'informationQuery', 'awaitingFunds', 'recallRequested'];
    case 'paymentSettlementPostponed':
      return ['signed', 'awaitingSettlement', 'informationQuery', 'awaitingFunds', 'recallRequested'];
    case 'paymentComplete':
      return [
        'new',
        'readyForSubmission',
        'submitted',
        'readyForSigning',
        'signed',
        'awaitingSettlement',
        'settled',
        'informationQuery',
        'awaitingFunds',
        'recallRequested',
      ];
    case 'paymentConfirmationUploaded':
      return ['complete'];
  }
};

//----------------------------------------------------------------------------------------------------------------------
