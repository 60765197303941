import { RouteRecordRaw } from 'vue-router';

import LfxTodo from '@/components/todo/LfxTodo.vue';
import LfxTodoModal from '@/components/todo/LfxTodoModal.vue';

import LfxAdmin from '@/views/admin/LfxAdmin.vue';
import LfxNoUserAccessLogsList from '@/views/users/LfxNoUserAccessLogsList.vue';

import LfxUsersList from '@/views/users/LfxUsersList.vue';
import LfxImportBatchList from '@/views/general/LfxImportBatchList.vue';
import LfxImportBatchDetails from '@/views/general/LfxImportBatchDetails.vue';
import LfxImportBatchUpload from '@/views/general/LfxImportBatchUpload.vue'
import LfxImportBatchUpdateStatus from '@/views/general/LfxImportBatchUpdateStatus.vue'

import LdbEmailLogItem from '@/views/admin/LdbEmailLogItem.vue'

import LfxSignatoryRequestsList from '@/views/signatories/LfxSignatoryRequestsList.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/admin',
    name: 'admin',
    meta: { icon: 'system/access' },
    component: LfxAdmin,
    children: [
      {
        path: 'trading',
        name: 'adminTradingView',
        component: LfxTodo,
        meta: { content: { tabName: 'trading', label: 'Trading' } },
      },
      {
        path: 'permission_requests',
        name: 'adminPermissionRequests',
        component: LfxUsersList,
        meta: { content: { tabName: 'permissionRequests', label: 'Permission Requests' }, permissionReviewRoute: true },
      },
      {
        path: 'signatoryRequests',
        name: 'adminSignatoryRequests',
        component: LfxSignatoryRequestsList,
        meta: { content: { tabName: 'signatoryRequests', label: 'Signatory Requests' }, signatoryReviewRoute: true },
      },
      {
        path: 'noUserAccessLogs',
        name: 'noUserLogsView',
        component: LfxNoUserAccessLogsList,
        meta: {
          content: { tabName: 'noUserLogsView', label: 'No User Access Logs', layout: 'list', buttonGroup: 'noUserAccessLogs' },
        },
      },

      {
        path: 'dataImportBatches',
        name: 'adminDataImportBatches',
        component: LfxImportBatchList,
        meta: { content: { tabName: 'dataImportBatches', buttonGroup: 'dataImportBatches',label: 'Data Import Batches' } },
      },
      {
        path: 'dataImportBatch/import',
        name: 'adminDataImportBatchImport',
        components: { default: LfxImportBatchList, modal: LfxImportBatchUpload },
        meta: { content: { tabName: 'dataImportBatches', buttonGroup: 'dataImportBatches',label: 'Data Import Batches' } },
      },
      {
        path: 'dataImportBatch/:importBatchId',
        name: 'adminDataImportBatchDetails',
        components: { default: LfxImportBatchList, modal: LfxImportBatchDetails },
        meta: { content: { tabName: 'dataImportBatches', buttonGroup: 'dataImportBatches',label: 'Data Import Batches' } },
      },
      {
        path: 'dataImportBatch/:importBatchId(\\d+)/editStatus',
        name: 'adminDataImportBatchEditStatus',
        components: { default: LfxImportBatchList, modal: LfxImportBatchUpdateStatus },
        meta: { content: { tabName: 'dataImportBatches', buttonGroup: 'dataImportBatches',label: 'Data Import Batches' } },
      },

      {
        path: 'communication',
        name: 'adminCommunicationView',
        component: LfxTodo,
        meta: { content: { tabName: 'communication', label: 'Sent Communication' } },
      },
      // {
      //   path: 'communication/new',
      //   name: 'adminCommunicationCreate',
      //   component: LfxTodo,
      //   meta: { label: 'Send Communication' },
      // },
      // {
      //   path: 'communication/edit',
      //   name: 'adminCommunicationEdit',
      //   component: LfxTodo,
      //   meta: { label: 'Edit Communication' },
      // },
      {
        path: 'pager_duty',
        name: 'adminPagerDutyView',
        component: LfxTodo,
        meta: { content: { tabName: 'pagerDuty', label: 'Pager Duty' } },
      },
      // {
      //   path: 'pager_duty/edit',
      //   name: 'adminPagerDutyEdit',
      //   component: LfxTodo,
      //   meta: { label: 'Edit Pager Duty Settings' },
      // },
    ],
  },
  {
    path: '/emailLog/:emailLogId',
    name: 'emailLogItem',
    component: LdbEmailLogItem,
  }
];

export default routes;
