//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2025 LandoByte (Pty) Ltd.
//  File        : LfxSignatoryRequestDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Signatory Change Request record for the Capitec Forex System
//  Created     : 13 February 2025 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';
import { LfxSignatoryStatus } from './LfxSignatoryDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxSignatoryRequestStatus = 'pending' | 'requested' | 'approved' | 'rejected';
export type LfxSignatoryRequestChangeType =
  | 'newSignatory'
  | 'reactivateSignatory'
  | 'promoteSignatory'
  | 'modifyUserDetails';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxSignatoryRequestInt {
  id?: number;
  accountId?: number;
  intermediaryId?: number;
  userId?: number;
  signatoryId?: number;
  reference?: string;
  status?: LfxSignatoryRequestStatus;
  changeType?: LfxSignatoryRequestChangeType;
  previousSignatoryStatus?: LfxSignatoryStatus;
  changeFields?: { user: string[]; signatory: string[] };
  changeBeforeValues?: { user: (string | boolean | undefined)[]; signatory: (string | boolean | undefined)[] };
  changeAfterValues?: { user: (string | boolean | undefined)[]; signatory: (string | boolean | undefined)[] };

  requestedAt?: Date;
  requestedBy?: number;
  requestedByName?: string;

  approvedAt?: Date;
  approvedBy?: number;
  approvedByName?: string;

  rejectedAt?: Date;
  rejectedBy?: number;
  rejectedByName?: string;

  rejectionReason?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxSignatoryRequestDef: LdbDefinition = {
  title: 'Signatory Request',
  table: 'LfxSignatoryRequest',
  view: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
  collectionPath: '/signatoryRequest',
  addToStoreIfNotExist: true,
  pagingType: 'backEnd',
  indexes: ['userId', 'signatoryId'],
  groups: {
    system: { priority: 1, label: 'General', color: 'info' },
    change: { priority: 2, label: 'Change', color: 'info' },
    tracking: { priority: 3, label: 'Tracking', color: 'info' },
  },
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'reference' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: 0,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Intermediary',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: true,
        delete: false,
      },
      group: 'system',
    },
    userId: {
      label: 'Linked User',
      datatype: {
        foreignKey: {
          collection: 'fk_users',

          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      guards: { signerLevel: { notEq: 'contactOnly' } },
      group: 'system',
    },
    signatoryId: {
      label: 'Signatory',
      datatype: {
        foreignKey: {
          collection: 'fk_signatories',
          linkTable: 'LfxSignatory',
          linkField: 'id',
          displayField: 'fullName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'pending', name: 'Pending Request', tagType: undefined },
            { id: 'requested', name: 'Requested', tagType: 'info' },
            { id: 'approved', name: 'Approved', tagType: 'success' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'system',
    },
    changeType: {
      label: 'Change Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'newSignatory', name: 'New Signatory' },
            { id: 'reactivateSignatory', name: 'Reactivation' },
            { id: 'promoteSignatory', name: 'Promote from Contact or Contributor' },
            { id: 'modifyUserDetails', name: 'User Details Modification' },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'change',
    },
    previousSignatoryStatus: {
      label: 'Previous Signatory Status Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'disabled', name: 'Disabled', tagType: undefined },
            { id: 'pendingReview', name: 'Pending Review', tagType: 'info' },
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'settings',
    },

    changeFields: {
      label: 'Change Fields',
      datatype: {
        json: {},
      },
      default: [],
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: true },
      group: 'change',
    },
    changeBeforeValues: {
      label: 'Change Before Values',
      datatype: {
        json: {},
      },
      default: [],
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: true },
      group: 'change',
    },
    changeAfterValues: {
      label: 'Change AFter Values',
      datatype: {
        json: {},
      },
      default: [],
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: true },
      group: 'change',
    },
    requestedAt: {
      label: 'Requested At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'tracking',
    },
    requestedBy: {
      label: 'Requested By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'tracking',
    },
    requestedByName: {
      label: 'Requested By',
      datatype: 'text',
      search: true,
      default: '',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'tracking',
    },

    approvedAt: {
      label: 'Approved At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'tracking',
    },
    approvedBy: {
      label: 'Approved By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'tracking',
    },
    approvedByName: {
      label: 'Approved By',
      datatype: 'text',
      search: true,
      default: '',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'tracking',
    },

    rejectedAt: {
      label: 'Rejected At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'tracking',
    },
    rejectedBy: {
      label: 'Rejected By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'tracking',
    },
    rejectedByName: {
      label: 'Rejected By',
      datatype: 'text',
      search: true,
      default: '',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'tracking',
    },

    rejectionReason: {
      label: 'Rejection Reason',
      datatype: 'memo',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'tracking',
    },

    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'tracking',
    },
  },
};
