//@ts-nocheck
import VueStore from 'vue-class-store';
import store from '@/store';
import { requiredFields as paymentRequiredFields, LfxPaymentDef } from '@/definitions/LfxPaymentDef';

import { doPermissionAction } from '@/lfx_rest/lfx_action';

import { LfxModel, LfxDefinition } from './LfxModel';

@VueStore
class LfxPaymentMdl extends LfxDefinition {
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
export class LfxPayment extends LfxModel {
  constructor(data, state) {
    super({ definition_name: 'payment', ...data }, state);
  }
  get link() {
    // @ts-expect-error
    return `/account/${this.accountId}/transaction/${this.transactionFileId}/payment/${this.id}`;
  }

  // ---- General ----

  get outstandingFields(): string[] {
    const allowEditingInPortfolio = this.store.state.dbIntermediarySettings.bankDirectClients === true
    const outstandingFields: string[] = [];
    const requiredFields = paymentRequiredFields(this, this.bopCategoryObjects,allowEditingInPortfolio);
    for (const field of requiredFields!) {
      // console.log('FIELD', field);
      if (this![field] === null || this![field] === undefined) {
        outstandingFields.push(field);
      }
    }
    // console.log('outstanding fields', outstandingFields);
    return outstandingFields;
  }
  get bopCategoryObjects() {
    if (Array.isArray(this.bopCategories)) {
      const bopCats = [];
      for (const bopCatRecord of this.bopCategories as { bopCat: string }[]) {
        if (this.store.getters.config.bopCat[bopCatRecord.bopCat]) {
          bopCats.push(this.store.getters.config.bopCat[bopCatRecord.bopCat]);
        }
      }
      return bopCats;
    }
  }

  get checkForSubmittingUser() {
    let authUserRecord = this.store.state.user?.[this.store.state.authUser?.id];
    let authUserHasSignatory = authUserRecord?.hasSignatory;
    let authUserSignatoryIsActive = authUserRecord?.userSignatoryIsActive;
    return this.signatoryOption === 'submittingUser'
      ? authUserHasSignatory && authUserSignatoryIsActive
        ? true
        : false
      : true;
  }

  get isEditable() {
    return (
      ['new', 'readyForSubmission'].includes(this.status) &&
      !this.paymentLocked &&
      !this.transactionRecord?.isValueDateInThePast &&
      this.transactionRecord?.consolidatedStatus !== 'dealNotSettled'
    );
  }
  get isSubmittable() {
    return (
      ['readyForSubmission'].includes(this.status) &&
      !this.paymentLocked &&
      (this.outstandingFields.length === 0 || this.store.getters.bankDirectClients) &&
      this.checkForSubmittingUser &&
      this.transactionFileAwaitingPayment
    );
  }
  get isCancelable() {
    return ['new', 'readyForSubmission'].includes(this.status) && !this.paymentLocked;
  }
  get transactionRecord() {
    return this.store.state.transaction?.[this.transactionFileId]
  }
  get transactionFileAwaitingPayment() {
    return !!(this.transactionRecord?.status === 'awaitingPayment');
  }


  // Portfolio

  get hasPortfolio() {
    return !!this.integrationId;
  }
  get portfolioIsEditable() {
    return !!this.integrationId && ['readyForSigning', 'informationQuery'].includes(this.status);
  }
  get portfolioIsBankEditable() {
    return (
      !!this.integrationId &&
      ['signed', 'awaitingSettlement', 'settled', 'informationQuery', 'awaitingFunds'].includes(this.status)
    );
  }

  portfolios_retrievalStatus: 'none' | 'retrieving' | 'retrieved' | 'failed' = 'none';
  portfolios_retrievalTimestamp: null | Date = null;
  portfolios_retrievalAttemptsFailed: number = 0;

  get portfolios() {
    switch (this.portfolios_retrievalStatus) {
      case 'none':
        if (this.hasPortfolio) {
          this.getPaymentPortfolios();
        }
        break;
      case 'retrieving':
        break;
      case 'retrieved':
        // TODO load again if old
        break;
      case 'failed':
        if (this.previousPortfolios_retrievalAttemptsFailed < 3) {
          this.getPaymentPortfolios();
        }
        break;
    }
    return this.portfolio || {};
  }

  async getPaymentPortfolios() {
    this.portfolios_retrievalStatus = 'retrieving';
    // @ts-expect-error
    let response = await doPermissionAction(
      this,
      'getPaymentPortfolioHistory',
      { transactionId: this.transactionFileId, paymentId: this.id },
      {}
    );
    this.portfolios_retrievalTimestamp = new Date();
    if (response!.status === 'success') {
      this.portfolios_retrievalStatus = 'retrieved';
      this.portfolios_retrievalAttemptsFailed = 0;
    } else {
      this.portfolios_retrievalStatus = 'failed';
      this.portfolios_retrievalAttemptsFailed++;
    }
  }

  get reusablePreviousPortfolios() {
    let res = {};
    for (let portfolioId in this.portfolios) {
      let portfolio = this.portfolios[portfolioId];
      if (portfolio.readyForSigningAt) {
        res[portfolioId] = portfolio;
      }
    }
    return res;
  }

  get numberOfReusablePreviousPortfolios() {
    return Object.keys(this.reusablePreviousPortfolios).length;
  }
  get hasReusablePreviousPortfolios() {
    return this.numberOfReusablePreviousPortfolios > 0;
  }

  get portfolioIsArchived() {
    return (this.submittedAt > "2021-09-01" && this.submittedAt < "2022-09-20")
  }
  // ----- Many-to-many -----

  get isBalancePayment() {
    return this.store.state.transaction[this.transactionFileId]?.balancePaymentId === this.id;
  }

  // ---- Guards ----

  get capturePayment_buttonGuard() {
    return (
      this.isEditable &&
      (this.hasPermission.updatePayment ||
        this.hasPermission.updatePaymentBopCats ||
        this.hasPermission.updatePaymentDocuments ||
        this.hasPermission.updatePaymentSignatories)
    );
  }
  get editAmountForManyToMany_buttonGuard() {
    return this.isEditable && !this.isBalancePayment && this.hasPermission.editTransactionFilePaymentAmount;
  }

  get cancelTransferPayment_buttonGuard() {
    return this.isCancelable && this.hasPermission.cancelTransferPayment;
  }
  get editPaymentValue_buttonGuard() {
    return this.isEditable && this.hasPermission.editTransferPayment; // FIXME this permission check
  }
  get submitPayment_buttonGuard() {
    return this.isSubmittable && this.hasPermission.submitPaymentPortfolio && !this.isValueDateInThePast // && !this.store.state.account[this.accountId].contactDtailsOutstanding;
  }
  get viewPortfolio_buttonGuard() {
    return this.hasPortfolio && 
      !this.isEditable && 
      this.hasPermission.getPortfolioLink && 
      this.status !== 'submitted' && 
      (['intermediary', 'intermediaryBranch'].includes(this.store.state.authUser.accountLevel) ||
       (this.store.state.authUser.accountLevel === 'client' && this.hasPermission.submitPaymentPortfolio)
      );
  }
  get viewPortfolioLogs_buttonGuard() {
    return this.hasPortfolio;
  }
  get recallPayment_buttonGuard() {
    return this.portfolioIsEditable && this.hasPermission.cancelPaymentPortfolio;
  }
  get portfolioSummary_buttonGuard() {
    return (
      this.hasPortfolio &&
      !this.isEditable &&
      this.hasPermission.getPaymentPortfolioStatus &&
      this.status !== 'submitted'
    );
  }
  get addDocumentPayment_buttonGuard() {
    return this.portfolioIsEditable && this.hasPermission.addPaymentPortfolioDocument;
  }
  get updatePaymentStatus_buttonGuard() {
    return (
      // this.portfolioIsEditable &&
      this.hasPermission.rejectPaymentPortfolio ||
      this.hasPermission.paymentPortfolioReadyForSignature ||
      this.hasPermission.paymentPortfolioSentBack ||
      this.hasPermission.paymentPortfolioQueryInformation ||
      this.hasPermission.paymentPortfolioSigned ||
      this.hasPermission.paymentAwaitingFunds ||
      this.hasPermission.paymentAwaitingSettlement ||
      this.hasPermission.paymentSettlementPostponed ||
      this.hasPermission.paymentComplete ||
      this.hasPermission.paymentConfirmationUploaded
    ) 
    // && this.transactionRecord?.consolidatedStatus !== 'dealNotSettled' // Back office must be able to manually update in case of automation failue
  }
  get consolidatedStatus() {
    switch (this.status) {
      case 'new':
      case 'readyForSubmission':
        if (!this.bopCatTotalIsCorrect && ((this.store.state.dbIntermediarySettings?.bankDirectClients || this.store.state.account[this.intermediaryId]?.bankDirectClients) !== true)) {
          return 'bopCatAmountConflict';
        }
        return this.status;
      default:
        return this.status;
    }
  }
  get bopCatTotalIsCorrect() {
    if (
      this.status === 'new' &&
      (!this.numberOfBopCats ||
        !this.bopCategories ||
        (Array.isArray(this.bopCategories) && this.bopCategories.length === 0))
    ) {
      return true;
    }
    // let bopCatForeignAmountTotal = 0;
    if (Array.isArray(this.bopCategories)) {
      // for (const bopCat of this.bopCategories) {
      //   bopCatForeignAmountTotal += bopCat.foreignAmount;
      // }
      // if ([0, 0.0].includes(bopCatForeignAmountTotal - this.foreignAmount)) {
      if ([0, 0.0].includes(this.bopCatForeignAmountTotal - this.foreignAmount)) {
        return true;
      }
    }
    return false;
  }
  get bopCatForeignAmountTotal() {
    let bopCatForeignAmountTotal = 0;
    if (Array.isArray(this.bopCategories)) {
      for (const bopCat of this.bopCategories) {
        bopCatForeignAmountTotal += bopCat.foreignAmount;
      }
    }
    return bopCatForeignAmountTotal;
  }
  get fiaSelectedOnABopCat() {
    if (Array.isArray(this.bopCategories)) {
      for (const bopCat of this.bopCategories) {
       if (bopCat.allowanceIndicators === 'FIA') {
        return true
       };
      }
    }
    return false
  }
  get statusText() {
    // @ts-expect-error
    for (const field of this.definition.fields) {
      if (field.field === 'status') {
        if (field.datatype?.option?.options) {
          for (const option of field.datatype.option.options) {
            // @ts-expect-error
            if (option.id === this.status) {
              return option.name;
            }
          }
        }
      }
    }
    // @ts-expect-error
    return this.status;
  }

  get valueDateAsDateString() {
    // @ts-expect-error
    return this.valueDate?.substring(0, 10).replaceAll('/', '-') || "";
  }
  get isValueDateToday() {
    // @ts-expect-error
    return this.store.state.systemTodayAsString === this.valueDateAsDateString ? true : false;
  }
  get isValueDateInThePast() {
    // @ts-expect-error
    return new Date(this.store.state.systemTodayAsString) > new Date(this.valueDateAsDateString) ? true : false;
  }

  get valueDateTagType() {
    // @ts-expect-error
    switch (this.status) {
      case 'complete':
        return 'success';
      case 'deleted':
      case 'cancelled':
        return 'cancelled';
      default:
        // @ts-expect-error
        if (this.isValueDateInThePast) {
          return 'error';
        } else if (this.isValueDateToday) {
          return 'info';
        } else {
          return undefined;
        }
    }
  }

  // ------------------------------
  //  API calls
  // ------------------------------
}

export function Definition(context, name: string) {
  return new LfxPaymentMdl(LfxPaymentDef, LfxPayment, context, name, {
    fields: {
      // numberOfBopCats: {
      //   label: 'Number of BOP Categories',
      //   datatype: {
      //     option: {
      //       optionType: 'text',
      //       options: [
      //         { id: 1, name: '1' },
      //         { id: 2, name: '2' },
      //         { id: 3, name: '3' },
      //         { id: 4, name: '4' },
      //         { id: 5, name: '5' },
      //       ],
      //     },
      //   },
      //   default: null,
      //   mandatory: true,
      //   allowNullValues: false,
      //   views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      //   group: 'bop',
      // },
      bopCatGroupField: {
        label: 'BOP Category Group',
        datatype: {
          foreignKey: {
            collection: 'fk_bop_cat_groups',

            linkTable: 'LfxBopCatGroup',
            linkField: 'id',
            displayField: 'name',
          },
        },
        default: null,
        mandatory: false,
        allowNullValues: true,
        views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
        group: 'bop',
      },
    },
  });
}

export const getLowestPaymentStatus = (statusArray) => {
  const orderedStatuses = [
    'bopCatAmountConflict',
    'new',
    'readyForSubmission',
    'informationQuery',
    'recallRequested',
    'submitted',
    'readyForSigning',
    'signed',
    'awaitingFunds',
    'awaitingSettlement',
    'settled',
    'complete',
    'cancelled',
    'deleted',
    undefined,
  ];
  for (const orderedStatus of orderedStatuses) {
    if (statusArray.includes(orderedStatus)) {
      return orderedStatus;
    }
  }
  return 'dealConflict';
};
