<template>
  <LDBModal result="info" :header="header" size="xx-large" class="lfx-signatory-review">
    <template #body>
      <LDBField
        v-for="(field, fieldname) in requestFields"
        :key="fieldname"
        :field="field"
        view="item"
        :fieldname="field.name"
        :definition="signatoryRequestDefinition"
        :record="signatoryRequestRecord"
        @change="on_change"
      />
      <template v-if="showUserTable">
        <div class="lfx-signatory-review-view-table-container">
          <table-lite
            id="lfx-signatory-review-view-table"
            :is-static-mode="true"
            :is-slot-mode="true"
            :has-checkbox="false"
            :is-loading="false"
            :is-re-search="false"
            :columns="tableColumns"
            :rows="sortedRecords"
            :pageSize="sortedRecords.length"
            :total="sortedRecords.length"
          >
            <template v-for="(col, i) of tableColumns" v-slot:[col.field]="data" :key="i">
              {{ data.value[col.field] }}
            </template>
          </table-lite>
        </div>
      </template>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import TableLite from '@/views/general/TableLite.vue';
import { LfxSignatoryDef } from '@/definitions/LfxSignatoryDef';
import { LfxUserDef } from '@/definitions/LfxUserDef';
import { getFieldLabel, getOptionLabel } from '@/sharedUtils/LdbDefinitionUtils';

export default {
  name: 'LfxSignatoryDetails',
  components: { TableLite },
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.loadUserAndPermission();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['account', 'user', 'signatory', 'signatoryRequest', 'systemSettings']),
    header() {
      return `Review Signatory Request`;
    },
    showUserTable() {
      return true;
    },
    signatoryReviewEnabled() {
      return !!this.systemSettings?.featureEnable?.tocSignatoryReview;
    },
    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId] || {};
    },

    // ----- User Record -----

    userId() {
      return +this.signatoryRecord?.userId;
    },
    userRecord() {
      return this.user?.[this.userId];
    },

    // ----- Signatory Record -----

    signatoryDefinition() {
      return getDefinition('signatory');
    },
    signatoryId() {
      return +this.$route.params?.signatoryId;
    },
    signatoryRecord() {
      return this.userId ? this.userRecord?.userSignatory : this.signatory?.[this.signatoryId] || {};
    },

    signatoryRequestDefinition() {
      return getDefinition('signatoryRequest');
    },
    signatoryRequestId() {
      return +this.$route.params?.signatoryRequestId;
    },
    signatoryRequestRecord() {
      return this.signatoryRequest[this.signatoryRequestId];
    },

    // ----- Fields -----

    requestFields() {
      const res = [
        {
          name: 'accountId',
          label: 'Account',
          datatype: {
            foreignKey: {
              collection: 'fk_accounts',
              linkTable: 'LfxAccount',
              linkField: 'id',
              displayField: 'name',
            },
          },
        },
        {
          name: 'intermediaryId',
          label: 'Intermediary',
          datatype: {
            foreignKey: {
              collection: 'fk_intermediaries',
              linkTable: 'LfxAccount',
              linkField: 'id',
              displayField: 'name',
              sequelizeBelongsTo: 'Intermediary',
            },
          },
        },
        {
          name: 'userId',
          label: 'Linked User',
          datatype: {
            foreignKey: {
              collection: 'fk_users',

              linkTable: 'LfxUser',
              linkField: 'id',
              displayField: 'displayName',
            },
          },
        },
        {
          name: 'signatoryId',
          label: 'Signatory',
          datatype: {
            foreignKey: {
              collection: 'fk_signatories',
              linkTable: 'LfxSignatory',
              linkField: 'id',
              displayField: 'fullName',
            },
          },
        },
        {
          name: 'reference',
          label: 'Reference',
          datatype: 'text',
        },
        {
          name: 'status',
          label: 'Status',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'pending', name: 'Pending Request', tagType: undefined },
                { id: 'requested', name: 'Requested', tagType: 'info' },
                { id: 'approved', name: 'Approved', tagType: 'success' },
                { id: 'rejected', name: 'Rejected', tagType: 'error' },
              ],
            },
          },
        },
        {
          name: 'changeType',
          label: 'Change Type',
          datatype: {
            option: {
              optionType: 'text',
              options: [
                { id: 'newSignatory', name: 'New Signatory', tagType: 'info' },
                { id: 'reactivateSignatory', name: 'Reactivation', tagType: 'info' },
                {
                  id: 'promoteSignatory',
                  name: 'Promote from Contact or Contributor',
                  tagType: 'info',
                },
                {
                  id: 'modifyUserDetails',
                  name: 'User Details Modification',
                  tagType: 'info',
                },
              ],
            },
          },
        },
        {
          name: 'requestedAt',
          label: 'Requested At',
          datatype: {
            datetime: {
              type: 'datetime',
              format: 'human',
            },
          },
        },
        {
          name: 'requestedByName',
          label: 'Requested By',
          datatype: 'text',
        },
      ];
      return res;
    },
    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: this.signatoryRequestRecord?.status === 'requested' ? 'Cancel' : 'Back',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'approveRequest',
          label: 'Approve',
          type: 'primary',
          action: 'releaseSignatoryRequest',
          params: {
            accountId: this.accountId,
            signatoryId: this.signatoryId,
            signatoryRequestId: this.signatoryRequestId,
          },
          actionParams: {},
          guards: this.signatoryReviewEnabled && this.signatoryRequestRecord?.status === 'requested',
          enabledGuards: true,
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
            }
          },
        },
        {
          name: 'rejectRequest',
          label: 'Reject',
          type: 'primary',
          action: 'rejectSignatoryRequest',
          params: {
            accountId: this.accountId,
            signatoryId: this.signatoryId,
            signatoryRequestId: this.signatoryRequestId,
          },
          actionParams: {},
          guards: this.signatoryReviewEnabled && this.signatoryRequestRecord?.status === 'requested',
          enabledGuards: true,
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    // ----- TABLE -----
    showBankMarkup() {
      return this.$store.state.authUser.accountLevel === 'bank';
    },
    tableColumns() {
      let self = this;
      let res = [
        {
          col: 'fieldColumn',
          field: 'fieldName',
          label: 'Field',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'oldValueColumn',
          field: 'oldValue',
          label: 'Old Value',
          isKey: false,
          sortable: false,
          guards: true,
        },
        {
          col: 'newValueColumn',
          field: 'newValue',
          label: 'New Value',
          isKey: false,
          sortable: false,
          guards: true,
        },
      ];
      return res.filter((f) => f.guards === true);
    },
    sortedRecords() {
      const res = [];
      for (let i = 0; i < this.signatoryRequestRecord?.changeFields?.user.length; i++) {
        const fieldName = this.signatoryRequestRecord?.changeFields?.user?.[i];
        let fieldLabel = fieldName;
        let oldValue = this.signatoryRequestRecord?.changeBeforeValues?.user?.[i];
        let newValue = this.signatoryRequestRecord?.changeAfterValues?.user?.[i]
        try {
          fieldLabel = getFieldLabel(LfxUserDef,fieldName)
          oldValue = getOptionLabel(LfxUserDef,fieldName,oldValue)
          newValue = getOptionLabel(LfxUserDef,fieldName,newValue)
        }catch(err){console.log(err)}

        res.push({
          fieldName: fieldLabel,
          oldValue: oldValue,
          newValue: newValue,
        });
      }
      for (let i = 0; i < this.signatoryRequestRecord?.changeFields?.signatory.length; i++) {
        const fieldName = this.signatoryRequestRecord?.changeFields?.signatory?.[i];
        let fieldLabel = fieldName;
        let oldValue = this.signatoryRequestRecord?.changeBeforeValues?.signatory?.[i];
        let newValue = this.signatoryRequestRecord?.changeAfterValues?.signatory?.[i]
        try {
          fieldLabel = getFieldLabel(LfxSignatoryDef,fieldName)
          oldValue = getOptionLabel(LfxSignatoryDef,fieldName,oldValue)
          newValue = getOptionLabel(LfxSignatoryDef,fieldName,newValue)
        }catch{}
        res.push({
          fieldName: fieldLabel,
          oldValue: oldValue,
          newValue: newValue,
        });
      }
      return res;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    onApiSuccess(msg, evt) {
      console.log('1', evt);
      this.$router.go(-1);
    },
    async loadUserAndPermission() {
      if (!this.signatoryRecord) {
        await doPermissionAction(this, 'getAccountSignatory', {
          accountId: this.accountId,
          signatoryId: this.signatoryId,
        });
      }
      if (!this.userRecord && this.signatoryRecor) {
        await doPermissionAction(this, 'getUser', { userId: this.userId });
      }
    },
    on_change({ field, value }) {},
  },
};
</script>

<style>
.lfx-signatory-review-view-table-container {
  position: relative;
  min-height: 400px;
}

#lfx-signatory-review-view-table {
  min-width: 100%;
}

div.lfx-signatory-review {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>
