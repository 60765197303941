import VueStore from 'vue-class-store';

import { LfxAccountDef } from '@/definitions/LfxAccountDef';
import { LfxModel, LfxDefinition } from './LfxModel';

import store from '@/store';
import { doPermissionAction, multiLoadPatchContent } from '@/lfx_rest/lfx_action';
import { LfxAccountMarkupInt } from '@/definitions/LfxAccountMarkupDef';
import { calculateAvailableOpenDealLimit } from '@/sharedUtils/LfxTradeCalc';
import {
  displayBranchForListView,
  intermediaryFieldViewGuard,
  notIntermediaryFieldViewGuard,
} from '../sharedUtils/LdbDefinitionUtils';

import { standardTextWhiteList, isAlphaWhiteList } from '../sharedUtils/LdbValidations';

// @ts-ignore
@VueStore
// @ts-ignore
class LfxAccountMdl extends LfxDefinition {
  // @ts-expect-error
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }

  getDefinitionName(data: any) {
    return data.accountLevel;
  }
  async retrieveItem(id: number) {
    await doPermissionAction({}, 'getAccount', { accountId: id }, {});
  }
}

// @ts-ignore
@VueStore
// @ts-ignore
export class LfxAccount extends LfxModel {
  // @ts-expect-error
  constructor(data, state) {
    super(data, state);
  }

  // -------------------
  // ----- General -----
  // -------------------

  get definition_name() {
    // @ts-expect-error
    return this.accountLevel;
  }

  set definition_name(value) {
    // @ts-expect-error
    this.accountLevel = value;
  }

  get link() {
    // @ts-expect-error
    // return `/${this.accountLevel}/${this.id}`;
    return `/account/${this.id}`;
  }

  get displayFKLink() {
    //@ts-expect-error
    return !this.restrictedView && this.store.getters.myAccount.userReviewStatus !== 'nonCompliant';
  }

  get subCollections() {
    return [
      // 'inwardSwifts', // TODO
      'bankAccounts',
      'beneficiaries',
      // 'markups', // TODO
      'signatories',
      // 'applications', // TODO
    ];
  }

  get accountIsBankDirectClient() {
    //@ts-expect-error
    if (this.accountLevel !== 'client') {
      return false;
    }
    //@ts-expect-error
    return this.store.state.account[this.intermediaryId]?.bankDirectClients === true;
  }

  // ----- Parent Account Ids -----

  get parentAccountIds() {
    // @ts-expect-error
    return this.getParentAccountsforId(this.id);
  }
  getParentAccountsforId(accountId: number): number[] {
    //@ts-expect-error
    if (accountId !== this.id && !this.store.state.account[accountId]) {
      this.doRetrieveParentMarkups();
      return [];
    } else {
      // @ts-expect-error
      if (accountId !== 1 && accountId !== this.store.state.account[accountId].accountId) {
        return [
          //@ts-expect-error
          this.store.state.account[accountId].accountId,
          // @ts-expect-error
          ...this.getParentAccountsforId(this.store.state.account[accountId].accountId),
        ];
      } else {
        return [];
      }
    }
  }

  // ----- Data loading -----

  on_after_activate(arg: string) {
    switch (arg) {
      case 'general':
        // @ts-expect-error
        switch (this.accountLevel) {
          case 'client':
            // @ts-expect-error
            if (!['na', 'complete', 'cancelled', 'retrievedFromBank'].includes(this.currentApplicationStatus)) {
              this.getOpenApplications();
            }
            // @ts-expect-error
            if (this.accountIsActive && this.tradingEnabled && this.hasPermission.bookDeal) {
              this.loadDataForDealing();
            }
            break;
          case 'intermediary':
            // TODO remove once confirmed not required - seems to be loaded already in initial load
            this.loadRecord();
            break;
        }
        break;
      case 'swift':
        this.loadDataForDealing();
        this.loadDataForPayment();
        break;
      case 'beneficiaries':
        this.beneficiaries;
        break;
      case 'applications':
        this.applications;
        break;
    }
  }

  loadRecord() {
    // // @ts-expect-error
    // doPermissionAction({}, 'getAccount', { accountId: this.id }, {});
  }
  loadDataForDealing() {
    this.bankAccounts;
    this.parentMarkups;
  }
  loadDataForPayment() {
    this.bankAccounts;
    this.beneficiaries;
  }
  loadDataForSignatories() {
    this.configuredSignatories;
  }

  // ----- Enable intermediary tags -----

  get enableIntermediaryNameTag() {
    // @ts-expect-error
    return this.name ? 'success' : 'error';
  }
  get enableIntermediaryCifTag() {
    // @ts-expect-error
    return this.cifNumber ? 'success' : 'error';
  }
  get enableIntermediaryBpTag() {
    // @ts-expect-error
    return this.bpNumber ? 'success' : 'error';
  }
  get enableIntermediaryCommissionAccountTag() {
    // @ts-expect-error
    return this.commissionAccount ? 'success' : 'error';
  }
  get enableIntermediaryOrganisationTag() {
    // @ts-expect-error
    return this.organisation ? 'success' : 'error';
  }
  get enableIntermediaryNumberOfActiveIntermediaryUsers() {
    return +this.numberOf_active_intermediaryUsers > 1
      ? 'success'
      : +this.numberOf_active_intermediaryUsers == 1
      ? 'info'
      : 'warning';
  }
  get enableIntermediaryNumberOf_active_intermediaryUsers_withActiveManageUser() {
    return +this.numberOf_active_intermediaryUsers_withActiveManageUser > 1
      ? 'success'
      : +this.numberOf_active_intermediaryUsers_withActiveManageUser == 1
      ? 'info'
      : 'warning';
  }
  get enableIntermediaryNumberOf_active_systemUser() {
    // return +this.numberOf_active_systemUser > 1 ? 'warning' : +this.numberOf_active_systemUser == 1 ? 'success' : 'info';
    return +this.numberOf_active_systemUser == 1 ? 'success' : 'warning';
  }

  // ----- Disable intermediary tags -----

  get disableIntermediaryNumberOfActiveIntermediaryUsers() {
    return +this.numberOf_active_intermediaryUsers > 0 ? 'info' : 'success';
  }

  // -------------------
  // ----- Details -----
  // -------------------

  // ----- Record -----

  get consolidatedStatus() {
    //@ts-expect-error
    if (['closed', 'notWithThisIntermediary', 'mandateDatabaseConflict'].includes(this.status)) {
      //@ts-expect-error
      return this.status;
    }
    //@ts-expect-error
    if (this.accountLevel === 'client' && ['new', 'submitted'].includes(this.status)) {
      //@ts-expect-error
      return this.currentApplicationStatus;
    }
    //@ts-expect-error
    if (this.kycCompliance === 'nonCompliant') {
      return 'kcyNonCompliant';
    }
    //@ts-expect-error
    if (![null, undefined, 'active'].includes(this.bancsStatus)) {
      return 'bankNotActive';
    }
    //@ts-expect-error
    return this.status;
  }
  get userReviewStatus() {
    //@ts-expect-error
    if (this.store?.state?.systemSettings?.userReview?.enabled === false) {
      return 'compliant';
    }
    //@ts-expect-error
    switch (this.accountLevel) {
      case 'bank':
        //@ts-expect-error
        const userTeamId = this.store?.state?.user?.[this.store?.state?.authUser?.id]?.userTeamId;
        if (userTeamId) {
          //@ts-expect-error
          return this.store?.state?.config?.[1]?.userTeam?.[userTeamId]?.userReviewStatus;
        }
      case 'intermediary':
        const lastUserReviewDate =
          //@ts-expect-error
          this.lastUserReviewDate ||
          //@ts-expect-error
          this.IntermediarySettings?.lastUserReviewDate ||
          //@ts-expect-error
          this.store.state.dbIntermediarySettings?.lastUserReviewDate
            ? new Date(
                // @ts-expect-error
                this.lastUserReviewDate ||
                  // @ts-expect-error
                  this.IntermediarySettings?.lastUserReviewDate ||
                  // @ts-expect-error
                  this.store.state.dbIntermediarySettings?.lastUserReviewDate
              )
            : undefined;
        //@ts-expect-error
        const previousReviewStartDate = this.store?.state?.systemSettings?.userReview
          ?.previousIntermediaryReviewStartDate
          ? // @ts-expect-error
            new Date(this.store.state.systemSettings.userReview.previousIntermediaryReviewStartDate)
          : undefined;
        //@ts-expect-error
        const previousReviewDate = this.store?.state?.systemSettings?.userReview?.previousIntermediaryReviewDate
          ? // @ts-expect-error
            new Date(this.store.state.systemSettings.userReview.previousIntermediaryReviewDate)
          : undefined;
        const nextReviewDate = new Date(
          //@ts-expect-error
          new Date(this.store?.state?.systemSettings?.userReview?.nextIntermediaryReviewDate).setHours(23, 59, 59)
        );
        //@ts-expect-error
        const nextReviewStartsDate = this.store?.state?.systemSettings?.userReview?.nextIntermediaryReviewStartDate
          ? //@ts-expect-error
            new Date(this.store?.state?.systemSettings?.userReview?.nextIntermediaryReviewStartDate)
          : new Date(
              new Date(nextReviewDate).setDate(
                //@ts-expect-error
                nextReviewDate.getDate() - this.store?.state?.systemSettings?.userReview?.daysBeforeButtonEnable || 21
              )
            );
        //@ts-expect-error
        const createdDate = new Date(this.createdAt);
        //@ts-expect-error
        console.log('ACCOUNT ' + this.id, {
          createdDate,
          lastUserReviewDate,
          previousReviewStartDate,
          previousReviewDate,
          nextReviewStartsDate,
          nextReviewDate,
          a:
            previousReviewStartDate &&
            (!lastUserReviewDate || lastUserReviewDate < previousReviewStartDate) &&
            previousReviewDate &&
            createdDate < previousReviewDate,
          b: new Date() < nextReviewStartsDate,
          c: !lastUserReviewDate || lastUserReviewDate < nextReviewStartsDate,
          //@ts-expect-error
          settings: this.store?.state?.systemSettings?.userReview,
        });
        if (
          previousReviewStartDate &&
          (!lastUserReviewDate || lastUserReviewDate < previousReviewStartDate) &&
          previousReviewDate &&
          createdDate < previousReviewDate
        ) {
          return 'nonCompliant';
        }
        if (new Date() < nextReviewStartsDate) {
          return 'compliant';
        }
        if (!lastUserReviewDate || lastUserReviewDate < nextReviewStartsDate) {
          return 'due';
        }
        return 'compliant';
      case 'intermediaryBranch':
      case 'client':
        if (
          //@ts-expect-error
          (this.accountLevel === 'client' &&
            //@ts-expect-error
            this.store?.state?.account?.[this.intermediaryId]?.allowClientsToSubmitPortfolios) ||
          //@ts-expect-error
          this.store?.state?.account?.[this.intermediaryId]?.IntermediarySettings?.allowClientsToSubmitPortfolios ||
          //@ts-expect-error
          this.store.state.dbIntermediarySettings?.allowClientsToSubmitPortfolios
        ) {
          let selectedUserTeam;
          //@ts-expect-error
          for (const userTeamId in this.store?.state?.config?.[1]?.userTeam) {
            //@ts-expect-error
            const userTeam = this.store?.state?.config?.[1]?.userTeam?.[userTeamId];
            //@ts-expect-error
            if (userTeam.accountId === this.id) {
              selectedUserTeam = userTeam;
            }
          }
          if (selectedUserTeam) {
            return selectedUserTeam.userReviewStatus;
          }
        }
        //@ts-expect-error
        const intermediaryUserReviewStatus = this.store?.state?.account?.[this.intermediaryId]?.userReviewStatus;
        return intermediaryUserReviewStatus;
    }
    return undefined;
  }

  // ----- Trade Limit ----

  get availableOpenDealLimit() {
    if (this.isClient) {
      const openDeals: any[] = [];
      // @ts-expect-error
      for (const dId in this.store.state.deal) {
        if (
          // @ts-expect-error
          this.store.state.deal[dId].accountId === this.id &&
          ['requested', 'processed', 'booked', 'released', 'fecModificationRequested'].includes(
            // @ts-expect-error
            this.store.state.deal[dId].status
          )
        ) {
          // @ts-expect-error
          openDeals.push(this.store.state.deal[dId]);
        }
      }
      // @ts-expect-error
      return calculateAvailableOpenDealLimit(this.openDealLimit, openDeals);
    } else {
      return undefined;
    }
  }

  get tradingEnabledTagType() {
    // @ts-expect-error
    switch (this.status) {
      case 'active':
        // @ts-expect-error
        return this.tradingEnabled ? 'success' : 'error';
      default:
        return undefined;
    }
  }

  // --------------------
  // ----- Settings -----
  // --------------------

  async addToSettings(settingsType: 'generalSettings' | 'intermediarySettings' | 'clientSettings', updateToObject: any) {
    if (this.accountSettings_retrievalStatus !== 'retrieved') {
      await this.retrieveAccountStatus();
    }
    if (this.accountSettings_retrievalStatus !== 'retrieved') {
      throw 'Settings could not be retrieved';
    }
    for (const field in updateToObject) {
      this.savedAccountSettings[settingsType][field] = updateToObject[field];
    }
    let action: 'updateAccountClientSettings' | 'updateAccountIntermediarySettings' | 'updateAccountGeneralSettings';
    switch (settingsType) {
      case 'generalSettings':
        action = 'updateAccountGeneralSettings';
        break;
      case 'intermediarySettings':
        action = 'updateAccountIntermediarySettings';
        break;
      case 'clientSettings':
        action = 'updateAccountClientSettings';
        break;
    }
    await doPermissionAction(
      this,
      action,
      //@ts-expect-error
      { accountId: this.id },
      { newSettings: this.savedAccountSettings[settingsType] }
    );
  }

  accountSettings_retrievalStatus: 'none' | 'retrieving' | 'retrieved' | 'failed' = 'none';
  accountSettings_retrievalTimestamp: null | Date = null;
  accountSettings_retrievalAttemptsFailed: number = 0;
  savedAccountSettings: {
    generalSettings?: any;
    intermediarySettings?: any;
    clientSettings?: any;
  } = {};

  get accountSettings() {
    //@ts-expect-error
    if (typeof this.id !== 'number') {
      return {};
    }
    switch (this.accountSettings_retrievalStatus) {
      case 'none':
        this.retrieveAccountStatus();
        break;
      case 'retrieving':
        break;
      case 'retrieved':
        // TODO load again if old
        break;
      case 'failed':
        if (this.accountSettings_retrievalAttemptsFailed < 3) {
          this.retrieveAccountStatus();
        }
        break;
    }
    return this.savedAccountSettings;
  }
  async reloadAccountSettings() {
    this.accountSettings_retrievalStatus = 'none';
    return this.accountSettings;
  }
  async retrieveAccountStatus() {
    this.accountSettings_retrievalStatus = 'retrieving';
    // @ts-expect-error
    let response = (await doPermissionAction(this, 'getAccountAllSettings', { accountId: this.id }, {})) as any;
    this.accountSettings_retrievalTimestamp = new Date();
    if (response!.status === 'success') {
      this.savedAccountSettings = response.response;
      this.accountSettings_retrievalStatus = 'retrieved';
      this.accountSettings_retrievalAttemptsFailed = 0;
    } else {
      this.accountSettings_retrievalStatus = 'failed';
      this.accountSettings_retrievalAttemptsFailed++;
    }
  }
  downloadableDocumentsStatus = 'none';
  savedDownloadAbleDocuments = [];
  async triggerGetDownloadableDocuments() {
    this.downloadableDocumentsStatus = 'none';
    //@ts-expect-error
    const response = await doPermissionAction(this, 'accountDownloadDocuments', {}, { accountUuid: this.uuid });
    if (response?.status === 'success') {
      this.savedDownloadAbleDocuments = response.response;
      this.downloadableDocumentsStatus = 'retrieved';
    } else {
      this.downloadableDocumentsStatus = 'error';
    }
    this.savedDownloadAbleDocuments;
  }
  get downloadAbleDocuments() {
    if (this.downloadableDocumentsStatus === 'none') {
      this.triggerGetDownloadableDocuments();
    }
    return this.savedDownloadAbleDocuments;
  }
  get savedDownloadAbleDocumentsText() {
    if (this.downloadableDocumentsStatus !== 'retrieved') {
      return undefined;
    }
    if (this.savedDownloadAbleDocuments && this.savedDownloadAbleDocuments.length === 0) {
      return undefined;
    }
    const documentTextArray = [];
    for (const downloadableDocument of this.savedDownloadAbleDocuments as any) {
      if (downloadableDocument.documentDescription) {
        documentTextArray.push(downloadableDocument.documentDescription);
      }
    }
    if (documentTextArray.length === 0) {
      return undefined;
    }
    return documentTextArray.join(', ');
  }

  get fecMarkups() {
    if (this.accountSettings?.generalSettings?.fecMarkups) {
      return this.accountSettings?.generalSettings?.fecMarkups;
    }
    //@ts-expect-error
    return this.store.state.systemSettings.trading.fecAdditionalSpreads;
  }
  get fecMarkupsText() {
    const fecMarkups = this.fecMarkups;
    if (this.accountSettings_retrievalStatus !== 'retrieved') {
      return 'Loading...';
    }
    return `• 1 Month: ${fecMarkups['1month']}
    • 2 Month: ${fecMarkups['2month']}
    • 3 Month: ${fecMarkups['3month']}
    • 4 Month: ${fecMarkups['4month']}
    • 5 Month: ${fecMarkups['5month']}
    • 6 Month: ${fecMarkups['6month']}
    • 12 Month: ${fecMarkups['12month']}`;
  }
  // ------------------------
  // ----- Transactions -----
  // ------------------------

  // Inward SWIFTs

  get clientInwardSwifts() {
    let res = {};
    if (this.isClient) {
      // @ts-expect-error
      for (let iws in this.store.state.swift) {
        // @ts-expect-error
        let swift = this.store.state.swift[iws];
        // @ts-expect-error
        if (swift.accountId === this.id) {
          // @ts-expect-error
          res[iws] = swift;
        }
      }
    }
    return res;
  }
  get clientAvailableSwifts() {
    const res = {};
    if (this.isClient) {
      for (const s in this.clientInwardSwifts) {
        // @ts-expect-error
        if (this.clientInwardSwifts[s].status === 'available') {
          // @ts-expect-error
          res[s] = this.clientInwardSwifts[s];
        }
      }
    }
    return res;
  }

  // -------------------------
  // ----- Bank Accounts -----
  // -------------------------

  // ----- All Bank Accounts associated with this Account

  bankAccounts_retrievalStatus: 'none' | 'retrieving' | 'retrieved' | 'failed' = 'none';
  bankAccounts_retrievalTimestamp: null | Date = null;
  bankAccounts_retrievalAttemptsFailed: number = 0;

  get bankAccounts() {
    switch (this.bankAccounts_retrievalStatus) {
      case 'none':
        this.retrieve_bankAccounts();
        break;
      case 'retrieving':
        break;
      case 'retrieved':
        // TODO load again if old
        break;
      case 'failed':
        if (this.bankAccounts_retrievalAttemptsFailed < 3) {
          this.retrieve_bankAccounts();
        }
        break;
    }
    return this.accountBankAccountsFromStore;
  }

  async retrieve_bankAccounts() {
    this.bankAccounts_retrievalStatus = 'retrieving';
    let response = await multiLoadPatchContent(this, {
      action: 'getBankAccounts', //@ts-expect-error
      pathParams: { accountId: this.id }, //@ts-expect-error
      additionalSelectorObject: {},
    });
    this.bankAccounts_retrievalTimestamp = new Date();
    if (response!.status === 'success') {
      this.bankAccounts_retrievalStatus = 'retrieved';
      this.bankAccounts_retrievalAttemptsFailed = 0;
    } else {
      this.bankAccounts_retrievalStatus = 'failed';
      this.bankAccounts_retrievalAttemptsFailed++;
    }
  }

  get accountBankAccountsFromStore() {
    let accountBankAccounts = {};
    // @ts-expect-error
    let bankAccounts = this.store.state.bankAccount;
    for (let b in bankAccounts) {
      let bankAccount = bankAccounts[b];
      // @ts-expect-error
      if (bankAccount.accountId === this.id) {
        // @ts-expect-error
        accountBankAccounts[b] = bankAccount;
      }
    }
    return accountBankAccounts;
  }

  // ----- Filtered Bank Accounts

  // Filters

  filter_bankAccount(filterOptions: {
    currency: string | undefined;
    includeAuthUserBankAccounts: boolean;
    bankAccountTypes: 'own' | 'beneficiary' | 'both';
    status: string | undefined;
  }) {
    console.warn('OLDER FILTER IMPLEMENTATION');
    var bankAccountsTest: any = this.bankAccounts;
    if (filterOptions.includeAuthUserBankAccounts) {
      bankAccountsTest = {
        ...bankAccountsTest,
        //@ts-expect-error
        ...this.store.state.account[this.store.state.authUser.accountId].bankAccount,
      };
    }
    const bankAccountsReturn: any = {};
    for (const b in bankAccountsTest) {
      // TODO add something to check beneficiaryStatus on beneficiary bankAccounts
      if (
        (filterOptions.currency === undefined || bankAccountsTest[b].currencyId === filterOptions.currency) &&
        (filterOptions.bankAccountTypes === 'both' ||
          (filterOptions.bankAccountTypes === 'own' && !bankAccountsTest[b].beneficiaryId) ||
          (filterOptions.bankAccountTypes === 'beneficiary' && bankAccountsTest[b].beneficiaryId)) &&
        (filterOptions.status === undefined || bankAccountsTest[b].status === filterOptions.status)
      ) {
        bankAccountsReturn[b] = bankAccountsTest[b];
      }
    }
    return bankAccountsReturn;
  }

  filter_bankAccounts(
    bankAccounts: object | undefined,
    filterOptions: {
      currency: string | undefined;
      includeAuthUserBankAccounts: boolean;
      bankAccountTypes: 'own' | 'beneficiary' | 'both';
      status: string | undefined;
    }
  ) {
    let bankAccountsTest = bankAccounts;
    if (bankAccounts === undefined) {
      bankAccountsTest = this.bankAccounts;
    }
    if (filterOptions.includeAuthUserBankAccounts) {
      bankAccountsTest = {
        ...bankAccountsTest,
        //@ts-expect-error
        ...this.store?.state?.account?.[this.store?.state?.authUser?.accountId]?.bankAccounts,
      };
    }
    const bankAccountsReturn: any = {};
    for (const b in bankAccountsTest) {
      if (
        // @ts-expect-error
        (filterOptions.currency === undefined || bankAccountsTest[b].currencyId === filterOptions.currency) &&
        (filterOptions.bankAccountTypes === 'both' ||
          // @ts-expect-error
          (filterOptions.bankAccountTypes === 'own' && !bankAccountsTest[b].beneficiaryId) ||
          // @ts-expect-error
          (filterOptions.bankAccountTypes === 'beneficiary' && bankAccountsTest[b].beneficiaryId)) &&
        // @ts-expect-error
        (filterOptions.status === undefined || bankAccountsTest[b].status === filterOptions.status)
      ) {
        // @ts-expect-error
        bankAccountsReturn[b] = bankAccountsTest[b];
      }
    }
    return bankAccountsReturn;
  }

  // Own Bank Accounts

  get own_bankAccounts() {
    if (this.isClient) {
      let bankAccounts = this.bankAccounts;
      const filterOptions = {
        currency: undefined,
        includeAuthUserBankAccounts: false,
        bankAccountTypes: 'own',
        status: undefined,
      } as const;
      return this.filter_bankAccounts(bankAccounts, filterOptions);
    } else {
      return {};
    }
  }

  get firstOwn_bankAccountId() {
    return Object.keys(this.own_bankAccounts)[0];
  }

  get ownActive_bankAccounts() {
    let bankAccounts = this.own_bankAccounts;
    const filterOptions = {
      currency: undefined,
      includeAuthUserBankAccounts: false,
      bankAccountTypes: 'own',
      status: 'active',
    } as const;
    return this.filter_bankAccounts(bankAccounts, filterOptions);
  }

  get hasOwnActive_bankAccount() {
    return Object.keys(this.ownActive_bankAccounts).length > 0;
  }

  get hasBankAccountsForTransferDeals() {
    let res = false;
    let bankAccounts = this.ownActive_bankAccounts;
    let numberOfClientBankAccounts = Object.keys(bankAccounts).length;
    if (numberOfClientBankAccounts > 1) {
      let uniqueCurrencyOptions = new Set();
      for (let b_acc in bankAccounts) {
        let bankAccount = bankAccounts[b_acc];
        if (bankAccount.status === 'active') {
          uniqueCurrencyOptions.add(bankAccount.currencyId);
        }
      }
      let uniqueCurrencyOptionsSize = uniqueCurrencyOptions.size;
      let hasZarAccount = uniqueCurrencyOptions.has('ZAR'); // Note: this should be reworked when we can do foreign crosses
      if (uniqueCurrencyOptionsSize > 1 && hasZarAccount) {
        res = true;
      }
    }
    return res;
  }

  // Beneficiary Bank Accounts

  get beneficiaries_bankAccounts() {
    let bankAccounts = this.bankAccounts;
    const filterOptions = {
      currency: undefined,
      includeAuthUserBankAccounts: false,
      bankAccountTypes: 'beneficiary',
      status: undefined,
    } as const;
    return this.filter_bankAccounts(bankAccounts, filterOptions);
  }

  get beneficiariesActive_bankAccounts() {
    let bankAccounts = this.beneficiaries_bankAccounts;
    const filterOptions = {
      currency: undefined,
      includeAuthUserBankAccounts: false,
      bankAccountTypes: 'beneficiary',
      status: 'active',
    } as const;
    return this.filter_bankAccounts(bankAccounts, filterOptions);
  }

  get hasBeneficiariesActive_bankAccounts() {
    return Object.keys(this.beneficiariesActive_bankAccounts).length > 0;
  }

  // ----- Older implementation below - to be deleted -----

  filteredBankAccounts(filterOptions: {
    currency: string | undefined;
    includeAuthUserBankAccounts: boolean;
    bankAccountTypes: 'own' | 'beneficiary' | 'both';
  }) {
    console.warn('FIXME: ACCESSED OLD BANK ACCOUNT IMPLEMENTATION'); // FIXME
    var bankAccountsTest: any = this.bankAccounts;
    if (filterOptions.includeAuthUserBankAccounts) {
      bankAccountsTest = {
        ...bankAccountsTest,
        //@ts-expect-error
        ...this.store.state.account[this.store.state.authUser.accountId].bankAccounts,
      };
    }
    const bankAccountsReturn: any = {};
    for (const b in bankAccountsTest) {
      if (
        (filterOptions.currency === undefined || bankAccountsTest[b].currencyId === filterOptions.currency) &&
        (filterOptions.bankAccountTypes === 'both' ||
          (filterOptions.bankAccountTypes === 'own' && !bankAccountsTest[b].beneficiaryId) ||
          (filterOptions.bankAccountTypes === 'beneficiary' && bankAccountsTest[b].beneficiaryId))
      ) {
        bankAccountsReturn[b] = bankAccountsTest[b];
      }
    }
    return bankAccountsReturn;
  }

  get activeClientBankAccounts() {
    console.warn('FIXME: ACCESSED OLD BANK ACCOUNT IMPLEMENTATION'); // FIXME
    return this.ownActive_bankAccounts;
  }

  get beneficiaryBankAccounts() {
    console.warn('FIXME: ACCESSED OLD BANK ACCOUNT IMPLEMENTATION'); // FIXME
    return this.beneficiaries_bankAccounts;
  }

  get activeBeneficiaryBankAccounts() {
    console.warn('FIXME: ACCESSED OLD BANK ACCOUNT IMPLEMENTATION'); // FIXME
    return this.beneficiariesActive_bankAccounts;
  }

  // -------------------------
  // ----- Beneficiaries -----
  // -------------------------

  beneficiaries_retrievalStatus: 'none' | 'retrieving' | 'retrieved' | 'failed' = 'none';
  beneficiaries_retrievalTimestamp: null | Date = null;
  beneficiaries_retrievalAttemptsFailed: number = 0;

  get beneficiaries() {
    switch (this.beneficiaries_retrievalStatus) {
      case 'none':
        this.retrieveBeneficiaries();
        break;
      case 'retrieving':
        break;
      case 'retrieved':
        // TODO load again if old
        break;
      case 'failed':
        if (this.beneficiaries_retrievalAttemptsFailed < 3) {
          this.retrieveBeneficiaries();
        }
        break;
    }
    return this.accountBeneficiaryFromStore;
  }

  async retrieveBeneficiaries() {
    this.beneficiaries_retrievalStatus = 'retrieving';
    // let response = await doPermissionAction(this, 'getBeneficiaries', { accountId: this.id }, {});
    let response = await multiLoadPatchContent(this, {
      action: 'getBeneficiaries', //@ts-expect-error
      pathParams: { accountId: this.id }, //@ts-expect-error
      additionalSelectorObject: { orderBy: { name: 'asc' } },
    });

    this.beneficiaries_retrievalTimestamp = new Date();
    if (response!.status === 'success') {
      this.beneficiaries_retrievalStatus = 'retrieved';
      this.beneficiaries_retrievalAttemptsFailed = 0;
    } else {
      this.beneficiaries_retrievalStatus = 'failed';
      this.beneficiaries_retrievalAttemptsFailed++;
    }
  }

  get accountBeneficiaryFromStore() {
    let accountBeneficiary = {};
    // @ts-expect-error
    let beneficiaries = this.store.state.beneficiary;
    for (let b in beneficiaries) {
      let beneficiary = beneficiaries[b];
      // @ts-expect-error
      if (beneficiary.accountId === this.id) {
        // @ts-expect-error
        accountBeneficiary[b] = beneficiary;
      }
    }
    return accountBeneficiary;
  }

  // -------------------
  // ----- Markups -----
  // -------------------

  parentMarkupStatus: 'none' | 'retrieving' | 'retrieved' = 'none';
  get parentMarkups() {
    // @ts-expect-error
    if (typeof this.id === 'number') {
      this.parentAccountIds;
      switch (this.parentMarkupStatus) {
        case 'none':
          this.doRetrieveParentMarkups();
          return undefined;
        case 'retrieving':
          return undefined;
        case 'retrieved':
          return this.buildParentMarkups();
        default:
          return undefined;
      }
    }
  }
  async doRetrieveParentMarkups() {
    this.parentMarkupStatus = 'retrieving';
    //@ts-expect-errors
    await doPermissionAction({}, 'getAccountParentMarkup', { accountId: this.id }, {});
    this.parentMarkupStatus = 'retrieved';
  }
  buildParentMarkups() {
    if (this.parentMarkupStatus !== 'retrieved') {
      return undefined;
    }
    const spreadObject: { [currencyPair: string]: LfxAccountMarkupInt[] } = {};
    //@ts-expect-error
    for (const currencyPairName in this.store.getters.config.currencyPair) {
      spreadObject[currencyPairName] = [];
    }
    this.addMarkupsForAccount(spreadObject);
    return spreadObject; // TODO sort spreadObject as for sortMarkups in LfxTradeCalc.ts
  }
  addMarkupsForAccount(spreadObject: { [currencyPair: string]: LfxAccountMarkupInt[] }) {
    //@ts-expect-error
    for (const markupId in this.markup) {
      //@ts-expect-error
      spreadObject[this.markup[markupId].currencyPair]?.push(this.markup[markupId]);
    }
    //@ts-expect-error
    if (this.id !== 1 && this.id !== this.accountId) {
      //@ts-expect-error
      this.store.state.account[this.accountId].addMarkupsForAccount(spreadObject);
    }
  }

  // get markupLogs() { // Removed - 2024-09-16 because markupLogs are now stored directly under LfxAccount
  //   const logs = {};
  //   //@ts-expect-error
  //   const markups = this.markup || {};
  //   for (const markupId in markups) {
  //     const markupLogs = markups[markupId].log || {};
  //     for (const logId in markupLogs) {
  //       //@ts-expect-error
  //       logs[logId] = this.markup[markupId].log[logId];
  //     }
  //   }
  //   return logs;
  // }

  // ------------------------------------
  // ----- Signatories and Contacts -----
  // ------------------------------------

  // ----- Account Signatories and Contacts

  signatoriesAndContacts_retrievalStatus: 'none' | 'retrieving' | 'retrieved' | 'failed' = 'none';
  signatoriesAndContacts_retrievalTimestamp: null | Date = null;
  signatoriesAndContacts_retrievalAttemptsFailed: number = 0;

  get signatoriesAndContacts() {
    switch (this.signatoriesAndContacts_retrievalStatus) {
      case 'none':
        this.retrieve_signatoriesAndContacts();
        break;
      case 'retrieving':
        break;
      case 'retrieved':
        // TODO load again if old
        break;
      case 'failed':
        if (this.signatoriesAndContacts_retrievalAttemptsFailed < 3) {
          this.retrieve_signatoriesAndContacts();
        }
        break;
    }
    return this.accountSignatoriesAndContactsFromStore;
  }

  async retrieve_signatoriesAndContacts() {
    this.signatoriesAndContacts_retrievalStatus = 'retrieving';
    // @ts-expect-error
    let response = await doPermissionAction(this, 'getAccountSignatories', { accountId: this.id }, {});
    this.signatoriesAndContacts_retrievalTimestamp = new Date();
    if (response!.status === 'success') {
      this.signatoriesAndContacts_retrievalStatus = 'retrieved';
      this.signatoriesAndContacts_retrievalAttemptsFailed = 0;
    } else {
      this.signatoriesAndContacts_retrievalStatus = 'failed';
      this.signatoriesAndContacts_retrievalAttemptsFailed++;
    }
  }

  get accountSignatoriesAndContactsFromStore() {
    let accountSignatoriesAndContacts = {};
    // @ts-expect-error
    let signatoriesAndContacts = this.store.state.signatory;
    for (let s in signatoriesAndContacts) {
      let signatory = signatoriesAndContacts[s];
      // @ts-expect-error
      if (signatory.accountId === this.id) {
        // @ts-expect-error
        accountSignatoriesAndContacts[s] = signatory;
      }
    }
    return accountSignatoriesAndContacts;
  }

  // ----- Account Payment Signatories

  get signatories() {
    let res = {};
    let signatories = this.signatoriesAndContacts;
    for (let s in signatories) {
      // @ts-expect-error
      let signatory = signatories[s];
      if (signatory.paymentSignatory) {
        // @ts-expect-error
        res[s] = signatory;
      }
    }
    return res;
  }

  get signatories_activeStatus() {
    let res = {};
    let signatories = this.signatories;
    for (let sig in signatories) {
      // @ts-expect-error
      let signatory = signatories[sig];
      if (signatory.status === 'active') {
        // @ts-expect-error
        res[sig] = signatory;
      }
    }
    return res;
  }
  get noFecSignatoryAssigned() {
    // @ts-expect-error
    if (!this.mayBookFec) {
      return false;
    }
    if (this.signatoriesAndContacts) {
      for (const signatoryId in this.signatoriesAndContacts) {
        // @ts-expect-error
        const signatory = this.signatoriesAndContacts[signatoryId];
        if (signatory.signerLevel !== 'contactOnly' && signatory.status === 'active' && signatory.fecReviewSignatory) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  get first_signatoryId() {
    return Object.keys(this.signatories)[0];
  }

  get individualSignatory() {
    if (this.signatoriesAndContacts_retrievalStatus !== 'retrieved') {
      return null;
    }
    for (const s in this.signatoriesAndContacts) {
      //@ts-expect-error
      if (this.signatoriesAndContacts[s].signatoryType === 'individual') {
        //@ts-expect-error
        return this.signatoriesAndContacts[s];
      }
    }
    return undefined;
  }
  get entityMainContact() {
    if (this.signatoriesAndContacts_retrievalStatus !== 'retrieved') {
      return null;
    }
    for (const s in this.signatoriesAndContacts) {
      //@ts-expect-error
      if (this.signatoriesAndContacts[s].signatoryType === 'mainContact') {
        //@ts-expect-error
        return this.signatoriesAndContacts[s];
      }
    }
    return undefined;
  }
  get individualSignatoryOrMainContact() {
    //@ts-expect-error
    if (this.accountType === 'individual') return this.individualSignatory;
    return this.entityMainContact;
  }
  get individualHasSignatory() {
    if (this.signatoriesAndContacts_retrievalStatus !== 'retrieved') {
      return undefined;
    }
    return this.individualSignatory !== undefined;
  }
  get entityHasMainContact() {
    if (this.signatoriesAndContacts_retrievalStatus !== 'retrieved') {
      return undefined;
    }
    return this.entityMainContact !== undefined;
  }

  signatoryIsDirectorFieldLabel(defaultLabel: string) {
    //@ts-expect-error
    const currentClientApplicationId = this.currentClientApplicationId || Object.keys(this.application || {})?.[0];
    //@ts-expect-error
    const application = this.application?.[currentClientApplicationId];
    const applicationCompanyType = application?.application_cif?.[application?.fullCifId]?.EntityInfo?.companyType;
    switch (applicationCompanyType) {
      case 'pty':
        return 'Is the Signatory a Director?';
      case 'cc':
        return 'Is the Signatory a Member?';
      case 'trust':
        return 'Is the Signatory a Trustee?';
      case 'estateLate':
        return 'Is the Signatory a Director?';
      case 'partnership':
        return 'Is the Signatory a Partner?';
      case 'club':
        return 'Is the Signatory a Member?';
      case 'association':
        return 'Is the Signatory a Member?';
    }
    return defaultLabel;
  }
  signatoryIsSigningDirectorFieldLabel(defaultLabel: string) {
    //@ts-expect-error
    const currentClientApplicationId = this.currentClientApplicationId || Object.keys(this.application || {})?.[0];
    //@ts-expect-error
    const application = this.application?.[currentClientApplicationId];
    const applicationCompanyType = application?.application_cif?.[application?.fullCifId]?.EntityInfo?.companyType;
    switch (applicationCompanyType) {
      case 'pty':
        return 'Will the director sign the application?';
      case 'cc':
        return 'Will the member sign the application?';
      case 'trust':
        return 'Will the trustee sign the application?';
      case 'estateLate':
        return 'Will the director sign the application?';
      case 'partnership':
        return 'Will the partner sign the application?';
      case 'club':
        return 'Will the member sign the application?';
      case 'association':
        return 'Will the member sign the application?';
    }
    return defaultLabel;
  }

  // ----- Parent Payment Signatories

  parentSignatories_retrievalStatus: 'none' | 'retrieving' | 'retrieved' | 'failed' = 'none';
  parentSignatories_retrievalTimestamp: null | Date = null;
  parentSignatories_retrievalAttemptsFailed: number = 0;

  get parentSignatories() {
    switch (this.parentSignatories_retrievalStatus) {
      case 'none':
        this.getParentSignatories();
        break;
      case 'retrieving':
        break;
      case 'retrieved':
        // TODO load again if old
        break;
      case 'failed':
        if (this.parentSignatories_retrievalAttemptsFailed < 3) {
          this.getParentSignatories();
        }
        break;
    }
    return this.parentSignatoriesFromStore;
  }

  get parentSignatoriesFromStore() {
    let parentSignatories = {};
    // @ts-expect-error
    let signatories = this.store.state?.signatory;
    for (let s in signatories) {
      let signatory = signatories[s];
      if (this.parentAccountIds.includes(signatory.accountId)) {
        // @ts-expect-error
        parentSignatories[s] = signatory;
      }
    }
    return parentSignatories;
  }

  get activeParentSignatories() {
    let res = {};
    let signatories = this.parentSignatories;
    for (let sig in signatories) {
      // @ts-expect-error
      let signatory = signatories[sig];
      if (signatory.status === 'active' && signatory.paymentSignatory) {
        // @ts-expect-error
        res[sig] = signatory;
      }
    }
    return res;
  }

  // ----- Configured Payment Signatories

  get configuredSignatories() {
    //@ts-expect-error
    switch (this.bopFormSignatoryOption) {
      case 'client':
        return this.signatories_activeStatus;
      case 'intermediary':
        return this.activeParentSignatories;
      case 'intermediaryAndClient':
        return { ...this.signatories_activeStatus, ...this.activeParentSignatories };
      case 'submittingUser':
        return {};
    }
  }

  // ----- Client Application Signatories

  get applicationPortfolioSignatories() {
    // const res = [];
    // for (const s in this.signatoriesAndContacts) {
    //   //@ts-expect-error
    //   if (this.signatoriesAndContacts?.[s].accountOpeningSignatory) {
    //     //@ts-expect-error
    //     res.push(this.signatoriesAndContacts[s]);
    //   }
    // }
    let res = {};
    let signatories = this.signatoriesAndContacts;
    for (let s in signatories) {
      // @ts-expect-error
      let signatory = signatories[s];
      if (signatory.accountOpeningSignatory) {
        // @ts-expect-error
        res[s] = signatory;
      }
    }
    return res;
  }

  // --------------------
  // ----- Contacts -----
  // --------------------

  // -----------------
  // ----- Users -----
  // -----------------

  users_retrievalStatus: 'none' | 'retrieving' | 'retrieved' | 'failed' = 'retrieved';
  users_retrievalTimestamp: null | Date = null;
  users_retrievalAttemptsFailed: number = 0;

  get users() {
    switch (this.users_retrievalStatus) {
      case 'none':
        this.retrieve_users();
        break;
      case 'retrieving':
        break;
      case 'retrieved':
        // TODO load again if old
        break;
      case 'failed':
        if (this.users_retrievalAttemptsFailed < 3) {
          this.retrieve_users();
        }
        break;
    }
    return this.accountUsersFromStore;
  }

  async retrieve_users() {
    // this.bankAccounts_retrievalStatus = 'retrieving';
    // // @ts-expect-error
    // let response = await doPermissionAction(this, 'getBankAccounts', { accountId: this.id }, {});
    // this.bankAccounts_retrievalTimestamp = new Date();
    // if (response!.status === 'success') {
    //   this.bankAccounts_retrievalStatus = 'retrieved';
    //   this.bankAccounts_retrievalAttemptsFailed = 0;
    // } else {
    //   this.bankAccounts_retrievalStatus = 'failed';
    //   this.bankAccounts_retrievalAttemptsFailed++;
    // }
    console.log('RETRIEVING USERS'); // TODO
  }

  get accountUsersFromStore() {
    let accountUsers = {};
    // @ts-expect-error
    let users = this.store.state.user;
    for (const u in users) {
      let user = users[u];
      // @ts-expect-error
      if (user.accountId === this.id) {
        // @ts-expect-error
        accountUsers[u] = user;
      }
    }
    return accountUsers;
  }

  // TODO make below load users

  get intermediaryUsersFromStore() {
    let intermediaryUsers = {};
    //@ts-expect-error
    if (this.accountLevel === 'intermediary') {
      // @ts-expect-error
      let users = this.store.state.user;
      for (const u in users) {
        let user = users[u];
        // @ts-expect-error
        if (user.intermediaryId === this.id) {
          // @ts-expect-error
          intermediaryUsers[u] = user;
        }
      }
    }
    return intermediaryUsers;
  }
  get active_intermediaryUsers() {
    let active_intermediaryUsers = {};
    const users = this.intermediaryUsersFromStore;
    for (const u in users) {
      // @ts-expect-error
      const user = users[u];
      if (user.status === 'active' && !user.systemUser) {
        // @ts-expect-error
        active_intermediaryUsers[u] = user;
      }
    }
    return active_intermediaryUsers;
  }
  get numberOf_active_intermediaryUsers() {
    return Object.keys(this.active_intermediaryUsers).length.toString();
  }

  get active_intermediaryUsers_withActiveManageUser() {
    let active_intermediaryUsers_withActiveManageUser = {};
    const users = this.active_intermediaryUsers;
    for (const u in users) {
      // @ts-expect-error
      const user = users[u];
      if (user.hasActiveManageUserPermission) {
        // @ts-expect-error
        active_intermediaryUsers_withActiveManageUser[u] = user;
      }
    }
    return active_intermediaryUsers_withActiveManageUser;
  }
  get numberOf_active_intermediaryUsers_withActiveManageUser() {
    return Object.keys(this.active_intermediaryUsers_withActiveManageUser).length.toString();
  }

  get systemUsers() {
    let systemUsers = {};
    const users = this.intermediaryUsersFromStore;
    for (const u in users) {
      // @ts-expect-error
      const user = users[u];
      if (user.systemUser) {
        // @ts-expect-error
        systemUsers[u] = user;
      }
    }
    return systemUsers;
  }
  get active_systemUser() {
    let active_systemUser = {};
    const users = this.systemUsers;
    for (const u in users) {
      // @ts-expect-error
      const user = users[u];
      if (user.status === 'active' && user.esbUserStatus === 'active') {
        // @ts-expect-error
        active_systemUser[u] = user;
      }
    }
    return active_systemUser;
  }
  get numberOf_active_systemUser() {
    return Object.keys(this.active_systemUser).length.toString();
  }

  // -------------------------------
  // ----- Client Applications -----
  // -------------------------------

  get applicationDocumentUploadType() {
    //@ts-expect-error
    const settingsDocumentUpload = this.store.state.systemSettings?.onboarding?.documentUpload || 'signiFlow';
    if (settingsDocumentUpload === 'local') {
      return 'local';
    }
    const intermediaryHasOwnSignUpForm = this.closestIntermediaryHasOwnSignUpForm;
    if (intermediaryHasOwnSignUpForm === false) {
      return 'signiFlow';
    } else {
      return 'local';
    }
  }
  get closestIntermediaryHasOwnSignUpForm() {
    //@ts-expect-error
    switch (this.accountLevel) {
      case 'bank':
        return false;
      case 'client':
        //@ts-expect-error
        return this.store.state.account[this.accountId]?.closestIntermediaryHasOwnSignUpForm;
      default:
        //@ts-expect-error
        let thisIntermediaryHasOwnSignUpForm = this.IntermediarySettings?.intermediaryHasOwnSignUpForm;
        //@ts-expect-error
        if (!this.notMyAccount && this.store.state.dbIntermediarySettings?.intermediaryHasOwnSignUpForm !== undefined) {
          //@ts-expect-error
          thisIntermediaryHasOwnSignUpForm = this.store.state.dbIntermediarySettings?.intermediaryHasOwnSignUpForm;
        }
        if (thisIntermediaryHasOwnSignUpForm !== undefined) {
          return thisIntermediaryHasOwnSignUpForm;
        }
        //@ts-expect-error
        if (this.accountLevel === 'intermediary') {
          return false;
        }
        //@ts-expect-error
        return this.store.state.account[this.accountId]?.closestIntermediaryHasOwnSignUpForm;
    }
  }

  applications_retrievalStatus: 'none' | 'retrieving' | 'retrieved' | 'failed' = 'none';
  applications_retrievalTimestamp: null | Date = null;
  applications_retrievalAttemptsFailed: number = 0;

  get applications() {
    switch (this.applications_retrievalStatus) {
      case 'none':
        this.getApplications();
        break;
      case 'retrieving':
        break;
      case 'retrieved':
        // TODO load again if old
        break;
      case 'failed':
        if (this.applications_retrievalAttemptsFailed < 3) {
          this.getApplications();
        }
        break;
    }
    // @ts-expect-error
    return this.application || {};
  }

  async getOpenApplications() {
    //@ts-expect-error
    await doPermissionAction(this, 'getAccount', { accountId: this.id }, {});
  }
  async getApplications() {
    this.applications_retrievalStatus = 'retrieving';
    //@ts-expect-error
    let response = await doPermissionAction(this, 'getClientApplications', { accountId: this.id }, {});
    this.applications_retrievalTimestamp = new Date();
    if (response!.status === 'success') {
      this.applications_retrievalStatus = 'retrieved';
      this.applications_retrievalAttemptsFailed = 0;
    } else {
      this.applications_retrievalStatus = 'failed';
      this.applications_retrievalAttemptsFailed++;
    }
  }

  get hasApplications() {
    return Object.keys(this.applications).length > 0;
  }

  get currentClientApplicationId() {
    let active_clientApplications: string[] = [];
    // @ts-expect-error
    let applications = this.application; // Note: this looks at application not applications to avoid loading old applications
    for (let a in applications) {
      let application = applications[a];
      if (application !== 'completed') {
        active_clientApplications.push(a);
      }
    }
    return active_clientApplications[0];
  }

  // ------------------------------
  // Permissions and guards
  // ------------------------------

  // ---- General -----

  get notMyAccount() {
    // @ts-expect-error
    return this.store.state.authUser.accountId !== this.id;
  }
  get accountIsVisible() {
    // @ts-expect-error
    return this.visibility === 'visible';
  }
  get accountIsActive() {
    // @ts-expect-error
    return this.status === 'active';
  }
  get isActionable() {
    return (
      //@ts-expect-error
      (this.notMyAccount || (this.store.state.authUser.accountId === 1 && this.id !== 1)) &&
      this.accountIsVisible &&
      //@ts-expect-error
      !this.restrictedView
    );
  }
  get isActive() {
    return this.isActionable && this.accountIsActive;
  }

  get activeForTrading() {
    return this.isClient &&
      this.accountIsActive &&
      // @ts-expect-error
      this.tradingEnabled &&
      // this.kycCompliance !== 'nonCompliant' && // REMOVED TO ALLOW TRADING WHEN A CLIENT HAS BEEN FIXED BUT NOT YET UPDATED IN CFS
      this.hasOwnActive_bankAccount // TODO check if this makes sense
      ? true
      : false;
  }

  get isClient() {
    // @ts-expect-error
    return this.accountLevel === 'client';
  }
  get isClientThatIsAllowedToSubmitBopPortfolios() {
    // Colloquially referred to as the Direct Clients - the 12 special cases
    let res = false;
    // @ts-expect-error
    if (this.isClient && this.store?.state?.account?.[this.intermediaryId]?.allowClientsToSubmitPortfolios) {
      res = true;
    }
    return res;
  }
  get isIntermediaryOrBranch() {
    // @ts-expect-error
    return this.accountLevel === 'intermediary' || this.accountLevel === 'intermediaryBranch';
  }

  // ---- Guards for multiple views -----

  get editAccountDetails_buttonGuard() {
    // let accountLevel = this.record.accountLevel;
    // let specificEditAction =
    //   accountLevel === 'intermediary'
    //     ? 'editIntermediary'
    //     : accountLevel === 'intermediaryBranch'
    //     ? 'editBranch'
    //     : accountLevel === 'client'
    //     ? 'editClient'
    //     : undefined;
    return this.isActionable && this.hasPermission.editAccount; //this.permissions[specificEditAction];
  }
  get accountOpdenDealLimitEdit_buttonGuard() {
    return (
      this.isActionable &&
      // @ts-expect-error
      !['closed', 'notWithThisIntermediary', 'mandateDatabaseConflict'].includes(this.status) &&
      this.hasPermission.editAccount &&
      // @ts-expect-error
      this.accountLevel === 'client'
    );
  }
  get moveAccount_buttonGuard() {
    return false;
    // this.isActionable && this.hasPermission.moveAccount
    // // @ts-expect-error
    // && (this.accountLevel === 'intermediaryBranch' || this.accountLevel === 'client')
  }
  get moveClient_buttonGuard() {
    return this.isActionable && this.isClient && this.hasPermission.moveClient;
    // return true && this.isActionable && this.isClient;
  }
  get moveBranch_buttonGuard() {
    // @ts-expect-error
    return this.isActionable && this.accountLevel === 'intermediaryBranch' && this.hasPermission.moveBranch;
  }

  // Branches

  get accountAddBranch_buttonGuard() {
    return this.accountIsVisible && this.accountIsActive && this.hasPermission.createBranch;
  }
  get accountBulkEnableBranch_buttonGuard() {
    return (
      this.accountIsVisible &&
      this.accountIsActive &&
      // @ts-expect-error
      this.accountLevel === 'intermediary' &&
      this.hasPermission.bulkEnableBranch
    );
  }
  get accountBulkEnableUser_buttonGuard() {
    return (
      this.accountIsVisible &&
      this.accountIsActive &&
      // @ts-expect-error
      (this.accountLevel === 'intermediary' || this.accountLevel === 'intermediaryBranch') &&
      this.hasPermission.bulkEnableBranch
    );
  }

  // Clients
  get closeClient_buttonGuard() {
    // @ts-expect-error
    return this.isActionable && this.isClient && this.status === 'active' && this.hasPermission.closeClient;
  }

  get childrenBranches() {
    const directBranches = [];
    //@ts-expect-error
    for (const accountId in this.store.state.account) {
      //@ts-expect-error
      const account = this.store.state.account[accountId];
      //@ts-expect-error
      if (account.accountId === this.id && account.accountLevel === 'intermediaryBranch') {
        directBranches.push(account);
      }
    }
    const res = [];
    for (const branch of directBranches) {
      res.push(branch);
      const branchBranches = branch.childrenBranches;
      for (const branch of branchBranches) {
        res.push(branch);
      }
    }
    return res;
  }
  get childrenBranchIds() {
    const res = [];
    for (const childBranch of this.childrenBranches) {
      res.push(childBranch.id);
    }
    return res;
  }

  get contactDtailsOutstanding() {
    //@ts-expect-error
    if (this.accountLevel !== 'client') {
      return false;
    }
    //@ts-expect-error
    if (this.accountType === 'individual') {
      //@ts-expect-error
      return !this.contactNumber || !this.emailAddress || !this.identificationType || !this.identification;
    } else {
      return (
        //@ts-expect-error
        !this.contactName ||
        //@ts-expect-error
        !this.contactNumber ||
        //@ts-expect-error
        !this.emailAddress ||
        //@ts-expect-error
        !this.identificationType ||
        //@ts-expect-error
        !this.identification
      );
    }
  }
  // ------------------------------

  // Previous Crypto Payments

  previousCryptoPayments_retrievalStatus: 'none' | 'retrieving' | 'retrieved' | 'failed' = 'none';
  previousCryptoPayments_retrievalTimestamp: null | Date = null;
  previousCryptoPayments_retrievalAttemptsFailed: number = 0;
  get previousCryptoPayments() {
    //@ts-expect-error
    if (this.accountType !== 'individual') {
      return []
    }
    switch (this.previousCryptoPayments_retrievalStatus) {
      case 'none':
        this.getPreviousCryptoPayments();
        break;
      case 'retrieving':
        break;
      case 'retrieved':
        // TODO load again if old
        break;
      case 'failed':
        if (this.previousCryptoPayments_retrievalAttemptsFailed < 3) {
          this.getPreviousCryptoPayments();
        }
        break;
    }
    //@ts-expect-error
    return this.previousCryptoPayment || [];
  }
  async getPreviousCryptoPayments() {
    this.previousCryptoPayments_retrievalStatus = 'retrieving';
    const now = new Date();
    const yearAgo = (new Date((new Date()).setFullYear(now.getFullYear() - 1))).toISOString()
    let response = await doPermissionAction(
      this,
      'getPayments',
      {},
      {additionalSelector:{
        where:{ 
          //@ts-expect-error
          accountId: this.id, 
          isCrypto: true, 
          status: 'complete', 
          completedAt: { gte: yearAgo }},
        orderBy: { completedAt: 'desc' }},
        includeDealNumbers: true
    }, 
    );
    this.previousCryptoPayments_retrievalTimestamp = new Date();
    if (response!.status === 'success') {
      this.previousCryptoPayments_retrievalStatus = 'retrieved';
      this.previousCryptoPayments_retrievalAttemptsFailed = 0;
      //@ts-expect-error
      this.previousCryptoPayment = response?.response?.rows
    } else {
      this.previousCryptoPayments_retrievalStatus = 'failed';
      this.previousCryptoPayments_retrievalAttemptsFailed++;
    }
  }

  // ------------------------------
  // API Calls
  // ------------------------------

  // ----- General -----

  // ----- Account details -----

  async unhideAccount() {
    console.warn('OLD API IMPLEMENTATION');
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    let payload = {};
    let options = { update_state: true, return_collection_path: `/account/` };
    // @ts-expect-error
    let response = await this.store.dispatch('db_put_action', {
      //@ts-expect-error
      path: `/account/${this.id}/unhide`,
      payload,
      options,
    });
    //@ts-expect-error
    this.store.dispatch('showLoader', false);
    return response;
  }

  // @ts-expect-error
  async moveAccount(payload) {
    console.warn('OLD API IMPLEMENTATION');
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    let options = { update_state: true, return_collection_path: `/account/` };
    // @ts-expect-error
    let response = await this.store.dispatch('db_put_action', {
      //@ts-expect-error
      path: `/account/${this.id}/moveAccount`,
      payload,
      options,
    });
    //@ts-expect-error
    this.store.dispatch('showLoader', false);
    return response;
  }

  // @ts-expect-error
  async moveClient(payload) {
    console.warn('OLD API IMPLEMENTATION');
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    let options = { update_state: true, return_collection_path: `/account/` };
    // @ts-expect-error
    let response = await this.store.dispatch('db_put_action', {
      //@ts-expect-error
      path: `/account/${this.id}/moveClient`,
      payload,
      options,
    });
    //@ts-expect-error
    this.store.dispatch('showLoader', false);

    // TODO: recalc markups, signatories, beneficiaries, bankAccounts etc that depend on nested structure
    return response;
  }

  // @ts-expect-error
  async moveBranch(payload) {
    console.warn('OLD API IMPLEMENTATION');
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    let options = { update_state: true, return_collection_path: `/account/` };
    // @ts-expect-error
    let response = await this.store.dispatch('db_put_action', {
      //@ts-expect-error
      path: `/account/${this.id}/moveBranch`,
      payload,
      options,
    });
    //@ts-expect-error
    this.store.dispatch('showLoader', false);
    return response;
  }

  async hideAccount() {
    console.warn('OLD API IMPLEMENTATION');
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    let payload = {};
    let options = { update_state: true, return_collection_path: `/account/` };
    // @ts-expect-error
    let response = await this.store.dispatch('db_put_action', {
      //@ts-expect-error
      path: `/account/${this.id}/hide`,
      payload,
      options,
    });
    //@ts-expect-error
    this.store.dispatch('showLoader', false);
    return response;
  }

  // ----- Intermediaries -----

  // @ts-expect-error
  async addIntermediary(payload) {
    console.warn('OLD API IMPLEMENTATION');
    let record = payload;
    record.collection_path = '/account';
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    // @ts-expect-error
    let options = { return_collection_path: '/account', action: 'intermediary', parentId: this.id };
    // @ts-expect-error
    let response = await this.store.dispatch('db_create_document', { record, options });
    // @ts-expect-error
    this.store.dispatch('showLoader', false);
    return response;
  }

  // ----- Branches -----

  // @ts-expect-error
  async addBranch(payload) {
    console.warn('OLD API IMPLEMENTATION');
    let record = payload;
    record.collection_path = '/account';
    // @ts-expect-error
    let options = { return_collection_path: '/account', action: 'branch', parentId: this.id };
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    // @ts-expect-error
    let response = await this.store.dispatch('db_create_document', { record, options });
    // @ts-expect-error
    this.store.dispatch('showLoader', false);
    return response;
  }

  // ----- Clients -----

  // @ts-expect-error
  async addClient(payload) {
    console.warn('OLD API IMPLEMENTATION');
    let record = payload;
    record.collection_path = '/account';
    // @ts-expect-error
    let options = { return_collection_path: '/account', action: 'client', parentId: this.id };
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    // @ts-expect-error
    let response = await this.store.dispatch('db_create_document', { record, options });
    // @ts-expect-error
    this.store.dispatch('showLoader', false);
    return response;
  }

  // @ts-expect-error
  async importClient(payload) {
    console.warn('OLD API IMPLEMENTATION');
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    let options = { update_state: true, return_collection_path: `/account` };
    // @ts-expect-error
    let response = await this.store.dispatch('db_put_action', {
      path: `/client/import`,
      payload,
      options,
    });
    //@ts-expect-error
    this.store.dispatch('showLoader', false);
    return response;
  }

  // ----- Transactions -----

  // ----- Bank Accounts -----

  async getBankAccounts() {
    console.warn('OLD BANK ACCOUNT LOADING'); // FIXME: use retrieve_bankAccounts
    // @ts-expect-error
    let response = await doPermissionAction(this, 'getBankAccounts', { accountId: this.id }, {});
    return response;
  }

  // ----- Beneficiaries -----

  async getBeneficiaries() {
    console.warn('OLD BENEFICIARY LOADING'); // FIXME: use retrieve_beneficiaries
    // @ts-expect-error
    let response = await doPermissionAction(this, 'getBeneficiaries', { accountId: this.id }, {});
    return response;
  }

  // @ts-expect-error
  async addBeneficiary(payload) {
    console.warn('OLD API IMPLEMENTATION');
    let record = payload;
    record.collection_path = `/account`;
    let options = {
      // @ts-expect-error
      return_collection_path: `/account/${this.id}/beneficiary`,
      action: 'beneficiary',
      // @ts-expect-error
      parentId: this.id,
    };
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    // @ts-expect-error
    let response = await this.store.dispatch('db_create_document', { record, options });
    // @ts-expect-error
    this.store.dispatch('showLoader', false);
    return response;
  }

  // ----- Markups -----

  async getMarkups() {
    console.warn('OLD API IMPLEMENTATION');
    // @ts-expect-error
    let response = await this.store.dispatch('db_get_collection', {
      // @ts-expect-error
      collection_path: `/account/${this.id}/markup`,
      // @ts-expect-error
      options: { return_collection_path: `/account/${this.id}/markup`, definition_name: 'account_markup' },
    });
    return response;
  }

  // @ts-expect-error
  async addAccountMarkup(payload) {
    console.warn('OLD API IMPLEMENTATION');
    let record = payload;
    record.collection_path = '/account';
    // @ts-expect-error
    let options = { return_collection_path: '/account', action: 'markup', parentId: this.id };
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    // @ts-expect-error
    let response = await this.store.dispatch('db_create_document', { record, options });
    // @ts-expect-error
    this.store.dispatch('showLoader', false);
    return response;
  }

  // ----- Signatories -----

  async getParentSignatories() {
    this.parentSignatories_retrievalStatus = 'retrieving';
    // @ts-expect-error
    let response = await doPermissionAction(this, 'getAccountParentSignatories', { accountId: this.id }, {});
    if (response!.status === 'success') {
      this.parentSignatories_retrievalStatus = 'retrieved';
      this.parentSignatories_retrievalTimestamp = new Date();
      this.parentSignatories_retrievalAttemptsFailed = 0;
    } else {
      this.parentSignatories_retrievalStatus = 'failed';
      this.parentSignatories_retrievalTimestamp = new Date();
      this.parentSignatories_retrievalAttemptsFailed++;
    }
  }

  // ----- Users -----

  // @ts-expect-error
  async createUser(data) {
    console.warn('OLD API IMPLEMENTATION');
    let record = data;
    record.collection_path = '/account';
    let options = {
      // @ts-expect-error
      parentId: this.id,
      action: 'user',
      return_collection_path: '/user',
    };
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    // @ts-expect-error
    let response = await this.store.dispatch('db_create_document', { record, options });
    // @ts-expect-error
    this.store.dispatch('showLoader', false);
    return response;
  }

  // ----- Logs -----
}

// @ts-expect-error
export function Definition(context, name: string) {
  return new LfxAccountMdl(LfxAccountDef, LfxAccount, context, name, {});
}

// @ts-expect-error
export function bankDefinition(context, name: string) {
  return new LfxAccountMdl(LfxAccountDef, LfxAccount, context, name, {
    title: 'Bank',
    fields: {
      accountId: { views: { item: false, list: false } },
      intermediaryId: { views: { create: false, edit: false, item: false, list: false } },
      accountType: { views: { item: false } },
      identificationType: { views: { item: false } },
      identification: { views: { item: false, list: false } },
      externalReference: { views: { list: false } },
      bopFormSignatoryOption: { views: { list: false } },
      organisation: { views: { create: true, edit: true, item: true } },
    },
  });
}

// @ts-expect-error
export function intermediaryDefinition(context, name: string) {
  return new LfxAccountMdl(LfxAccountDef, LfxAccount, context, name, {
    title: 'Intermediary',
    fields: {
      accountId: { views: { item: true, list: false } },
      intermediaryId: { views: { create: false, edit: false, item: false, list: false } },
      identificationType: { views: { edit: false, item: false } },
      identification: { views: { edit: false, list: false } },
      cifNumber: { views: { addIntermediary: true } },
      bpNumber: { views: { create: true, edit: true, addIntermediary: true } },
      commissionAccount: { views: { create: true, edit: true, addIntermediary: true } },
      organisation: { views: { create: true, edit: true, addIntermediary: true } },
      name: { views: { item: false, addIntermediary: true } },
      externalReference: { views: { edit: false, item: false, list: false } },
      bopFormSignatoryOption: { views: { list: false, addIntermediary: true } },
    },
  });
}

// @ts-expect-error
export function intermediaryBranchDefinition(context, name: string) {
  return new LfxAccountMdl(LfxAccountDef, LfxAccount, context, name, {
    title: 'Branch',
    fields: {
      accountId: {
        write: true,
        views: { moveAccount: true },
        // datatype: {
        //   foreignKey: {
        //     guards: {
        //       intermediaryId(record: any) {
        //         return {
        //           eq: record?.store?.state?.account?.[record?.store?.state?.authUser?.accountId]?.intermediaryId,
        //         };
        //       },
        //     },
        //   },
        // },
      },
      intermediaryId: {
        views: { create: false, edit: false, item: intermediaryFieldViewGuard, list: intermediaryFieldViewGuard },
      },
      name: { views: { item: false, addBranch: true } },
      identificationType: { views: { create: true, edit: false, item: false } },
      identification: { views: { edit: false, list: false } },
      cifNumber: { views: { create: false, edit: false, item: false, list: false } },
      commissionAccount: { views: { create: false, edit: false, item: false, list: false } },
      organisation: { views: { create: false, edit: false, item: false, list: false } },
      // mayCompleteOwnKyc: { views: { create: false, edit: false, item: false, list: false } },
      // mayProcessBulkFees: { views: { create: false, edit: false, item: false, list: false } },
      // bankDirectClients: { views: { create: false, edit: false, item: false, list: false } },
      externalReference: { views: { edit: false, item: false, list: false } },
      bopFormSignatoryOption: { views: { edit: false, create: false, list: false } },
    },
  });
}

// @ts-expect-error
export function clientDefinition(context, name: string) {
  return new LfxAccountMdl(LfxAccountDef, LfxAccount, context, name, {
    title: 'Client',
    fields: {
      accountId: {
        views: { create: true, item: true, list: displayBranchForListView, addClient: true, moveAccount: true },
      },
      intermediaryId: {
        views: { create: false, edit: false, item: intermediaryFieldViewGuard, list: intermediaryFieldViewGuard },
      },
      accountType: {
        label: 'Client Type',
        default: null,
        views: {
          create: true,
          checkId: true,
          addClient: false,
          addAssociatedCif: true,
        },
        datatype: {
          option: {
            optionType: 'text',
            options: [
              { id: 'entity', name: 'Entity' },
              { id: 'individual', name: 'Individual' },
              { id: 'existing', name: 'Existing Client' },
            ],
          },
        },
      },

      firstName: {
        label: 'First Name',
        datatype: 'text',
        default: null,
        mandatory: false,
        allowNullValues: true,
        validators: {
          is: { msg: 'Please use only alpha charaters', args: [isAlphaWhiteList] },
          len: { msg: 'First Name should be between 2 and 50 characters', args: [2, 50] },
        },
        write: true,
        views: { passportIndividual: true },
        group: 'system',
      },
      middleName: {
        label: 'Middle Name',
        datatype: 'text',
        default: null,
        mandatory: false,
        allowNullValues: true,
        validators: {
          is: { msg: 'Please use only alpha charaters', args: [isAlphaWhiteList] },
          len: { msg: 'Middle Name should be between 0 and 50 characters', args: [0, 50] },
        },
        write: true,
        views: { passportIndividual: true },
        group: 'system',
      },
      surname: {
        label: 'Surname',
        datatype: 'text',
        default: null,
        mandatory: false,
        allowNullValues: true,
        validators: {
          is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
          len: { msg: 'Name should be between 2 and 200 characters', args: [2, 200] },
        },
        write: true,
        views: { passportIndividual: true },
        group: 'system',
      },

      name: {
        views: {
          create: true,
          item: false,
          addClient: true,
          addAssociatedCif: true,
          checkIdReadOnly: false,
          importClientReadOnly: true,
        },
      },
      identificationType: {
        views: {
          create: true,
          edit: false,
          item: true,
          checkId: true,
          addClient: false,
          addAssociatedCif: true,
        },
        datatype: {
          option: {
            optionType: 'text',
            options: [
              {
                id: 'rsaRegistrationNumber',
                name: 'RSA Company Registration Number',
                guards: { accountType: { eq: 'entity' } },
              },
              {
                id: 'internationalRegistrationNumber',
                name: 'International Company Registration Number',
                guards: { accountType: { eq: 'entity' } },
              },
              { id: 'rsaId', name: 'RSA ID Number', guards: { accountType: { eq: 'individual' } } },
              { id: 'passport', name: 'Passport', guards: { accountType: { eq: 'individual' } } },
              {
                id: 'rsaNonCompanyRegistrationNumber',
                name: 'Other RSA Registration Number',
                guards: { accountType: { eq: 'entity' } },
              },
              { id: 'cifNumber', name: 'CIF Number', guards: { accountType: { eq: 'existing' } } },
            ],
          },
        },
      },
      identification: {
        views: {
          create: true,
          edit: false,
          item: true,
          checkId: true,
          addClient: false,
          addAssociatedCif: true,
        },
      },
      cifNumber: { views: { importClientReadOnly: true } },
      commissionAccount: { views: { create: false, edit: false, item: false, list: false } },
      intermediaryHasOwnSignUpForm: { views: { create: false, edit: false, item: false, list: false } },
      organisation: { views: { create: false, edit: false, item: false, list: false } },
      // mayCompleteOwnKyc: { views: { create: false, edit: false, item: false, list: false } },
      // mayProcessBulkFees: { views: { create: false, edit: false, item: false, list: false } },
      externalReference: { views: { addClient: true, importClient: true } },
      tradingEnabled: { views: { item: true } },
      lastTradeTimstamp: { views: { create: false, list: true } },
      bopFormSignatoryOption: { views: { create: false, edit: false, list: false } },
      note: { views: { edit: true, item: true, closeClient: true } },
    },
  });
}
