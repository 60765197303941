<template>
  <template v-if="view === 'create'">
    <div class="flex-container">
      <div class="flex-item">
        <h1>Amount</h1>
        <LDBField
          v-if="show_externalReferenceField"
          :field="externalReferenceField"
          :view="externalReferenceField_captureView"
          :fieldname="externalReferenceField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          v-if="show_fromSettlementAccountIdField"
          :field="fromSettlementAccountIdField"
          :view="fromSettlementAccountIdField_captureView"
          :fieldname="fromSettlementAccountIdField.name"
          :definition="definition"
          :record="newRecord"
          :overrideOptions="fromSettlementAccountOptions"
          @change="on_change"
        />
        <capitec-spacer v-if="inwardSwiftIdField_captureView === 'item'" />
        <LDBField
          v-if="show_inwardSwiftIdField"
          :field="inwardSwiftIdField"
          :view="inwardSwiftIdField_captureView"
          :fieldname="inwardSwiftIdField.name"
          :definition="definition"
          :record="newRecord"
          :overrideOptions="inwardSwiftOptions"
          @change="on_change"
        />
        <LDBField
          v-if="show_toSettlementAccountIdField"
          :field="toSettlementAccountIdField"
          :view="toSettlementAccountIdField_captureView"
          :fieldname="toSettlementAccountIdField.name"
          :definition="definition"
          :record="newRecord"
          :overrideOptions="toSettlementAccountOptions"
          @change="on_change"
        />
        <capitec-spacer v-if="currencyPairField_captureView === 'item'" />
        <LDBField
          :field="currencyPairField"
          :view="currencyPairField_captureView"
          :fieldname="currencyPairField.name"
          :definition="definition"
          :record="newRecord"
          :overrideOptions="currencyPairOptions"
          @change="on_change"
        />
        <capitec-spacer v-if="actionField_captureView === 'item' && currencyPairField_captureView !== 'item'" />
        <LDBField
          :key="actionFieldKey"
          :field="actionField"
          :view="actionField_captureView"
          :fieldname="actionField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          :field="amountField"
          :view="amountField_captureView"
          :fieldname="amountField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
      </div>

      <div class="flex-item">
        <h1>Counter Amount</h1>
        <LDBField
          :key="deliveryTypeKey"
          :field="deliveryTypeField"
          :view="deliveryTypeField_captureView"
          :fieldname="deliveryTypeField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <p v-if="deliveryType && !deliveryTypeIsStillValidCheck">The selected Delivery Type is no longer available.</p>
        <LDBField
          v-if="show_forwardContractTypeField"
          :field="forwardContractTypeField"
          :view="forwardContractTypeField_captureView"
          :fieldname="forwardContractTypeField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          v-if="show_optionStartDateField"
          :field="optionStartDateField"
          :view="optionStartDateField_captureView"
          :fieldname="optionStartDateField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <p v-if="!checkOptionStartDateIsValid.isValid">{{ checkOptionStartDateIsValid.message }}</p>
        <LDBField
          v-if="show_valueDateField"
          :field="valueDateField"
          :view="valueDateField_captureView"
          :fieldname="valueDateField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <p v-if="!checkValueDateIsValid.isValid">{{ checkValueDateIsValid.message }}</p>
        <LDBField
          v-if="show_markupTypeField"
          :key="markupTypeKey"
          :field="markupTypeField"
          :view="markupTypeField_captureView"
          :fieldname="markupTypeField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          v-if="show_setMarkupField"
          :field="setMarkupField"
          :view="setMarkupField_captureView"
          :fieldname="setMarkupField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <capitec-spacer v-if="clientRateField_captureView === 'item'" />
        <LDBField
          v-if="show_forwardPointsPipsField"
          :field="forwardPointsPipsField"
          :view="forwardPointsPipsField_captureView"
          :fieldname="forwardPointsPipsField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          v-if="show_clientRateField"
          :field="clientRateField"
          :view="clientRateField_captureView"
          :fieldname="clientRateField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />

        <!-- <p v-if="error">{{ error }}</p> -->
        <p v-if="error">{{ errorDisplay }}</p>
        <capitec-spacer />
        <LDBField
          v-if="counterAmountField"
          :field="counterAmountField"
          view="item"
          :fieldname="counterAmountField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />

        <!-- <capitec-spacer /> -->
        <LDBField
          v-if="show_detailsOfChargeField"
          :field="detailsOfChargeField"
          :view="detailsOfCharge_captureView"
          :fieldname="detailsOfChargeField.name"
          :definition="undefined"
          :record="newRecord"
          @change="on_change"
        />
      </div>

      <div v-if="showMarkupAndRatesBreakdown === 'full'" class="flex-item">
        <h1>Markups and Rates</h1>
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Rate</th>
              <th>Markup (Pips)</th>
              <th>Amount (ZAR)</th>
            </tr>
          </thead>
          <tr>
            <td>Spot Rate</td>
            <td>{{ spotRate }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr v-if="showForwardFieldGroup">
            <td>Forward Points</td>
            <td></td>
            <td>{{ forwardPointsPips }}</td>
            <td></td>
          </tr>
          <tr v-if="showForwardFieldGroup">
            <td>Bench Rate</td>
            <td>{{ bench }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Bank Markup</td>
            <td></td>
            <td>{{ bankPipsNoFec }}</td>
            <td>{{ bankAmount }}</td>
          </tr>
          <tr v-if="showForwardFieldGroup">
            <td>Bank FEC Markup</td>
            <td></td>
            <td>{{ fecMarkup }}</td>
            <td></td>
          </tr>
          <tr>
            <td>Bank Rate</td>
            <td>{{ bankRate }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Total Intermediary Markup</td>
            <td></td>
            <td>{{ interPips }}</td>
            <td>{{ interAmount }}</td>
          </tr>
          <tr v-for="item in intermediaryPipBreakdown" :key="item.accountId">
            <td>● {{ item.name }}</td>
            <td></td>
            <td>{{ +item.pipsNoFec }}</td>
            <td>{{ item.amount }}</td>
          </tr>
          <tr v-if="showForwardFieldGroup">
            <td>● Intermediary FEC Markup</td>
            <td></td>
            <td>{{ interFecMarkup }}</td>
            <td></td>
          </tr>
          <tr>
            <td>Client Rate</td>
            <td>{{ clientRate }}</td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>

    <div class="lfx-content-title-bar-buttons lfx-new-deal-buttons">
      <LDBButton
        v-for="button in leftButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :params="button.params"
        :actionParams="newRecord"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      />
      <capitec-spacer />
      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :params="button.params"
        :actionParams="newRecord"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      />
    </div>
    <div v-if="isDevelopment">{{ actionParams }}</div>
  </template>

  <template v-if="view === 'item'">
    <div class="flex-container">
      <div class="flex-item">
        <h1>Summary</h1>
        <LDBField
          :field="accountIdField"
          view="item"
          :fieldname="accountIdField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          v-if="externalReferenceField && newRecord.externalReference"
          :field="externalReferenceField"
          view="item"
          :fieldname="externalReferenceField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          v-if="inwardSwiftIdField && newRecord.inwardSwiftId"
          :field="inwardSwiftIdField"
          view="item"
          :fieldname="inwardSwiftIdField.name"
          :definition="definition"
          :record="newRecord"
          :overrideOptions="inwardSwiftOptions"
          @change="on_change"
        />
        <LDBField
          v-if="fromSettlementAccountIdField && newRecord.fromSettlementAccountId"
          :field="fromSettlementAccountIdField"
          view="item"
          :fieldname="fromSettlementAccountIdField.name"
          :definition="definition"
          :record="newRecord"
          :overrideOptions="fromSettlementAccountOptions"
          @change="on_change"
        />
        <LDBField
          v-if="toSettlementAccountIdField && newRecord.toSettlementAccountId"
          :field="toSettlementAccountIdField"
          view="item"
          :fieldname="toSettlementAccountIdField.name"
          :definition="definition"
          :record="newRecord"
          :overrideOptions="toSettlementAccountOptions"
          @change="on_change"
        />
        <LDBField
          v-if="deliveryTypeField"
          :field="deliveryTypeField"
          view="item"
          :fieldname="deliveryTypeField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <p v-if="deliveryType && !deliveryTypeIsStillValidCheck">The selected Delivery Type is no longer available.</p>
        <LDBField
          v-if="forwardContractTypeField && newRecord.forwardContractType"
          :field="forwardContractTypeField"
          view="item"
          :fieldname="forwardContractTypeField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          v-if="optionStartDateField && newRecord.optionStartDate"
          :field="optionStartDateField"
          view="item"
          :fieldname="optionStartDateField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          v-if="availableOpenDealLimitField"
          :field="availableOpenDealLimitField"
          view="item"
          :fieldname="availableOpenDealLimitField.name"
          :definition="clientDefinition"
          :record="accountRecord"
          @change="on_change"
        />
        <p v-if="dealExceedsAvailableLimit">
          <!-- <span> <capitec-icon icon="status/error" /></span>  -->
          Deal Value exceeds the Client's Available Open Deal Amount.
        </p>

        <capitec-spacer />
        <LDBField
          v-if="show_forwardPointsPipsField"
          :field="forwardPointsPipsField"
          view="item"
          :fieldname="forwardPointsPipsField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          v-if="showMarkupAndRatesBreakdown === 'simplified'"
          :field="clientRateField"
          view="item"
          :fieldname="clientRateField.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />

        <capitec-spacer />
        <LDBField
          v-if="sellAmountField"
          :field="sellAmountField"
          view="item"
          :fieldname="sellAmountFieldName"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          v-if="show_detailsOfChargeField"
          :field="detailsOfChargeField"
          view="item"
          :fieldname="detailsOfChargeField.name"
          :definition="undefined"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          v-if="show_totalCostField"
          :field="totalZarCostField"
          view="item"
          :fieldname="totalZarCostField.name"
          :definition="undefined"
          :record="newRecord"
          @change="on_change"
        />
        <LDBField
          v-if="buyAmountField"
          :field="buyAmountField"
          view="item"
          :fieldname="buyAmountFieldName"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
      </div>

      <div v-if="showMarkupAndRatesBreakdown === 'full'" class="flex-item">
        <h1>Markups and Rates</h1>
        <p v-if="error">{{ errorDisplay }}</p>
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Rate</th>
              <th>Markup (Pips)</th>
              <th>Amount (ZAR)</th>
            </tr>
          </thead>
          <tr>
            <td>Spot Rate</td>
            <td>{{ spotRate }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr v-if="showForwardFieldGroup">
            <td>Forward Points</td>
            <td></td>
            <td>{{ forwardPointsPips }}</td>
            <td></td>
          </tr>
          <tr v-if="showForwardFieldGroup">
            <td>Bench Rate</td>
            <td>{{ bench }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Bank Markup</td>
            <td></td>
            <td>{{ bankPipsNoFec }}</td>
            <td>{{ bankAmount }}</td>
          </tr>
          <tr v-if="showForwardFieldGroup">
            <td>Bank FEC Markup</td>
            <td></td>
            <td>{{ fecMarkup }}</td>
            <td></td>
          </tr>
          <tr>
            <td>Bank Rate</td>
            <td>{{ bankRate }}</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Total Intermediary Markup</td>
            <td></td>
            <td>{{ interPips }}</td>
            <td>{{ interAmount }}</td>
          </tr>
          <tr v-for="item in intermediaryPipBreakdown" :key="item.accountId">
            <td>● {{ item.name }}</td>
            <td></td>
            <td>{{ +item.pipsNoFec }}</td>
            <td>{{ item.amount }}</td>
          </tr>
          <tr v-if="showForwardFieldGroup">
            <td>● Intermediary FEC Markup</td>
            <td></td>
            <td>{{ interFecMarkup }}</td>
            <td></td>
          </tr>
          <tr>
            <td>Client Rate</td>
            <td>{{ clientRate }}</td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>
    <p v-if="duplicateBookingTokenFound">This Deal screen has already been submitted and may already have created a Deal.<br/>To prevent duplication, please double check whether it was booked before booking the deal again.</p>
    <div class="lfx-content-title-bar-buttons lfx-new-deal-buttons">
      <LDBButton
        v-for="button in leftButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :params="button.params"
        :actionParams="button.actionParams"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      />
      <capitec-spacer />

      <LDBButton
        v-for="button in rightButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        :action="button.action"
        :params="button.params"
        :actionParams="button.actionParams"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      />
    </div>
  </template>

  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import lodash from 'lodash';
import { v4 as uuidv4 } from 'uuid'
const merge = lodash.merge;

import {
  getCurrencyType,
  getSpotFromCachedRic,
  calculateForwardPoints,
  calculateCounterAmount,
  calculateMarkupsAndRates,
  getMarkupAmount,
  getDealLocalAmount,
} from '@/sharedUtils/LfxTradeCalc';
import { roundTo } from '@/sharedUtils/LdbSharedUtils';

import { upgradeRatesFeed } from '@/webSockets/LfxRatesFeedWebSocket';

export default {
  name: 'LfxNewDeal',
  emits: ['step-back', 'step-next', 'allow-review', 'update-currency-pair', 'update-markup-object'],
  data() {
    return {
      newRecord: {},
      error: null,
      now: new Date(),
      bookingToken: undefined,
      duplicateBookingTokenFound: false
    };
  },
  created() {
    void this.accountRecord?.accountSettings;
    this.accountRecord?.loadDataForDealing();
    this.upgradeRatesFeed_method();
    this.defineNewRecord();
    this.populateNewRecord();
    // TODO: Capture spot rates for simplified trading
  },
  mounted() {
    this.bookingToken = undefined;
    this.bookingToken = uuidv4()
    console.log("Booking Token Generated " + this.bookingToken)
  },
  computed: {
    ...mapGetters([
      'config',
      'permissions',
      'currencyPairs',
      'activeCurrencyPairs',
      'allowTradingCurrencyPairs',
      'isDevelopment',
      'publicHolidayForDate',
      'isBeforeSameDayCutOffTimes',
      'sameDayCutOffTimes_asDates',
      'isWithinTradingHours',
      'tradeSettings',
      'isTestingSdlc',
    ]),
    ...mapState([
      'transaction',
      'deal',
      'account',
      'swift',
      'rates',
      'ratesFeedStatus',
      'systemSettings',
      'authUser',
      // 'accountSettings',
    ]),

    // ----- Test -----

    systemButtonStyle() {
      return { '--theme-button-padding-top': '5px', '--theme-button-padding-bottom': '5px' };
    },
    test() {
      return this.todayDate;
    },

    // ----- General -----

    view() {
      return this.$route.meta?.content?.view || 'item';
    },
    fieldView() {
      return this.view === 'create' ? 'edit' : 'item';
    },

    // requiredDataLoaded() {
    //   return this.accountRecord?.dataLoadedForDealing;
    // },

    errorDisplay() {
      let errorDisplay = this.error.message;
      switch (this.error.message) {
        case 'Markup is too large: > 0.1': // TODO make dynamic based on maximumMarkupPercentage
          errorDisplay =
            'The Total Intermediary Markup can not exceed 10% of the Bank Rate. Reduce the Fixed Client Rate or reconfigure the Intermediary Markup.'; // TODO make dynamic based on maximumMarkupPercentage
          break;
        case 'Markup must be positive':
          errorDisplay = 'The Total Intermediary Markup can not be less than zero.';
          break;
      }
      return errorDisplay;
    },

    showAmounts() {
      return this.authUser.accountLevel !== 'client';
    },
    showMarkupAndRatesBreakdown() {
      return this.authUser.accountLevel === 'client' ? 'simplified' : 'full';
    },

    // ----- Transaction Record -----

    transactionId() {
      return +this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    // ----- Deal Record -----

    dealId() {
      // return +this.$route.params?.dealId;
      return +this.transactionRecord?.firstDealId;
    },
    dealRecord() {
      return this.deal?.[this.dealId];
    },

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId || this.transactionRecord?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },
    intermediaryRecord() {
      return this.account?.[this.accountRecord.intermediaryId];
    },
    clientMayBookFec() {
      return this.accountRecord.mayBookFec && this.accountRecord.fecDocumentsInOrder;
    },

    activeClientBankAccounts() {
      return this.accountRecord?.ownActive_bankAccounts;
    },
    clientInwardSwifts() {
      return this.accountRecord?.clientInwardSwifts;
    },

    clientDefinition() {
      return getDefinition('client');
    },

    // ----- Deal definition -----

    definition() {
      return getDefinition('deal');
    },
    collectionPath() {
      return `/deal`;
    },

    transactionType() {
      return this.$route.meta?.content?.transactionType || this.$route.meta?.modal?.transactionType;
    },

    // ----- SWIFT record -----

    swiftId() {
      return +this.$route.params?.swiftId;
    },

    inwardSwiftOptions() {
      let inwardSwiftOptions = {};
      if (this.transactionType === 'inwardDeal') {
        if (this.swiftId) {
          inwardSwiftOptions[this.swiftId] = this.swift?.[this.swiftId];
        } else {
          for (let iws in this.clientInwardSwifts) {
            let swift = this.clientInwardSwifts?.[iws];
            if (swift.status === 'available') {
              inwardSwiftOptions[iws] = swift;
            }
          }
          inwardSwiftOptions['X'] = { id: 'X', swiftNumber: 'SWIFT not available yet' };
        }
      }
      return inwardSwiftOptions;
    },

    inwardSwiftId() {
      return this.newRecord?.inwardSwiftId;
    },
    inwardSwiftRecord() {
      return this.swift?.[this.inwardSwiftId];
    },
    inwardSwiftCurrencyId() {
      return this.inwardSwiftRecord?.currencyId;
    },
    inwardSwiftAmount() {
      return this.inwardSwiftRecord?.amount;
    },

    // ----- Settlement Accounts -----

    fromSettlementAccountOptions() {
      return this.activeClientBankAccounts;
    },

    fromSettlementAccountId() {
      return this.newRecord?.fromSettlementAccountId;
    },
    fromSettlementAccountCurrencyId() {
      return this.accountRecord?.bankAccounts?.[this.fromSettlementAccountId]?.currencyId;
    },

    toSettlementAccountOptions() {
      let toSettlementAccountOptions = {};
      for (let b_acc in this.activeClientBankAccounts) {
        let bankAccount = this.activeClientBankAccounts[b_acc];
        if (this.toCurrencyOptions?.has(bankAccount.currencyId)) {
          toSettlementAccountOptions[b_acc] = bankAccount;
        }
      }
      return toSettlementAccountOptions;
    },

    toSettlementAccountId() {
      return this.newRecord?.toSettlementAccountId;
    },
    toSettlementAccountCurrencyId() {
      return this.accountRecord?.bankAccounts?.[this.toSettlementAccountId]?.currencyId;
    },

    // ----- Currencies -----

    fromCurrencyId() {
      return this.transactionType === 'inwardDeal' ? this.inwardSwiftCurrencyId : this.fromSettlementAccountCurrencyId;
    },
    toCurrencyId() {
      return this.toSettlementAccountCurrencyId;
    },
    combinedCurrencyIds() {
      return `${this.fromCurrencyId}-${this.toCurrencyId}`;
    },

    currencyPairOptionsFilteredByFromCurrency() {
      // let availableCurrencyPairs = this.activeCurrencyPairs; // TODO remove this - used for testing // avr 2023-03-13
      let availableCurrencyPairs = this.allowTradingCurrencyPairs;
      let filterCurrency = this.fromCurrencyId;
      let filteredCurrencyPairs = {};
      if (filterCurrency) {
        for (let cP in availableCurrencyPairs) {
          let currencyPair = availableCurrencyPairs[cP];
          if (currencyPair.quoteCurrency === filterCurrency || currencyPair.baseCurrency === filterCurrency) {
            filteredCurrencyPairs[cP] = currencyPair;
          }
        }
      } else {
        filteredCurrencyPairs = availableCurrencyPairs;
      }
      return filteredCurrencyPairs;
    },

    toCurrencyOptions() {
      let availableCurrencyPairs = this.currencyPairOptionsFilteredByFromCurrency;
      let filterCurrency = this.fromCurrencyId;
      let toCurrencyOptions = [];
      for (let cP in availableCurrencyPairs) {
        let currencyPair = availableCurrencyPairs[cP];
        if (currencyPair.quoteCurrency !== filterCurrency) {
          toCurrencyOptions.push(currencyPair.quoteCurrency);
        }
        if (currencyPair.baseCurrency !== filterCurrency) {
          toCurrencyOptions.push(currencyPair.baseCurrency);
        }
      }
      let uniqueToCurrencyOptions = new Set(toCurrencyOptions);
      return uniqueToCurrencyOptions;
    },

    currencyPairOptions() {
      let availableCurrencyPairs = this.currencyPairOptionsFilteredByFromCurrency;
      let filterCurrency = this.toSettlementAccountCurrencyId;
      let filteredCurrencyPairs = {};
      if (this.transactionType === 'outwardDeal') {
        filteredCurrencyPairs = availableCurrencyPairs;
      } else if (filterCurrency) {
        for (let cP in availableCurrencyPairs) {
          let currencyPair = availableCurrencyPairs[cP];
          if (currencyPair.quoteCurrency === filterCurrency || currencyPair.baseCurrency === filterCurrency) {
            filteredCurrencyPairs[cP] = currencyPair;
          }
        }
      } else {
        filteredCurrencyPairs = availableCurrencyPairs;
      }
      const filteredCurrencyPairsArray = [];
      for (const currencyPair in filteredCurrencyPairs) {
        filteredCurrencyPairsArray.push(filteredCurrencyPairs[currencyPair]);
      }
      filteredCurrencyPairsArray.sort((currencyPair1, currencyPair2) => {
        return currencyPair1.index - currencyPair2.index;
      });
      const sortedCurrencyPairs = {};
      for (const currencyPair of filteredCurrencyPairsArray) {
        sortedCurrencyPairs[currencyPair.id] = currencyPair;
      }
      return sortedCurrencyPairs;
    },

    currencyPair() {
      return this.activeCurrencyPairs?.[this.newRecord?.currencyPair];
    },
    quoteCurrency() {
      return this.currencyPair?.quoteCurrency;
    },
    baseCurrency() {
      return this.currencyPair?.baseCurrency;
    },
    instrument() {
      return this.currencyPair?.instrument;
    },

    action() {
      return this.newRecord?.action;
    },
    counterAction() {
      let counterAction = null;
      if (this.action === 'Buy') {
        counterAction = 'Sell';
      } else if (this.action === 'Sell') {
        counterAction = 'Buy';
      }
      return counterAction;
    },

    currency() {
      return this.newRecord?.currency;
    },
    counterCurrency() {
      let currency = this.currency;
      let counterCurrency = null;
      if (this.currencyPair) {
        counterCurrency = this.getOtherCurrencyIdInCurrencyPair(this.currencyPair, this.currency);
      }
      return counterCurrency;
    },
    currencyType() {
      let currency = this.currency;
      let currencyPair = this.currencyPair;
      return currency && currencyPair ? getCurrencyType(currency, currencyPair) : null;
    },

    sellCurrencyId() {
      let res = undefined;
      switch (this.transactionType) {
        case 'outwardDeal':
          res = this.fromSettlementAccountCurrencyId;
          break;
        case 'transferDeal':
          res = this.fromSettlementAccountCurrencyId;
          break;
        case 'inwardDeal':
          if (this.inwardSwiftId && this.inwardSwiftId !== 'X') {
            res = this.inwardSwiftCurrencyId;
          } else if (this.inwardSwiftId === 'X' && this.toSettlementAccountId) {
            res = this.getOtherCurrencyIdInCurrencyPair(this.currencyPair, this.toSettlementAccountCurrencyId);
          }
          break;
        case 'addDeal':
          res = this.fromSettlementAccountCurrencyId;
          break;
      }
      return res;
    },
    buyCurrencyId() {
      let res = undefined;
      if (this.currencyPair) {
        res = this.getOtherCurrencyIdInCurrencyPair(this.currencyPair, this.sellCurrencyId);
      }
      return res;
    },

    // ----- Delivery Type -----

    transactionTypeForCutOffCheck() {
      let transactionTypeForCutOffCheck = 'outwardDeal';
      if (this.transactionType === 'transferDeal') {
        transactionTypeForCutOffCheck = 'transferDeal';
      } else if (this.transactionType === 'inwardDeal') {
        transactionTypeForCutOffCheck = 'inwardDeal';
      }
      return transactionTypeForCutOffCheck;
    },

    sameDayCutOffTimeLabel() {
      const hours = this.sameDayCutOffTimes_asDates?.[this.transactionTypeForCutOffCheck]?.getHours();
      const minutes = this.sameDayCutOffTimes_asDates?.[this.transactionTypeForCutOffCheck]
        ?.getMinutes()
        .toLocaleString('en-ZA', { minimumIntegerDigits: 2 });
      return `${hours}:${minutes}`;
    },
    pastSameDayCutOffTime() {
      return this.isBeforeSameDayCutOffTimes?.[this.transactionTypeForCutOffCheck] ? false : true;
    },
    allowSameDay() {
      return this.transactionType === 'outwardDeal' || this.transactionType === 'addDeal'
        ? this.currencyPair?.allowSameDay
        : true;
    },
    todayDate() {
      let now = this.now;
      let today = now.toISOString();
      let cleanToday = today.substring(0, 10);
      let todayDate = new Date(cleanToday);
      return todayDate;
    },
    todayDateISOString() {
      return this.todayDate?.toISOString();
    },
    publicHolidayForToday() {
      let date = this.todayDateISOString.substring(0, 10);
      return this.currencyPair ? this.publicHolidayForDate(date, this.currencyPair?.id) : undefined;
    },
    todayDateLabel() {
      if (!this.isWithinTradingHours) {
        return 'Not available outside trading hours.'; // TODO display trading hours
      } else if (!this.allowSameDay) {
        return `Not available for the ${this.currencyPair.name} currency pair.`;
      } else if (this.publicHolidayForToday) {
        let currency = this.publicHolidayForToday.currencyId;
        let name = this.publicHolidayForToday.name;
        return `Not available due to ${currency} Public Holiday - ${name}.`;
      } else if (this.pastSameDayCutOffTime) {
        return `Not available after ${this.sameDayCutOffTimeLabel}.`;
      } else {
        return this.todayDate?.toLocaleString('en-ZA', { dateStyle: 'full' });
      }
    },
    tomDateISOString() {
      return this.currencyPair?.tomDate;
    },
    tomDate() {
      return this.tomDateISOString ? new Date(this.tomDateISOString) : undefined;
    },
    tomDateLabel() {
      if (!this.isWithinTradingHours) {
        return 'Not available outside trading hours.'; // TODO display trading hours
      } else {
        return this.tomDate
          ? new Date(this.tomDate)?.toLocaleString('en-ZA', { dateStyle: 'full' })
          : `Not available due to one or more Public Holidays.`;
      }
    },
    spotDateISOString() {
      return this.currencyPair?.spotDate;
    },
    spotDate() {
      return new Date(this.spotDateISOString);
    },
    spotDateLabel() {
      if (!this.isWithinTradingHours) {
        return 'Not available outside trading hours.'; // TODO display trading hours
      } else {
        return new Date(this.spotDate)?.toLocaleString('en-ZA', { dateStyle: 'full' });
      }
    },

    allowForward() {
      return this.currencyPair?.allowForward;
    },
    forwardDateLabel() {
      return !this.clientMayBookFec
        ? 'Client is not enabled for FEC or FEC Documentation is not in order'
        : !this.isWithinTradingHours
        ? 'Not available outside trading hours.' // TODO display trading hours
        : !this.allowForward
        ? `Not available for this currency pair.`
        : this.inwardSwiftId && this.inwardSwiftId !== 'X'
        ? `Not available when booking a deal from an inward SWIFT.`
        : this.deliveryType === 'FWD' && this.valueDate
        ? new Date(this.valueDate)?.toLocaleString('en-ZA', { dateStyle: 'full' })
        : `Select a value date`;
    },

    deliveryType() {
      return this.newRecord?.deliveryType;
    },
    valueDateISOString() {
      return this.newRecord?.valueDate;
    },
    valueDate() {
      if (this.valueDateISOString) {
        return new Date(this.valueDateISOString);
      } else {
        return null;
      }
    },
    existingTransactionValueDate() {
      // return this.transactionRecord?.valueDate;
      return this.transactionRecord?.valueDate;
    },

    checkOptionStartDateIsValid() {
      let res = { isValid: true, message: '' };
      if (this.deliveryType === 'FWD' && this.forwardContractType === 'partiallyOptional' && this.optionStartDate) {
        const optionStartDate = new Date(this.optionStartDate);
        if (optionStartDate.getDay() === 0) {
          res.isValid = false;
          res.message = 'Option Start Date cannot fall on a Sunday.';
        } else if (optionStartDate.getDay() === 6) {
          res.isValid = false;
          res.message = 'Option Start Date cannot fall on a Saturday.';
        } else {
          let publicHoliday = this.publicHolidayForDate(
            optionStartDate.toISOString().substring(0, 10),
            this.currencyPair?.id
          );
          if (publicHoliday) {
            res.isValid = false;
            res.message = `Option Start Date cannot fall on ${publicHoliday.currencyId} Public Holiday: ${publicHoliday.name}.`;
          }
        }
      }
      return res;
    },
    checkValueDateIsValid() {
      let res = { isValid: true, message: '' };
      if (this.deliveryType === 'FWD' && this.valueDate) {
        if (this.valueDate.getDay() === 0) {
          res.isValid = false;
          res.message = 'Value Date cannot fall on a Sunday.';
        } else if (this.valueDate.getDay() === 6) {
          res.isValid = false;
          res.message = 'Value Date cannot fall on a Saturday.';
        } else {
          let publicHoliday = this.publicHolidayForDate(
            this.valueDate.toISOString().substring(0, 10),
            this.currencyPair?.id
          );
          if (publicHoliday) {
            res.isValid = false;
            res.message = `Value Date cannot fall on ${publicHoliday.currencyId} Public Holiday: ${publicHoliday.name}.`;
          }
        }
      }
      return res;
    },

    // ----- Forwards -----

    forwardContractType() {
      return this.newRecord?.forwardContractType;
    },
    optionStartDate() {
      return this.newRecord?.optionStartDate;
    },

    maxForwardMonths() {
      // if (this.isTestingSdlc) {
      //   return 12;
      // }
      // return 6;
      return 12;
    },

    maxValueDate() {
      let today = this.todayDate;
      // let today = new Date(2022, 7, 31, 2); // test for end feb
      let day = today.getUTCDate();
      let month = today.getUTCMonth();
      let year = today.getUTCFullYear();
      let newYear = year;
      let newMonth = month + this.maxForwardMonths;
      if (newMonth > 11) {
        newMonth = newMonth - 12;
        newYear = newYear + 1;
      }
      let newDay = day;
      let maxValueDate = new Date(newYear, newMonth, newDay, 2);
      for (let i = 0; i < 3; i++) {
        if (maxValueDate.getUTCDate() === newDay) {
          break;
        } else {
          newDay = newDay - 1;
        }
      }
      maxValueDate = new Date(newYear, newMonth, newDay, 2);
      return maxValueDate;
    },

    minValueDate() {
      return this.newRecord?.forwardContractType === 'partiallyOptional'
        ? this.newRecord.optionStartDate
        : this.spotDateISOString;
    },

    maxOptionStartDate() {
      let maxOptionStartDate = new Date(this.maxValueDate.getTime() - 24 * 60 * 60 * 1000);
      return maxOptionStartDate;
    },

    // ----- Rate -----

    cachedRic() {
      return this.rates?.[this.instrument];
    },
    spotRate() {
      let cachedRic = this.cachedRic;
      let currencyType = this.currencyType;
      let action = this.action;
      return cachedRic && currencyType && action ? getSpotFromCachedRic(cachedRic, currencyType, action) : null;
    },

    clientRate() {
      return this.newRecord?.clientRate;
    },

    // ----- Markup -----

    fixedRate_optionGuard() {
      return this.permissions?.freezeExchangeRate;
    },

    markupType() {
      return this.newRecord?.markupType;
    },
    setMarkup() {
      return this.newRecord?.setMarkup;
    },

    parentMarkupStatus() {
      return this.accountRecord?.parentMarkupStatus;
    },
    accountMarkups() {
      let accountMarkups = undefined;
      if (this.currencyPair) {
        let parentMarkups = this.accountRecord?.parentMarkups;
        if (this.parentMarkupStatus === 'retrieved') {
          accountMarkups = parentMarkups?.[this.currencyPair.id];
        }
      }
      const allAccountIds = [this.accountRecord?.id];
      for (const id of this.accountRecord?.parentAccountIds) {
        allAccountIds.push(id);
      }
      if (Array.isArray(accountMarkups)) {
        for (let i = 0; i < allAccountIds.length; i++) {
          if (allAccountIds[i] !== 1) {
            let markupFound = false;
            for (const markup of accountMarkups) {
              if (markup.accountId === allAccountIds[i]) {
                markupFound = true;
              }
            }
            if (!markupFound && !!allAccountIds[i + 1]) {
              accountMarkups.push({
                accountId: allAccountIds[i],
                parentId: allAccountIds[i + 1],
                markupType: 'pips',
                pips: 0,
              });
            }
          }
        }
      }
      return accountMarkups;
    },

    optionalAdditionalPercentage() {
      return +this.systemSettings?.trading?.forwardOptionalPercentage;
    },

    calculateForwardPointsCheckDependencies() {
      let res =
        this.cachedRic &&
        this.forwardContractType &&
        this.spotDate &&
        this.valueDate &&
        this.currencyType &&
        this.action &&
        this.optionalAdditionalPercentage &&
        this.optionStartDate
          ? true
          : false;
      return res;
    },

    calculateForwardPoints() {
      return this.deliveryType === 'FWD'
        ? calculateForwardPoints(
            this.cachedRic,
            this.forwardContractType,
            this.spotDate,
            this.valueDate,
            this.currencyType,
            this.action,
            this.optionalAdditionalPercentage,
            new Date(this.optionStartDate)
          )
        : undefined;
    },

    markupObject() {
      let markupObject = {
        currencyType: this.currencyType,
        action: this.action,
        valueDate: this.valueDate,
        markupType: this.markupType,
        spotRate: this.spotRate,
        clientRate: this.clientRate,
        accountMarkups: this.accountMarkups,
        config: {
          maximumMarkupPercentage: this.systemSettings?.trading?.maximumMarkupPercentage,
          flatFeeRates: { OUR: 50000, SHARE: 25000, BEN: 0 },
          allowIntermediaryMarkupToBeNegative: this.tradeSettings.allowIntermediaryMarkupToBeNegative === true,
        },
      };

      if (this.markupType === 'fixedSpread' && this.setMarkup) {
        markupObject.setMarkup = +this.setMarkup;
      }

      if (this.deliveryType === 'FWD') {
        markupObject.forwardPoints = this.calculateForwardPoints;
        // markupObject.config.fecAdditionalSpreads = this.systemSettings?.trading?.fecAdditionalSpreads;
        markupObject.config.fecAdditionalSpreads = this.intermediaryRecord.fecMarkups;
        markupObject.config.interFecAdditionalSpreads = this.accountRecord.fecMarkups;
      }

      return markupObject;
    },

    markupReturn() {
      let currencyType = this.currencyType;
      let action = this.action;
      let valueDate = this.valueDate;
      let markupType = this.markupType;
      let spotRate = this.spotRate;

      let accountMarkups = this.accountMarkups;

      let maximumMarkupPercentage = this.systemSettings?.trading?.maximumMarkupPercentage;

      let checkDependencies =
        currencyType && action && valueDate && markupType && spotRate && accountMarkups && maximumMarkupPercentage;
      // console.log({
      //   checkDependencies,
      //   currencyType,
      //   action,
      //   valueDate,
      //   markupType,
      //   spotRate,
      //   accountMarkups,
      //   maximumMarkupPercentage,
      // });
      let markupReturn = undefined;
      if (checkDependencies) {
        try {
          markupReturn = calculateMarkupsAndRates(this.markupObject);
          this.$emit('update-markup-object', markupReturn);
          this.error = null;
        } catch (error) {
          console.log('Markup Error: ', error);
          this.error = error;
        }
      }
      return markupReturn;
    },

    forwardPointsPips() {
      if (this.forwardPoints === undefined) {
        return undefined;
      }
      return roundTo(this.forwardPoints * 10000, 0);
    },

    forwardPoints() {
      return this.markupReturn?.forwardPoints;
    },
    bench() {
      return this.markupReturn?.bench;
    },
    bankPips() {
      return this.markupReturn?.bankPips;
    },
    bankPipsNoFec() {
      if (this.bankPips !== undefined) {
        return this.bankPips - this.fecMarkup || 0;
      }
      return undefined;
    },
    bankAmount() {
      let deal = {
        currency: this.currency,
        amount: this.amount,
        counterAmount: this.counterAmount,
        bench: this.bench,
      };
      let markupPips = this.bankPips;
      return this.bankPips ? getMarkupAmount(deal, markupPips, this.quoteCurrency) / 100 : null;
    },
    fecMarkup() {
      return this.markupReturn?.fecMarkup;
    },
    interFecMarkup() {
      return this.markupReturn?.interFecMarkup;
    },
    bankRate() {
      return this.markupReturn?.bankRate;
    },
    interPips() {
      return this.markupReturn?.interPips;
    },
    interAmount() {
      let deal = {
        currency: this.currency,
        amount: this.amount,
        counterAmount: this.counterAmount,
        bench: this.bench,
      };
      let markupPips = this.interPips;
      return this.interPips ? getMarkupAmount(deal, markupPips, this.quoteCurrency) / 100 : null;
    },
    markupReturn_clientRate() {
      return this.markupReturn?.clientRate;
    },

    pipBreakdown() {
      return this.markupReturn?.pipBreakdown;
    },
    intermediaryPipBreakdown() {
      let intermediaryPipBreakdown = [];
      let pipBreakdown = this.pipBreakdown;
      let deal = {
        currency: this.currency,
        amount: this.amount,
        counterAmount: this.counterAmount,
        bench: this.bench,
      };
      if (pipBreakdown) {
        for (let p = 0; p < pipBreakdown.length; p++) {
          let pbd = pipBreakdown[p];
          if (pbd.accountId !== 1) {
            pbd.name = this.account?.[pbd.accountId]?.name;
            pbd.pipsNoFec = pbd.pips;
            if (p === pipBreakdown.length - 1) {
              pbd.pipsNoFec = pbd.pips - this.interFecMarkup || 0;
            }
            pbd.amount = getMarkupAmount(deal, pbd.pips, this.quoteCurrency) / 100;

            // TODO amount rounding and formatting
            // TODO pips rounding
            // TODO show saved markups
            // TODO show discounted markups
            intermediaryPipBreakdown.push(pbd);
          }
        }
      }
      return intermediaryPipBreakdown;
    },

    // ----- Amounts -----

    amount() {
      return this.newRecord?.amount;
    },
    counterAmount() {
      let amount = +this.amount;
      let rate = this.clientRate;
      let currencyType = this.currencyType;
      let decimals = 2; // TODO for currencyPairs that don't have 2 decimals - LdbFieldCurrency doesn't respect this
      return amount && rate && currencyType ? calculateCounterAmount(amount, rate, currencyType, decimals) : null;
    },

    // ----- Fields -----

    fields() {
      return this.newRecord.guarded_fields;
    },
    accountFields() {
      return this.accountRecord.guarded_fields;
    },

    balanceEnquiryRouteName() {
      // TODO route for addDeal and booking from inwardSwift
      switch (this.transactionType) {
        case 'outwardDeal':
          return 'newTransactionOutwardDealCaptureAccountBalance';
        case 'transferDeal':
          return 'newTransactionTransferDealCaptureAccountBalance';
        case 'inwardDeal':
          return 'newTransactionInwardDealCaptureAccountBalance';
      }
    },

    // External Reference field

    externalReferenceField() {
      return this.fields?.find((field) => field.name === 'externalReference');
    },
    show_externalReferenceField() {
      return this.tradeSettings.defaultFixedOption !== 'rate' ? true : false; // TODO: check user preferences
    },
    externalReferenceField_captureView() {
      return 'edit';
    },

    // From Settlement Account field

    fromSettlementAccountIdField() {
      let fieldName = 'fromSettlementAccountId';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        label: 'From Account',
        button: undefined,
      });

      if (
        this.newRecord.fromSettlementAccountId &&
        this.activeClientBankAccounts[this.newRecord.fromSettlementAccountId] &&
        this.permissions?.balanceEnquiry
      ) {
        const self = this;
        overriddenField.button = {
          action: 'click',
          icon: 'system/search-action',
          views: { edit: true, item: false },
          clickFunction() {
            self.$router.push({
              name: self.balanceEnquiryRouteName,
              params: { bankAccountId: self.newRecord.fromSettlementAccountId },
            });
          },
        };
      }
      return overriddenField;
    },
    show_fromSettlementAccountIdField() {
      return ['outwardDeal', 'transferDeal', 'addDeal'].includes(this.transactionType);
    },
    fromSettlementAccountIdField_captureView() {
      return this.transactionType === 'addDeal' ? 'item' : 'edit'; // Note: This forces the additional deal to come from the same bankAccount
    },

    // Inward SWIFT field

    inwardSwiftIdField() {
      // TODO: add button to SWIFT field to check SWIFT information
      return this.fields?.find((field) => field.name === 'inwardSwiftId');
    },
    show_inwardSwiftIdField() {
      return this.transactionType === 'inwardDeal' ? true : false;
    },
    inwardSwiftIdField_captureView() {
      return this.swiftId ? 'item' : 'edit';
    },

    // To Settlement Account field

    toSettlementAccountIdField() {
      let fieldName = 'toSettlementAccountId';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        label: 'To Account',
        enableGuards: this.newRecord.fromSettlementAccountId || this.newRecord.inwardSwiftId ? true : false,
        button: undefined,
      });

      if (
        this.newRecord.toSettlementAccountId &&
        this.activeClientBankAccounts[this.newRecord.toSettlementAccountId] &&
        this.permissions?.balanceEnquiry
      ) {
        const self = this;
        overriddenField.button = {
          action: 'click',
          icon: 'system/search-action',
          views: { edit: true, item: false },
          clickFunction() {
            self.$router.push({
              name: self.balanceEnquiryRouteName,
              params: { bankAccountId: self.newRecord.toSettlementAccountId },
            });
          },
        };
      }
      return overriddenField;
    },
    show_toSettlementAccountIdField() {
      return ['transferDeal', 'inwardDeal'].includes(this.transactionType);
    },
    toSettlementAccountIdField_captureView() {
      return 'edit';
    },

    // Currency Pair field

    currencyPairField() {
      let fieldName = 'currencyPair';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        enableGuards: this.currencyPair_enableGuards,
      });
      return overriddenField;
    },
    currencyPair_enableGuards() {
      switch (this.transactionType) {
        case 'outwardDeal':
          return this.fromSettlementAccountId ? true : false;
        case 'transferDeal':
          return false; // Note: field not editable for transferDeal - inferred from to and from accounts
        case 'inwardDeal':
          return this.inwardSwiftId === 'X' && this.toSettlementAccountId ? true : false; // Field only editable if selected SWIFT not avaialable yet and then toSettlementAccount captured
        case 'addDeal':
          return false; // Note: field is not editable for addDeal - won't work if we allow different currency pairs when adding a deal
      }
    },
    currencyPairField_captureView() {
      switch (this.transactionType) {
        case 'outwardDeal':
          return 'edit';
        case 'transferDeal':
          return 'item';
        case 'inwardDeal':
          return this.inwardSwiftId === 'X' ? 'edit' : 'item';
        case 'addDeal':
          return 'item'; // Note: this won't work if we allow different currency pairs when adding a deal
      }
    },

    // Action field

    actionField() {
      let fieldName = 'action';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        datatype: {
          option: {
            optionType: 'text',
            options: [
              {
                id: 'Buy',
                name: this.currencyPair ? `Buy ${this.buyCurrencyId} - Counter ${this.sellCurrencyId}` : 'Buy',
                enableGuards: this.currencyPair ? true : false,
              },
              {
                id: 'Sell',
                name: this.currencyPair ? `Sell ${this.sellCurrencyId} - Counter ${this.buyCurrencyId}` : 'Sell',
                enableGuards: this.currencyPair ? true : false,
              },
            ],
          },
        },
      });
      return overriddenField;
    },
    actionField_captureView() {
      switch (this.transactionType) {
        case 'inwardDeal':
          return this.inwardSwiftId === 'X' ? 'edit' : 'item';
        default:
          return 'edit';
      }
    },
    actionFieldKey() {
      return `action-${this.combinedCurrencyIds}-${this.newRecord?.currencyPair}`;
    },

    // Delivery Type field

    deliveryTypeField() {
      let fieldName = 'deliveryType';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        datatype: {
          option: {
            optionType: 'text',
            options: [
              {
                id: 'Cash',
                name: this.currencyPair ? `Value Today - ${this.todayDateLabel}` : 'Value Today',
                guards: this.allowedDeliveryTypeOptions?.includes?.('Cash'),
                enableGuards:
                  this.allowSameDay &&
                  !this.publicHolidayForToday &&
                  !this.pastSameDayCutOffTime &&
                  this.isWithinTradingHours &&
                  this.deliveryType_requiredFieldsGuards
                    ? true
                    : false,
              },
              {
                id: 'Tom',
                name: this.currencyPair ? `Value Tomorrow - ${this.tomDateLabel}` : 'Value Tomorrow',
                guards: this.allowedDeliveryTypeOptions?.includes?.('Tom'),
                enableGuards:
                  this.tomDate && this.isWithinTradingHours && this.deliveryType_requiredFieldsGuards ? true : false,
              },
              {
                id: 'Spot',
                name: this.currencyPair ? `Spot - ${this.spotDateLabel}` : 'Spot',
                guards: this.allowedDeliveryTypeOptions?.includes?.('Spot'),
                enableGuards: this.isWithinTradingHours && this.deliveryType_requiredFieldsGuards ? true : false,
              },
              {
                id: 'FWD',
                name: this.currencyPair ? `Forward - ${this.forwardDateLabel}` : 'Forward',
                guards: this.fwd_optionGuard && this.allowedDeliveryTypeOptions?.includes?.('FWD'),
                enableGuards:
                  this.clientMayBookFec &&
                  (!this.inwardSwiftId || this.inwardSwiftId === 'X') &&
                  this.isWithinTradingHours &&
                  this.allowForward &&
                  this.deliveryType_requiredFieldsGuards
                    ? true
                    : false,
              },
            ],
          },
        },
      });
      // if (this.tradeSettings.defaultFixedOption === 'rate') {
      //   console.log('1');
      //   // TODO make own setting for this - should not be inferred from rate
      //   console.log(overriddenField.datatype.option.options);
      //   overriddenField.datatype.option.options.filter((opt) => opt.id === 'Spot');
      // }
      // console.log('overriddenField', overriddenField);
      return overriddenField;
    },
    deliveryTypeKey() {
      return `deliveryType-${this.combinedCurrencyIds}-${this.newRecord?.currencyPair}-${this.newRecord?.action}`;
    },
    deliveryTypeField_captureView() {
      return this.transactionType === 'addDeal' || this.allowedDeliveryTypeOptions?.length == 1 ? 'item' : 'edit';
    },

    allowedDeliveryTypeOptions() {
      const res = [...this.tradeSettings?.allowDeliveryTypes];
      if (this.authUser?.accountLevel === 'client') {
        return res.filter(
          (option) => this.accountRecord?.accountSettings?.generalSettings?.tradeSettings?.[option] !== false
        );
      }
      return res;
    },

    // Forward Contract Type field

    forwardContractTypeField() {
      let fieldName = 'forwardContractType';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
      });
      return overriddenField;
    },
    show_forwardContractTypeField() {
      return this.deliveryType === 'FWD' ? true : false;
    },
    forwardContractTypeField_captureView() {
      return 'edit';
    },

    // Option Start Date field

    optionStartDateField() {
      let fieldName = 'optionStartDate';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        datatype: {
          datetime: { input: 'calendar', minDate: this.spotDateISOString, maxDate: this.maxOptionStartDate },
        },
      });
      return overriddenField;
    },
    show_optionStartDateField() {
      return this.newRecord?.forwardContractType === 'partiallyOptional';
    },
    optionStartDateField_captureView() {
      return 'edit';
    },

    // Value Date field

    valueDateField() {
      let fieldName = 'valueDate';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        datatype: {
          datetime: { input: 'calendar', minDate: this.minValueDate, maxDate: this.maxValueDate },
        },
        // enableGuards:
        //   ['fixed', 'optional'].includes(this.forwardContractType) ||
        //   (this.forwardContractType === 'partiallyOptional' && this.newRecord?.optionStartDate)
        //     ? true
        //     : false, // TODO: capitec components disabled prop doesn't seem to work
      });
      return overriddenField;
    },
    show_valueDateField() {
      switch (this.transactionType) {
        case 'addDeal':
          return this.deliveryType === 'FWD' ? true : false;
        default:
          return ['fixed', 'optional'].includes(this.forwardContractType) ||
            (this.forwardContractType === 'partiallyOptional' && this.newRecord?.optionStartDate)
            ? this.checkOptionStartDateIsValid?.isValid
            : false;
      }
    },
    valueDateField_captureView() {
      return this.transactionType === 'addDeal' ? 'item' : 'edit';
    },

    // Forward Points Pips field

    forwardPointsPipsField() {
      let fieldName = 'forwardPoints';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        label: 'Forward Pips (included in the rate below)',
      });
      return overriddenField;
    },
    show_forwardPointsPipsField() {
      return (
        this.deliveryType === 'FWD' && this.newRecord.valueDate && this.showMarkupAndRatesBreakdown === 'simplified'
      );
      // TODO: check deliveryType is FWD and can't see full breakdown
      // return true;
    },
    forwardPointsPipsField_captureView() {
      return 'item';
    },

    // Amount field

    amountField() {
      let fieldName = 'amount';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        label: this.action ? `${this.action} Amount` : 'Amount',
        datatype: { currency: { symbol: this.currency || '' } },
        enableGuards: this.action ? true : false,
      });
      return overriddenField;
    },
    amountField_captureView() {
      return this.transactionType === 'inwardDeal' && this.inwardSwiftId !== 'X' ? 'item' : 'edit';
    },

    // Counter Amount field

    counterAmountField() {
      let fieldName = 'counterAmount';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        label: this.counterAction ? `${this.counterAction} Amount (Counter Amount)` : 'Counter Amount',
        datatype: { currency: { symbol: this.counterCurrency || '' } },
      });
      return overriddenField;
    },

    // Markup Type field

    markupTypeField() {
      let fieldName = 'markupType';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        datatype: {
          option: {
            optionType: 'text',
            options: [
              {
                id: 'fixedSpread',
                name: 'Fixed Intermediary Markup',
                guards: this.tradeSettings?.allowMarkupType?.includes('fixedSpread'),
                enableGuards: (this.action && this.currency && this.valueDate && this.amount) || false,
              },
              {
                id: 'fixedRate',
                name: 'Fixed Client Rate',
                guards: this.fixedRate_optionGuard && this.tradeSettings?.allowMarkupType?.includes('fixedRate'),
                enableGuards: (this.action && this.currency && this.valueDate && this.amount) || false,
              },
            ],
          },
        },
      });
      return overriddenField;
      // return this.viewFields?.find((field) => field.name === 'markupType');
    },
    markupTypeKey() {
      return `markupType-${this.combinedCurrencyIds}-${this.newRecord?.currencyPair}-${this.newRecord?.action}-${this.newRecord?.deliveryType}-${this.newRecord?.forwardContractType}-${this.newRecord?.optionStartDate}-${this.newRecord?.valueDate}`;
    },
    show_markupTypeField() {
      return this.authUser.accountLevel !== 'client' && !this.currencyPair?.parityPair;
    },
    markupTypeField_captureView() {
      // return this.permissions?.freezeExchangeRate ? 'edit' : 'item';
      return this.markupTypeField.datatype.option.options.filter((opt) => opt.guards).length > 1 ? 'edit' : 'item';
    },

    // Set Markup field

    setMarkupField() {
      let fieldName = 'setMarkup';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let overriddenField = merge(startingField, {
        label: 'Override Markup for this Deal (pips)',
        // guards: this.markupType === 'fixedSpread' && this.permissions?.overrideMarkup,
        enableGuards: (this.action && this.currency && this.valueDate && this.amount) || false,
      });
      return overriddenField;
      // return this.viewFields?.find((field) => field.name === 'setMarkup');
    },
    show_setMarkupField() {
      return this.markupType === 'fixedSpread' && (this.permissions?.overrideMarkup ? true : false) && !this.currencyPair?.parityPair;
    },
    setMarkupField_captureView() {
      return 'edit';
    },

    // let fieldName = 'amount';
    // let baseField = this.fields?.find((field) => field.name === fieldName);
    // let startingField = merge({}, baseField);
    // let overriddenField = merge(startingField, {});
    // return overriddenField;

    // Client Rate field

    clientRateField() {
      let fieldName = 'clientRate';
      let baseField = this.fields?.find((field) => field.name === fieldName);
      let startingField = merge({}, baseField);
      let decimalPlaces = 4;
      if (this.currencyPair?.baseCurrency !== 'ZAR') {
        decimalPlaces = 7;
      }
      let overriddenField = merge(startingField, {
        label: this.clientRateField_captureView === 'edit' ? 'Set Client Rate for this Deal' : 'Client Rate',
        datatype: { float: { decimalPlaces: decimalPlaces } },
        // guards: this.markupType === 'fixedRate' && this.permissions?.freezeExchangeRate,
      });
      return overriddenField;
      // return this.viewFields?.find((field) => field.name === 'clientRate');
    },
    show_clientRateField() {
      return (this.markupType === 'fixedRate' && this.permissions?.freezeExchangeRate) ||
        this.showMarkupAndRatesBreakdown === 'simplified'
        ? true
        : false;
    },
    clientRateField_captureView() {
      return this.tradeSettings.fixedRateEditable && this.permissions.freezeExchangeRate ? 'edit' : 'item';
    },

    // Details of Charge field

    detailsOfChargeField() {
      let field = {
        name: 'detailsOfCharge',
        label: 'Details of Charge',
        datatype: {
          option: {
            optionType: 'text',
            options: [
              { id: 'OUR', name: `Client - ZAR ${this.markupObject.config.flatFeeRates.OUR / 100}` },
              {
                id: 'SHARE',
                name: `Shared - ZAR ${this.markupObject.config.flatFeeRates.SHARE / 100}`,
              },
              { id: 'BEN', name: `Beneficiary - ZAR ${this.markupObject.config.flatFeeRates.BEN / 100}` },
            ],
          },
        },
        displayType: {
          datatype: 'option',
          display: 'radio',
        },
        default: null,
      };
      return field;
    },
    show_detailsOfChargeField() {
      return this.tradeSettings.displayFlatFee ? true : false;
    },
    detailsOfCharge_captureView() {
      return 'edit';
    },

    // Total Cost field

    totalZarCostField() {
      let field = {
        name: 'totalZarCost',
        label: 'Total ZAR Cost',
        datatype: { currency: { symbol: 'ZAR' } },
      };
      return field;
    },
    show_totalCostField() {
      return this.tradeSettings.displayFlatFee ? true : false;
    },

    // Account Id field

    accountIdField() {
      return this.fields.find((field) => field.name === 'accountId');
    },

    // Available Open Deal Limit field

    availableOpenDealLimitField() {
      return this.accountFields?.find((field) => field.name === 'availableOpenDealLimit');
    },

    availableOpenDealLimit() {
      return this.accountRecord?.availableOpenDealLimit;
    },
    dealLocalAmount() {
      return getDealLocalAmount(this.newRecord);
    },
    dealExceedsAvailableLimit() {
      return this.dealLocalAmount > this.availableOpenDealLimit;
    },

    // Buy Amount field

    buyAmountFieldName() {
      if (this.action === 'Buy') {
        return 'amount';
      } else {
        return 'counterAmount';
      }
    },
    buyAmountField() {
      return this[`${this.buyAmountFieldName}Field`];
    },

    // Sell Amount field

    sellAmountFieldName() {
      return this.buyAmountFieldName === 'amount' ? 'counterAmount' : 'amount';
    },
    sellAmountField() {
      return this[`${this.sellAmountFieldName}Field`];
    },

    // ----- Field Guards -----

    deliveryType_requiredFieldsGuards() {
      if (this.transactionType === 'outwardDeal') {
        return (this.fromSettlementAccountId && this.currencyPair && this.action && this.amount) ||
          // (this.allowedDeliveryTypeOptions?.length == 1 && this.tradeSettings.allowDeliveryTypes[0] === 'Spot') // TODO
          this.allowedDeliveryTypeOptions?.length == 1
          ? true
          : false;
      } else if (this.transactionType === 'transferDeal') {
        return (this.fromSettlementAccountId &&
          this.toSettlementAccountId &&
          this.currencyPair &&
          this.action &&
          this.amount) ||
          this.allowedDeliveryTypeOptions?.length == 1
          ? true
          : false;
      } else if (this.transactionType === 'inwardDeal') {
        return (this.inwardSwiftId && this.toSettlementAccountId && this.currencyPair && this.action && this.amount) ||
          this.allowedDeliveryTypeOptions?.length == 1
          ? true
          : false;
      } else if (this.transactionType === 'addDeal') {
        return true;
      }
    },

    fwd_optionGuard() {
      return this.permissions?.bookFec;
    },

    showForwardFieldGroup() {
      return this.deliveryType === 'FWD';
    },

    // ----- Form Buttons -----

    viewButtons() {
      if (this.view === 'create') {
        return 'dealStep1';
      } else if (this.view === 'item') {
        return 'dealStep2';
      }
    },

    cancelButton() {
      let self = this;
      let cancelButton = {
        name: 'cancelDeal',
        label: 'Cancel',
        type: '',
        action: 'click',
        clickEvent() {
          self.$router.go(-1);
        },
      };
      return cancelButton;
    },

    leftButtons() {
      let self = this;
      let allButtons = {
        dealStep1: [],
        dealStep2: [
          {
            name: 'back',
            label: 'Back',
            type: 'clear',
            action: 'click',
            enabledGuards: !this.duplicateBookingTokenFound,
            clickEvent() {
              self.$emit('step-back');
            },
          },
        ],
      };
      return allButtons?.[this.viewButtons] || [];
    },

    rightButtons() {
      let self = this;
      let allButtons = {
        dealStep1: [
          this.cancelButton,
          {
            name: 'reviewDeal',
            label: 'Next',
            type: 'primary',
            enabledGuards: this.reviewDeal_enableButtonGuards,
            action: 'click',
            clickEvent() {
              self.update_totalZarCost();
              self.$emit('step-next');
            },
          },
        ],
        dealStep2: [
          this.cancelButton,
          {
            name: 'bookDeal',
            label: 'Book Deal',
            type: 'primary',
            enabledGuards: this.bookDeal_enableButtonGuards,
            action: 'bookDeal',
            params: this.params,
            actionParams: this.actionParams,
            clickEvent(response) {
              if (response.status === 'success') {
                if (self.transactionType !== 'addDeal') {
                  if (
                    self.permissions.updatePayment &&
                    response.response?.Payments?.length === 1 &&
                    response.response?.Payments?.[0]?.id
                  ) {
                    self.$router.replace({
                      name: 'transactionPaymentRedirectToPaymentCapture',
                      params: { transactionId: response.response.id, paymentId: response.response.Payments[0].id },
                    });
                  } else {
                    self.$router.replace({ name: 'transaction', params: { transactionId: response.response.id } });
                  }
                } else {
                  // TODO go to deal that was just booked
                  self.$router.go(-1);
                }
              }
              if (response.status === 'failed' && response.name === 'duplicateBookingToken') {
                self.duplicateBookingTokenFound = true
              }
            },
          },
        ],
      };
      return allButtons?.[this.viewButtons] || [];
    },

    reviewDeal_enableButtonGuards() {
      if (this.duplicateBookingTokenFound) return false
      let res = false;

      let basicCheck = ['clientId', 'currencyPair', 'action', 'deliveryType', 'amount', 'markupType'].every(
        (field) => this.actionParams?.[field]
      );
      let deliveryTypeIsStillValidCheck = this.deliveryTypeIsStillValidCheck;
      let hasCounterAmount = this.newRecord?.counterAmount > 0 ? true : false;
      let inwardSwiftCheck =
        this.transactionType === 'inwardDeal' && this.inwardSwiftId !== 'X'
          ? ['inwardSwiftId'].every((field) => this.actionParams?.[field])
          : true;
      let sourceAccountCheck = ['outwardDeal', 'transferDeal', 'addDeal'].includes(this.transactionType)
        ? ['sourceBankAccountID'].every((field) => this.actionParams?.[field])
        : true;
      let destinationAccountCheck = ['transferDeal', 'inwardDeal'].includes(this.transactionType)
        ? ['destinationBankAccountID'].every((field) => this.actionParams?.[field])
        : true;
      let transactionIdCheck =
        this.transactionType === 'addDeal' ? ['transactionId'].every((field) => this.actionParams?.[field]) : true;
      let fwdCheck =
        this.deliveryType === 'FWD'
          ? ['forwardContractType', 'valueDate'].every((field) => this.actionParams?.[field]) &&
            this.checkValueDateIsValid?.isValid &&
            (this.forwardContractType === 'partiallyOptional'
              ? ['optionStartDate'].every((field) => this.actionParams?.[field]) &&
                this.checkOptionStartDateIsValid?.isValid
              : true)
          : true;
      let noErrorCheck = !this.error ? true : false;
      let mandatoryDetailsOfChargeCheck = this.tradeSettings.isFlatFeeMandatory
        ? this.actionParams.detailsOfCharge
          ? true
          : false
        : true;
      const clientCif = this.accountRecord.cifNumber
      const intermediaryCifNumber =  this.account?.[this.accountRecord.intermediaryId]?.cifNumber;
      const clientIsNotIntermediaryOrZeroMarkup = !clientCif || !intermediaryCifNumber || clientCif!== intermediaryCifNumber || (this.interPips === 0 || this.interPips === 0.0)
      if (
        basicCheck &&
        deliveryTypeIsStillValidCheck &&
        hasCounterAmount &&
        inwardSwiftCheck &&
        sourceAccountCheck &&
        destinationAccountCheck &&
        transactionIdCheck &&
        fwdCheck &&
        noErrorCheck &&
        mandatoryDetailsOfChargeCheck &&
        clientIsNotIntermediaryOrZeroMarkup
      ) {
        res = true;
      }

      return res;
    },
    deliveryTypeIsStillValidCheck() {
      let res = false;
      if (this.deliveryType) {
        let deliveryTypeOption = this.deliveryTypeField?.datatype?.option?.options?.find(
          (option) => option.id === this.deliveryType
        );
        if (deliveryTypeOption?.enableGuards === true) {
          res = true;
        }
      }
      return res;
    },

    bookDeal_enableButtonGuards() {
      let res = false;
      if (this.reviewDeal_enableButtonGuards && !this.dealExceedsAvailableLimit) {
        res = true;
      }
      return res;
    },

    // ----- API call -----

    // apiAction() {
    //   return 'bookDeal';
    // },
    params() {
      return {};
    },
    actionParams() {
      let actionParams = {
        clientId: this.accountId,
        currencyPair: this.newRecord?.currencyPair,
        currency: this.currency,
        action: this.action,
        deliveryType: this.deliveryType,
        amount: +this.amount,
        markupType: this.markupType,
        externalReference: this.newRecord.externalReference,
        bookingToken: this.bookingToken
      };

      if (this.transactionId) {
        actionParams.transactionId = this.transactionId;
      }

      if (this.deliveryType === 'FWD') {
        actionParams.forwardContractType = this.forwardContractType;
        actionParams.valueDate = this.valueDateISOString;
        if (this.forwardContractType === 'partiallyOptional') {
          actionParams.optionStartDate = this.optionStartDate;
        }
      }

      if (this.markupType === 'fixedSpread' && this.setMarkup) {
        actionParams.setMarkup = this.setMarkup;
      }
      if (this.markupType === 'fixedRate') {
        actionParams.clientRate = this.clientRate;
      }

      if (this.transactionType === 'inwardDeal' && this.inwardSwiftId !== 'X') {
        actionParams.inwardSwiftId = this.inwardSwiftId;
      }
      if (
        this.transactionType === 'outwardDeal' ||
        this.transactionType === 'transferDeal' ||
        this.transactionType === 'addDeal'
      ) {
        actionParams.sourceBankAccountID = this.fromSettlementAccountId;
      }
      if (this.transactionType === 'transferDeal' || this.transactionType === 'inwardDeal') {
        actionParams.destinationBankAccountID = this.toSettlementAccountId;
      }

      if (this.newRecord.detailsOfCharge) {
        actionParams.detailsOfCharge = this.newRecord.detailsOfCharge;
      }

      return actionParams;
    },
  },
  watch: {
    ratesFeedStatus(newValue, oldValue) {
      if (newValue === 'normal') {
        this.upgradeRatesFeed_method();
      }
    },

    // action(newValue, oldValue) {
    //   if (this.transactionType === 'addDeal' && newValue) {
    //     if (this.action === this.dealRecord.action) {
    //       // NOTE: this won't work for foreign crosses
    //       this.newRecord.currency = this.dealRecord.currency;
    //     } else {
    //       this.newRecord.currency = this.dealRecord.counterCurrency;
    //     }
    //   }
    // },

    markupReturn_clientRate(newValue, oldValue) {
      if (this.markupReturn_clientRate !== undefined) {
        this.newRecord.clientRate = this.markupReturn_clientRate;
      }
    },

    clientRate(newValue, oldValue) {
      this.update_counterAmount();
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Rates feed -----

    upgradeRatesFeed_method() {
      upgradeRatesFeed(this.$store);
    },

    // ----- Initial values -----

    defineNewRecord() {
      this.newRecord = this.definition.class(
        {
          ...this.definition.default_values,
          collection_path: '/deal',
          definition_name: 'deal',
          Table: 'LfxDeal',
        },
        this.$store
      );
    },

    populateNewRecord() {
      this.newRecord.accountId = this.accountId;

      if (this.tradeSettings.defaultFixedOption === 'rate') {
        this.newRecord.markupType = 'fixedRate';
      }
      // if (this.allowedDeliveryTypeOptions?.length == 1) {
      //   this.newRecord.deliveryType = this.allowedDeliveryTypeOptions[0];
      // }

      switch (this.transactionType) {
        case 'inwardDeal':
          if (this.swiftId) {
            this.newRecord.inwardSwiftId = this.swiftId;
            this.update_currencyPair();
            this.update_newRecordFromInwardSwift();
          }
          break;

        case 'addDeal':
          this.newRecord.fromSettlementAccountId = this.dealRecord?.fromSettlementAccountId; // NOTE: This is a shortcut that won't work for foreign crosses or multiple bank accounts with the same currencyId
          this.newRecord.currencyPair = this.dealRecord?.currencyPair; // NOTE: This is a shortcut that won't for foreign crosses

          if (this.existingTransactionValueDate === this.todayDateISOString) {
            this.newRecord.deliveryType = 'Cash';
            this.update_valueDateFromDeliveryType();
          } else if (this.existingTransactionValueDate === this.tomDateISOString) {
            this.newRecord.deliveryType = 'Tom';
            this.update_valueDateFromDeliveryType();
          } else if (this.existingTransactionValueDate === this.spotDateISOString) {
            this.newRecord.deliveryType = 'Spot';
            this.update_valueDateFromDeliveryType();
          } else {
            this.newRecord.deliveryType = 'FWD';
            // TODO: display error
          }
          break;
      }
    },

    // ----- Utility -----

    getOtherCurrencyIdInCurrencyPair(currencyPair, currencyId) {
      let otherCurrencyId =
        currencyId === currencyPair.quoteCurrency
          ? currencyPair.baseCurrency
          : currencyId === currencyPair.baseCurrency
          ? currencyPair.quoteCurrency
          : undefined;
      return otherCurrencyId;
    },

    // ----- Fields -----

    // Field changes

    on_change({ field, value }) {
      this.newRecord[field] = value;
      switch (field) {
        case 'fromSettlementAccountId':
        case 'inwardSwiftId':
        case 'toSettlementAccountId':
          this.resetSubsequentFields(field);
          this.update_currencyPair();
          this.update_newRecordFromInwardSwift();
          break;

        case 'currencyPair':
          this.$emit('update-currency-pair', value);
          this.resetSubsequentFields(field);
          this.selectSingleDeliveryType();
          break;
        case 'action':
          this.resetSubsequentFields(field);
          this.update_currency();
          this.selectSingleDeliveryType();
          this.onceOffClientRateCalc();
          break;
        case 'amount':
          this.selectSingleDeliveryType();
          this.update_counterAmount();
          break;

        case 'deliveryType':
        this.$emit('update-delivery-type', value);
          this.resetSubsequentFields(field);
          this.update_valueDateFromDeliveryType();
          this.update_counterAmount();
          // console.log(this.newRecord);
          break;
        case 'forwardContractType':
          this.resetSubsequentFields(field);
          break;
        case 'optionStartDate':
          this.resetSubsequentFields(field);
          break;
        case 'valueDate':
          this.resetSubsequentFields(field);
          // console.log('markupReturn forwardPoints', this.markupReturn.forwardPoints);
          // console.log('fields', this.fields);
          this.update_forwardPointsFromValueDate();
          // console.log('newRecord forwardPoints', this.newRecord.forwardPoints);
          break;
      }
    },

    resetSubsequentFields(field) {
      switch (field) {
        case 'inwardSwiftId':
        case 'fromSettlementAccountId':
          this.newRecord.toSettlementAccountId = null;
        case 'toSettlementAccountId':
          this.newRecord.currencyPair = null;
        case 'currencyPair':
          this.newRecord.action = null;
        case 'action':
          this.newRecord.currency = null;
          this.newRecord.counterCurrency = null;
          this.newRecord.amount = null;

          if (this.transactionType !== 'addDeal') {
            this.newRecord.deliveryType = null;
          }
          this.$emit('update-markup-object', undefined);

        // TODO: reset forwardPoints something something ???

        case 'deliveryType':
          const availableDeliveryTypes = this.deliveryTypeField?.datatype?.option?.options?.filter((opt) => opt.guards); // TODO: check enableGuards as well?
          // console.log('availableDeliveryTypes', availableDeliveryTypes);
          if (!availableDeliveryTypes.some((deliveryType) => deliveryType.id === 'FWD')) {
            break;
          }
          // TODO: the above only checks if FWD are possible to avoid resetting the subsequent fields
          // it would be better to check if the old deliveryType was changed from Cash, Tom, or Spot to FWD or vice versa
          // (as long as Cash, Tom, and Spot use the same Spot rate)
          this.newRecord.forwardContractType = null;
        case 'forwardContractType':
          this.newRecord.optionStartDate = null;
        case 'optionStartDate':
          if (this.transactionType !== 'addDeal') {
            this.newRecord.valueDate = null;
          }
        case 'valueDate':
          if (this.tradeSettings.defaultFixedOption === 'rate') {
            this.newRecord.markupType = 'fixedRate';
          } else {
            this.newRecord.markupType = 'fixedSpread';
          }
        case 'markupType':
          this.newRecord.setMarkup = null;
          // this.newRecord.clientRate = null;
          this.newRecord.clientRate = this.markupReturn_clientRate;
          // this.newRecord.counterAmount = null;
          this.newRecord.counterAmount = this.update_counterAmount();
      }
    },

    update_currencyPair() {
      let options = this.currencyPairOptions || {};
      let numberOfOptions = Object.keys(options).length;
      if (numberOfOptions === 1) {
        let key = Object.keys(options)[0];
        this.newRecord.currencyPair = options?.[key]?.id;
      }
    },

    update_newRecordFromInwardSwift() {
      if (this.inwardSwiftId && this.inwardSwiftId !== 'X') {
        this.newRecord.action = 'Sell';
        this.update_currency();
        // this.newRecord.currency = this.inwardSwiftCurrencyId;
        // this.update_counterCurrency();
        this.newRecord.amount = this.inwardSwiftAmount;
      }
    },

    update_currency() {
      switch (this.transactionType) {
        case 'outwardDeal':
        case 'addDeal':
          if (this.action === 'Buy') {
            this.newRecord.currency = this.getOtherCurrencyIdInCurrencyPair(this.currencyPair, this.fromCurrencyId);
          } else if (this.action === 'Sell') {
            this.newRecord.currency = this.fromCurrencyId;
          }
          break;
        case 'transferDeal':
          if (this.action === 'Buy') {
            this.newRecord.currency = this.toCurrencyId;
          } else if (this.action === 'Sell') {
            this.newRecord.currency = this.fromCurrencyId;
          }
          break;
        case 'inwardDeal':
          if (this.inwardSwiftId === 'X') {
            if (this.action === 'Buy') {
              this.newRecord.currency = this.toCurrencyId;
            } else if (this.action === 'Sell') {
              this.newRecord.currency = this.getOtherCurrencyIdInCurrencyPair(this.currencyPair, this.toCurrencyId);
            }
          } else if (this.inwardSwiftId && this.inwardSwiftId !== 'X') {
            if (this.action === 'Buy') {
              console.warn('Action should have been Sell');
            } else if (this.action === 'Sell') {
              this.newRecord.currency = this.fromCurrencyId;
            }
          }
          break;
        default:
          console.log(this.transactionType);
      }
      this.update_counterCurrency();
    },

    update_counterCurrency() {
      this.newRecord.counterCurrency = this.counterCurrency;
    },

    selectSingleDeliveryType() {
      // console.log('1');
      // console.log('options', this.deliveryTypeField?.datatype?.option?.options);

      const availableDeliveryTypes = this.deliveryTypeField?.datatype?.option?.options?.filter((opt) => opt.guards);
      // console.log('availableDeliveryTypes', availableDeliveryTypes);
      if (availableDeliveryTypes.length == 1) {
        // console.log('2');
        this.newRecord.deliveryType = availableDeliveryTypes[0].id;
        this.update_valueDateFromDeliveryType();
      }
    },

    update_valueDateFromDeliveryType() {
      if (this.deliveryType === 'Cash') {
        this.newRecord.valueDate = this.todayDate.toISOString();
      } else if (this.deliveryType === 'Tom') {
        this.newRecord.valueDate = this.tomDate.toISOString();
      } else if (this.deliveryType === 'Spot') {
        this.newRecord.valueDate = this.spotDate.toISOString();
      } else if (this.deliveryType === 'FWD') {
        this.newRecord.valueDate = null;
      }
    },

    update_forwardPointsFromValueDate() {
      this.newRecord.forwardPoints = this.forwardPointsPips;
    },

    onceOffClientRateCalc() {
      if (this.tradeSettings.defaultFixedOption === 'rate' && this.markupType === 'fixedRate') {
        let markupObject = {
          currencyType: this.currencyType,
          action: this.action,
          valueDate: this.valueDate,
          markupType: 'fixedSpread',
          spotRate: this.spotRate,
          accountMarkups: this.accountMarkups,
          config: {
            maximumMarkupPercentage: this.systemSettings?.trading?.maximumMarkupPercentage,
            flatFeeRates: { OUR: 50000, SHARE: 25000, BEN: 0 },
            allowIntermediaryMarkupToBeNegative: this.tradeSettings.allowIntermediaryMarkupToBeNegative === true,
          },
        };

        let markupReturn = undefined;
        try {
          markupReturn = calculateMarkupsAndRates(markupObject);
          this.$emit('update-markup-object', markupReturn);
          this.error = null;
        } catch (error) {
          console.log('Markup Error: ', error);
          this.error = error;
        }
        this.newRecord.clientRate = markupReturn.clientRate;
        // return markupReturn;
      }
    },

    update_counterAmount() {
      this.newRecord.counterAmount = this.counterAmount;
    },

    update_totalZarCost() {
      // console.log('sellAmountField', this.sellAmountField);
      // TODO: check if details of charge and sellAmount are the same currency
      this.newRecord.totalZarCost =
        this.newRecord[this.sellAmountField.field] +
        this.markupObject.config.flatFeeRates[this.newRecord.detailsOfCharge];
      // console.log(this.newRecord);
    },
  },
};
</script>

<style scoped>
/* div {
  border: 1px dotted red;
} */

p {
  margin: 10px;
  /* border: 1px dotted red; */
  color: red;
  padding: 10px;
}

div.flex-container {
  /* border: 1px dotted green; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  background-color: rgb(242, 241, 241);
}
div.nested-flex-container {
  /* border: 1px dotted lightgreen; */
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

div.flex-item {
  /* border: 1px dotted blue; */
  margin: 10px;
  padding: 10px;
  flex-grow: 1;
  flex-basis: 500px;
  background-color: white;
}

td {
  padding: 5px;
}
th {
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
}

.lfx-action-bar {
  width: 100%;
  max-height: 48px;
  /* border: 1px solid red; */
  display: flex;
  background-color: white;
  padding-right: 10px;
}

/* .lfx-action-bar > capitec-button {
  margin-left: 10px;
} */

.lfx-new-deal-buttons {
  background-color: white;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
