//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxApplicationCifIndividualDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Individual Info record for LandoByte TS systems
//  Created     : 30 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition, LdbViewFunction } from './LdbInterfaces';
import type { LfxApplicationDueDilligence, LfxApplicationRequiredFieldSet } from './LfxApplicationDef';
import type {
  LfxApplicantInfoStatus,
  LfxApplicationCifType,
  LfxApplicationCifAction,
  LfxApplicationGeoCode,
  LfxApplicationBankingAlerts,
} from './LfxApplicationCifDef';
import {
  msisdnValidator,
  isValidRsaId,
  isValidRsaTaxNumber,
  standardTextWhiteList,
  isAlphaWhiteList,
} from '../sharedUtils/LdbValidations';

//----------------------------------------------------------------------------------------------------------------------

export type LfxApplicationIndividualGender = 'M' | 'F';
export type LfxApplicationIndividualPreferredPhoneNumber = 'msisdn' | 'homePhone' | 'workPhone';
export type LfxApplicationIndividualIncomeFrequency = 'W' | 'M' | 'Y';
export type LfxApplicationIndividualResidentialStatus =
  | 'rsaResident'
  | 'nonResident'
  | 'tempResident'
  | 'rsaResidentNoId';
export type LfxApplicationIndividualReasonForNoId =
  | 'ReplacementPending'
  | 'Lost'
  | 'Minor'
  | 'NeverApplied'
  | 'Pensioner'
  | 'Stolen';
export type LfxApplicationIndividualTitle =
  | 'Mr'
  | 'Mrs'
  | 'Miss'
  | 'Ms'
  | 'Dr'
  | 'Prof'
  | 'Hon'
  | 'Rev'
  | 'Adv'
  | 'Past'
  | 'Capt'
  | 'Col'
  | 'Lady'
  | 'Lt'
  | 'Lord'
  | 'Maj'
  | 'Sgt'
  | 'Sir';
export type LfxApplicationIndividualHomeOwnership = 'H' | 'T' | 'F' | 'C' | 'A' | 'O';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxApplicationCifIndividualInt {
  id?: number;
  uuid?: string;
  applicationId?: number;
  applicationCifId?: number;
  accountId?: number;
  cifType?: LfxApplicationCifType;
  cifAction?: LfxApplicationCifAction;
  status?: LfxApplicantInfoStatus;

  firstName?: string;
  middleName?: string;
  surname?: string;

  residentialStatus?: LfxApplicationIndividualResidentialStatus;
  idNumber?: string;
  idIssueDate?: Date;
  passportNumber?: string;
  passportIssueDate?: Date;
  passportExpiryDate?: Date;
  passportCountryId?: string;
  taxNumber?: string;
  reasonForNoId?: LfxApplicationIndividualReasonForNoId;

  title?: LfxApplicationIndividualTitle;
  nationality?: string;
  dateOfBirth?: Date;
  placeOfBirth?: string;
  countryOfBirth?: string;
  gender?: LfxApplicationIndividualGender;
  civilStatus?: string;
  msisdn?: string;
  homePhone?: string;
  workPhone?: string;
  preferredContact?: LfxApplicationIndividualPreferredPhoneNumber;
  faxNumber?: string;
  emailAddress?: string;
  address?: string;
  suburb?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
  geoCode?: LfxApplicationGeoCode;
  industryCode?: string;
  occupation?: string;
  occupationCode?: string;
  designation?: string;
  employerName?: string;
  employerNatureOfBusiness?: string;
  incomeFrequency?: LfxApplicationIndividualIncomeFrequency;
  income?: number;
  sourceOfIncomeOption?: string;
  sourceOfIncome?: string;
  rentalIncomeFrequency?: LfxApplicationIndividualIncomeFrequency;
  rentalIncome?: number;
  homeOwnership?: LfxApplicationIndividualHomeOwnership;

  soleProprietor?: boolean;
  soleProprietorTradingAs?: string;
  solePropTradingAddressIsAddress?: boolean;
  solePropTradingAddress?: string;
  solePropTradingSuburb?: string;
  solePropTradingCity?: string;
  solePropTradingProvince?: string;
  solePropTradingPostalCode?: string;
  solePropTradingCountry?: string;
  customsClientNumber?: string;

  isUSATaxResident?: boolean;
  isRSATaxResident?: boolean;
  taxCountry?: string;
  taxIdentificationNumber?: string;

  isUbo?: boolean;
  uboOwnershipPercentage?: number;
  uboIndividualOwnershipType?: string;

  clientDueDilligence?: LfxApplicationDueDilligence;
  digitalSignatureReferences?: string;

  internetBanking?: boolean;
  bankingAlerts?: LfxApplicationBankingAlerts;
  emigratingBlock?: boolean;
  requiresFecFacility?: boolean;

  requiredFieldSet?: LfxApplicationRequiredFieldSet;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

const showFatcaCrsTaxIdentificationNumber: LdbViewFunction = (viewFunctionObject) => {
  const applicationCifIndividual = viewFunctionObject.record as LfxApplicationCifIndividualInt;
  return (
    applicationCifIndividual.cifType === 'full' &&
    (applicationCifIndividual.isRSATaxResident === false || applicationCifIndividual.isUSATaxResident === true)
  );
};

//----------------------------------------------------------------------------------------------------------------------

export const LfxApplicationCifIndividualDef: LdbDefinition = {
  title: 'Individual Information',
  table: 'LfxApplicationCifIndividual',
  collectionPath:
    '/account/{{accountId}}/application/{{applicationId}}/application_cif/{{applicationCifId}}/application_cif_individual',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: ['applicationCifId'],
  pagingType: 'backEnd',
  groups: {
    system: { priority: 1, label: 'System' },
    details: { priority: 2, label: 'Personal' },
    identification: { priority: 3, label: 'Identification' },
    contact: { priority: 4, label: 'Contact' },
    income: { priority: 5, label: 'Income' },
    fatca: { priority: 6, label: 'FATCA and CRS' },
    ubo: { priority: 7, label: 'UBO' },
    config: { priority: 8, label: 'Configuration' },
    soleProp: { priority: 9, label: 'Sole Prop' },
    other: { priority: 10, default: true, label: '' },
    changes: { priority: 11, label: 'Changes' },
  },
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'name' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    applicationId: {
      label: 'Application',
      datatype: {
        foreignKey: {
          linkTable: 'LfxApplication',
          linkField: 'id',
          collection: 'fk_applications',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    applicationCifId: {
      label: 'Application CIF',
      datatype: {
        foreignKey: {
          collection: 'fk_application_cifs',
          linkTable: 'LfxApplicationCif',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    cifType: {
      label: 'CIF Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'full', name: 'Full' },
            { id: 'associated', name: 'Associated' },
          ],
        },
      },
      default: 'full',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    cifAction: {
      label: 'CIF Action',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'create', name: 'Create' },
            { id: 'update', name: 'Update' },
          ],
        },
      },
      default: 'create',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    status: {
      label: 'Application Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'open', name: 'Open' },
            { id: 'locked', name: 'Locked' },
          ],
        },
      },
      default: 'open',
      mandatory: true,
      views: { create: true, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },

    firstName: {
      label: 'First Name',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [isAlphaWhiteList] },
        len: { msg: 'First Name should be between 1 and 40 characters', args: [1, 40] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    middleName: {
      label: 'Middle Name',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [isAlphaWhiteList] },
        len: { msg: 'Middle Name should be between 0 and 40 characters', args: [0, 40] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    surname: {
      label: 'Surname',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
        len: { msg: 'Surname should be between 1 and 40 characters', args: [1, 40] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    residentialStatus: {
      label: 'Residential Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            {
              id: 'rsaResident',
              name: 'RSA Resident',
              guards: (applicationCifIndividual: LfxApplicationCifIndividualInt) => {
                return !!applicationCifIndividual.idNumber;
              },
            },
            {
              id: 'nonResident',
              name: 'Non-RSA Resident',
              guards: (applicationCifIndividual: LfxApplicationCifIndividualInt) => {
                return !applicationCifIndividual.idNumber;
              },
            },
            {
              id: 'tempResident',
              name: 'Temporary Resident',
              guards: (applicationCifIndividual: LfxApplicationCifIndividualInt) => {
                return !applicationCifIndividual.idNumber;
              },
            },
            {
              id: 'rsaResidentNoId',
              name: 'RSA Resident with No ID',
              guards: (applicationCifIndividual: LfxApplicationCifIndividualInt) => {
                return !!applicationCifIndividual.idNumber;
              },
            },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'identification',
    },
    idNumber: {
      label: 'RSA ID Number',
      datatype: 'text',
      default: null,
      mandatory: { residentialStatus: 'rsaResident' },
      allowNullValues: true,
      guards: { residentialStatus: { in: ['rsaResident', 'rsaResidentNoId'] } },
      enableGuards: false,
      validators: {
        isNumeric: { msg: 'RSA ID Number should be numeric' },
        len: { msg: 'RSA ID Number must be 13 characters long', args: [13, 13] },
        custom: { vFunction: isValidRsaId, msg: 'Please enter a valid RSA ID Number', validatingInterface: 'frontend' },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'identification',
    },
    idIssueDate: {
      label: 'RSA ID Issue Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
          input: 'calendar',
          maxDate: { type: 'day', offset: 0 },
        },
      },
      default: null,
      mandatory: false,
      guards: { residentialStatus: { in: ['rsaResident', 'rsaResidentNoId'] } }, //Added to move rsaResidentNoId to use ID rather than passport
      allowNullValues: true,
      validators: {
        // isDate: { msg: 'Please Enter a Valid Date', args: true },
        // isBefore: { msg: 'RSA ID Issue Date must be in the past', args: undefined },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'identification',
    },
    passportNumber: {
      label: 'Passport Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Passport Number should be between 5 and 24 characters', args: [5, 24] },
      },
      guards: { residentialStatus: { in: ['nonResident', 'tempResident'] } }, //Updated to move rsaResidentNoId to use ID rather than passport
      enableGuards: true, // TODO: make this true only if idNumber has been captured, i.e. for case of 'rsaResidentNoId' only, should not be editable if used as primary means of identification
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'identification',
    },
    passportIssueDate: {
      label: 'Passport Issue Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
          input: 'calendar',
          minDate: { type: 'year', offset: -20 },
          maxDate: { type: 'day', offset: 0 },
        },
      },
      default: null,
      mandatory: false,
      validators: {
        // isDate: { msg: 'Please Enter a Valid Date', args: true },
        // isBefore: { msg: 'Passport Issue Date must be in the past', args: undefined },
      },
      guards: { residentialStatus: { in: ['nonResident', 'tempResident'] } }, //Updated to move rsaResidentNoId to use ID rather than passport
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'identification',
    },
    passportExpiryDate: {
      label: 'Passport Expiry Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
          input: 'calendar',
          minDate: { type: 'day', offset: 0 },
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        // isDate: { msg: 'Please Enter a Valid Date', args: true },
        // isAfter: { msg: 'Passport Expiry Date must be in the future', args: undefined },
      },
      guards: { residentialStatus: { in: ['nonResident', 'tempResident'] } }, //Updated to move rsaResidentNoId to use ID rather than passport
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'identification',
    },
    passportCountryId: {
      label: 'Passport Country',
      datatype: {
        foreignKey: {
          collection: 'fk_countries',
          linkTable: 'LdbCountry',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
          guards: {status: {eq:"enabled"}}
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { residentialStatus: { in: ['nonResident', 'tempResident'] } }, //Updated to move rsaResidentNoId to use ID rather than passport
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'identification',
    },
    taxNumber: {
      label: 'RSA Tax Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' } },
      validators: {
        isNumeric: { msg: 'Tax Number should be numeric' },
        len: { msg: 'Tax Number must be 10 characters long', args: [10, 10] },
        custom: {
          vFunction: isValidRsaTaxNumber,
          msg: 'Please enter a valid Tax Number',
          validatingInterface: 'frontend',
        },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'identification',
    },
    reasonForNoId: {
      label: 'Reason for No ID',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'ReplacementPending', name: 'Replacement Pending' },
            { id: 'Lost', name: 'Lost' },
            { id: 'Minor', name: 'Minor' },
            { id: 'NeverApplied', name: 'Never Applied' },
            { id: 'Pensioner', name: 'Pensioner' },
            { id: 'Stolen', name: 'Stolen' },
          ],
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { residentialStatus: { in: ['rsaResidentNoId'] } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'identification',
    },
    title: {
      label: 'Title',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'Mr', name: 'Mr' },
            { id: 'Mrs', name: 'Mrs' },
            { id: 'Miss', name: 'Miss' },
            { id: 'Ms', name: 'Ms' },
            { id: 'Dr', name: 'Dr' },
            { id: 'Prof', name: 'Prof' },
            { id: 'Hon', name: 'Hon' },
            { id: 'Rev', name: 'Rev' },
            { id: 'Adv', name: 'Adv' },
            { id: 'Past', name: 'Past' },
            { id: 'Capt', name: 'Capt' },
            { id: 'Col', name: 'Col' },
            { id: 'Lady', name: 'Lady' },
            { id: 'Lt', name: 'Lt' },
            { id: 'Lord', name: 'Lord' },
            { id: 'Maj', name: 'Maj' },
            { id: 'Sgt', name: 'Sgt' },
            { id: 'Sir', name: 'Sir' },
          ],
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    nationality: {
      label: 'Nationality',
      datatype: {
        foreignKey: {
          collection: 'fk_countries',
          linkTable: 'LdbCountry',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
          guards: {status: {eq:"enabled"}}
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    dateOfBirth: {
      label: 'Date of Birth',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
          input: 'calendar',
          maxDate: { type: 'day', offset: 0 },
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        // isDate:{msg:"Please Enter a Valid Date",args:true},
        // isBefore: { msg: 'Date of Birth must be in the past', args: undefined },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    placeOfBirth: {
      label: 'Place of Birth',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      // validators: {
      //   is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
      //   len: { msg: 'Place of Birth should be between 5 and 50 characters', args: [5, 50] },
      // },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    countryOfBirth: {
      label: 'Country of Birth',
      datatype: {
        foreignKey: {
          collection: 'fk_countries',
          linkTable: 'LdbCountry',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
          guards: {status: {eq:"enabled"}}
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    gender: {
      label: 'Gender',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'M', name: 'Male' },
            { id: 'F', name: 'Female' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    civilStatus: {
      label: 'Civil Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'single', name: 'Single' },
            { id: 'married', name: 'Married' },
            { id: 'divorced', name: 'Divorced' },
            { id: 'widowed', name: 'Widowed' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'details',
    },
    msisdn: {
      label: 'Mobile Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: { msisdnValidator },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    homePhone: {
      label: 'Home Phone Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: { msisdnValidator },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    workPhone: {
      label: 'Work Phone Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: { msisdnValidator },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    preferredContact: {
      label: 'Preferred Contact',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'msisdn', name: 'Mobile Number', guards: true },
            {
              id: 'homePhone',
              name: 'Home Phone Number',
              guards: (applicationCifIndividual: LfxApplicationCifIndividualInt) => {
                return !!applicationCifIndividual.homePhone;
              },
            },
            {
              id: 'workPhone',
              name: 'Work Phone Number',
              guards: (applicationCifIndividual: LfxApplicationCifIndividualInt) => {
                return !!applicationCifIndividual.workPhone;
              },
            },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    faxNumber: {
      label: 'Fax Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        custom: {
          vFunction: msisdnValidator,
          msg: 'Please enter a valid phone number',
          validatingInterface: 'frontend',
        },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    emailAddress: {
      label: 'Email Address',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: { isEmail: { msg: 'Email is invalid' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    address: {
      label: 'Street Address',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Address should be between 5 and 40 characters', args: [5, 40] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    suburb: {
      label: 'Suburb',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Suburb should be between 2 and 40 characters', args: [2, 40] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    city: {
      label: 'City',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'City should be between 2 and 40 characters', args: [2, 40] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    province: {
      label: 'Province',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Province should be between 2 and 40 characters', args: [2, 40] },
      },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    postalCode: {
      label: 'Postal Code',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Postal Code should be between 2 and 9 characters', args: [2, 9] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    country: {
      label: 'Country',
      datatype: {
        foreignKey: {
          collection: 'fk_countries',
          linkTable: 'LdbCountry',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
          guards: {status: {eq:"enabled"}}
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'contact',
    },
    geoCode: {
      label: 'Geo Code',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'easternCape', name: 'Eastern Cape' },
            { id: 'freeState', name: 'Free State' },
            { id: 'gauteng', name: 'Gauteng' },
            { id: 'kzn', name: 'KZN' },
            { id: 'limpopo', name: 'Limpopo' },
            { id: 'mpumalanga', name: 'Mpumalanga' },
            { id: 'northWest', name: 'North West' },
            { id: 'nothernCape', name: 'Northern Cape' },
            { id: 'westernCape', name: 'Western Cape' },
            { id: 'national', name: 'National' },
            { id: 'offShore', name: 'Off Shore' },
          ],
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    industryCode: {
      label: 'Industry Code',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      button: {
        action: 'click',
        icon: 'system/search-action',
        actionName: 'actIndustryCode',
        views: { create: true, edit: true, item: false, list: false, delete: false, csv: false },
      },
      validators: {
        isNumeric: { msg: 'Industry Code should be numeric' },
        len: { msg: 'Industry Code must be 4 digits long', args: [4, 4] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    occupation: {
      label: 'Occupation',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      // validators: {
      //   is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
      //   len: { msg: 'Occupation should be between 2 and 50 characters', args: [2, 50] },
      // },
      guards: { cifType: { eq: 'full' } },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'income',
    },
    occupationCode: {
      label: 'Occupation Code',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      button: {
        action: 'click',
        icon: 'system/search-action',
        actionName: 'actOccupationCode',
        views: { create: true, edit: true, item: false, list: false, delete: false, csv: false },
      },
      validators: {
        isNumeric: { msg: 'Occupation Code should be numeric' },
        len: { msg: 'Occupation Code must be 3 digits long', args: [3, 3] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    designation: {
      label: 'Designation',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Designation should be between 2 and 50 characters', args: [2, 50] },
      },
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    employerName: {
      label: 'Employer Name',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Employer Name should be between 2 and 100 characters', args: [2, 100] },
      },
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    employerNatureOfBusiness: {
      label: 'Employer Nature of Business',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Employer Nature of Business should be between 2 and 100 characters', args: [2, 100] },
      },
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    incomeFrequency: {
      label: 'Income Frequency',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'W', name: 'Weekly' },
            { id: 'M', name: 'Monthly' },
            { id: 'Y', name: 'Annual' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: false,
      guards: { cifType: { eq: 'full' } },
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'income',
    },
    income: {
      label: 'Annual Income',
      datatype: { currency: { symbol: 'R' } },
      default: 0,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' } },
      validators: {
        isNumeric: { msg: 'Income should be numeric' },
        min: { msg: 'Income must be greater than 0', args: [0] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    sourceOfIncomeOption: {
      label: 'Source Of Funds',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: '01', name: 'Salary' },
            { id: '02', name: 'Pension' },
            { id: '03', name: 'Commission' },
            { id: '04', name: 'Investment Income' },
            { id: '06', name: 'Business profit' },
            { id: '07', name: 'Social Grant' },
            { id: '09', name: 'Allowance/Maintenance' },
            { id: '10', name: 'Inheritance' },
            { id: '11', name: 'Prize Winnings' },
            { id: '12', name: 'Donations and Gifts' },
            { id: '13', name: 'Rental income' },
            { id: '05', name: 'Other' },
          ],
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    sourceOfIncome: {
      label: 'Source Of Funds',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Source Of Funds should be between 2 and 30 characters', args: [2, 30] },
      },
      guards: { cifType: { eq: 'full' } },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    rentalIncomeFrequency: {
      label: 'Rental Income Frequency',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'W', name: 'Weekly' },
            { id: 'M', name: 'Monthly' },
            { id: 'Y', name: 'Annual' },
          ],
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' } },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'income',
    },
    rentalIncome: {
      label: 'Annual Rental Income',
      datatype: { currency: { symbol: 'R' } },
      default: 0,
      mandatory: false,
      allowNullValues: true,
      validators: {
        isNumeric: { msg: 'Rental Income should be numeric' },
        min: { msg: 'Rental Income must be greater than 0', args: [0] },
      },
      guards: { cifType: { eq: 'full' }, rentalIncomeFrequency: { in: ['W', 'M', 'Y'] } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    homeOwnership: {
      label: 'Home Ownership',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'H', name: 'Home Owner' },
            { id: 'T', name: 'Tenant' },
            { id: 'F', name: 'Living in family home' },
            { id: 'C', name: 'Co-Resident' },
            { id: 'A', name: 'Co-Habiting' },
            { id: 'O', name: 'Other' },
          ],
        },
      },
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'income',
    },

    soleProprietor: {
      label: 'Sole Proprietor?',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: false,
      mandatory: true,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    soleProprietorTradingAs: {
      label: 'Trading As',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      guards: { soleProprietor: { eq: true } },
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [isAlphaWhiteList] },
        len: { msg: 'Name should be between 5 and 20 characters', args: [5, 20] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'soleProp',
    },
    solePropTradingAddressIsAddress: {
      label: "Trading Address is Client's Address?",
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: false,
      mandatory: true,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' }, soleProprietor: { eq: true } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'soleProp',
    },

    solePropTradingAddress: {
      label: 'Trading Street Address',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Address should be between 5 and 40 characters', args: [5, 40] },
      },
      guards: { cifType: { eq: 'full' }, soleProprietor: { eq: true }, solePropTradingAddressIsAddress: { eq: false } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'soleProp',
    },
    solePropTradingSuburb: {
      label: 'Trading Suburb',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Suburb should be between 2 and 40 characters', args: [2, 40] },
      },
      guards: { cifType: { eq: 'full' }, soleProprietor: { eq: true }, solePropTradingAddressIsAddress: { eq: false } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'soleProp',
    },
    solePropTradingCity: {
      label: 'Trading City',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'City should be between 2 and 40 characters', args: [2, 40] },
      },
      guards: { cifType: { eq: 'full' }, soleProprietor: { eq: true }, solePropTradingAddressIsAddress: { eq: false } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'soleProp',
    },
    solePropTradingProvince: {
      label: 'Trading Province',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Province should be between 2 and 40 characters', args: [2, 40] },
      },
      guards: { cifType: { eq: 'full' }, soleProprietor: { eq: true }, solePropTradingAddressIsAddress: { eq: false } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'soleProp',
    },
    solePropTradingPostalCode: {
      label: 'Trading Postal Code',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Postal Code should be between 2 and 9 characters', args: [2, 9] },
      },
      guards: { cifType: { eq: 'full' }, soleProprietor: { eq: true }, solePropTradingAddressIsAddress: { eq: false } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'soleProp',
    },
    solePropTradingCountry: {
      label: 'Trading Country',
      datatype: {
        foreignKey: {
          collection: 'fk_countries',
          linkTable: 'LdbCountry',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
          guards: {status: {eq:"enabled"}}
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' }, soleProprietor: { eq: true }, solePropTradingAddressIsAddress: { eq: false } },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'soleProp',
    },

    customsClientNumber: {
      label: 'Customs Client Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      validators: {
        isNumeric: { msg: 'CCN should be numeric' },
        len: { msg: 'CCN must be longer than 4 digits', args: [4, undefined] },
      },
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    isRSATaxResident: {
      label: 'RSA Tax Resident?',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: false,
      mandatory: false,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'fatca',
    },
    isUSATaxResident: {
      label: 'USA Tax Resident?',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: false,
      mandatory: false,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'fatca',
    },
    taxCountry: {
      label: 'Tax Country',
      datatype: {
        foreignKey: {
          collection: 'fk_countries',
          linkTable: 'LdbCountry',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
          guards: {status: {eq:"enabled"}}
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' }, isUSATaxResident: { eq: false }, isRSATaxResident: { eq: false } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'fatca',
    },
    taxIdentificationNumber: {
      label: 'Tax Identification Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Tax Identification Number should be between 5 and 250 characters', args: [5, 250] },
      },
      guards: { cifType: { eq: 'full' } },
      views: {
        create: true,
        edit: showFatcaCrsTaxIdentificationNumber,
        item: showFatcaCrsTaxIdentificationNumber,
        list: false,
        delete: false,
        csv: false,
      },
      group: 'fatca',
    },
    isUbo: {
      label: 'Ultimate Beneficial Owner? (>25%)',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: false,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'associated' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'ubo',
    },
    uboOwnershipPercentage: {
      label: 'Ownershipt Percentage',
      datatype: 'percentage',
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: {
        cifType: { eq: 'associated' },
        isUbo: { eq: true },
        uboIndividualOwnershipType: { notEq: 'Most_Senior_Executive' },
      },
      validators: {
        isNumeric: { msg: 'Ownershipt Percentage should be numeric' },
        min: { msg: 'Ownershipt Percentage must be more than 25', args: [0.25] },
        max: { msg: 'Ownershipt Percentage must be less than 100', args: [1] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'ubo',
    },
    uboIndividualOwnershipType: {
      label: 'Ownership Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            {
              id: 'Individual_25',
              name: 'Individual who directly or indirectly holds 25% or more ownership interest in the company',
            },
            {
              id: 'Individual_different_shares_classes',
              name: 'Individual who controls the company through different classes of shares or through a shareholder agreement ',
            },
            {
              id: 'Beneficiary_Or_Trustee_25',
              name: 'Individual who is a beneficiary or trustee of the trust that holds 25% or more ownership interest in the company',
            },
            {
              id: 'Most_Senior_Executive',
              name: 'Individual who holds the most senior executive management position in the company',
            },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      guards: { cifType: { eq: 'associated' }, isUbo: { eq: true } },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'ubo',
    },

    clientDueDilligence: {
      label: 'Due Dilligence',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'Face-to-Face', name: 'Face To Face' },
            { id: 'Non-Face-to-Face', name: 'Non Face To Face' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      guards: { cifType: { eq: 'full' } },
      default: 'Face-to-Face',
      mandatory: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    digitalSignatureReferences: {
      label: 'DocuSign Envelopes',
      datatype: 'text',
      default: null,
      mandatory: false,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'DocuSign Envelopes should be between 5 and 250 characters', args: [5, 250] },
      },
      guards: { cifType: { eq: 'full' } },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    internetBanking: {
      label: 'Internet Banking?',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: false,
      mandatory: false,
      guards: { cifType: { eq: 'full' } },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    bankingAlerts: {
      label: 'Banking Alerts',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'E', name: 'Email' },
            { id: 'S', name: 'SMS' },
            { id: 'B', name: 'SMS and Email' },
          ],
        },
      },
      default: 'B',
      mandatory: true,
      guards: { cifType: { eq: 'full' } },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    emigratingBlock: {
      label: 'Emigrating Block?',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: false,
      mandatory: false,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    requiresFecFacility: {
      label: 'Client would like to use FEC Products?',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: false,
      mandatory: false,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },

    requiredFieldSet: {
      label: 'Required Field Set',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'full', name: 'Full', tagType: 'info' },
            { id: 'minimum', name: 'Minimum', tagType: 'info' },
          ],
        },
      },
      default: 'full',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};

//----------------------------------------------------------------------------------------------------------------------

export const requiredFields = (
  cifIndividualApplication: LfxApplicationCifIndividualInt,
  documentUpdateSetting: 'signiFlow' | 'local'
) => {
  let requiredFields;
  if (cifIndividualApplication.requiredFieldSet !== 'minimum') {
    requiredFields = [
      'firstName',
      'surname',
      'residentialStatus',
      'title',
      'nationality',
      'dateOfBirth',
      'countryOfBirth',
      'gender',
      'msisdn',
      'emailAddress',
      'address',
      'suburb',
      'city',
      'postalCode',
      'country',
      'geoCode',
      'industryCode',
    ];
    if (cifIndividualApplication.cifType === 'full') {
      requiredFields.push('preferredContact');
      requiredFields.push('occupationCode');
      requiredFields.push('designation');
      requiredFields.push('income');
      requiredFields.push('sourceOfIncomeOption');
      requiredFields.push('soleProprietor');
      requiredFields.push('clientDueDilligence');
      // requiredFields.push('internetBanking'); // No longer required
      requiredFields.push('bankingAlerts');
    } else {
      if (cifIndividualApplication.isUbo) {
        requiredFields.push('uboIndividualOwnershipType');
        if (cifIndividualApplication.uboIndividualOwnershipType !== 'Most_Senior_Executive') {
          requiredFields.push('uboOwnershipPercentage');
        }
      }
    }
    if (cifIndividualApplication.soleProprietor) {
      requiredFields.push('soleProprietorTradingAs');
      requiredFields.push('solePropTradingAddressIsAddress');
      if (cifIndividualApplication.solePropTradingAddressIsAddress === false) {
        requiredFields.push('solePropTradingAddress');
        requiredFields.push('solePropTradingSuburb');
        requiredFields.push('solePropTradingCity');
        requiredFields.push('solePropTradingPostalCode');
        requiredFields.push('solePropTradingCountry');
      }
    }
    if (cifIndividualApplication.isUSATaxResident === false && cifIndividualApplication.isRSATaxResident === false) {
      requiredFields.push('taxCountry');
    }
  } else {
    requiredFields = [
      'firstName',
      'surname',
      'residentialStatus',
      'msisdn',
      'emailAddress',
      'country',
      'clientDueDilligence',
      'industryCode',
    ];

    // Added because of failed submits
    requiredFields.push('geoCode');
    requiredFields.push('nationality');

    if (documentUpdateSetting !== 'signiFlow') {
      // From WSDL
      requiredFields.push('dateOfBirth');
      requiredFields.push('gender');
      // Added because of failed submits
      requiredFields.push('address');
      requiredFields.push('suburb');
      requiredFields.push('city');
      requiredFields.push('postalCode');
      requiredFields.push('title');
      if (cifIndividualApplication.cifType === 'full') {
        requiredFields.push('income');
      }
    }
  }
  switch (cifIndividualApplication.residentialStatus) {
    case 'rsaResident':
      requiredFields.push('idNumber');
      if (documentUpdateSetting !== 'signiFlow') {
        requiredFields.push('idIssueDate');
      }
      break;
    case 'nonResident':
      requiredFields.push('passportNumber');
      requiredFields.push('passportCountryId');
      if (documentUpdateSetting !== 'signiFlow') {
        requiredFields.push('passportIssueDate');
        requiredFields.push('passportExpiryDate');
      }
      break;
    case 'tempResident':
      requiredFields.push('passportNumber');
      if (documentUpdateSetting !== 'signiFlow') {
        requiredFields.push('passportIssueDate');
        requiredFields.push('passportExpiryDate');
        requiredFields.push('passportCountryId');
      }
      break;
    case 'rsaResidentNoId':
      requiredFields.push('idNumber');
      if (documentUpdateSetting !== 'signiFlow') {
        // requiredFields.push('passportNumber'); //Updated to move rsaResidentNoId to use ID rather than passport
        // requiredFields.push('passportIssueDate'); //Updated to move rsaResidentNoId to use ID rather than passport
        // requiredFields.push('passportExpiryDate'); //Updated to move rsaResidentNoId to use ID rather than passport
        // requiredFields.push('passportCountryId'); //Updated to move rsaResidentNoId to use ID rather than passport
        requiredFields.push('idIssueDate'); //Added to move rsaResidentNoId to use ID rather than passport
        requiredFields.push('reasonForNoId');
      }
      break;
  }
  return requiredFields;
};

//----------------------------------------------------------------------------------------------------------------------
