<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    @tab-select="onTabSelect"
    :show_actions="showActions"
    :form_layout="formLayout"
    :show_label="showLabel"
    :label="contentLabel"
    :show_sidebar="false"
  >
    <template #actions>
      <LDBButton
        :xstyle="` max-height:32px; font-size:14px; --theme-button-font-size:14px ; ${
          button.style === undefined ? '' : button.style
        }`"
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :type="button.type"
        @click="button.clickEvent"
      ></LDBButton
    ></template>

    <template #content>
      <router-view></router-view>
    </template>
  </LFXContent>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';

export default {
  name: 'LfxReports',
  components: { LFXContent },
  props: {},
  data() {
    return {};
  },
  created() {
    if (this.$route.name === 'reports') {
      // this.$router.replace({ name: 'reportsDealSummary' });
      this.selectFirstTab();
    }
  },
  mounted() {},
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'reports') {
      next(false);
      this.selectFirstTab();
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(['myAccount']),
    ...mapState(['authUser','account']),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        label: 'Reports',
      };
      return titleBar;
    },

    // ----- Tabs -----
    myAccountIsLocked() {
      // return (this.account?.[this.authUser?.accountId]?.userReviewStatus === 'nonCompliant' && this.myAccount.accountLevel !== 'bank') ? true : false;  //2024-09-19 Bank clients should be disabled if no review done
      return (this.account?.[this.authUser?.accountId]?.userReviewStatus === 'nonCompliant') ? true : false;  
    },

    showTabs() {
      return true;
    },
    tabs() {
      let tabs = [
        {
          label: 'Deal Summary',
          routeName: 'reportsDealSummary',
          tabName: 'reportsDealSummary',
          guards: !this.myAccountIsLocked && this.authUser.accountLevel !== 'client',
        },
        { label: 'Rolled Deals', tabName: 'rolledDealReport', routeName: 'rolledDealReport', guards: !this.myAccountIsLocked },
        {
          label: 'Invoice Report',
          tabName: 'reportsInvoiceReport',
          routeName: 'reportsInvoiceReport',
          guards: !this.myAccountIsLocked && this.authUser.accountLevel !== 'client',
        },
        {
          label: 'Invoice Number Report',
          tabName: 'reportsInvoiceNumberReport',
          routeName: 'reportsInvoiceNumberReport',
          guards: false,
        },
        {
          label: 'Forward Reval Report',
          tabName: 'reportsForwardRevalReport',
          routeName: 'reportsForwardRevalReport',
          guards: !this.myAccountIsLocked,
        },
        {
          label: 'Payment Processing',
          tabName: 'reportsPaymentProcessing',
          routeName: 'reportsPaymentProcessing',
          guards: !this.myAccountIsLocked,
        },
        {
          label: 'Advanced Payments',
          tabName: 'reportsAdvancedPayments',
          routeName: 'reportsAdvancedPayments',
          guards: !this.myAccountIsLocked,
        },
        {
          label: 'Inward SWIFTs',
          tabName: 'reportsInwardSwifts',
          routeName: 'reportsInwardSwifts',
          guards: !this.myAccountIsLocked,
        },
        { label: 'BOP Portfolios', tabName: 'reportsBOPPortfolios', routeName: 'reportsBOPPortfolios', guards: !this.myAccountIsLocked },
        { label: 'User Access', tabName: 'reportsUserAccess', routeName: 'reportsUserAccess', guards: true },
        { label: 'User Review', tabName: 'reportsUserReview', routeName: 'reportsUserReview', guards: !this.myAccountIsLocked && this.authUser.accountLevel === 'bank' },
        { label: 'Email Search', tabName: 'generalEmailSearch', routeName: 'generalEmailSearch', guards: this.authUser.accountLevel === 'bank' },
        { label: 'Intermediary Markup', tabName: 'intermediaryMarkupReport', routeName: 'intermediaryMarkupReport', guards: !this.myAccountIsLocked && this.authUser.accountLevel === 'bank' },
        {
          label: 'User Activity - Deals',
          tabName: 'reportsUserActivityDeals',
          routeName: 'reportsUserActivityDeals',
          guards: false,
        },
        { label: 'Other', tabName: 'reportsOther', routeName: 'reportsOther', guards: false },
      ];
      return tabs.filter((tab) => tab.guards);
    },
    selectedTabName() {
      const tabRouteDepth = this.componentRouteDepth + 1;
      return this.$route.fullPath.split('/')[tabRouteDepth];
    },
    selectedTabIndex() {
      let tabNames = this.tabs.map((t) => t.name);
      return tabNames.indexOf(this.selectedTabName);
    },
    firstAvailableTab() {
      return this.tabs[0];
    },
    areTabsAvailable() {
      if (this.tabs.length > 0) {
        return true;
      } else return false;
    },

    // ----- Action Bar -----

    showActions() {
      return false;
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.layout === 'list' ? false : true;
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.label ? this.$route.meta.label : 'TODO';
    },

    // ----- Guards -----

    canViewInfo() {
      // TODO
      return true;
    },
    canViewPermissions() {
      // TODO
      return true;
    },
    canViewSettings() {
      // TODO
      return true;
    },
    canViewPreferences() {
      // TODO
      return true;
    },
    canViewLogs() {
      // TODO
      return true;
    },

    // ----- Record -----

    // recordName() {
    //   // return this.$route.params.record;
    //   return 'user';
    // },
    // recordId() {
    //   return this.$route.params.userId;
    // },
    // record() {
    //   return this.$store.state?.[this.recordName]?.[this.recordId];
    // },
    // definition() {
    //   return getDefinition(this.recordName);
    // },

    // ----- Route -----

    componentRoutePath() {
      return 'reports';
    },

    componentRouteDepth() {
      let path = this.$route.fullPath;
      let pathSections = path.split('/');
      const componentRouteDepth = pathSections.indexOf(this.componentRoutePath);
      return componentRouteDepth;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    // ----- Tabs -----

    selectFirstTab() {
      if (this.tabs.length) {
        this.$router.replace({ name: this.tabs[0].routeName });
      }
    },

    // tabName(index) {
    //   return this.tabs[index].name;
    // },
    // onTitleBarButtonClick(button) {
    //   this.$router.push({ name: button.routeName });
    // },
    // onTabClick(evt) {
    //   let tabIndex = evt.detail.selectedIndex;
    //   let tabName = this.tabName(tabIndex);
    //   let path = this.$route.fullPath;
    //   let pathSections = path.split('/');
    //   const tabRouteDepth = this.componentRouteDepth + 1;
    //   pathSections[tabRouteDepth] = tabName;
    //   let newPathSections = [];
    //   for (let i = 0; i <= tabRouteDepth; i++) {
    //     newPathSections.push(pathSections[i]);
    //   }
    //   let newPath = newPathSections.join('/');
    //   this.$router.push(newPath);
    //   // added logic for handling sub-tabs - avr 2021-10-08
    // },
    onTabSelect(tab) {
      this.$router.push({ name: tab.routeName });
    },
  },
};
</script>

<style scoped>
/* capitec-title-bar {
  background-color: rgb(127, 197, 255);
} */
</style>
