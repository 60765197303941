<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div v-if="mounted">
        <p>The balance of the deal will be bought out and a new Buy Out deal created.</p>
        <p>Note: no markup can be captured on the Buy Out Deal. No cash flow will be calculated in CFS.</p>
        <LDBField
          v-for="(field, fieldname) in dealFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="definition"
          :record="record"
          @change="on_change"
        />
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          :view="field.view"
          :fieldname="field.name"
          :definition="definition"
          :record="submitObject"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';
import { calculateCounterAmount, getCurrencyType } from '@/sharedUtils/LfxTradeCalc';

export default {
  name: 'LfxDealPartialBuyOut',
  components: { LDBField },
  emits: ['on-api-success'],
  props: {},
  data() {
    return {
      submitObject: {
        dealIsNotSettled: false,
        buyOutDealAmount: undefined,
        buyOutDealCounterAmount: undefined,
        buyOutDealRate: undefined,
        buyOutDealNumber: undefined,
        supersededByDealNumber: undefined,
        rejectionReason: undefined
      },
      mounted: true,
    };
  },
  created() {},
  mounted() {
    if (this.dealNotSettled) {
      this.submitObject.rejectionReason = 'Deal not settled before cutoff'
      this.submitObject.dealIsNotSettled = true
    }
    if (this.record?.currency === this.tfRecord?.paymentCurrency) {
      this.submitObject.buyOutDealAmount = this.record.unCommittedPaymentAmount
    } else {
      this.submitObject.buyOutDealCounterAmount = this.record.unCommittedPaymentAmount
    }
  },
  beforeUnmount() {
    this.record.revert();
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['deal']),
    header() {
      return `Partial Buy Out Deal`;
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'cancel',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },

        {
          name: 'partialBuyOut',
          label: 'Partial Buy Out Deal',
          type: 'primary',
          action: 'partialBuyOut',
          params: {
            transactionId: this.record.transactionFileId,
            dealId: this.record.id,
          },
          actionParams: this.submitObject,
          guards: true,
          clickEvent() {
            self.$router.replace({ name: 'transactionDealDetailsView' });
          },
        },
      ];
      return allButtons !== undefined ? allButtons : [];
    },

    // ----- Definition -----

    definition() {
      return getDefinition('deal');
    },

    // ----- Record -----
    recordName() {
      return 'deal';
    },
    recordId() {
      return +this.$route.params.dealId;
    },
    record() {
      let rec = this.$store.state?.deal[this.recordId];
      return rec;
    },
    tfRecord() {
      return this.$store.state?.transaction?.[this.record?.transactionFileId];
    },

    // ----- Fields -----

    dealNotSettled() {
      return this.record.status === 'dealNotSettled'
    
    },
    dealFields() {
      const fields = [
        {
          label: "Deal Rate",
          datatype: { float: { decimalPlaces: 7 } },
          name: "clientRate",
          mandatory: true,
          guards: this.dealNotSettled,
          view: "item"
        },
        {
          label: "Amount",
          datatype: { currency: { symbol: this.record?.currency } },
          name: "amount",
          mandatory: true,
          guards: this.dealNotSettled,
          view: "item"
        },
        {
          label: "Counter Amount",
          datatype: { currency: { symbol: this.record?.counterCurrency } },
          name: "counterAmount",
          mandatory: true,
          guards: this.dealNotSettled,
          view: "item"
        },
        {
          label: "Payment Amount",
          datatype: { currency: { symbol:  this.tfRecord?.paymentCurrency } },
          name: "paymentAmount",
          mandatory: true,
          guards: this.dealNotSettled,
          view: "item"
        },
        {
          label: "Committed Amount",
          datatype: { currency: { symbol:  this.tfRecord?.paymentCurrency } },
          name: "committedPaymentAmount",
          mandatory: true,
          guards: this.dealNotSettled,
          view: "item"
        },
        {
          label: "Uncommitted Amount",
          datatype: { currency: { symbol:  this.tfRecord?.paymentCurrency } },
          name: "unCommittedPaymentAmount",
          mandatory: true,
          guards: this.dealNotSettled,
          view: "item"
        },

      ]
      const res = [];
      for (const field of fields) {
        if (field.guards) {
          res.push(field)
        }
      }
      return res;

    },
    viewFields() {
      const fields = [
        {
          label: "Buy Out Deal Rate",
          datatype: { float: { decimalPlaces: 7 } },
          name: "buyOutDealRate",
          mandatory: true,
          guards: true,
          view: "edit"
        },
        {
          label: "Buy Out Deal Amount",
          datatype: { currency: { symbol: this.record?.currency } },
          name: "buyOutDealAmount",
          mandatory: true,
          guards: true,
          view: "item"
        },
        {
          label: "Buy Out Deal Counter Amount",
          datatype: { currency: { symbol: this.record?.counterCurrency } },
          name: "buyOutDealCounterAmount",
          mandatory: true,
          guards: true,
          view: "item"
        },
        {
          label: "Buy Out Deal Number",
          datatype: "text",
          name: "buyOutDealNumber",
          mandatory: true,
          guards: true,
          view: "edit"
        },
        {
          label: "Superseding Deal Number",
          datatype: "text",
          name: "supersededByDealNumber",
          mandatory: false,
          guards: this.dealNotSettled,
          view: "edit"
        },
        {
          label: "Rejection Reason",
          datatype: "text",
          name: "rejectionReason",
          mandatory: true,
          guards: true,
          view: this.dealNotSettled ? "item" : 'edit'
        }
      ]
      const res = [];
      for (const field of fields) {
        if (field.guards) {
          res.push(field)
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),

    on_change({ field, value }) {
      this.submitObject[field] = value;
      if (field === 'buyOutDealRate') {
        this.calculateBuyOutDealSecondAmount()
      }
    },
    calculateBuyOutDealSecondAmount() {
      let currencyPair = this.record?.currencyPairRecord;
      const currencyType = currencyPair ? getCurrencyType(this.tfRecord?.paymentCurrency, currencyPair) : null;
      if (this.record?.currency === this.tfRecord?.paymentCurrency) {
        this.submitObject.buyOutDealCounterAmount = calculateCounterAmount(
          this.submitObject.buyOutDealAmount,
          this.submitObject.buyOutDealRate,
          currencyType,
          2
        )
      } else {
        this.submitObject.buyOutDealAmount = calculateCounterAmount(
          this.submitObject.buyOutDealCounterAmount,
          this.submitObject.buyOutDealRate,
          currencyType,
          2
        )
      }
    },

    // ----- API calls -----
    async onApiSuccess(_responseObject) {},
  },
};
</script>

<style></style>
