//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LdbDefinitionUtils.ts
//  Author      : Bevan Timm
//  Description : This module contains util functions relating to a LdbDefinition
//  Created     : 22 September 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type {
  LdbDefinition,
  LdbViewFunction,
  LdbViewNames,
  LdbSearchRangeFunction,
  LdbOptionType,
  LdbField,
} from '../definitions/LdbInterfaces';

//----------------------------------------------------------------------------------------------------------------------

export const fieldNames = (definition: LdbDefinition): string[] => {
  return viewFieldNames(undefined, definition);
};
export const viewFieldNames = (view: LdbViewNames | undefined, definition: LdbDefinition): string[] => {
  const fields = Object.keys(definition.fields);
  if (view) {
    const viewFields = [];
    for (const key of fields) {
      if (definition.fields[key].views[view]) viewFields.push(key);
    }
    return viewFields;
  } else {
    return fields;
  }
};

//----------------------------------------------------------------------------------------------------------------------

export const intermediaryFieldViewGuard: LdbViewFunction = (viewFunctionObject) => {
  return viewFunctionObject.authUser.accountLevel === 'bank';
};
export const notIntermediaryFieldViewGuard: LdbViewFunction = (viewFunctionObject) => {
  return !intermediaryFieldViewGuard(viewFunctionObject);
};
export const displayBranchForListView: LdbViewFunction = (viewFunctionObject) => {
  return (
    (!intermediaryFieldViewGuard(viewFunctionObject) || viewFunctionObject.routeMetaData.includeBranchInListView) &&
    !['client', 'bank'].includes(viewFunctionObject.authUser.accountLevel)
  );
};

//----------------------------------------------------------------------------------------------------------------------

export const fieldsAreNotTheSameViewGuard = (fieldA: string, fieldB: string) => {
  const viewFunction: LdbViewFunction = ({ record }) => {
    //@ts-ignore
    return !!record && record[fieldA] !== record[fieldB];
  };
  return viewFunction;
};

//----------------------------------------------------------------------------------------------------------------------

export const monthToDate: LdbSearchRangeFunction = (viewFunctionObject) => {
  let date = new Date();
  let y = date.getFullYear();
  let m = date.getMonth();
  let d = date.getDate();
  let startOfMonth = new Date(y, m, 1).toISOString();
  let toDate = new Date(y, m, d).toISOString();
  return { from: startOfMonth, to: toDate };
};

//----------------------------------------------------------------------------------------------------------------------

export const yearToDate: LdbSearchRangeFunction = (viewFunctionObject) => {
  let date = new Date();
  let y = date.getFullYear();
  let m = date.getMonth();
  let d = date.getDate();
  let startOfYear = new Date(y, 0, 1).toISOString();
  let toDate = new Date(y, m, d).toISOString();
  return { from: startOfYear, to: toDate };
};

//----------------------------------------------------------------------------------------------------------------------

export const previousMonth: LdbSearchRangeFunction = (viewFunctionObject) => {
  let date = new Date();
  let y = date.getFullYear();
  let m = date.getMonth();
  let startOfMonth = new Date(y, m - 1, 1).toISOString();
  let endOfMonth = new Date(y, m, 0).toISOString();
  return { from: startOfMonth, to: endOfMonth };
};

//----------------------------------------------------------------------------------------------------------------------

export const getOptionLabel = (definition: LdbDefinition, fieldName: string, option: string) => {
  const fields = definition?.fields || [];
  let fieldObject;
  if (Array.isArray(fields)) {
    for (const field of fields) {
      if (field.name === fieldName) {
        fieldObject = field;
      }
    }
  } else {
    fieldObject = fields[fieldName];
  }
  if (fieldObject) {
    const options = (fieldObject.datatype as LdbOptionType)?.option?.options || [];
    for (const optionItem of options) {
      if (optionItem.id === option) {
        return optionItem.name || option;
      }
    }
  }
  return option;
};
export const getFieldLabel = (definition: LdbDefinition, fieldName: string) => {  
  const fields = definition?.fields || [];
  let fieldObject;
  if (Array.isArray(fields)) {
    for (const field of fields) {
      if (field.name === fieldName) {
        fieldObject = field;
      }
    }
  } else {
    fieldObject = fields[fieldName];
  }
  if (fieldObject) {
    return fieldObject.label
  }
  return fieldName
}

//----------------------------------------------------------------------------------------------------------------------

export const mapSorderByField = (definition: LdbDefinition, orderField: string, count?: number): string => {
  if (!count) {
    count = 1;
  }
  if (count < 4) {
    let field: LdbField | undefined = undefined;
    if (Array.isArray(definition.fields)) {
      for (const definitionField of definition.fields) {
        if (definitionField.field === orderField) {
          field = definitionField;
        }
      }
    } else {
      field = definition.fields[orderField];
    }
    if (field) {
      if (field.property && field.property.sort !== 'none') {
        return field.property.sort;
      }
      //@ts-expect-error
      if (field.datatype.id && field.datatype.id.descriptionField) {
        //@ts-expect-error
        if (field.datatype.id.descriptionField !== 'id') {
          //@ts-expect-error
          return mapSorderByField(definition, field.datatype.id.descriptionField as string, count + 1);
        }
        //@ts-expect-error
        return field.datatype.id.descriptionField
      }
    }
  }
  return orderField;
};

//----------------------------------------------------------------------------------------------------------------------

// export const dateLimit = (dayOffset: number | undefined) => {
//   // const viewFunction: LdbViewFunction = ({record}) => {
//   console.log('dayOffset', dayOffset);
//   let today = new Date();
//   console.log('TODAY', today);

//   let day = today.getUTCDate();
//   let month = today.getUTCMonth();
//   let year = today.getUTCFullYear();

//   let startOfToday = new Date(year, month, day, 2);

//   console.log('startOfToday', startOfToday);
//   // let res = startOfToday.toISOString()
//   return '2022-09-28T00:00:00.000Z';
//   // }
//   // return viewFunction
// };
