import VueStore from 'vue-class-store';

import { LfxBankAccountInt, LfxBankAccountDef } from '@/definitions/LfxBankAccountDef';

import { LfxModel, LfxDefinition } from './LfxModel';
import { LdbViewFunction } from '@/definitions/LdbInterfaces';
import { intermediaryOrBranchUser } from '@/definitions/LfxAccountDef';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import { swiftTextWhiteList } from '@/sharedUtils/LdbValidations';

@VueStore
// @ts-ignore
class LfxBankAccountMdl extends LfxDefinition {
  // @ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }  
}

@VueStore
// @ts-ignore
export class LfxBankAccount extends LfxModel {
  // @ts-ignore
  constructor(data, state) {
    super({ definition_name: 'bankAccount', ...data }, state);
  }

  get link() {
    // @ts-ignore
    return this.beneficiaryId
      ? // @ts-ignore
        `/account/${this.accountId}/beneficiary/${this.beneficiaryId}/bank_account/${this.id}`
      : // @ts-ignore
        `/account/${this.accountId}/bank_account/${this.id}`;
  }

  // ----------------------------------
  // ----- Guards and Permissions -----
  // ----------------------------------

  // ----- Buttons -----

  // Client bank accounts

  get accountBankAccountReopen_buttonGuard() {
    // @ts-ignore
    return this.status === 'closed' && this.hasPermission.reopenAccount;
  }
  get accountBankAccountBalance_buttonGuard() {
    return (
      // @ts-ignore
      this.status === 'active' && this.isBusinessBankAccount && !this.beneficiaryId && this.hasPermission.balanceEnquiry
    );
  }
  get ensureBankAccountMandate_buttonGuard() {
    return (
      // @ts-ignore
      this.status === 'active' && this.isBusinessBankAccount && !this.beneficiaryId && this.hasPermission.ensureBankAccountMandate
    );
  }
  get accountBankAccountStatement_buttonGuard() {
    return (
      // @ts-ignore
      this.status === 'active' &&
      // @ts-ignore
      this.isBusinessBankAccount &&
      // @ts-ignore
      !this.beneficiaryId &&
      this.hasPermission.accountStatement
    );
  }
  get accountBankAccountClose_buttonGuard() {
    // @ts-ignore
    return this.status === 'active' && this.hasPermission.closeAccount;
  }
  // ----- Logs -----
  get bankAccountLogs() {
    const logs = {};
    // @ts-expect-error
    let bankAccountLogs = this.store.state.account[this.accountId].bankAccountLog;
    for (const bankAccountLogId in bankAccountLogs) {
      //@ts-expect-error
      if (bankAccountLogs[bankAccountLogId].bankAccountId === this.id) {
        //@ts-expect-error
        logs[bankAccountLogId] = bankAccountLogs[bankAccountLogId];
      }
    }

    return logs;
  }

  // ----------------------------------
  // ----- API calls -----
  // ----------------------------------

  // Generic

  // @ts-expect-error
  async editBankAccount({ record, fields }) {
    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    // @ts-expect-error
    record.collection_path = this.beneficiaryId
      ? // @ts-expect-error
        `/account/${this.accountId}/beneficiary/${this.beneficiaryId}/bankAccount`
      : // @ts-expect-error
        `/client/${this.accountId}/bankAccount`;
    let options = { return_collection_path: '' };
    // @ts-expect-error
    options.return_collection_path = this.beneficiaryId
      ? // @ts-expect-error
        `/account/${this.accountId}/beneficiary/${this.beneficiaryId}/bank_account`
      : // @ts-expect-error
        `/account/${this.accountId}/bankAccount`;
    // @ts-expect-error
    let response = await this.store.dispatch('db_update_document', { record, fields, options });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  //  Client bank accounts

  async closeBankAccount(closeOtherAccountsWithTheSameNumber:boolean) {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {closeOtherAccountsWithTheSameNumber:closeOtherAccountsWithTheSameNumber === true};
    // @ts-ignore
    let options = { update_state: true, return_collection_path: `/account/${this.accountId}/bankAccount` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/client/${this.accountId}/bankAccount/${this.id}/close`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  async reopenBankAccount() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    // @ts-ignore
    let options = { update_state: true, return_collection_path: `/account/${this.accountId}/bankAccount` };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/client/${this.accountId}/bankAccount/${this.id}/reopen`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  // Beneficiary bank accounts

  async disableBeneficiaryBankAccount() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    // @ts-ignore
    let options = {
      update_state: true,
      // @ts-expect-error
      return_collection_path: `/account/${this.accountId}/beneficiary/${this.beneficiaryId}/bank_account`,
    };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/account/${this.accountId}/beneficiary/${this.beneficiaryId}/bankAccount/${this.id}/disable`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  async enableBeneficiaryBankAccount() {
    // @ts-ignore
    this.store.dispatch('showLoader', true);
    let payload = {};
    // @ts-ignore
    let options = {
      update_state: true,
      // @ts-expect-error
      return_collection_path: `/account/${this.accountId}/beneficiary/${this.beneficiaryId}/bank_account`,
    };
    // @ts-ignore
    let response = await this.store.dispatch('db_put_action', {
      //@ts-ignore
      path: `/account/${this.accountId}/beneficiary/${this.beneficiaryId}/bankAccount/${this.id}/enable`,
      payload,
      options,
    });
    //@ts-ignore
    this.store.dispatch('showLoader', false);
    return response;
  }

  get beneficiaryIsNonCompliant() {
    //@ts-expect-error
    const beneficiary = this.store?.state?.beneficiary?.[this.beneficiaryId];
    // if (beneficiary?.fullName?.length > 35) return true  //SWIFTValidationRemoved
    // if (beneficiary?.address?.length > 35) return true  //SWIFTValidationRemoved
    if (!swiftTextWhiteList.test(beneficiary?.fullName)) return true
    if (!swiftTextWhiteList.test(beneficiary?.address)) return true
    //@ts-expect-error
    if (!(new RegExp("^[a-zA-Z0-9 -]*$")).test(this.accountNumber)) return true
    return false
  }
}


const userMayEditAccountNumber:LdbViewFunction = (viewFunctionObject) => {
  //@ts-expect-error
  return viewFunctionObject.authUser.permissions.includes("releaseClient") && viewFunctionObject.record?.status === 'active' && !viewFunctionObject.record?.accountNumber;
}
// @ts-ignore
export function Definition(context, name: string) {
  return new LfxBankAccountMdl(LfxBankAccountDef, LfxBankAccount, context, name, {
    fields: {
      description: { views: { importBankAccount: true, clientAccountEdit: true } },
      accountNumber: { views: { importBankAccount: true, clientAccountEdit:userMayEditAccountNumber } },
      fromDate: { views: { statementSearch: true } },
      toDate: { views: { statementSearch: true } },
      externalReference: { views: { clientAccountEdit: intermediaryOrBranchUser } },
      notes: { views: { clientAccountEdit: intermediaryOrBranchUser } },
    },
  });
}
