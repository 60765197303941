<template>
  <!-- <LDBItemView :record="record" :definition="definition" @on-save="onSaveAccount" :view="view" /> -->
  <LDBItemView
    :record="record"
    :definition="definition"
    :view="view"
    :showNextButton="showNextButton"
    action="editUser"
    :params="{ userId: record.id }"
    @on-save="onSave"
    @on-next="onNext"
  />

  <router-view></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import LDBItemView from '@/landobyte_vue/LDBItemView.vue';

export default {
  name: 'LfxUserDetails',
  components: { LDBItemView },
  props: {},
  emits: ['step-next'],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters([]),
    ...mapState(['systemSettings']),
    recordName() {
      return 'user';
    },
    recordId() {
      return this.$route.params.userId;
    },
    record() {
      return this.$store.state[this.recordName][this.recordId];
    },
    definition() {
      return getDefinition(this.recordName);
    },
    view() {
      return this.$route.meta?.content?.view
        ? this.$route.meta.content.view
        : this.$route.meta?.view
        ? this.$route.meta?.view
        : 'item';
    },

    // ----- Form Buttons -----

    showNextButton() {
      return this.$route.meta?.content?.formButtons?.showNextButton;
    },
  },
  watch: {},
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),
    onSave() {
      // this.showLoader(true);
      // let record = this.record;
      // let response = await this.$store.dispatch('db_update_document', { record, fields });
      // this.showLoader(false);
      // if (response !== undefined) {
      if (this.$route.matched[0].name === 'user') {
        this.$router.replace({ name: 'userDetailsView' });
      }
      // }
    },
    onNext() {
      this.$emit('step-next');
    },
  },
};
</script>
