//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxTradeCalc.ts
//  Author      : Bevan Timm
//  Description : This module contains trading calculation functions for use in the LandoFX System
//  Created     : 14 October 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type {
  LfxDealAction,
  LfxDealForwardContractType,
  LfxDealInitiatingInterface,
  LfxDealMarkupType,
} from '../definitions/LfxDealDef';
import type { LfxAccountMarkupType } from '../definitions/LfxAccountMarkupDef';
import type { LdbCurrencyInt } from '../definitions/LdbCurrencyDef';
import type { LfxCurrencyPairInt } from '../definitions/LfxCurrencyPairDef';
import { roundTo } from './LdbSharedUtils';
import { LfxAccountAccountLevel } from '../definitions/LfxAccountDef';
import { LfxPaymentDetailsOfCharge, LfxPaymentInt } from '../definitions/LfxPaymentDef';

//----------------------------------------------------------------------------------------------------------------------

export type currencyType = 'quote' | 'base';

export type cachedRicType = {
  bid: number;
  ask: number;
  SN?: number; // MID POINT
  'SN-BID'?: number;
  'SN-ASK'?: number;
  SW?: number; // MID POINT
  'SW-BID'?: number;
  'SW-ASK'?: number;
  '2W'?: number; // MID POINT
  '2W-BID'?: number;
  '2W-ASK'?: number;
  '1M'?: number; // MID POINT
  '1M-BID'?: number;
  '1M-ASK'?: number;
  '2M'?: number; // MID POINT
  '2M-BID'?: number;
  '2M-ASK'?: number;
  '3M'?: number; // MID POINT
  '3M-BID'?: number;
  '3M-ASK'?: number;
  '6M'?: number; // MID POINT
  '6M-BID'?: number;
  '6M-ASK'?: number;
  '1Y'?: number; // MID POINT
  '1Y-BID'?: number;
  '1Y-ASK'?: number;
};

//----------------------------------------------------------------------------------------------------------------------

export const getSpotFromCachedRic = (cachedRic: cachedRicType, currencyType: currencyType, action: LfxDealAction) => {
  if ((currencyType === 'quote' && action === 'Buy') || (currencyType === 'base' && action === 'Sell')) {
    return cachedRic.ask;
  } else {
    return cachedRic.bid;
  }
};

//----------------------------------------------------------------------------------------------------------------------

export const calculateCounterAmount = (amount: number, rate: number, currencyType: currencyType, decimals?: number) => {
  if (!decimals) {
    decimals = 0;
  }
  var counterAmount;
  if (currencyType === 'quote') {
    counterAmount = amount * rate;
  } else {
    counterAmount = amount / rate;
  }
  return roundTo(counterAmount, decimals);
};

//----------------------------------------------------------------------------------------------------------------------

export const calculateMarkedUpRate = (
  rate: number,
  markup: number,
  currencyType: currencyType,
  action: LfxDealAction
) => {
  var markedUpRate;
  if (currencyType === 'quote') {
    if (action === 'Buy') {
      markedUpRate = rate + markup / 10000;
    } else {
      markedUpRate = rate - markup / 10000;
    }
  } else {
    if (action === 'Buy') {
      markedUpRate = rate - markup / 10000;
    } else {
      markedUpRate = rate + markup / 10000;
    }
  }
  return +(+markedUpRate).toPrecision(6);
  // return roundTo(markedUpRate, 4);
};

export const calculateMarkupFromRates = (
  baseRate: number,
  markedUpRate: number,
  currencyType: currencyType,
  action: LfxDealAction
) => {
  var rateDiff = 0;
  if (currencyType === 'quote') {
    if (action === 'Buy') {
      rateDiff = markedUpRate - baseRate;
    } else {
      rateDiff = baseRate - markedUpRate;
    }
  } else {
    if (action === 'Buy') {
      rateDiff = baseRate - markedUpRate;
    } else {
      rateDiff = markedUpRate - baseRate;
    }
  }
  return roundTo(rateDiff * 10000, 4);
};

export const calculateMarkupFromBankAndPercentage = (
  baseRate: number,
  percentage: number,
  currencyType: currencyType,
  action: LfxDealAction
) => {
  var percentageOfRate;
  if (currencyType === 'quote') {
    if (action === 'Buy') {
      percentageOfRate = (baseRate * percentage!) / (1 + percentage!);
    } else {
      percentageOfRate = (baseRate * percentage!) / (1 - percentage!);
    }
  } else {
    if (action === 'Buy') {
      percentageOfRate = (baseRate * percentage!) / (1 - percentage!);
    } else {
      percentageOfRate = (baseRate * percentage!) / (1 + percentage!);
    }
  }
  return roundTo(percentageOfRate * 10000, 2);
};

//----------------------------------------------------------------------------------------------------------------------

export const checkValidMarkup = (
  baseRate: number,
  markup: number,
  maximumMarkupPercentage: number,
  maximumMarkup?: number
) => {
  if (markup < 0) {
    throw new Error('Markup must be positive');
  }
  if (maximumMarkup && markup > maximumMarkup) {
    throw new Error(
      `Markup is too large (${markup.toString()}) cannot exceed the Maximum Markup ${maximumMarkup.toString()}`
    );
  }
  if (markup / 10000 / baseRate > maximumMarkupPercentage) {
    throw new Error('Markup has exceeded the allowed tolerance.');
  }
};
export const checkValidClientRate = (
  bankRate: number,
  clientRate: number,
  currencyType: currencyType,
  action: LfxDealAction,
  maximumMarkupPercentage: number,
  maximumMarkup?: number
) => {
  const markup = calculateMarkupFromRates(bankRate, clientRate, currencyType, action);
  checkValidMarkup(bankRate, markup, maximumMarkupPercentage, maximumMarkup);
};

//----------------------------------------------------------------------------------------------------------------------

export const getCurrencyType = (currency: LdbCurrencyInt | string, currencyPair: LfxCurrencyPairInt): currencyType => {
  var currencyId;
  if (typeof currency === 'string') {
    currencyId = currency;
  } else {
    currencyId = currency.id;
  }
  var currencyType: 'quote' | 'base';
  if (currencyId === currencyPair.quoteCurrency) {
    currencyType = 'quote';
  } else {
    currencyType = 'base';
  }
  return currencyType;
};

export const getToAndFromCurrencies = (
  currency: LdbCurrencyInt | string,
  currencyPair: LfxCurrencyPairInt,
  action: LfxDealAction
) => {
  const currencyType = getCurrencyType(currency, currencyPair);
  if (currencyType === 'quote') {
    if (action === 'Buy') {
      return { fromCurrency: currencyPair.baseCurrency, toCurrency: currencyPair.quoteCurrency };
    } else {
      return { fromCurrency: currencyPair.quoteCurrency, toCurrency: currencyPair.baseCurrency };
    }
  } else {
    if (action === 'Buy') {
      return { fromCurrency: currencyPair.quoteCurrency, toCurrency: currencyPair.baseCurrency };
    } else {
      return { fromCurrency: currencyPair.baseCurrency, toCurrency: currencyPair.quoteCurrency };
    }
  }
};

//----------------------------------------------------------------------------------------------------------------------

export const calculateMarkupsAndRates = (markupObject: {
  currencyType: currencyType;
  action: LfxDealAction;
  valueDate: Date;
  markupType: LfxDealMarkupType;
  spotRate?: number;
  bankRate?: number;
  clientRate?: number;
  forwardPoints?: number;
  setMarkup?: number;
  detailsOfCharge?: LfxPaymentDetailsOfCharge;
  accountMarkups: {
    accountId: number;
    parentId: number;
    markupType?: LfxAccountMarkupType;
    pips?: number;
    percentage?: number;
    discountedBankMarkup?: number;
    maximumIntermediaryMarkup?: number;
  }[];
  config: {
    maximumMarkupPercentage: number;
    fecAdditionalSpreads?: {
      '1month': number;
      '2month': number;
      '3month': number;
      '4month': number;
      '5month': number;
      '6month': number;
      '12month': number;
    };
    interFecAdditionalSpreads?: {
      '1month': number;
      '2month': number;
      '3month': number;
      '4month': number;
      '5month': number;
      '6month': number;
      '12month': number;
    };
    flatFeeRates?: {
      OUR: number;
      SHARE: number;
      BEN: number;
    };
    allowIntermediaryMarkupToBeNegative?: boolean;
  };
}) => {
  const markupReturn: {
    spotRate: number;
    bench: number;
    bankRate: number;
    clientRate: number;
    totalPips: number;
    unmodifiedBankPips: number;
    bankPips: number;
    interPips: number;
    unmodifiedInterPips: number;
    fecMarkup: number;
    interFecMarkup: number;
    forwardPoints: number;
    pipBreakdown: { accountId: number; pips: number }[];
    flatFee: number;
  } = {
    spotRate: markupObject.spotRate!,
    bench: markupObject.spotRate!,
    bankRate: markupObject.bankRate!,
    clientRate: 0,
    totalPips: 0,
    bankPips: 0,
    unmodifiedBankPips: 0,
    interPips: 0,
    unmodifiedInterPips: 0,
    fecMarkup: 0,
    interFecMarkup: 0,
    forwardPoints: 0,
    pipBreakdown: [],
    flatFee: 0,
  };
  if (markupObject.forwardPoints) {
    markupReturn.forwardPoints = markupObject.forwardPoints / 10000;
  }
  if (markupReturn.forwardPoints !== 0 && markupObject.config.fecAdditionalSpreads) {
    markupReturn.fecMarkup = getFecMarkup(markupObject.config.fecAdditionalSpreads, markupObject.valueDate);
  }
  if (markupReturn.forwardPoints !== 0 && markupObject.config.interFecAdditionalSpreads) {
    markupReturn.interFecMarkup = getFecMarkup(markupObject.config.interFecAdditionalSpreads, markupObject.valueDate);
  }
  if (markupReturn.spotRate) {
    markupReturn.bench = roundTo(markupReturn.spotRate + markupReturn.forwardPoints, 4);
  }
  markupObject.accountMarkups = sortMarkups(markupObject.accountMarkups);
  const topLevelAccountId = markupObject.accountMarkups[0].accountId;
  const bankSpreadOverrides: number[] = [];
  const maxInterMarkups: number[] = [];
  for (const accountMarkup of markupObject.accountMarkups) {
    // This is done early to complete the bank rate/bench rate calculateMarkupsAndRates
    if (accountMarkup.parentId === 1) {
      if (accountMarkup.markupType === 'percentage') {
        if (markupReturn.bench) {
          markupReturn.bankPips = roundTo(accountMarkup.percentage! * markupReturn.bench * 10000, 2);
        } else {
          markupReturn.bankPips = calculateMarkupFromBankAndPercentage(
            markupObject.bankRate!,
            accountMarkup.percentage!,
            markupObject.currencyType,
            markupObject.action
          );
        }
        markupReturn.bankPips = markupReturn.bankPips + markupReturn.fecMarkup;
      } else {
        markupReturn.bankPips = +accountMarkup.pips! + +markupReturn.fecMarkup;
      }
      break;
    } else {
      if (typeof accountMarkup.discountedBankMarkup === 'number' && accountMarkup.discountedBankMarkup > 0) {
        bankSpreadOverrides.push(accountMarkup.discountedBankMarkup);
      }
      if (typeof accountMarkup.maximumIntermediaryMarkup === 'number' && accountMarkup.maximumIntermediaryMarkup > 0) {
        maxInterMarkups.push(accountMarkup.maximumIntermediaryMarkup);
      }
    }
  }
  markupReturn.unmodifiedBankPips = markupReturn.bankPips;
  if (bankSpreadOverrides.length > 0) {
    bankSpreadOverrides.push(markupReturn.bankPips);
    markupReturn.bankPips = Math.min(...bankSpreadOverrides);
  }
  markupReturn.pipBreakdown.push({
    accountId: 1,
    pips: markupReturn.bankPips,
  });

  if (!markupReturn.bench) {
    if (!markupObject.bankRate) {
      throw new Error('Either bank or spotRate rate must be defined');
    }
    markupReturn.bench = calculateMarkedUpRate(
      markupObject.bankRate!,
      -markupReturn.bankPips,
      markupObject.currencyType,
      markupObject.action
    );
    markupReturn.spotRate = markupReturn.bench - markupReturn.forwardPoints;
  } else {
    if (!markupObject.bankRate) {
      markupReturn.bankRate = calculateMarkedUpRate(
        markupReturn.bench,
        markupReturn.bankPips,
        markupObject.currencyType,
        markupObject.action
      );
    } else {
      markupReturn.bankPips = calculateMarkupFromRates(
        markupReturn.bench,
        markupObject.bankRate,
        markupObject.currencyType,
        markupObject.action
      );
    }
  }

  for (const accountMarkup of markupObject.accountMarkups.reverse()) {
    if (accountMarkup.parentId !== 1) {
      var pips = 0;
      if (accountMarkup.pips) {
        pips = +accountMarkup.pips;
      }
      if (accountMarkup.markupType == 'percentage') {
        pips = roundTo(markupReturn.bench * accountMarkup.percentage! * 10000, 2);
      }
      if (accountMarkup.accountId === topLevelAccountId) {
        pips += +markupReturn.interFecMarkup;
      }
      markupReturn.interPips = markupReturn.interPips + pips;
      markupReturn.totalPips = markupReturn.totalPips + pips;
      markupReturn.pipBreakdown.push({
        accountId: accountMarkup.parentId,
        pips: pips,
      });
    }
  }
  let checkMaximumMarkup;
  markupReturn.unmodifiedInterPips = markupReturn.interPips;
  if (maxInterMarkups.length > 0) {
    let maxMarkupEnforcement: 'strict' | 'lenient';
    maxMarkupEnforcement = 'strict';
    if (maxMarkupEnforcement !== 'strict') {
      const maxMarkup = Math.max(...maxInterMarkups);
      checkMaximumMarkup = maxMarkup;
      if (maxMarkup < markupReturn.interPips) {
        const markupChange = markupReturn.interPips - maxMarkup;

        markupReturn.pipBreakdown = modifyPipBreakdownsBy(markupReturn.pipBreakdown.reverse(), -markupChange).reverse();
        markupReturn.totalPips = markupReturn.totalPips - markupChange;
        markupReturn.interPips = maxMarkup;
      }
    } else {
      const minMaxMarkup = Math.min(...maxInterMarkups);
      checkMaximumMarkup = minMaxMarkup;
      if (minMaxMarkup < markupReturn.interPips) {
        throw new Error(
          `Total Intermediary Markup (${markupReturn.interPips?.toString()}) cannot exceed the configured Maximum Intermediary Markup ${minMaxMarkup?.toString()}`
        );
      }
    }
  }

  if (markupObject.markupType == 'fixedRate') {
    if (!markupObject.clientRate) {
      throw new Error('clientRate must be defined for fixedRate markup type');
    }
    markupReturn.clientRate = markupObject.clientRate;
    try {
      checkValidClientRate(
        markupReturn.bankRate,
        markupReturn.clientRate,
        markupObject.currencyType,
        markupObject.action,
        markupObject.config.maximumMarkupPercentage,
        checkMaximumMarkup
      );
    } catch (checkValidClientRateError) {
      if (
        markupObject.config.allowIntermediaryMarkupToBeNegative === true &&
        (checkValidClientRateError as Error).message === 'Markup must be positive'
      ) {
        markupReturn.bankRate = markupReturn.clientRate;
      } else {
        throw checkValidClientRateError;
      }
    }
    const configuredInterPips = markupReturn.interPips;
    markupReturn.interPips = calculateMarkupFromRates(
      markupReturn.bankRate!,
      markupReturn.clientRate!,
      markupObject.currencyType,
      markupObject.action
    );
    const interPipChange = markupReturn.interPips - configuredInterPips;
    markupReturn.pipBreakdown = modifyPipBreakdownsBy(markupReturn.pipBreakdown.reverse(), interPipChange).reverse();
  } else {
    if (markupObject.setMarkup !== undefined && markupObject.setMarkup !== null) {
      checkValidMarkup(
        markupReturn.bankRate,
        markupObject.setMarkup,
        markupObject.config.maximumMarkupPercentage,
        checkMaximumMarkup
      );
      const myPipsIndex = markupReturn.pipBreakdown.length - 1;
      markupReturn.interPips =
        markupReturn.interPips + markupObject.setMarkup - markupReturn.pipBreakdown[myPipsIndex].pips;
      markupReturn.totalPips =
        markupReturn.totalPips + markupObject.setMarkup - markupReturn.pipBreakdown[myPipsIndex].pips;
      markupReturn.pipBreakdown[myPipsIndex].pips = markupObject.setMarkup;
    } else {
      checkValidMarkup(
        markupReturn.bankRate,
        markupReturn.interPips,
        markupObject.config.maximumMarkupPercentage,
        checkMaximumMarkup
      );
    }
    markupReturn.clientRate = calculateMarkedUpRate(
      markupReturn.bankRate!,
      markupReturn.interPips,
      markupObject.currencyType,
      markupObject.action
    );
  }
  markupReturn.totalPips = roundTo(markupReturn.totalPips, 2);
  if (markupObject.detailsOfCharge && markupObject.config.flatFeeRates) {
    markupReturn.flatFee = markupObject.config.flatFeeRates[markupObject.detailsOfCharge];
  }
  return markupReturn;
};

const sortMarkups = (
  accountMarkups: {
    accountId: number;
    parentId: number;
    markupType?: LfxAccountMarkupType;
    pips?: number;
    percentage?: number;
  }[],
  findAccount?: number,
  sortedMarkups?: {
    accountId: number;
    parentId: number;
    markupType?: LfxAccountMarkupType;
    pips?: number;
    percentage?: number;
  }[],
  testedAccounts?: number[]
): {
  accountId: number;
  parentId: number;
  markupType?: LfxAccountMarkupType;
  pips?: number;
  percentage?: number;
}[] => {
  if (!findAccount) {
    findAccount = 1;
  }
  if (!sortedMarkups) {
    sortedMarkups = [];
  }
  if (!testedAccounts) {
    testedAccounts = [];
  }
  var foundAccount = false;
  if (!testedAccounts.includes(findAccount)) {
    testedAccounts.push(findAccount);
    for (const markup of accountMarkups) {
      if (markup.parentId === findAccount) {
        findAccount = markup.accountId;
        sortedMarkups.push(markup);
        foundAccount = true;
        break;
      }
    }
  }
  if (!foundAccount) {
    return sortedMarkups.reverse();
  } else {
    return sortMarkups(accountMarkups, findAccount, sortedMarkups, testedAccounts);
  }
};

const modifyPipBreakdownsBy = (
  pipBreakdowns: { accountId: number; pips: number }[],
  interPipChange: number,
  index?: number
): { accountId: number; pips: number }[] => {
  if (!index) {
    index = 0;
  }
  const newPips = pipBreakdowns[index].pips + interPipChange;
  if (newPips < 0) {
    if (index + 1 >= pipBreakdowns.length) {
      pipBreakdowns[index].pips = newPips;
      return pipBreakdowns;
    } else {
      pipBreakdowns[index].pips = 0;
      return modifyPipBreakdownsBy(pipBreakdowns, newPips, index + 1);
    }
  } else {
    pipBreakdowns[index].pips = newPips;
    return pipBreakdowns;
  }
};

const getFecMarkup = (
  fecAdditionalSpreads: {
    '1month': number;
    '2month': number;
    '3month': number;
    '4month': number;
    '5month': number;
    '6month': number;
    '12month': number;
  },
  valueDate: Date
) => {
  const now = new Date();
  var months;
  months = (valueDate.getFullYear() - now.getFullYear()) * 12;
  months -= now.getMonth();
  months += valueDate.getMonth();
  if (valueDate.getDate() > now.getDate()) {
    months += 1;
  }
  switch (months) {
    case 1:
      return fecAdditionalSpreads['1month'];
    case 2:
      return fecAdditionalSpreads['2month'];
    case 3:
      return fecAdditionalSpreads['3month'];
    case 4:
      return fecAdditionalSpreads['4month'];
    case 5:
      return fecAdditionalSpreads['5month'];
    case 6:
      return fecAdditionalSpreads['6month'];
    default:
      return fecAdditionalSpreads['12month'] || fecAdditionalSpreads['6month'];
  }
};
//----------------------------------------------------------------------------------------------------------------------

interface simpliciedAccountMarkup {
  accountId: number;
  pips: number;
}
interface simplifiedMarkupObject {
  spotRate?: number;
  bench?: number;
  bankRate?: number;
  clientRate?: number;
  accountMarkups?: simpliciedAccountMarkup[];
}

export const reCalculateMarkupsAndRates = (
  oldMarkupObject: simplifiedMarkupObject,
  newMarkupObject: simplifiedMarkupObject,
  currencyType: currencyType,
  action: LfxDealAction
): simplifiedMarkupObject => {
  var bankMarkup: simpliciedAccountMarkup;
  var interMarkups: simpliciedAccountMarkup[] = [];
  for (const markup of oldMarkupObject.accountMarkups!) {
    if (markup.accountId === 1) {
      bankMarkup = markup;
    } else {
      interMarkups.push(markup);
    }
  }
  if (newMarkupObject.bench) {
    if (newMarkupObject.bankRate) {
      bankMarkup!.pips = calculateMarkupFromRates(newMarkupObject.bench, newMarkupObject.bankRate, currencyType, action);
    } else {
      newMarkupObject.bankRate = calculateMarkedUpRate(newMarkupObject.bench, bankMarkup!.pips, currencyType, action);
    }
  } else {
    if (newMarkupObject.bankRate) {
      newMarkupObject.bench = calculateMarkedUpRate(newMarkupObject.bankRate!, -bankMarkup!.pips, currencyType, action);
    } else {
      newMarkupObject.bench = oldMarkupObject.bench;
      newMarkupObject.bankRate = oldMarkupObject.bankRate;
    }
  }
  if (!newMarkupObject.clientRate) {
    newMarkupObject.clientRate = oldMarkupObject.clientRate;
  }
  const newInterMarkup = newMarkupObject.clientRate! - newMarkupObject.bankRate!;
  const oldInterMarkup = oldMarkupObject.clientRate! - oldMarkupObject.bankRate!;
  if (newInterMarkup !== oldInterMarkup) {
    interMarkups = modifyPipBreakdownsBy(interMarkups, roundTo(newInterMarkup - oldInterMarkup, 4) * 10000); // DOES THIS NEED TO BE REVERSED TOO?
  }
  newMarkupObject.accountMarkups = [bankMarkup!, ...interMarkups];
  return newMarkupObject;
};

//----------------------------------------------------------------------------------------------------------------------

export const getCurrencyIntFromFloat = (amountFloat: number) => {
  return Math.round(amountFloat * 100);
};
export const getFloatFromCurrencyInt = (amountInt: number) => {
  return amountInt / 100;
};

//----------------------------------------------------------------------------------------------------------------------

export const calculateForwardPoints = (
  cachedRic: cachedRicType,
  contractType: LfxDealForwardContractType,
  spotDate: Date,
  valueDate: Date,
  currencyType: currencyType,
  action: LfxDealAction,
  optionAddionalPercentage: number,
  optionStartDate?: Date
): number => {
  if (
    contractType === 'optional' &&
    ((currencyType === 'quote' && action === 'Sell') || (currencyType === 'base' && action === 'Buy'))
  ) {
    return 0; // For inward optional contracts, do not add forward points
  }
  if (contractType === 'partiallyOptional') {
    if (!optionStartDate) {
      throw new Error('optionStartDate must be set for partiallyOptional');
    }
    const fixedPortion = calculateForwardPoints(
      cachedRic,
      'fixed',
      spotDate,
      optionStartDate,
      currencyType,
      action,
      optionAddionalPercentage,
      optionStartDate
    );
    const fullOptionalPortion = calculateForwardPoints(
      cachedRic,
      'optional',
      spotDate,
      valueDate,
      currencyType,
      action,
      optionAddionalPercentage,
      optionStartDate
    );
    const partialOptionalPortion = calculateForwardPoints(
      cachedRic,
      'optional',
      spotDate,
      optionStartDate,
      currencyType,
      action,
      optionAddionalPercentage,
      optionStartDate
    );
    return roundTo(fixedPortion + fullOptionalPortion - partialOptionalPortion, 2);
  }
  let rateSide: 'BID' | 'ASK';
  if ((currencyType === 'quote' && action === 'Buy') || (currencyType === 'base' && action === 'Sell')) {
    rateSide = 'ASK';
  } else {
    rateSide = 'BID';
  }
  const valueDateDiff = daysDiff(spotDate, valueDate);
  if (valueDateDiff <= 0) {
    throw new Error('Value Date is not after Spot Date');
  }
  const forwardDiffArray = getForwardDatesDiff(spotDate);
  const valueDateNearest = getNearestForwardDates(valueDateDiff, forwardDiffArray, cachedRic, rateSide);
  var fixedForwardPoints;
  if (valueDateNearest.previousDiff.rate === valueDateNearest.nextDiff?.rate) {
    fixedForwardPoints = valueDateNearest.previousDiff.points!;
  } else {
    fixedForwardPoints = Math.round(
      valueDateNearest.previousDiff.points! +
        ((valueDateNearest.nextDiff!.points! - valueDateNearest.previousDiff.points!) *
          (valueDateDiff - valueDateNearest.previousDiff.diff)) /
          (valueDateNearest.nextDiff!.diff - valueDateNearest.previousDiff.diff)
    );
  }
  if (contractType === 'optional') {
    return roundTo(fixedForwardPoints * (1 + optionAddionalPercentage), 2);
  } else {
    return roundTo(fixedForwardPoints, 2);
  }
};

const daysDiff = (firstDate: Date, secondDate: Date) => {
  return Math.round((secondDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24));
};

type forwardDiffArrayType = [
  { SN: number },
  { SW: number },
  { '2W': number },
  { '1M': number },
  { '2M': number },
  { '3M': number },
  { '6M': number },
  { '1Y': number }
];
const getForwardDatesDiff = (spotDate: Date): forwardDiffArrayType => {
  return [
    { SN: 1 },
    { SW: 7 },
    { '2W': 14 },
    { '1M': daysDiff(spotDate, new Date(new Date(spotDate).setMonth(spotDate.getMonth() + 1))) },
    { '2M': daysDiff(spotDate, new Date(new Date(spotDate).setMonth(spotDate.getMonth() + 2))) },
    { '3M': daysDiff(spotDate, new Date(new Date(spotDate).setMonth(spotDate.getMonth() + 3))) },
    { '6M': daysDiff(spotDate, new Date(new Date(spotDate).setMonth(spotDate.getMonth() + 6))) },
    { '1Y': daysDiff(spotDate, new Date(new Date(spotDate).setMonth(spotDate.getMonth() + 12))) },
  ];
};
const getNearestForwardDates = (
  valueDateDaysDiff: number,
  forwardDiffArray: forwardDiffArrayType,
  cachedRic: cachedRicType,
  rateSide: 'BID' | 'ASK'
) => {
  const closestDiffObject: {
    previousDiff: { rate: string; diff: number; points?: number };
    nextDiff?: { rate: string; diff: number; points?: number };
  } = {
    previousDiff: { rate: 'SN', diff: 1 },
  };
  for (const diff of forwardDiffArray) {
    const diffKey = Object.keys(diff)[0] as keyof typeof diff;
    const diffDay = diff[diffKey];
    if (valueDateDaysDiff === diffDay) {
      closestDiffObject.previousDiff.rate = diffKey;
      closestDiffObject.previousDiff.diff = diff[diffKey];
      closestDiffObject.nextDiff = {
        rate: diffKey,
        diff: diff[diffKey],
      };
      break;
    } else if (valueDateDaysDiff >= diffDay) {
      closestDiffObject.previousDiff.rate = diffKey;
      closestDiffObject.previousDiff.diff = diff[diffKey];
    } else {
      closestDiffObject.nextDiff = {
        rate: diffKey,
        diff: diff[diffKey],
      };
      break;
    }
  }
  if (!closestDiffObject.nextDiff) {
    throw new Error('Value date beyond longest ric');
  }
  const midOrSide: 'mid' | 'side' = 'mid';
  if (midOrSide === 'mid') {
    closestDiffObject.previousDiff.points = cachedRic[closestDiffObject.previousDiff.rate as keyof typeof cachedRic];
    closestDiffObject.nextDiff.points = cachedRic[closestDiffObject.nextDiff.rate as keyof typeof cachedRic];
  } else {
    closestDiffObject.previousDiff.points =
      cachedRic[(closestDiffObject.previousDiff.rate + '-' + rateSide) as keyof typeof cachedRic] ||
      cachedRic[closestDiffObject.previousDiff.rate as keyof typeof cachedRic];
    closestDiffObject.nextDiff.points =
      cachedRic[(closestDiffObject.nextDiff.rate + '-' + rateSide) as keyof typeof cachedRic] ||
      cachedRic[closestDiffObject.nextDiff.rate as keyof typeof cachedRic];
  }
  return closestDiffObject;
};
//----------------------------------------------------------------------------------------------------------------------

export const getFullDealNumber = (dealNumber: number | string | undefined) => {
  if (dealNumber === undefined) {
    return undefined;
  }
  if (typeof dealNumber === 'number') {
    dealNumber = dealNumber.toString();
  }
  const splitDealNumber = dealNumber.split('-');
  if (splitDealNumber.length === 1) {
    dealNumber = '3-' + dealNumber;
  }
  return dealNumber;
};

export const getDealNumberPrefix = (dealNumber: string | undefined) => {
  if (!dealNumber) {
    return undefined;
  } else {
    const splitDealNumber = dealNumber.split('-');
    if (splitDealNumber.length === 1) {
      return '3';
    } else {
      return splitDealNumber[0];
    }
  }
};

//----------------------------------------------------------------------------------------------------------------------

export const getCurrentForwardContractType = (
  contractType: LfxDealForwardContractType,
  optionStartDate: Date | undefined,
  valueDateIsAfterSpotDate: boolean,
  valueDate: Date
): 'fixed' | 'optional' => {
  if (valueDateIsAfterSpotDate) {
    return 'optional';
  }
  if (contractType === 'partiallyOptional') {
    if (optionStartDate! > (new Date(valueDate) || new Date())) {
      return 'fixed';
    }
    return 'optional';
  }
  return contractType;
};

//----------------------------------------------------------------------------------------------------------------------

export const getInitiatingInterfaceForAccountLevel = (
  accountLevel: LfxAccountAccountLevel
): LfxDealInitiatingInterface => {
  switch (accountLevel) {
    case 'bank':
      return 'bank';
    case 'intermediary':
      return 'intermediary';
    case 'intermediaryBranch':
      return 'intermediary';
    case 'client':
      return 'client';
  }
};

//----------------------------------------------------------------------------------------------------------------------

export const getSplitPaymentAmounts = (
  balancePayment: LfxPaymentInt,
  editingPayment: LfxPaymentInt | undefined,
  newPaymentAmount: number,
  decimals?: number
) => {
  if (!decimals) {
    decimals = 0;
  }
  const splitPaymentObject: {
    newPaymentAmount?: number;
    newForeignAmount?: number;
    balancePaymentAmount?: number;
    balanceForeignAmount?: number;
  } = {
    newPaymentAmount: newPaymentAmount,
    balancePaymentAmount: balancePayment.paymentAmount! - newPaymentAmount,
  };
  if (editingPayment) {
    splitPaymentObject.balancePaymentAmount =
      balancePayment.paymentAmount! - (newPaymentAmount - editingPayment.paymentAmount!);
  }
  if (balancePayment.paymentCurrency === balancePayment.foreignCurrency) {
    splitPaymentObject.newForeignAmount = newPaymentAmount;
    splitPaymentObject.balanceForeignAmount = splitPaymentObject.balancePaymentAmount!;
  } else {
    console.log("wot",{
      "!editingPayment":!editingPayment,
      "editingPayment.id":editingPayment?.id,
      "balancePayment.foreignAmount":balancePayment.foreignAmount,
      "newPaymentAmount":newPaymentAmount,
      "balancePayment.paymentAmount":balancePayment.paymentAmount,
      "editingPayment.foreignAmount":editingPayment?.foreignAmount,
      "editingPayment.paymentAmount":editingPayment?.paymentAmount

    })
    if (!editingPayment) {
      splitPaymentObject.newForeignAmount = roundTo(
        (balancePayment.foreignAmount! * newPaymentAmount) / balancePayment.paymentAmount!,
        decimals  
      );
      splitPaymentObject.balanceForeignAmount = balancePayment.foreignAmount! - splitPaymentObject.newForeignAmount; // Moved on 2024-10-23
    } else {
      if (editingPayment.paymentAmount === 0) {
        splitPaymentObject.newForeignAmount = 0
      } else {
        splitPaymentObject.newForeignAmount = roundTo(
          (editingPayment.foreignAmount! * newPaymentAmount) / editingPayment.paymentAmount!,
          decimals
        );  
      }
      splitPaymentObject.balanceForeignAmount = balancePayment.foreignAmount! - (splitPaymentObject.newForeignAmount - editingPayment!.foreignAmount!) // Added on 2024-10-23
    }
  }
  return splitPaymentObject;
};

//----------------------------------------------------------------------------------------------------------------------

export const getNewPaymentAmounts = (
  modificationAmount: number, // Positive for an increase in value, negative for a decrese
  paymentArray: LfxPaymentInt[],
  balancePaymentId: number
) => {
  var balancePayment: LfxPaymentInt | undefined;
  var portfolioBalancePayment: LfxPaymentInt | undefined;
  const nonBalancePayments: LfxPaymentInt[] = [];
  const portfolioNonBalancePayments: LfxPaymentInt[] = [];
  const newPaymentArray:LfxPaymentInt[] = [];
  for (const payment of paymentArray) {
    if (payment.id === balancePaymentId) {
      if (['new', 'readyForSubmission'].includes(payment.status!)) {
        balancePayment = payment;
      } else {
        portfolioBalancePayment = payment
      }
    } else {
      if (['new', 'readyForSubmission'].includes(payment.status!)) {
        nonBalancePayments.push(payment);
      } else {
        portfolioNonBalancePayments.push(payment);
      }
    }
  }
  if (!balancePayment && !portfolioBalancePayment) {
    throw new Error('Balance Payment Not Found');
  }
  for (const item of [portfolioBalancePayment,...portfolioNonBalancePayments,balancePayment, ...nonBalancePayments].reverse()) {
    if (item !== undefined) {
      newPaymentArray.push(item)
    }
  }
  paymentArray = newPaymentArray;
  return doGetNewPaymentAmounts(modificationAmount, paymentArray, []);
};
const doGetNewPaymentAmounts = (
  modificationAmount: number,
  paymentArray: LfxPaymentInt[],
  changePaymentArray: { payment: LfxPaymentInt; newPaymentAmount: number; newForeignAmount: number }[]
): { payment: LfxPaymentInt; newPaymentAmount: number; newForeignAmount: number }[] => {
  if (modificationAmount === 0 || modificationAmount === 0.0) {
    return changePaymentArray;
  } else if (paymentArray.length === 0) {
    throw new Error('No further payments to reduce');
  } else {
    var newPaymentAmount = paymentArray[0].paymentAmount!;
    var newForeignAmount = paymentArray[0].foreignAmount!;
    if (modificationAmount > 0 || -modificationAmount <= paymentArray[0].paymentAmount!) {
      newPaymentAmount += modificationAmount!;
      newForeignAmount = getNewForeignAmount(paymentArray[0], newPaymentAmount);
      modificationAmount = 0;
    } else {
      newPaymentAmount = 0; //TODO
      newForeignAmount = 0;
      modificationAmount += paymentArray[0].paymentAmount!;
    }
    changePaymentArray.push({
      payment: paymentArray[0],
      newPaymentAmount: newPaymentAmount,
      newForeignAmount: newForeignAmount,
    });
  }
  paymentArray.shift();
  return doGetNewPaymentAmounts(modificationAmount, paymentArray, changePaymentArray);
};
const getNewForeignAmount = (payment: LfxPaymentInt, newPaymentAmount: number, decimals?: number) => {
  if (!decimals) {
    decimals = 0;
  }
  if (payment.paymentCurrency === payment.foreignCurrency) {
    return newPaymentAmount;
  } else {
    return roundTo((payment.foreignAmount! * newPaymentAmount) / payment.paymentAmount!, decimals);
  }
};

//----------------------------------------------------------------------------------------------------------------------

export const getDealForeignAmount = (deal: { currency: string; amount: number; counterAmount: number }) => {
  if (deal.currency === 'ZAR') {
    return deal.counterAmount!;
  } else {
    return deal.amount!;
  }
};

export const getDealLocalAmount = (deal: { currency: string; amount: number; counterAmount: number }) => {
  if (deal.currency === 'ZAR') {
    return deal.amount!;
  } else {
    return deal.counterAmount!;
  }
};

//----------------------------------------------------------------------------------------------------------------------

export const getMarkupAmount = (
  deal: { currency: string; amount: number; counterAmount: number; bench: number },
  markupPips: number,
  quoteCurrency: string
) => {
  let quoteCurrencyAmount;
  if (quoteCurrency === deal.currency) {
    quoteCurrencyAmount = deal.amount;
  } else {
    quoteCurrencyAmount = deal.counterAmount;
  }
  const baseCurrencyMarkup = Math.round((quoteCurrencyAmount! * markupPips) / 10000);
  if (quoteCurrency === 'ZAR' && deal.bench) {
    return roundTo(baseCurrencyMarkup / deal.bench, 0);
  } else {
    return baseCurrencyMarkup;
  }
};

//----------------------------------------------------------------------------------------------------------------------

export const calculateAvailableOpenDealLimit = (
  limit: number | undefined,
  deals: { currency: string; amount: number; counterAmount: number }[]
): number => {
  if (!limit) {
    limit = 0;
  }
  for (const deal of deals) {
    limit = limit - getDealLocalAmount(deal);
  }
  return limit;
};

//----------------------------------------------------------------------------------------------------------------------
