//@ts-nocheck

import { addDefinition } from '@/db-interface/db_definitions_store';

import {
  Definition as account,
  bankDefinition as bank,
  intermediaryDefinition as intermediary,
  intermediaryBranchDefinition as intermediaryBranch,
  clientDefinition as client,
} from '@/models/LfxAccountMdl';

import { Definition as intermediarySettings } from '@/models/LfxIntermediarySettingsMdl';
import { Definition as test } from '@/models/LdbTestMdl';
import { Definition as application } from '@/models/LfxApplicationMdl';
import { Definition as application_cif } from '@/models/LfxApplicationCifMdl';
import { Definition as application_cif_individual } from '@/models/LfxApplicationCifIndividualMdl';
import { Definition as application_cif_entity } from '@/models/LfxApplicationCifEntityMdl';
import { Definition as application_account } from '@/models/LfxApplicationAccountMdl';

import { Definition as applicationDocument } from '@/models/LfxApplicationDocumentMdl';
import { Definition as applicationDocumentType } from '@/models/LfxApplicationDocumentTypeMdl';

import { Definition as bankAccount } from '@/models/LfxBankAccountMdl';
import { Definition as bankAccountLog } from '@/models/LfxBankAccountLogMdl';
import { Definition as bank_account_commission_level } from '@/models/LfxBankAccountCommissionLevelMdl';

import { Definition as signatory } from '@/models/LfxSignatoryMdl';
import { Definition as signatoryLog } from '@/models/LfxSignatoryLogMdl';
import { Definition as signatoryRequest } from '@/models/LfxSignatoryRequestMdl';
import { Definition as account_markup } from '@/models/LfxAccountMarkup';
import { Definition as accountMarkupLog } from '@/models/LfxAccountMarkupLogMdl';
import { Definition as beneficiary } from '@/models/LfxBeneficiaryMdl';

import { Definition as user } from '@/models/LfxUserMdl';
import { Definition as user_permission } from '@/models/LdbUserPermissionMdl';
import { Definition as user_access_log } from '@/models/LfxUserAccessLogMdl';
import { Definition as emailLog } from '@/models/LdbEmailLogMdl';
import { Definition as userTeam } from '@/models/LfxUserTeamMdl';

import { Definition as transaction } from '@/models/LfxTransactionFileMdl';
import { Definition as transaction_file_log } from '@/models/LfxTransactionFileLogMdl';
import { Definition as deal } from '@/models/LfxDealMdl';
import { Definition as deal_log } from '@/models/LfxDealLogMdl';
import { Definition as failedTreasuryDeal } from '@/models/LfxFailedTreasuryDealMdl';
import { Definition as deal_markup } from '@/models/LfxDealMarkupMdl';
import { Definition as inward_swift } from '@/models/LfxInwardSwiftMdl';
import { Definition as inwardSwiftLog } from '@/models/LfxInwardSwiftLogMdl';
import { Definition as payment } from '@/models/LfxPaymentMdl';
import { Definition as dealPayment } from '@/models/LfxDealPaymentMdl';
import { Definition as payment_message_log } from '@/models/LfxPaymentMessageLogMdl';
import { Definition as client_message_log } from '@/models/LfxClientMessageLogMdl';
import { Definition as payment_portfolio } from '@/models/LfxPaymentPortfolioMdl';
import { Definition as client_portfolio } from '@/models/LfxClientPortfolioMdl';
import { Definition as incomingReport } from '@/models/LfxIncomingReportMdl';

import { Definition as bulkFeeBatch } from '@/models/LfxBulkFeeBatchMdl';
import { Definition as importBatch } from '@/models/LfxImportBatchMdl';

import { Definition as country } from '@/models/LdbCountryMdl';
import { Definition as currency } from '@/models/LdbCurrencyMdl';
import { Definition as currencyPair } from '@/models/LfxCurrencyPairMdl';
import { Definition as publicHoliday } from '@/models/LfxPublicHolidayMdl';
import { Definition as permission } from '@/models/LdbPermissionMdl';
import { Definition as permissionGroup } from '@/models/LdbPermissionGroupMdl';
import { Definition as bopCat } from '@/models/LfxBopCatMdl';
import { Definition as bopCatGroup } from '@/models/LfxBopCatGroupMdl';

import { Definition as config } from '@/models/LfxConfigMdl';
import { Definition as systemSettings} from '@/models/LfxSystemSettingsMdl'

const definitions = {
  test,
  account,
  bank,
  intermediary,
  intermediaryBranch,
  client,
  intermediarySettings,
  application,
  application_cif,
  application_cif_individual,
  application_cif_entity,
  application_account,
  applicationDocument,
  applicationDocumentType,
  client_message_log,
  client_portfolio,
  incomingReport,

  bankAccount,
  bankAccountLog,
  bank_account_commission_level,

  signatory,
  signatoryLog,
  signatoryRequest,
  account_markup,
  accountMarkupLog,
  beneficiary,

  user,
  user_permission,
  user_access_log,
  emailLog,
  userTeam,

  transaction,
  transaction_file_log,
  deal,
  deal_log,
  failedTreasuryDeal,
  deal_markup,
  inward_swift,
  inwardSwiftLog,
  payment,
  dealPayment,
  payment_message_log,
  payment_portfolio,

  bulkFeeBatch,
  importBatch,

  country,
  currency,
  currencyPair,
  publicHoliday,
  permission,
  permissionGroup,
  bopCat,
  bopCatGroup,

  config,
  systemSettings,
};

export default definitions;
