//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxTransactionFileDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Transaction File record for LandoByte TS systems
//  Created     : 11 October 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import {
  intermediaryFieldViewGuard,
  fieldsAreNotTheSameViewGuard,
  displayBranchForListView,
} from '../sharedUtils/LdbDefinitionUtils';
import type { LdbDefinition, LdbViewFunction } from './LdbInterfaces';
import { LfxDealStatus } from './LfxDealDef';
import { LfxPaymentStatus } from './LfxPaymentDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxTransactionFileStatus =
  | 'new'
  | 'awaitingDealRelease'
  | 'awaitingPayment'
  | 'completed'
  | 'rejected'
  | 'cancelled'
  | 'dealConflict'
  | 'paymentConflict'
  | 'merged'
  | 'awaitingFecDrawdown';
export type LfxTransactionFileTransactionType =
  | 'inwardDeal'
  | 'inwardPayment'
  | 'outwardDeal'
  | 'outwardPayment'
  | 'transferDeal'
  | 'transferPayment';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxTransactionFileInt {
  id?: number;
  uuid?: string;
  accountId?: number;
  intermediaryId?: number;
  branchId?: number;
  balancePaymentId?: number;
  mergedIntoTransactionFileId?: number;

  reference?: string;
  externalReference?: string;
  transactionType?: LfxTransactionFileTransactionType;

  status?: LfxTransactionFileStatus;
  paymentCurrency?: string;
  totalPaymentAmount?: number;
  originalValueDate?: Date;
  valueDate?: Date;
  mostRecent?: Date;
  totalDeals?: number;
  activeDeals?: number;
  totalPayments?: number;
  activePayments?: number;

  dealSummary?: { id?: number; dealNumber?: string; status?: LfxDealStatus|'notInThisTransactionFile'; currency?: string; amount?: number }[];
  paymentSummary?: { id?: number; status?: LfxPaymentStatus; paymentAmount?: number }[];

  requiresIntermediary?: boolean;
  requiresBank?: boolean;

  transactionLocked?: boolean;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

export const transactionClientFieldGuard: LdbViewFunction = (viewFunctionObject) => {
  return !viewFunctionObject.routeMetaData?.hideClientAndBranchName
};
export const transactionBranchFieldGuard: LdbViewFunction = (viewFunctionObject) => {
  return !viewFunctionObject.routeMetaData?.hideClientAndBranchName && displayBranchForListView(viewFunctionObject)
};
export const transactionIntermediaryFieldGuard: LdbViewFunction = (viewFunctionObject) => {
  return !viewFunctionObject.routeMetaData?.hideClientAndBranchName && intermediaryFieldViewGuard(viewFunctionObject)
};

//----------------------------------------------------------------------------------------------------------------------

export const LfxTransactionFileDef: LdbDefinition = {
  title: 'Transaction File',
  table: 'LfxTransactionFile',
  collectionPath: '/transaction',
  view: {
    create: false,
    edit: false,
    item: true,
    list: true,
    delete: false,
    csv: false,
    // tiles:{tile:{layout:[['title','arrow'],['number','.'],['.','amount'],['status','currency']]}}
  },
  indexes: ['accountId', 'branchId'],
  pagingType: 'backEnd',
  addToStoreIfNotExist: true,
  groups: {
    summary: { priority: 1, label: 'General' },
    system: { priority: 2, label: 'System' },
    changes: { priority: 3, label: 'Changes' },
  },
  fields: {
    id: {
      label: 'Reference',
      datatype: { id: { descriptionField: 'reference' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'summary',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    accountId: {
      label: 'Client',
      search: true,
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: transactionClientFieldGuard, delete: false, csv: false },
      group: 'summary',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: intermediaryFieldViewGuard,
        list: transactionIntermediaryFieldGuard,
        delete: false,
        csv: false,
      },
      group: 'summary',
    },
    branchId: {
      label: 'Branch',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_branches',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: transactionBranchFieldGuard, delete: false, csv: false },
      group: 'summary',
    },
    balancePaymentId: {
      label: 'Balance Payment',
      datatype: {
        foreignKey: {
          linkTable: 'LfxPayment',
          linkField: 'id',
          displayField: 'reference',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    mergedIntoTransactionFileId: {
      label: 'Merged Into Transaction File',
      datatype: {
        foreignKey: {
          collection: 'fk_transaction_files',

          linkTable: 'LfxTransactionFile',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'summary',
    },
    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      search: true,
      views: {
        create: false,
        edit: false,
        item: false,
        list: false,
        delete: false,
        csv: false,
        // tiles:{tile:'title'}
      },
    },
    externalReference: {
      label: 'External Reference',
      datatype: 'text',
      default: null,
      search: true,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'summary',
    },
    transactionType: {
      label: 'Transaction Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'inwardDeal', name: 'Inward Deal' },
            { id: 'inwardPayment', name: 'Inward Payment' },
            { id: 'outwardDeal', name: 'Outward Deal' },
            { id: 'outwardPayment', name: 'Outward Payment' },
            { id: 'transferDeal', name: 'Transfer Deal' },
            { id: 'transferPayment', name: 'Transfer Payment' },
          ],
        },
      },
      default: 'inwardDeal',
      mandatory: true,
      allowNullValues: false,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'summary',
    },
    status: {
      label: 'Transaction File Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New', tagType: 'info' },
            { id: 'awaitingDealRelease', name: 'Awaiting Deal Release', tagType: 'warning' },
            { id: 'awaitingPayment', name: 'Awaiting Payment', tagType: 'info' },
            { id: 'completed', name: 'Completed', tagType: 'success' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
            { id: 'cancelled', name: 'Cancelled', tagType: 'cancelled' },
            { id: 'dealConflict', name: 'Deal Conflict', tagType: 'error' },
            { id: 'paymentConflict', name: 'Payment Conflict', tagType: 'error' },
            { id: 'merged', name: 'Merged', tagType: 'cancelled' },
            { id: 'awaitingFecDrawdown', name: 'Awaiting Fec Drawdown', tagType: 'info'}
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'summary',
    },
    consolidatedStatus: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New', tagType: 'info' },
            { id: 'awaitingDealRelease', name: 'Awaiting Deal Release', tagType: 'warning' },
            { id: 'awaitingPayment', name: 'Awaiting Payment', tagType: 'info' },
            { id: 'completed', name: 'Completed', tagType: 'success' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
            { id: 'cancelled', name: 'Cancelled', tagType: 'cancelled' },
            { id: 'dealConflict', name: 'Deal Conflict', tagType: 'error' },
            { id: 'paymentConflict', name: 'Payment Conflict', tagType: 'error' },
            { id: 'merged', name: 'Merged', tagType: 'cancelled' },
            { id: 'awaitingFecDrawdown', name: 'Awaiting Fec Drawdown', tagType: 'info'},
            { id: 'dealNotSettled', name: 'Deal Not Settled before VD', tagType: 'error'},

            // Payment Statuses
            { id: 'bopCatAmountConflict', name: 'BOP Cat Amount Conflict', tagType: 'warning' },
            { id: 'paymentNew', name: 'Payment not Captured', tagType: 'info' },
            { id: 'readyForSubmission', name: 'Ready For Submission', tagType: 'info' },
            { id: 'submitted', name: 'Portfolio Submitted', tagType: 'warning' },
            { id: 'readyForSigning', name: 'Ready For Signing', tagType: 'info' },
            { id: 'signed', name: 'Awaiting Bank Validation', tagType: 'warning' },
            { id: 'awaitingSettlement', name: 'Awaiting Settlement', tagType: 'warning' },
            { id: 'settled', name: 'Settled', tagType: 'warning' },
            { id: 'complete', name: 'Completed', tagType: 'success' },
            { id: 'informationQuery', name: 'Information Query', tagType: 'warning' },
            { id: 'awaitingFunds', name: 'Awaiting Funds', tagType: 'info' },
            { id: 'recallRequested', name: 'Recall Requested', tagType: 'warning' },
            { id: 'cancelled', name: 'Cancelled', tagType: 'cancelled' },
            { id: 'deleted', name: 'Deleted', tagType: 'cancelled' },

          ],
        },
      },
      property: { read: true, write: false, source: 'frontend', sort: 'status' },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'summary',
    },
    paymentCurrency: {
      label: 'Currency',
      datatype: {
        foreignKey: {
          collection: 'fk_currencies',

          linkTable: 'LdbCurrency',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'summary',
    },
    totalPaymentAmount: {
      label: 'Amount',
      datatype: { currency: { symbol: '' } },
      default: 0,
      mandatory: true,
      allowNullValues: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: true,
        delete: false,
        csv: false,
        tiles: { default: 'infoValue' },
      },
      group: 'summary',
    },
    dealNumbers: {
      label: 'Deals',
      datatype: 'text',
      default: null,
      property:{read:true,write:false,source:'frontend', sort: 'none'},
      mandatory:false,
      views: {
        create: false,
        edit: false,
        item: false,
        list: true,
        delete: false,
        search: true,
        csv: false,
      },
    },

    originalValueDate: {
      label: 'Original Value Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      guards: { valueDate: { notEq: 'originalValueDate' } },
      views: { create: false, edit: false, item: true, list: false, delete: false, search: true, csv: false },
      group: 'summary',
    },
    valueDate: {
      label: 'Value Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
        },
      },
      displayType: {
        displayAsTag: true,
        tagTypeFunctionName: 'valueDateTagType',
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'summary',
    },
    totalDeals: {
      label: 'Total Deals',
      datatype: 'integer',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: {
        create: false,
        edit: false,
        item: fieldsAreNotTheSameViewGuard('totalDeals', 'activeDeals'),
        list: false,
        delete: false,
        csv: false,
      },
      group: 'summary',
    },
    activeDeals: {
      label: 'Active Deals',
      datatype: 'text',
      displayType: {
        displayAsTag: true,
        tagTypeFunctionName: 'activeDealsTagType',
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'summary',
    },
    totalPayments: {
      label: 'Total Payments',
      datatype: 'integer',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: {
        create: false,
        edit: false,
        item: fieldsAreNotTheSameViewGuard('totalPayments', 'activePayments'),
        list: false,
        delete: false,
        csv: false,
      },
      group: 'summary',
    },
    activePayments: {
      label: 'Active Payments',
      datatype: 'integer',
      displayType: {
        displayAsTag: true,
        tagTypeFunctionName: 'activePaymentsTagType',
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'summary',
    },

    dealSummary: {
      label: 'Deal Summary',
      datatype: {
        json: {
          structure: [
            {
              id: 'number',
              dealNumber: 'string',
              status: 'string',
              currency: 'string',
              amount: 'number',
            },
          ],
        },
      },
      default: [],
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'summary',
    },
    paymentSummary: {
      label: 'Payment Summary',
      datatype: {
        json: {
          structure: [
            {
              id: 'number',
              status: 'string',
              paymentAmount: 'number'
            },
          ],
        },
      },
      default: [],
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'summary',
    },

    requiresIntermediary: {
      label: 'Requires Intermediary Or Client Input',
      datatype: 'boolean',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    requiresBank: {
      label: 'Requires Bank Input',
      datatype: 'boolean',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    transactionLocked: {
      label: 'Transaction Locked',
      datatype: 'boolean',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    mostRecent: {
      label: 'Most Recent',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'changes',
    },

    createdAt: {
      label: 'Initiated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};
