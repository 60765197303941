//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : lfx_action.ts
//  Author      : Bevan Timm
//  Description : This module implements LdbActions for LandoByte Vue systems
//  Created     : 15 March 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import axios, { AxiosError, AxiosResponse } from 'axios';
var Mustache = require('mustache');

import ACTIONS from '@/definitions/actions';
import { ENV_VARIABLES } from '@/store/config_env';
import { LdbAPIAction, LdbApiResponse } from '@/definitions/actions/LdbActionTypes';
import { lfx_auth_get_mfa_token } from '@/store/lfx_auth';
import store from '@/store';
import { db_assign_object_to_state } from '@/db-interface/db_state';
import { LdbSelect } from '@/definitions/LdbInterfaces';
const APIURL = () => ENV_VARIABLES.VUE_REST_API_URL;

//----------------------------------------------------------------------------------------------------------------------

const actionContainsFileUpload = (action: keyof typeof ACTIONS) => {
  let result = false;
  let actionParams = (ACTIONS[action] as LdbAPIAction).params;
  for (let p in actionParams) {
    let param = actionParams[p];
    if (param.format === 'binary') {
      result = true;
      break;
    }
  }
  return result;
};

export const doPermissionAction = async (vueObject: any, action: keyof typeof ACTIONS, pathParams: any, params: any): Promise<{ status: 'success' | 'failed'; response: any; message?: string }|undefined> => { //TODO remove the undefined return when we have some space
  let actionConfig = ACTIONS[action] as LdbAPIAction;
  let headers: any = { ...store.getters.authHeaders };
  if (actionContainsFileUpload(action)) {
    headers['Content-Type'] = 'multipart/form-data';
  }
  if (actionConfig.mfa === true && vueObject.showLoader) {
    vueObject.showLoader(false);
    //@ts-expect-error
    let getMFATokenResult = await lfx_auth_get_mfa_token(store, {
      requestedAction: action,
      messageText: 'string' //TODO
    });
    vueObject.showLoader(true);
    if (getMFATokenResult.result === 'success') {
      headers.mfaToken = getMFATokenResult.mfaToken;
      return await server_call(vueObject, actionConfig, replacedPath(actionConfig, pathParams), params, headers);
    } else {
      vueObject?.showToast?.({
        type: 'error',
        header: 'MFA Failed',
        //@ts-expect-error
        message: getMFATokenResult.response.message,
        show: true
      });
      vueObject.showLoader(false);
    }
  } else {
    return await server_call(vueObject, actionConfig, replacedPath(actionConfig, pathParams), params, headers);
  }
};

const replacedPath = (apiAction: LdbAPIAction, params: any) => {
  let path = apiAction.path.replace(/{/g, '{{').replace(/}/g, '}}');
  return Mustache.render(path, params);
};

//----------------------------------------------------------------------------------------------------------------------

export async function performContextAction(
  context: any,
  actionInfo: {
    action: keyof typeof ACTIONS;
    pathParams: { [field: string]: string | number };
    params: LdbSelect;
  }
) {
  const result = await doPermissionAction(context, actionInfo.action, actionInfo.pathParams, {additionalSelector:actionInfo.params});
}
export async function multiLoadPatchContent(
  context: any,
  actionInfo: {
    action: keyof typeof ACTIONS;
    pathParams: { [field: string]: string | number };
    additionalSelectorObject: LdbSelect;
  }
): Promise<{ status: 'success' | 'failed'; response: any; message?: string }|undefined> { //TODO remove the undefined return when we have some space
  if (!actionInfo.additionalSelectorObject.limit) {
    actionInfo.additionalSelectorObject.limit = 500
  }
  if (!actionInfo.additionalSelectorObject.offset) {
    actionInfo.additionalSelectorObject.offset = 0
  }
  const result = await doPermissionAction(context, actionInfo.action, actionInfo.pathParams, {additionalSelector:actionInfo.additionalSelectorObject});
  if (
      result?.response?.rows?.length === actionInfo.additionalSelectorObject.limit && 
      (actionInfo.additionalSelectorObject.limit + actionInfo.additionalSelectorObject.offset < 10000) //Import a maximum of of any type
    ) {
      actionInfo.additionalSelectorObject.offset += actionInfo.additionalSelectorObject.limit;
      return await multiLoadPatchContent(context,actionInfo)
  } else {
    return result
  }
};

//----------------------------------------------------------------------------------------------------------------------

const server_call = async (
  vueObject: any,
  actionConfig: LdbAPIAction,
  path: string,
  body: any,
  headers: any
): Promise<{ status: 'success' | 'failed'; response: any; message?: string; name?: string }> => {
  let url = `${APIURL()}${path}`;
  let params = { ...body };
  removeNullsAndUndefineds(params);
  let config = { headers: headers };
  if (actionConfig?.responses?.[200]?.type === 'download') {
    //@ts-ignore
    config.responseType = 'blob';
  }
  try {
    let http_res: AxiosResponse<any>;
    switch (actionConfig.method) {
      case 'get':
        http_res = await axios.get(url, config);
        break;
      case 'put':
        http_res = await axios.put(url, params, config);
        break;
      case 'post':
        if (headers['Content-Type'] === 'multipart/form-data') {
          const formData = new FormData();
          for (let p in params) {
            formData.append(p, params[p]);
          }
          http_res = await axios.post(url, formData, config);
        } else {
          http_res = await axios.post(url, params, config);
        }
        break;
      case 'patch':
        http_res = await axios.patch(url, params, config);
        break;
    }
    let data = http_res.data;
    // let status: 'success' | 'failed' = http_res.status === 200 ? 'success' : 'failed';
    const responseCodeString = http_res.status.toString();
    const responseCodeNumber = +responseCodeString as keyof LdbApiResponse;
    let status: 'success' | 'failed' = responseCodeString.substring(0, 1) === '2' ? 'success' : 'failed';
    const response = { status, response: data, code: responseCodeNumber, headers: http_res.headers };
    if (status === 'success') {
      if (vueObject.onSuccess?.onBeforeStoreUpdate) {
        vueObject.onSuccess.onBeforeStoreUpdate(response);
      }
      if (!vueObject.onSuccess || vueObject.onSuccess.doStoreUpdate) {
        if (data.rows && Array.isArray(data.rows)) {
          for (const row of data.rows) {
            db_assign_object_to_state(store, row, true);
          }
        } else if (Array.isArray(data)) {
          for (const row of data) {
            db_assign_object_to_state(store, row, true);
          }
        } else {
          db_assign_object_to_state(store, data, true);
        }
      }
      if (vueObject.onSuccess?.onAfterStoreUpdate) {
        vueObject.onSuccess.onAfterStoreUpdate(response);
      }
      vueObject?.$emit?.('on-api-success', response);
      if (actionConfig?.responses?.[responseCodeNumber]?.message || (actionConfig?.responses?.[responseCodeNumber]?.messageSource === 'response' && data.message)) {
        let duration = actionConfig?.responses?.[responseCodeNumber]?.duration
          ? actionConfig?.responses?.[responseCodeNumber]?.duration
          : 5000; //TODO return after testing
          // : true;
        let header = actionConfig?.responses?.[responseCodeNumber]?.header
          ? actionConfig?.responses?.[responseCodeNumber]?.header
          : 'Success';
        vueObject?.showToast?.({
          type: 'success',
          header: header,
          message: actionConfig?.responses?.[responseCodeNumber]?.message || data.message,
          show: duration
        });
      }
    }
    return response;
  } catch (rawErr) {
    const err = rawErr as AxiosError;
    let default_message = 'An unknown error has ocurred, please notify the developers.';
    let message = default_message;
    let name = undefined;
    let responseDescription = 'Error';
    let forceToast = false;
    if (err.message === "Network Error") {
      forceToast = true;
      message = "The Capitec Forex back end could not be reached. Please check your internet connection and try again."
    } else if (err.code === "ECONNABORTED" || err.message === "Request failed with status code 504") {
      forceToast = true;
      message = "The connection to the Capitec Forex back end timed out. Please check your internet connection and try again."
    } else if (err.response?.data?.systemState === 'accessDisabled') {
      forceToast = true;
      message = "Access to Capitec Forex has been disabled"
    } else if ((err as AxiosError).response) {
      const errorResponse = (err as AxiosError).response;
      if (errorResponse?.status === 524 || errorResponse?.status === 504) {
        const errorReportObject = {url:errorResponse.config.url,...errorResponse.data}
        void sendFrontEndError(524,'Timeout ',errorReportObject)
        message = "The connection to the Capitec Forex back end timed out. Please check your internet connection and try again."      
      }
      let responseData = errorResponse?.data;
      if (responseData instanceof Blob) {
        responseData = await (responseData as Blob).text()
        try {
          responseData = JSON.parse(responseData)
        } catch {}
      }
      name = responseData?.name;
      message = responseData?.message
        ? responseData?.message
        : responseData?.validationError
        ? responseData?.validationError?.[0]?.message
        : responseData?.error
        ? responseData?.error?.code +
          ': ' +
          responseData?.error?.codeResaon +
          ' - ' +
          responseData?.error?.message
        : default_message;
      let responseCode = errorResponse?.status;
      responseDescription = actionConfig?.responses?.[responseCode! as keyof typeof actionConfig.responses]?.header
        ? `${actionConfig?.responses?.[responseCode! as keyof typeof actionConfig.responses]?.header}`
        : 'Error';
    }
    if (message === default_message) {
      if (err.toJSON) {
        console.log("UnknownError",err.toJSON())
      } else {
        console.log("UnknownError",err)
      }
    }
    if (vueObject?.showToast || forceToast) {
      vueObject?.showToast?.({ type: 'error', header: responseDescription, message: message, show: true });
    }
    vueObject?.$emit?.('on-api-error', { status: 'failed', response: err, message: message });
    return { status: 'failed', response: err, message: message, name: name };
  }
};
const removeNullsAndUndefineds = (params: any) => {
  for (const key of Object.keys(params)) {
    if (params[key] === null || params[key] === undefined) {
      delete params[key];
    }
  }
};

// TODO MAKE THIS WORK
const sendFrontEndError = async (errorCode:number,message:string,object:any)  => {
  console.log("Sending Front End Error")

  try {
    const APIURL = ENV_VARIABLES.VUE_REST_API_URL;
    const path = '/admin/1/logError'
    let url = `${APIURL}${path}`;
  
    let headers = { ...store.getters.authHeaders };
    let config = { headers: headers }
    const http_res = await axios.put(url, {
      errorType: errorCode + ' - ' + message,
      errorReport: object
    }, config);
    let data = http_res.data;
    let status = http_res.status;
    return { status, response: data };
  } catch (err) {
    console.log("sendFrontEndError err",err)
  }
}

export const formatReturnedError = (error:AxiosError|undefined) => {
  let message = "Communication Error";
  if (error) {
    if (error.message) {
      message = error.message;
    }
    //@ts-expect-error
    const data = error.response?.response.data || error.response?.data || error.data
    if (data) {
      if (data.message) {
        message = data.message
      }
      if (data.codeReason) {
        message = data.codeReason + ' - ' + message;
      }
      if (data.code) {
        message = data.code + ' - ' + message;
      }  
    }
  }
  return message
}