import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import { LfxDealInt, LfxDealDef } from '@/definitions/LfxDealDef';
import { LfxModel, LfxDefinition } from './LfxModel';

//@ts-ignore
import { http_patch, http_get, http_put } from '@/lfx_rest';
import { calculateMarkupFromRates, getCurrencyType, getDealNumberPrefix } from '@/sharedUtils/LfxTradeCalc';
import { formatCurrencyString } from '@/sharedUtils/LdbSharedUtils';

// @ts-ignore
@VueStore
//@ts-ignore
class LfxDealMdl extends LfxDefinition {
  //@ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

// @ts-ignore
@VueStore
//@ts-ignore
export class LfxDeal extends LfxModel {
  //@ts-ignore
  constructor(data, state) {
    super({ definition_name: 'deal', ...data }, state);
    //@ts-ignore
  }

  get link() {
    // @ts-expect-error
    return `/account/${this.accountId}/transaction/${this.transactionFileId}/deal/${this.id}`;
  }

  get valueDateAsDateString() {
    // @ts-expect-error
    return this.valueDate?.substring(0, 10).replaceAll('/', '-') || "";
  }
  get isValueDateToday() {
    // @ts-expect-error
    return this.store.state.systemTodayAsString === this.valueDateAsDateString ? true : false;
  }
  get isValueDateInThePast() {
    // @ts-expect-error
    return new Date(this.store.state.systemTodayAsString) > new Date(this.valueDateAsDateString) ? true : false;
  }

  get valueDateTagType() {
    // @ts-expect-error
    switch (this.status) {
      case 'tfCompleted':
        return 'success';
      case 'rejected':
      case 'superseded':
      case 'fecDrawnDown':
      case 'cancelled':
        return 'cancelled';
      // case 'dealConflict':
      //   return 'warning'; // Deal can not have status of dealConflict
      default:
        // // @ts-expect-error
        if (this.isValueDateInThePast) {
          return 'error';
        } else if (this.isValueDateToday) {
          return 'info';
        } else {
          return undefined;
        }
    }
  }
  get bankAction() {
    // @ts-expect-error
    if (this.action === 'Buy') {
      return 'Sell';
    } else {
      return 'Buy';
    }
  }
  get actionDisplay() {
    // @ts-expect-error
    if (this.store.state.authUser.accountLevel === 'bank') {
      // @ts-expect-error
      if (this.action === 'Buy') {
        return 'Sell';
      } else {
        return 'Buy';
      }
    }
    // @ts-expect-error
    return this.action;
  }

  get resendDealButtonGuard() {
    return (
      // @ts-expect-error
      (this.status === 'rejected' || this.status === 'prepared') &&
      // @ts-expect-error
      this.deliveryType !== 'FWD' &&
      this.hasPermission.resendDeal
    );
  }
  get fecModificationIsReleaseable() {
    return (
      // @ts-expect-error
      this.deliveryType === 'FWD' &&
      // @ts-expect-error
      this.status === 'fecModificationRequested' &&
      this.hasPermission.fecReleaseModification
    );
  }
  get unCommittedPaymentAmount () {
    // @ts-expect-error
    return this.paymentAmount - this.committedPaymentAmount
  }
  get isPartiallyUsed() {
    // @ts-expect-error
    return (this.committedPaymentAmount !== 0) && (this.committedPaymentAmount !== this.paymentAmount) && (this.committedPaymentAmount !==  (- 1 * this.paymentAmount))
  }
  get isPartialDrawdown() {
    //@ts-expect-error
    if (!this.supersedesDealId) {
      return false;
    }
    //@ts-expect-error
    return this.store.state.deal?.[this.supersedesDealId]?.transactionFileId !== this.transactionFileId;
  }
  get activePartialDrawdownAmount() {
    if (this.activePartialDrawdownDeal) {
      return this.activePartialDrawdownDeal.currency + ' ' + formatCurrencyString(this.activePartialDrawdownDeal.amount);
    }
  }
  get activePartialDrawdownDeal() {
    for (const dealId in this.partialDrawdownDeals) {
      //@ts-expect-error
      if (['prepared', 'requested'].includes(this.partialDrawdownDeals[dealId].status)) {
        //@ts-expect-error
        return this.partialDrawdownDeals[dealId];
      }
    }
    return undefined;
  }
  get partialDrawdownDeals() {
    const res = {};
    //@ts-expect-error
    if (this.forwardModificationType === 'partialDrawdown') {
      //@ts-expect-error
      for (const dealId in this.store.state.deal) {
        if (
          //@ts-expect-error
          this.store.state.deal[dealId].supersedesDealId === this.id &&
          //@ts-expect-error
          this.store.state.deal[dealId].transactionFileId !== this.transactionFileId
        ) {
          //@ts-expect-error
          res[dealId] = this.store.state.deal[dealId];
        }
      }
    }
    return res;
  }
  get releaseFecModificationButtonGuard() {
    return (
      this.fecModificationIsReleaseable &&
      // @ts-expect-error
      this.store.state.authUser.id === this.bankDealerUserId
    );
  }
  get fecReadyForDrawdown() {
    return (
      // @ts-expect-error
      ['released'].includes(this.status) &&
      // @ts-expect-error
      this.deliveryType === 'FWD' &&
      // @ts-expect-error
      getDealNumberPrefix(this.dealNumber) &&
      this.valueDateIsSpotOrBeyond
    );
  }
  get valueDateIsSpotOrBeyond() {
    //@ts-expect-error
    const valueDate = new Date(this.valueDate);
    const currencyPairSpotDate = new Date(this.currencyPairRecord.spotDate);
    return currencyPairSpotDate >= valueDate;
  }
  get dealIsReleaseable() {
    // @ts-expect-error
    return ['requested', 'processed', 'booked'].includes(this.status) && this.hasPermission.releaseDeal;
  }
  get claimDealButtonGuard() {
    return (
      (this.dealIsReleaseable || this.fecModificationIsReleaseable) &&
      // @ts-expect-error
      this.store.state.authUser.id !== this.bankDealerUserId &&
      !this.isPartialDrawdown
    );
  }
  get releaseDealButtonGuard() {
    // @ts-expect-error
    return this.dealIsReleaseable && this.store.state.authUser.id === this.bankDealerUserId;
  }
  get updateDealSettlementAccountButtonGuard() {
    return (
      //@ts-expect-error
      this.status === 'released' &&
      //@ts-expect-error
      (this.initiatingInterface === 'bank' || (this.deliveryType === 'FWD' || this.dealNumber?.substring(0,3) === '171')) &&
      this.hasPermission.updateDealSettlementAccount
    );
  }
  get modifyValueDateGuard() {
    // @ts-expect-error
    return this.status === 'released' && this.hasPermission.releaseDeal;
  }
  get drawDownFecGuard() {
    return (
      // @ts-expect-error
      this.status === 'released' &&
      // @ts-expect-error
      this.deliveryType === 'FWD' &&
      // @ts-expect-error
      getDealNumberPrefix(this.dealNumber) === '169' &&
      this.hasPermission.drawdownFec
    );
  }
  get rejectDealButtonGuard() {
    return (
      // @ts-expect-error
      !['rejected', 'cancelled', 'fecDrawnDown', 'superseded', 'tfCompleted'].includes(this.status) &&
      this.hasPermission.releaseDeal
    );
  }
  get paymentAmount() {
    // @ts-expect-error
    if (this.action === 'Buy') {
      // @ts-expect-error
      if (this.dealType === 'buyOut') {
        // @ts-expect-error
        return -1 * this.counterAmount!;
      } else {
        // @ts-expect-error
        return this.amount;
      }
    } else {
      // @ts-expect-error
      if (this.dealType === 'buyOut') {
        // @ts-expect-error
        return -1 * this.amount!;
      } else {
        // @ts-expect-error
        return this.counterAmount;
      }
    }
  }
  get tileSubTitle() {
    // @ts-expect-error
    if (this.dealType === 'buyOut') {
      return "Buy Out"
    }
    // @ts-expect-error
    return this.dealNumber
  }
  get consolidatedStatus() {
    //@ts-expect-error
    return this.status;
  }
  get currencyType() {
    //@ts-expect-error
    return getCurrencyType(this.currency, this.currencyPairRecord);
  }
  get intermediaryMarkup() {
    //@ts-expect-error
    if (this.bankRate && this.clientRate) {
      //@ts-expect-error
      return calculateMarkupFromRates(this.bankRate, this.clientRate, this.currencyType, this.action);
    }
  }
  get bankMarkup() {
    //@ts-expect-error
    if (this.bench && this.bankRate) {
      //@ts-expect-error
      return calculateMarkupFromRates(this.bench, this.bankRate, this.currencyType, this.action);
    }
  }
  get statusText() {
    // @ts-expect-error
    for (const field of this.definition.fields) {
      if (field.field === 'status') {
        if (field.datatype?.option?.options) {
          for (const option of field.datatype.option.options) {
            // @ts-expect-error
            if (option.id === this.status) {
              return option.name;
            }
          }
        }
      }
    }
    // @ts-expect-error
    return this.status;
  }
  get currencyPairRecord() {
    // @ts-expect-error
    return this.store.state.config[1].currencyPair[this.currencyPair];
  }
  get quoteCurrency() {
    const currencyPair = this.currencyPairRecord;
    return currencyPair.quoteCurrency;
  }
}

function guardFecOnCapture(authUser: any, view: string, dealObject: any) {
  return dealObject?.deliveryType === 'FWD';
}
//@ts-ignore
export function Definition(context, name: string) {
  return new LfxDealMdl(LfxDealDef, LfxDeal, context, name, {
    fields: {
      currencyPair: { write: true, views: { offlineDealCapture: true, offlineDealCaptureReview: true } },
      currency: { write: true, views: { offlineDealCapture: true, offlineDealCaptureReview: true } },
      counterCurrency: { write: true, views: { offlineDealCaptureReview: true } },
      action: { write: true, views: { offlineDealCapture: true, offlineDealCaptureReview: true } },
      deliveryType: { write: true, views: { offlineDealCapture: true, offlineDealCaptureReview: true } },
      forwardContractType: {
        write: true,
        views: { offlineDealCapture: guardFecOnCapture, offlineDealCaptureReview: true },
      },
      optionStartDate: { write: true, views: { offlineDealCapture: guardFecOnCapture, offlineDealCaptureReview: true } },
      valueDate: { write: true, views: { offlineDealCapture: guardFecOnCapture, offlineDealCaptureReview: true } },
      amount: { write: true, views: { offlineDealCapture: true, offlineDealCaptureReview: true } },
      counterAmount: { write: true, views: { offlineDealCaptureReview: true } },
      spotRate: { views: { offlineDealCaptureReview: true } },
      bench: { views: { offlineDealCaptureReview: true } },
      bankRate: { write: true, views: { offlineDealCapture: true, offlineDealCaptureReview: true } },
      clientRate: { write: true, views: { offlineDealCapture: true, offlineDealCaptureReview: true } },
      forwardPoints: { write: true, views: { offlineDealCapture: guardFecOnCapture, offlineDealCaptureReview: true } },
      fromAccountNumber: {
        label: 'From Account Number',
        datatype: 'text',
        default: null,
        mandatory: false,
        views: { write: true, offlineDealCapture: true, offlineDealCaptureReview: true },
        group: 'treasury',
      },
      toAccountNumber: {
        label: 'To Account Number',
        datatype: 'text',
        default: null,
        mandatory: false,
        views: { write: true, offlineDealCapture: true, offlineDealCaptureReview: true },
        group: 'treasury',
      },

      dealNumber: { views: { releaseDeal: true, offlineDealCapture: true, offlineDealCaptureReview: true } },
      buyOutDealNumber: { views: { rejectDealNotSettled: true, } },
      supersededByDealNumber: { views: { rejectDealNotSettled: true, } },
      rejectionReason: { views: { rejectDeal: true, rejectDealNotSettled: true, } },
      inwardSwiftId: {
        label: 'Inward SWIFT',
        datatype: {
          foreignKey: {
            collection: 'fk_inward_swifts',
            linkTable: 'LfxInwardSWIFT',
            linkField: 'id',
            displayField: 'swiftNumber',
            linkInSql: false,
          },
        },
        default: null,
        mandatory: false,
        allowNullValues: true,
        views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      },

      setMarkup: {
        label: 'Deal Markup',
        datatype: 'float',
        default: null,
        mandatory: false,
        allowNullValues: false,
        validators: {
          isNumeric: { msg: 'Pips should be numeric' },
        },
        views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      },
    },
  });
}
