import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition, LdbOptionType } from '@/definitions/LdbInterfaces';
import { LfxSignatoryRequestInt, LfxSignatoryRequestDef } from '@/definitions/LfxSignatoryRequestDef';
import { LfxModel, LfxDefinition } from './LfxModel';

//@ts-ignore
import { http_patch, http_get, http_put } from '@/lfx_rest';

@VueStore
//@ts-ignore
class LfxSignatoryRequestMdl extends LfxDefinition {
  //@ts-ignore
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

@VueStore
//@ts-ignore
export class LfxSignatoryRequest extends LfxModel {
  //@ts-ignore
  constructor(data, state) {
    super({ definition_name: 'signatoryRequest', ...data }, state);
  }

  get link() {
    // @ts-ignore
    return `/account/${this.accountId}/signatory/${this.signatoryId}/request/${this.id}`;
  }
}

//@ts-ignore
export function Definition(context, name: string) {
  return new LfxSignatoryRequestMdl(LfxSignatoryRequestDef, LfxSignatoryRequest, context, name, {});
}
