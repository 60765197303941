//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2022 LandoByte (Pty) Ltd.
//  File        : LfxImportBatchDef.ts
//  Author      : Bevan Timm
//  Description : This module defines an Import Batch record for LandoByte TS systems
//  Created     : 03 June 2022 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition } from '../definitions/LdbInterfaces';
import { displayBranchForListView, intermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';

//----------------------------------------------------------------------------------------------------------------------

export type LfxImportBatchStatus = 'new' | 'inProgress' | 'completed' | 'error' | 'cancelled';
export type LfxImportBatchImportType =
  | 'combined'
  | 'intermediary'
  | 'branch'
  | 'client'
  | 'individualId'
  | 'accountMarkup'
  | 'user'
  | 'signatory'
  | 'bankAccount'
  | 'transactionFile'
  | 'deal'
  | 'dealMarkup'
  | 'payment'
  | 'bopCat'
  | 'inwardSwift'
  | 'uploadOnly'
  | 'individualSignatory'
  | 'mainContact'
  | 'movedAccount'
  | 'createBeneficiaries'
  | 'clientRemediation'
  | 'dealManualProcess'
  | 'odinCredentialsCapture'
  | 'clientDisable' 
  | 'bankAccountDisable';
  export type LfxImportBatchImportPurpose = "migration"|"bulkCreate"|"bulkUpdate"

//----------------------------------------------------------------------------------------------------------------------

export interface LfxImportBatchInt {
  id?: number;
  uuid?: string;
  reference?: string;
  combinedBatchId?: number;
  accountId?: number;
  intermediaryId?: number;
  branchId?: number;

  status?: LfxImportBatchStatus;
  importType?: LfxImportBatchImportType;
  importPurpose?: LfxImportBatchImportPurpose;
  splitString?: string;
  batchOptions?:any;
  originalFileName?: string;
  s3Bucket?: string;
  uploadFileS3Key?: string;
  errorFileS3Key?: string;
  originalFileSize?: number;
  totalLineCount?: number;
  processedLineCount?: number;
  errorLineCount?: number;

  uploadedTimestamp?: Date;
  processStartTimestamp?: Date;
  lastUpdateTimestamp?: Date;
  completeTimestamp?: Date;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxImportBatchDef: LdbDefinition = {
  title: 'Import Batch',
  table: 'LfxImportBatch',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  actions: {
    list: 'getImportBatches',
    item: 'getImportBatch',
  },
  collectionPath: '/importBatch',
  addToStoreIfNotExist: true,
  pagingType: 'backEnd',
  indexes: ['branchId','accountId'],
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'reference' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    combinedBatchId: {
      label: 'Combined Batch',
      datatype: {
        foreignKey: {
          linkTable: 'LfxImportBatch',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_clients',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Client',
        },
      },
      default: null,
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'system',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Intermediary',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: intermediaryFieldViewGuard,
        list: intermediaryFieldViewGuard,
        delete: false,
        csv: true,
      },
      group: 'system',
    },
    branchId: {
      label: 'Branch',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_branches',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: displayBranchForListView, delete: false, csv: false },
      group: 'summary',
    },

    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New', tagType: 'info' },
            { id: 'inProgress', name: 'In Progress', tagType: 'warning' },
            { id: 'completed', name: 'Completed', tagType: 'success' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
    },
    importType: {
      label: 'Import Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'combined', name: 'Combined', tagType: 'success', guards: false },
            { id: 'intermediary', name: 'Intermediary Migration', tagType: 'info', guards: false },
            { id: 'branch', name: 'Branch Migration', tagType: 'info', guards: false },
            { id: 'client', name: 'Client Migration', tagType: 'info', guards: false },
            { id: 'individualId', name: 'Individual ID Details Migration', tagType: 'info', guards: false },
            { id: 'accountMarkup', name: 'Account Markups Migration', tagType: 'info', guards: false },
            { id: 'user', name: 'User Migration', tagType: 'info', guards: false },
            { id: 'signatory', name: 'Signatory Migration', tagType: 'info', guards: false },
            { id: 'bankAccount', name: 'Bank Account Migration', tagType: 'info', guards: false },
            { id: 'transactionFile', name: 'Transaction File Migration', tagType: 'info', guards: false },
            { id: 'deal', name: 'Deal Migration', tagType: 'info', guards: false },
            { id: 'dealMarkup', name: 'Deal Markup Migration', tagType: 'info', guards: false },
            { id: 'payment', name: 'Payment Migration', tagType: 'info', guards: false },
            { id: 'bopCat', name: 'Multiple BOP Categories Migration', tagType: 'info', guards: false },
            { id: 'inwardSwift', name: 'Inward Swift Migration', tagType: 'info', guards: false },
            { id: 'uploadOnly', name: 'Upload Only Migration', tagType: undefined, guards: false },
            { id: 'individualSignatory', name: 'Individual Signatory Migration', tagType: 'info', guards: false },
            { id: 'mainContact', name: 'Main Contact Migration', tagType: 'info', guards: false },
            { id: 'movedAccount', name: 'Moved Accounts Migration', tagType: 'info', guards: false },

            { id: 'createBeneficiaries', name: 'Bulk Create Beneficiaries', tagType: 'info'},
            { id: 'clientRemediation', name: 'Client Remediation', tagType: 'info'},
            { id: 'dealManualProcess', name: 'Bulk Manual Deal Process', tagType: 'info'},
            { id: 'odinCredentialsCapture', name: 'Bulk Capture Odin OAuth Credentials', tagType: 'info'},
            { id: 'clientDisable', name: 'Bulk Disable Clients', tagType: 'info'},
            { id: 'bankAccountDisable', name: 'Bulk Disable Capitec BB Bank Accounts', tagType: 'info'},
          ],
        },
      },
      default: 'active',
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
    },
    importPurpose: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'migration', name: 'Migration', tagType: 'info' },
            { id: 'bulkCreate', name: 'Bulk Create', tagType: 'info' },
            { id: 'bulkUpdate', name: 'Bulk Update', tagType: 'info' },
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
    },

    splitString: {
      label: 'Split String',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    batchOptions: {
      label: 'Batch Options',
      datatype: {json:{}},
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    originalFileName: {
      label: 'Original File Name',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    s3Bucket: {
      label: 'Specified S3 Bucket',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    uploadFileS3Key: {
      label: 'Upload File S3Key',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    errorFileS3Key: {
      label: 'Error File S3Key',
      datatype: 'text',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    originalFileSize: {
      label: 'Original File Size',
      datatype: 'integer',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },

    totalLineCount: {
      label: 'Total Line Count',
      datatype: 'integer',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    processedLineCount: {
      label: 'Processed Line Count',
      datatype: 'integer',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    errorLineCount: {
      label: 'Error Line Count',
      datatype: 'integer',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    uploadedTimestamp: {
      label: 'Uploaded Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    processStartTimestamp: {
      label: 'Process Started Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    lastUpdateTimestamp: {
      label: 'Last Updated Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    completeTimestamp: {
      label: 'Completed Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
    },
    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: '',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },
  },
};
